import { ReactNode } from "react";

interface ChatInputMenuContentProps {
  children: ReactNode;
}
const ChatInputMenuContent = ({ children }: ChatInputMenuContentProps) => {
  return <div className="chat-input-links me-md-2">{children}</div>;
};

export default ChatInputMenuContent;

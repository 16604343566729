import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

export interface DataTypes {
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  isFavorite: boolean;
}
interface InviteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: any) => void;
}
const InviteContactModal = ({
  isOpen,
  onClose,
  onInvite,
}: InviteContactModalProps) => {
  const { t } = useTranslation(["common", "contacts", "chats", "form"]);
  /*
  data input handeling
  */
  const [data, setData] = useState<DataTypes>({} as DataTypes);
  useEffect(() => {
    setData({
      phone: null,
      firstName: null,
      lastName: null,
      isFavorite: false,
    });
  }, []);

  const onChangeData = (
    field: "phone" | "firstName" | "lastName" | "isFavorite",
    value: string | boolean | null
  ) => {
    if (value === "" && field !== "isFavorite") {
      value = null;
    }
    setData(prev => ({ ...prev, [field]: value }));
  };

  /*
  validation
  */
  const [valid, setValid] = useState<boolean>(false);
  useEffect(() => {
    if (
      data.phone !== null &&
      isValidPhoneNumber(String(data.phone)) &&
      data.firstName !== null &&
      data.lastName !== null
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        {t(["common:button.add-contact"])}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
          <div className="mb-4">
            <Label htmlFor="AddContactModalPhone-input" className="form-label">
              {t(["common:profile.phone"])}
            </Label>
            <PhoneInput
              htmlFor="AddContactModalPhone-input"
              defaultCountry="BR"
              className="w-100"
              value={data["phone"] || undefined}
              onChange={(value: any) => {
                onChangeData("phone", value);
              }}
            />
          </div>

          <div className="d-sm-flex justify-content-between">
            <div className="mb-3">
              <Label
                htmlFor="AddContactModalFirstName-input"
                className="form-label"
              >
                {t(["common:profile.first-name"])}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="AddContactModalFirstName-input"
                placeholder={t(["form:placeholder.first-name"])}
                value={data["firstName"] || ""}
                onChange={(e: any) => {
                  onChangeData("firstName", e.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <Label
                htmlFor="AddContactModalLastName-input"
                className="form-label"
              >
                {t(["common:profile.last-name"])}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="AddContactModalLastName-input"
                placeholder={t(["form:placeholder.last-name"])}
                value={data["lastName"] || ""}
                onChange={(e: any) => {
                  onChangeData("lastName", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="my-4">
            <Label className="form-label">
              {t(["common:button.add-contact-to"])}
            </Label>
            <div className="d-flex gap-2">
              <Input
                type="checkbox"
                id="AddContactModalLastAvatar-input"
                placeholder="Enter avatar url..."
                checked={data["isFavorite"]}
                onChange={(e: any) => {
                  onChangeData("isFavorite", e.target.checked);
                }}
              />
              <span>{t(["chats:menu.favorites"])}</span>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          {t(["common:button.close"])}
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={!valid}
          onClick={() => onInvite(data)}
        >
          {t(["common:button.add"])}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;

import React from 'react';

interface UserAvatarProps {
    id: string;
    avatarUrl: string;
    fullName: string;
    isSelected: boolean;
    onClick: (id: string) => void;
}

const getInitials = (fullName: string) => {
    const names = fullName.split(' ');
    const initials = names.map(name => name[0]).join('');
    return initials.substring(0, 2).toUpperCase();
};

const UserAvatar: React.FC<UserAvatarProps> = ({ id, avatarUrl, fullName, isSelected, onClick }) => {
    const initials = getInitials(fullName);

    return (
        <div
            className={`user-avatar ${isSelected ? 'selected' : ''}`}
            onClick={() => onClick(id)}
            title={fullName}
        >
            {avatarUrl ? (
                <img src={avatarUrl} alt={fullName} className="avatar" />
            ) : (
                <div className="avatar placeholder">
                    {initials}
                </div>
            )}
        </div>
    );
};

export default UserAvatar;

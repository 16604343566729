import { TemplateMessageBody } from "./TemplateMessageBody";
import { TemplateMessageButton } from "./TemplateMessageButton";
import { TemplateMessageButtonGroup } from "./TemplateMessageButtonGroup";
import { TemplateMessageContent } from "./TemplateMessageContent";
import { TemplateMessageFooter } from "./TemplateMessageFooter";
import { TemplateMessageHeader } from "./TemplateMessageHeader";
import { TemplateMessageIcon } from "./TemplateMessageIcon";

export const TemplateMessage = {
  Root: TemplateMessageContent,
  Header: TemplateMessageHeader,
  Body: TemplateMessageBody,
  Footer: TemplateMessageFooter,
  ButtonGroup: TemplateMessageButtonGroup,
  Button: TemplateMessageButton,
  Icon: TemplateMessageIcon,
};

import React, { useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
import EditContactModal from "../../../components/EditContactModal";

interface BasicDetailsProps {
  chatUserDetails: any;
}
const BasicDetails = ({ chatUserDetails }: BasicDetailsProps) => {
  const { t } = useTranslation(["common", "profileDetails"]);

  const fullName = chatUserDetails.firstName
    ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
    : "-";


   const phone =
    chatUserDetails.id && chatUserDetails.platform === 0
      ? parsePhoneNumber(`+${chatUserDetails?.id}`)
      : null;
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const[contactIdState, setContactState] =useState("")
  function hadleContactId() {
    setContactState(chatUserDetails.id)
    setModalIsOpen(true)
  }
  function handleModalOnClose() {
    setModalIsOpen(false)
  }
  return (
    <>
    <div className="pb-2">
      <h5 className="font-size-11 text-uppercase mb-2">
        {t(["profileDetails:sidebar.info"])}
      </h5>
      <div>
        <div className="d-flex align-items-end">
          <div className="flex-grow-1">
            <p className="text-muted font-size-14 mb-1">
              {t(["common:profile.name"])}
            </p>
          </div>
          <div className="flex-shrink-0">
            <button onClick ={()=>hadleContactId()} type="button" className="btn btn-sm btn-soft-primary">
              {t(["common:button.edit"])}
            </button>
          </div>
        </div>
        <h5 className="font-size-14">{fullName}</h5>
      </div>

      <div className="mt-4">
        <p className="text-muted font-size-14 mb-1">
          {t(["common:profile.email"])}
        </p>
        <h5 className="font-size-14">
          {chatUserDetails.email ? chatUserDetails.email : "-"}
        </h5>
      </div>

      <div className="mt-4">
        <p className="text-muted font-size-14 mb-1">
          {chatUserDetails.platform === 0
            ? t(["common:profile.phone"])
            : "Profile"}
        </p>
        <div className="font-size-14 mb-0 d-flex gap-1 align-items-center">
          {phone ? (
            <>
              <img
                className="avatar-xs px-1"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${phone.country}.svg`}
                alt=""
              />
              <span>{formatPhoneNumberIntl(phone.number)}</span>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
      <EditContactModal isOpen={modalIsOpen} onClose={handleModalOnClose} contactId={contactIdState}  />      
    
    </>
  );
};

export default BasicDetails;

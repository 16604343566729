import { ReactNode } from "react";
import { Card } from "reactstrap";

interface FileItemProps {
  children: ReactNode;
}

export function FileItem({ children }: FileItemProps) {
  return (
    <Card className="p-2 border mb-2">
      <div className="d-flex align-items-center">{children}</div>
    </Card>
  );
}

import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import MyProfile from "./MyProfile";
import UserDescription from "./UserDescription";
import Media from "../../../components/Media";
import AttachedFiles from "../../../components/AttachedFiles";

// actions
import { getProfileDetails } from "../../../redux/actions";
import { Button } from "reactstrap";
import EditProfileModal from "../../../components/EditProfileModal";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { profileDetails, getProfileLoading, isProfileFetched, settings } =
    useAppSelector(state => ({
      settings: state.Settings.settings,
      profileDetails: state.Profile.profileDetails,
      getProfileLoading: state.Profile.getProfileLoading,
      isProfileFetched: state.Profile.isProfileFetched,
    }));

  // get user profile details
  useEffect(() => {
    dispatch(getProfileDetails());
  }, [dispatch]);

  /*
  add edit modal handling
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="position-relative">
      {getProfileLoading && !isProfileFetched && <Loader />}

      <MyProfile
        basicDetails={profileDetails.basicDetails}
        settings={settings}
      />

      <AppSimpleBar className="p-4 profile-desc">
        <div className="float-end">
          <Button
            color="none"
            type="button"
            className="btn btn-soft-primary btn-sm"
            onClick={openModal}
          >
            <i className="bx bxs-pencil align-middle"></i>
          </Button>
        </div>
        <UserDescription basicDetails={profileDetails.basicDetails} />

        <hr className="my-4" />

        <Media media={profileDetails.media} limit={2} />

        <hr className="my-4" />

        <AttachedFiles attachedFiles={profileDetails.attachedFiles} />
      </AppSimpleBar>
      <EditProfileModal isOpen={isOpen} onClose={closeModal} />
    </div>
  );
};

export default Index;

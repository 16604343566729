import React from "react";

//translations
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import classnames from "classnames";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  changeSelectedChat,
  changeSelectedCampaign,
  changeTab,
} from "../../../redux/actions";
import { CampaignProps, ContactCampaignProps } from "../../../data/analytics";
import { TABS } from "../../../constants";
import { Badge } from "reactstrap";

interface GroupProps {
  group: ContactCampaignProps;
}
const Group = ({ group }: GroupProps) => {
  const { t } = useTranslation(["common"]);

  // global store
  const { dispatch } = useRedux();

  const onSelectChat = (group: CampaignProps) => {
    dispatch(changeSelectedChat(null));
    dispatch(changeSelectedCampaign(group));
    dispatch(changeTab(TABS.ANALYTICS));
  };

  return (
    <li>
      <Link
        to="#"
        onClick={() =>
          onSelectChat({
            id: group.id,
            name: group.name,
            status: group.status,
          })
        }
        className="py-0 px-4"
      >
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              #
            </span>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <span className="text-truncate text-capitalize mb-0">
              {group.name}
            </span>
            <Badge
              className={classnames("mx-2", {
                "bg-primary": group.lead.WasContacted,
                "bg-danger": !group.lead.WasContacted,
              })}
            >
              {group.lead.WasContacted
                ? t(["common:options.contacted"])
                : t(["common:options.not-contacted"])}
            </Badge>
          </div>
        </div>
      </Link>
    </li>
  );
};
interface GroupsProps {
  campaigns: ContactCampaignProps[];
}
const Groups = ({ campaigns = [] }: GroupsProps) => {
  const { t } = useTranslation(["profileDetails", "notifications"]);

  const groups = campaigns.length;
  return (
    <div className="profile-groups">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h5 className="font-size-11 text-muted text-uppercase">
            {t(["profileDetails:sidebar.campaigns"])}
          </h5>
        </div>
      </div>

      {groups ? (
        <ul className="list-unstyled chat-list mx-n4">
          {(campaigns || []).map(group => (
            <Group group={group} key={group.id} />
          ))}
        </ul>
      ) : (
        <p>{t(["notifications:error.campaigns.not-exists"])}</p>
      )}
    </div>
  );
};

export default Groups;

// firestore
export const FIRESTORE_MAIN_COLLECTION = "tenants";

//auth
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const JWT_REGISTER = "/post-jwt-register";
export const POST_FAKE_REGISTER = "/post-fake-register";

export const USER_CHANGE_PASSWORD = "/user-change-password";

// profile & settings
export const GET_PROFILE_DETAILS = "/profile-details";
export const GET_USER_SETTINGS = "/user-settings";
export const UPDATE_ETTINGS = "/update-user-settings";
export const FIRESTORE_USERS_COLLECTION = "users";

// contacts
export const FIRESTORE_CONTACTS_COLLECTION = "contacts";
export const GET_CONTACTS = "/user-contacts";
export const INVITE_CONTACT = "/invite-contact";

// calls
export const GET_CALLS_LIST = "/calls-list";

// bookmarks
export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

// chats
export const GET_FAVOURITES = "/get-favourites";
export const GET_DIRECT_MESSAGES = "/get-direct-messages";
export const GET_CHANNELS = "/get-channels";
export const ADD_CONTACTS = "/add-contact";
export const CREATE_CHANNEL = "/create-channel";
export const GET_CHAT_USER_DETAILS = "/get-user-details";
export const GET_CHAT_USER_CONVERSATIONS = "/get-user-conversations";
export const SEND_MESSAGE = "/Message/SendTextMessage";
export const SEND_MESSAGE_MEDIA = "/Message/SendMediaMessage";
export const SEND_MESSAGE_MEDIA_UPLOAD = "/Media";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/delete-message";
export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";
export const GET_ROBOT_STATUS = "/Robot/status";
export const TOGGLE_ROBOT_STATUS = "/Robot/status/change";
export const FIRESTORE_MEDIA_HISTORY_COLLECTION = "mediahistory";
export const FIRESTORE_MESSAGES_COLLECTION = "messages";
export const FIRESTORE_TAGS_COLLECTION = "tags";

// groups
export const GET_CHANNEL_DETAILS = "/get-channel-details";

// analytics
export const GET_LIST_CAMPAIGNS = "/get-list-campaigns";
export const GET_SELECTED_CAMPAIGN_DATA = "/get-selected-campaign-data";
export const ANALYTICS_OVERVIEW_API = "/Analytics/Overview";
export const ANALYTICS_OVERVIEW_API_GENERAL = "/GeneralInfo";
export const ANALYTICS_OVERVIEW_API_MONTHLY = "/MontlyContacts";
export const ANALYTICS_OVERVIEW_API_TOP = "/TopCampaigns";
export const ANALYTICS_CAMPAIGN_API = "/Analytics/Campaign";
export const ANALYTICS_CAMPAIGN_API_STATUS = "/Status";
export const ANALYTICS_CAMPAIGN_API_MONTHLY_LEADS = "/MontlyLeads";
export const ANALYTICS_CAMPAIGN_API_CONCLUSION_PERCENTAGE =
  "/ConclusionPercentage";
export const ANALYTICS_CAMPAIGN_API_AIRLINE = "/Airline";
export const FIRESTORE_CAMPAIGNS_COLLECTION = "campaigns";
export const FIRESTORE_CAMPAIGNS_CONTACTS_COLLECTION = "contacts";

// notifications
export const FIRESTORE_NOTIFICATIONS_COLLECTION = "notifications";

// storage
export const STORAGE_IMAGES = "images";

// templates
export const GET_TEMPLATES = "/Message/TemplatesMessage";
export const SEND_TEMPLATE = "/Message/SendTemplateMessage";

// attendants
export const CREATE_ATTENDANTS = "/Attendant";
export const GET_ATTENDANTS = "/Attendant";
export const DELETE_ATTENDANTS = "/Attendant";

// kanban
export const FIRESTORE_BOARDS_COLLECTION = "boards";

import React, { Suspense, useEffect } from "react";
import { inject } from '@vercel/analytics';

// scss
import "./assets/scss/theme.scss";

// actions
import { useSelector, useDispatch } from "react-redux";
import { changelayoutMode } from "./redux/actions";

//Route
import Routes from "./routes";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

//api config
import config from "./config";

//init firebase backend
initFirebaseBackend(config.FIRE_BASE);

const App = () => {
  const dispatch = useDispatch();
  inject();

  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  // Dark/Light Mode
  useEffect(() => {
    var getLayoutMode = localStorage.getItem("layoutMode");
    if (getLayoutMode) {
      dispatch(changelayoutMode(getLayoutMode));
    } else {
      dispatch(changelayoutMode(layoutMode));
    }
  }, [layoutMode, dispatch]);

  return (
    <Suspense fallback={null}>
      <Routes />
    </Suspense>
  );
};

export default App;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { UncontrolledTooltip } from "reactstrap";

import { RobotStatus } from "../../../../components/RobotStatusButton";
import { Countdown } from "../../../../components/Countdown";
import { useRedux } from "../../../../hooks";
import { setChatStatus, toggleRobotStatus } from "../../../../redux/actions";
import { PlatformTypes } from "../../../../data";

interface ChatBotSectionProps {
  onClick?: () => void;
  className?: string;
}

const ChatBotSection = ({ onClick, className }: ChatBotSectionProps) => {
  const { t } = useTranslation(["conversation"]);

  const { dispatch, useAppSelector } = useRedux();

  const { isUserDetailsLoading, chatUserDetails, chatStatus, robotStatus } =
  useAppSelector(
    useMemo(() => (state) => ({
      isUserDetailsLoading: state.Chats.getUserDetailsLoading,
      chatUserDetails: state.Chats.chatUserDetails,
      chatStatus: state.Chats.chatStatus,
      robotStatus: state.Chats.robotStatus,
    }), [])
  );


  function handleOnClickRobotStatus() {
    if (isUserDetailsLoading) return;
    if (onClick) onClick();
    if (!chatStatus.isActive) return;
    dispatch(toggleRobotStatus(robotStatus.contactId, !robotStatus.isStopped));
  }

  function handleOnChatStatusExpireTime() {
    dispatch(
      setChatStatus({
        ...chatStatus,
        isActive: false,
      })
    );

    if (!robotStatus.isStopped) {
      return;
    }
    dispatch(toggleRobotStatus(chatUserDetails.id, false));
  }

  function handleOnRobotStatusExpireTime() {
    if (!robotStatus.isStopped) {
      return;
    }
    dispatch(toggleRobotStatus(chatUserDetails.id, false));
  }

  return (
    <RobotStatus.Root
      className={classnames("position-relative", className, {
        "pt-2":
          chatStatus.isActive &&
          chatUserDetails.platform === PlatformTypes.whatsApp,
      })}
    >
      {chatUserDetails.platform === PlatformTypes.whatsApp &&
      !chatStatus.isActive ? (
        <>
          <RobotStatus.Button
            id="chatbot-blocked-button"
            onClick={handleOnClickRobotStatus}
            color="info"
          >
            <i className={classnames("bx", "bx-mail-send no-text")} />
          </RobotStatus.Button>

          <UncontrolledTooltip
            placement="top-start"
            target="chatbot-blocked-button"
            innerClassName="p-3"
          >
            {t(["conversation:bot.blocked"])}
          </UncontrolledTooltip>
        </>
      ) : null}

      {chatStatus.isActive && !robotStatus.isStopped ? (
        <>
          <RobotStatus.Button
            id="chatbot-disabled-button"
            onClick={handleOnClickRobotStatus}
            color="danger"
          >
            <i className={classnames("bx", "bx-stop")} />

            <RobotStatus.ButtonText>
              {t(["conversation:bot.stop"])}
            </RobotStatus.ButtonText>
          </RobotStatus.Button>
        </>
      ) : null}

      {chatStatus.isActive && robotStatus.isStopped ? (
        <>
          <RobotStatus.Button
            id="chatbot-enabled-button"
            onClick={handleOnClickRobotStatus}
            color="primary"
          >
            <i className={classnames("bx", "bx-play")} />

            <RobotStatus.ButtonText>
              {t(["conversation:bot.start"])}
            </RobotStatus.ButtonText>
          </RobotStatus.Button>

          <UncontrolledTooltip
            placement="top-start"
            target="chatbot-enabled-button"
            innerClassName="p-3"
          >
            {robotStatus.expirationTime ? (
              <Countdown
                expirationDate={robotStatus.expirationTime}
                className="text-primary font-size-16 text-decoration-underline"
                onExpire={handleOnRobotStatusExpireTime}
              />
            ) : null}
            {t(["conversation:bot.auto-start"])}
          </UncontrolledTooltip>
        </>
      ) : null}

      {chatStatus.isActive && chatStatus.expirationTime ? (
        <Countdown
          expirationDate={chatStatus.expirationTime}
          childrenPosition="right"
          onExpire={handleOnChatStatusExpireTime}
          className="text-secondary font-size-12 pb-1"
        >
          <i
            id="countdown-timer-info"
            className="bx bxs-info-circle cursor-pointer"
            style={{ fontSize: ".8rem" }}
          />
          <UncontrolledTooltip
            placement="top-start"
            target="countdown-timer-info"
            innerClassName="p-3"
          >
            {t(["conversation:bot.unblocked"])}
          </UncontrolledTooltip>
        </Countdown>
      ) : null}
    </RobotStatus.Root>
  );
};

export default ChatBotSection;

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

// import classnames from "classnames";

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from "reactstrap";

import { STATUS_TYPES } from "../constants";
import { useRedux } from "../hooks/index";
import {
  getProfileDetails,
  getSettings,
  updateProfileDetails,
} from "../redux/actions";

import Loader from "./Loader";
import InputCountrySelect from "./InputCountrySelect";

export interface DataTypes {
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  description: string | null;
  location: string | null;
  status: STATUS_TYPES;
}
interface EditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const EditProfileModal = ({ isOpen, onClose }: EditProfileModalProps) => {
  const { t } = useTranslation(["common", "chats", "form"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    profileDetails,
    getProfileLoading,
    isProfileFetched,
    isProfileDetailsLoading,
    isProfileDetailsUpdated,
  } = useAppSelector(state => ({
    profileDetails: state.Profile.profileDetails,
    getProfileLoading: state.Profile.getProfileLoading,
    isProfileFetched: state.Profile.isProfileFetched,
    isProfileDetailsLoading: state.Profile.isProfileDetailsLoading,
    isProfileDetailsUpdated: state.Profile.isProfileDetailsUpdated,
  }));
  
  const [data, setData] = useState<DataTypes>({} as DataTypes);
  useEffect(() => {
    setData({
      firstName: null,
      lastName: null,
      title: null,
      description: null,
      location: null,
      status: STATUS_TYPES.ACTIVE,
    });
  }, []);

  useEffect(() => {
    if (profileDetails && profileDetails.basicDetails) {
      setData({
        firstName: profileDetails.basicDetails.firstName,
        lastName: profileDetails.basicDetails.lastName,
        title: profileDetails.basicDetails.title,
        description: profileDetails.basicDetails.description,
        location: profileDetails.basicDetails.location,
        status: String(
          profileDetails.basicDetails.status
        ).toLowerCase() as STATUS_TYPES,
      });
    }
  }, [profileDetails, isOpen]);


const validFields = [
  "firstName",
  "lastName",
  "title",
  "description",
  "location",
  "status",
] as const;

const [valid, setValid] = useState<boolean>(false);

const validateField = (
  field: typeof validFields[number],
  value: string | boolean | null
) => {


  const requiredFields = ["firstName", "lastName", "status"];
  if (requiredFields.includes(field) && data[field] === null) {
    return false;
  }

  return value !== profileDetails.basicDetails[field] ? true : false;
};

const onChangeData = (
  field: typeof validFields[number],
  value: string | boolean | null
) => {
  setData(prev => ({ ...prev, [field]: value }));
  setValid(validateField(field, value));
};

  function handleSubmit() {
    if (!valid) return;
    dispatch(updateProfileDetails(data));
  }

  useEffect(() => {
    if (isProfileDetailsUpdated) {
      dispatch(getProfileDetails());
      dispatch(getSettings());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileDetailsUpdated]);

  /*
    drop down state
    */
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        {t(["common:button.edit-profile"])}
      </ModalHeader>
      <ModalBody className="p-4">
        {(getProfileLoading && !isProfileFetched) ||
        (isProfileDetailsLoading && !isProfileDetailsUpdated) ? (
          <Loader />
        ) : null}
        <Form>
          <div className="d-sm-flex justify-content-between">
            <div className="mb-3">
              <Label
                htmlFor="EditProfileModalFirstName-input"
                className="form-label"
              >
                {t(["common:profile.first-name"])}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="EditProfileModalFirstName-input"
                placeholder={t(["form:placeholder.first-name"])}
                value={data["firstName"] || ""}
                onChange={(e: any) => {
                  onChangeData("firstName", e.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <Label
                htmlFor="EditProfileModalLastName-input"
                className="form-label"
              >
                {t(["common:profile.last-name"])}
              </Label>
              <Input
                type="text"
                className="form-control"
                id="EditProfileModalLastName-input"
                placeholder={t(["form:placeholder.last-name"])}
                value={data["lastName"] || ""}
                onChange={(e: any) => {
                  onChangeData("lastName", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="mb-4">
            <Label htmlFor="EditProfileModalTitle-input" className="form-label">
              {t(["common:profile.title"])}
            </Label>
            <Input
              type="text"
              className="form-control"
              id="EditProfileModalTitle-input"
              placeholder={t(["form:placeholder.title"])}
              value={data["title"] || ""}
              onChange={(e: any) => {
                onChangeData("title", e.target.value);
              }}
            />
          </div>

          <div className="mb-4">
            <Label
              htmlFor="EditProfileModalDescription-input"
              className="form-label"
            >
              {t(["common:profile.description"])}
            </Label>
            <Input
              type="textarea"
              className="form-control"
              id="EditProfileModalDescription-input"
              placeholder={t(["form:placeholder.description"])}
              value={data["description"] || ""}
              onChange={(e: any) => {
                onChangeData("description", e.target.value);
              }}
            />
          </div>

          <div className="mb-4">
            <Label
              htmlFor="EditProfileModalLocation-input"
              className="form-label"
            >
              {t(["common:profile.location"])}
            </Label>
            <InputCountrySelect
              id="EditProfileModalLocation-input"
              value={data["location"] || ""}
              className="form-control"
              onChange={e => {
                onChangeData("location", e.target.value);
              }}
            />
          </div>

          {/* <div className="my-4">
            <Label
              htmlFor="EditProfileModalStatus-input"
              className="form-label"
            >
              {t(["common:profile.status"])}:
            </Label>
            <Dropdown
              id="AddContactModalStatus-input"
              className="d-inline-block ms-2"
              isOpen={dropdownOpen}
              toggle={toggle}
            >
              <DropdownToggle
                tag="a"
                className="text-muted d-flex align-items-center gap-2"
                role="button"
              >
                <i
                  className={classnames(
                    "bx",
                    "bxs-circle",
                    "font-size-10",
                    "align-middle",
                    { "text-success": data.status === STATUS_TYPES.ACTIVE },
                    { "text-warning": data.status === STATUS_TYPES.AWAY },
                    {
                      "text-danger":
                        data.status === STATUS_TYPES.DO_NOT_DISTURB,
                    },
                    {
                      "text-secondary": data.status === STATUS_TYPES.INACTIVE,
                    }
                  )}
                ></i>{" "}
                {t(`common:options.${data.status}` as const)}
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem
                  onClick={() => onChangeData("status", STATUS_TYPES.ACTIVE)}
                >
                  <i className="bx bxs-circle text-success font-size-10 me-1 align-middle"></i>{" "}
                  {t(["common:options.active"])}
                </DropdownItem>
                <DropdownItem
                  onClick={() => onChangeData("status", STATUS_TYPES.AWAY)}
                >
                  <i className="bx bxs-circle text-warning font-size-10 me-1 align-middle"></i>{" "}
                  {t(["common:options.away"])}
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    onChangeData("status", STATUS_TYPES.DO_NOT_DISTURB)
                  }
                >
                  <i className="bx bxs-circle text-danger font-size-10 me-1 align-middle"></i>{" "}
                  {t(["common:options.not-disturb"])}
                </DropdownItem>
                <DropdownItem
                  onClick={() => onChangeData("status", STATUS_TYPES.INACTIVE)}
                >
                  <i className="bx bxs-circle text-secondary font-size-10 me-1 align-middle"></i>{" "}
                  {t(["common:options.inactive"])}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          {t(["common:button.close"])}
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={
            !valid || getProfileLoading || isProfileDetailsLoading
              ? true
              : false
          }
          onClick={() => handleSubmit()}
        >
          {t(["common:button.save"])}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditProfileModal;

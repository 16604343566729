import { ReactNode } from "react";

interface FileItemIconProps {
  children: ReactNode;
}

export function FileItemIcon({ children }: FileItemIconProps) {
  return (
    <div className="flex-shrink-0 avatar-xs ms-1 me-3">
      <div className="avatar-title bg-soft-primary text-primary rounded-circle">
        {children}
      </div>
    </div>
  );
}

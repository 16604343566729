import * as url from "./urls";
import { getFirstLastNameOrPhone } from "../utils/getFirstLastName";
import { ContactTypes, ContactTypesBackend, PlatformTypes } from "../data";
import { STATUS_TYPES } from "../constants/settings";
import {
  GetFirestoreDocProps,
  UpdateFirestoreFieldProps,
  setFirestoreDoc,
  getFirestoreDocs,
  checkFirestoreDocExists,
  deleteFirestoreDoc,
  updateFirestoreDocField,
} from "./apiFirestore";
import { DataTypes } from "../components/InviteContactModal";
import { format } from "date-fns";

const getContacts = async (filters: Pick<GetFirestoreDocProps, "filter">) => {
  const params: GetFirestoreDocProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION],
    filter: filters?.filter,
  };

  const response = await getFirestoreDocs(params);
  const data: ContactTypes = response.map((doc: ContactTypesBackend) => {
    const { firstName, lastName } = getFirstLastNameOrPhone(
      doc.Name,
      doc.PhoneNumber
    );

    return {
      id: doc.id,
      firstName,
      lastName,
      profileImage: doc.AvatarUrl ? doc.AvatarUrl : undefined,
      about: undefined,
      email: undefined,
      location: undefined,
      channels: undefined,
      media: undefined,
      attachedFiles: undefined,
      status: STATUS_TYPES.ACTIVE,
      isFavourite: doc.IsFavorite,
      isArchived: false,
      platform: doc.Platform ? doc.Platform : PlatformTypes.whatsApp,
      phoneNumber:
        doc.Platform !== PlatformTypes.instagram &&
        doc.Platform !== PlatformTypes.messenger
          ? doc.PhoneNumber
          : undefined,
    } as ContactTypes;
  });

  return data;
};

const inviteContact = async (data: DataTypes) => {
  if (!data.phone) {
    return Promise.reject("notifications:error.contatcs.invalid-number");
  }

  const parsedPhone = data.phone?.replace("+", "");
  const paths = [url.FIRESTORE_CONTACTS_COLLECTION, String(parsedPhone)];

  const doc = await checkFirestoreDocExists({ paths });
  if (doc?.exist === true) {
    return Promise.reject("notifications:error.contatcs.repeat-number");
  }

  const params: UpdateFirestoreFieldProps = {
    paths,
    data: {
      AvatarUrl: null,
      IsFavorite: data.isFavorite,
      IsArchived: false,
      LastMessageAt: format(Date.now(), "dd/MM/yyy HH:mm"),
      LastMessagePreview: null,
      Name: data.firstName + " " + data.lastName,
      PhoneNumber: parsedPhone,
      UnreadCount: 0,
    },
  };

  const response = await setFirestoreDoc(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.contatcs.new-contact"]);
    }
    if (response.ok) {
      resolve(["notifications:success.contacts.add"]);
    } else {
      resolve(["notifications:error.contatcs.new-contact/some-error"]);
    }
  });
};
const updateContact = async (user: {
  id: string | number;
  data: string;
}) => {
  
  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(user.id)],
    data: {
      Name: user.data,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => { 
    if (response.error) {
      console.log("response.error",response.error)
      reject(["notifications:error.contacts.update-contact"]);
    }

    if (!user.data) {
      resolve(["notifications:error.contacts.update-contact"]);
    } else {
      resolve(["notifications:success.contacts.update"]);
    }
  });
};
const deleteContact = async (id: string | number) => {
  if (!id) {
    return Promise.reject("notifications:error.contatcs.invalid-number");
  }

  const paths = [url.FIRESTORE_CONTACTS_COLLECTION, String(id)];

  const response = await deleteFirestoreDoc({ paths });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.contatcs.remove-contact"]);
    }
    if (response.ok) {
      resolve(["notifications:success.contatcs.remove"]);
    } else {
      resolve(["notifications:error.contatcs.remove-contact/some-error"]);
    }
  });
};

export { getContacts, inviteContact, deleteContact,updateContact };

import React from "react";
import AvatarName from "../AvatarName";
import classnames from "classnames";

import { formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextAreaComponent } from '@syncfusion/ej2-react-inputs';
import { getFirstLastName } from "../../utils";
import { STATUS_TYPES, TABS } from "../../constants";
import { AttendantProps } from "../../data/attendants";
import { TagProps } from "reactstrap";
import { KanbanDataModel } from "../../data/kanban";
import { useRedux } from "../../hooks";
import { changeSelectedChat, changeTab, getChatUserConversations, getChatUserDetails, readConversation } from "../../redux/actions";
import { KanbanComponent } from "@syncfusion/ej2-react-kanban";
import CommentSession from "./CommentSection";

interface KanbanDialogFormTemplateProps {
    props: KanbanDataModel;
    attendants: Array<AttendantProps>;
    tags: Array<TagProps>;
    columns: Array<string>
    kanbanRef: React.RefObject<KanbanComponent>;
}

const KanbanDialogFormTemplate = ({ props, attendants, tags, columns, kanbanRef }: KanbanDialogFormTemplateProps) => {
    const { dispatch } = useRedux();

    const isOnline = STATUS_TYPES.ACTIVE;

    const phone =
        props.contactId && props.platform === 0
            ? parsePhoneNumber(`+${props?.contactId}`)
            : null;

    const { firstName, lastName } = getFirstLastName(props.title as string);

    const user = {
        id: props.contactId as string,
        platform: props.platform as number,
        firstName: firstName,
        lastName: lastName,
        tags: props.tags as string[],
        assignedAttendant: props.assignee as string
    }

    function handleClickContact(userId: string) {
        dispatch(getChatUserDetails(userId));
        dispatch(changeSelectedChat(user?.id));
        dispatch(getChatUserConversations(user?.id));
        dispatch(readConversation(user?.id));
        dispatch(changeTab(TABS.CHAT));
        if (kanbanRef.current) {
            kanbanRef.current.closeDialog();
        }
    }

    const assigneeData = attendants.map(attendant => attendant.fullName);
    const fieldsTags = { text: 'Name', value: 'id' };

    let statusData: string[] = columns;
    let priorityData: string[] = ['Baixa', 'Normal', 'Alta', 'Crítica'];

    return (
        <div>
            <div className="e-card-header-title" style={{ float: "left", paddingBottom: "30px" }}>
                <div className={classnames("chat-user-img", { online: isOnline }, "chat-user-img2")}>
                    <AvatarName
                        className="avatar-sm font-size-12"
                        user={user}
                        showPlatform
                        onClick={() => handleClickContact(user.id)}
                    />
                </div>
                <div className="bold-dark-gray-text e-tooltip-text">
                    <span onClick={() => handleClickContact(user.id)} className="span-title-kanban">{props.title}</span>
                </div>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td className="e-label">Telefone</td>
                        <td>
                            <div className="font-size-14 mb-0 d-flex gap-1 align-items-center">
                                {phone ? (
                                    <>
                                        <img
                                            className="avatar-xs px-1"
                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${phone.country}.svg`}
                                            alt=""
                                        />
                                        <span onClick={() => handleClickContact(user.id)} className="span-telefone-kanban">{formatPhoneNumberIntl(phone.number)}</span>
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-label">Status</td>
                        <td>
                            <DropDownListComponent id='Status' name="status" dataSource={statusData} className="e-field" placeholder='Status' value={props.status}></DropDownListComponent>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-label">Atendente</td>
                        <td>
                            <DropDownListComponent id='Assignee' name="assignee" className="e-field" dataSource={assigneeData} placeholder='Assignee' value={props.assignee}></DropDownListComponent>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-label">Prioridade</td>
                        <td>
                            <DropDownListComponent type="text" name="priority" id="Priority" popupHeight='300px' className="e-field" value={props.priority} dataSource={priorityData} placeholder='Priority'></DropDownListComponent>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-label">Rótulos</td>
                        <td>
                            <MultiSelectComponent name="tags" id="Tags" popupHeight='300px' mode="Box" className="e-field" fields={fieldsTags} value={props.tags} dataSource={tags} placeholder='Rótulos'></MultiSelectComponent>
                        </td>
                    </tr>
                    <tr>
                        <td className="e-label">Descrição</td>
                        <td>
                            <div className="e-float-input e-control-wrapper">
                                <TextAreaComponent name="summary" className="e-field custom-selection" value={props.summary}></TextAreaComponent >
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <CommentSession props={props} />
        </div>
    );
}

export default KanbanDialogFormTemplate;

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { AttendantsActionTypes } from "./types";
import {
  attendantsApiResponseSuccess,
  attendantsApiResponseError,
} from "./actions";

import { getAttendants as getAttendantsApi } from "../../api/index";

// helpers
// import {
//   showSuccessNotification,
//   showErrorNotification,
// } from "../../helpers/notifications";

function* getAttendants() {
  try {
    const response: Promise<any> = yield call(getAttendantsApi);
    yield put(
      attendantsApiResponseSuccess(
        AttendantsActionTypes.GET_ATTENDANTS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      attendantsApiResponseError(AttendantsActionTypes.GET_ATTENDANTS, error)
    );
  }
}

export function* watchGetAttendants() {
  yield takeEvery(AttendantsActionTypes.GET_ATTENDANTS, getAttendants);
}

function* analyticsSaga() {
  yield all([fork(watchGetAttendants)]);
}

export default analyticsSaga;

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ProfileActionTypes } from "./types";
import { profileApiResponseSuccess, profileApiResponseError } from "./actions";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

import {
  getProfileDetails as getProfileDetailsApi,
  updateProfileDetails as updateProfileDetailsApi,
  uploadProfilePhoto as uploadProfilePhotoApi,
  uploadProfileCover as uploadProfileCoverApi,
} from "../../api/index";

function* getProfileDetails() {
  try {
    const response: Promise<any> = yield call(getProfileDetailsApi);
    yield put(
      profileApiResponseSuccess(
        ProfileActionTypes.GET_PROFILE_DETAILS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      profileApiResponseError(ProfileActionTypes.GET_PROFILE_DETAILS, error)
    );
  }
}

function* updateProfileDetails({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateProfileDetailsApi, data);
    yield put(
      profileApiResponseSuccess(
        ProfileActionTypes.UPDATE_PROFILE_DETAILS,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      profileApiResponseError(ProfileActionTypes.UPDATE_PROFILE_DETAILS, error)
    );
  }
}

function* uploadProfilePhoto({ payload: { image } }: any) {
  try {
    const response: Promise<any> = yield call(uploadProfilePhotoApi, image);
    yield put(
      profileApiResponseSuccess(
        ProfileActionTypes.UPLOAD_PROFILE_PHOTO,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      profileApiResponseError(ProfileActionTypes.UPLOAD_PROFILE_PHOTO, error)
    );
  }
}

function* uploadProfileCover({ payload: { image } }: any) {
  try {
    const response: Promise<any> = yield call(uploadProfileCoverApi, image);
    yield put(
      profileApiResponseSuccess(
        ProfileActionTypes.UPLOAD_PROFILE_COVER,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      profileApiResponseError(ProfileActionTypes.UPLOAD_PROFILE_COVER, error)
    );
  }
}

export function* watchGetProfileDetails() {
  yield takeEvery(ProfileActionTypes.GET_PROFILE_DETAILS, getProfileDetails);
}

export function* watchUpdateProfileDetails() {
  yield takeEvery(
    ProfileActionTypes.UPDATE_PROFILE_DETAILS,
    updateProfileDetails
  );
}

export function* watchUploadProfilePhoto() {
  yield takeEvery(ProfileActionTypes.UPLOAD_PROFILE_PHOTO, uploadProfilePhoto);
}

export function* watchUploadProfileCover() {
  yield takeEvery(ProfileActionTypes.UPLOAD_PROFILE_COVER, uploadProfileCover);
}

function* profileSaga() {
  yield all([
    fork(watchGetProfileDetails),
    fork(watchUpdateProfileDetails),
    fork(watchUploadProfilePhoto),
    fork(watchUploadProfileCover),
  ]);
}

export default profileSaga;

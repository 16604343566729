export enum ContactsActionTypes {
  API_RESPONSE_SUCCESS = "@@contacts/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contacts/API_RESPONSE_ERROR",

  GET_CONTACTS = "@@contacts/GET_CONTACTS",
  RESET_CONTACTS = "@@contacts/RESET_CONTACTS",
  INVITE_CONTACT = "@@contacts/INVITE_CONTACT",
  DELETE_CONTACT = "@@contacts/DELETE_CONTACT",
  UPDATE_CONTACT_DETAILS = "@@contacts/UPDATE_CONTACT_DETAILS",
}
export interface ContactsState {
  contacts: Array<any>;
  isContactsFetched: boolean | null;
  getContactsLoading: boolean;
  isContactDeleted: boolean | null;
  isContactDeletedLoading: boolean | null;
  isContactUpdated: boolean | null;
  isContactUpdatedLoading: boolean | null;
}

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { i18nLanguagesProps } from "./i18next";

export const i18nLanguages: i18nLanguagesProps[] = [
  { key: "en", nativeName: "English" },
  { key: "pt", nativeName: "Português" },
];
const fallbackLng = "en";
const supportedLngs = i18nLanguages.map(({ key }) => key);
export const defaultNS = "common";

i18next
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng,
    supportedLngs,
    defaultNS,
  });

export default i18next;

import React, { useState } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { AttendantProps } from '../../data/attendants';
import { format } from 'date-fns';
import { CommentProps } from '../../redux/kanban/types';

interface CommentSectionProps {
  comment: CommentProps;
  currentUser: string;
  attendants: AttendantProps[];
  onDelete: () => void;
}

interface CommentBoxProps {
  onAddComment: (message: string) => void;
}

const getInitials = (name: string) => {
  const initials = name.split(' ').map(part => part[0]).join('');
  return initials.substring(0, 2).toUpperCase();
};

const formatDate = (timestamp) => {
  const { seconds, nanos } = timestamp;
  const milliseconds = (seconds * 1000) + (nanos / 1000000);
  const date = new Date(milliseconds);
  
  return format(date, 'dd/MM/yyyy HH:mm');
}

const Comment: React.FC<CommentSectionProps> = ({ comment, currentUser, attendants ,onDelete }) => {

  const isCurrentUser = comment.attendantId === currentUser;
  const attendant = attendants.find(attendant => attendant.id === comment.attendantId);
  const formattedDate = formatDate(comment.createdAt);

  return (
    <div className="comment">
      {attendant.avatarUrl ? (
        <img src={attendant.avatarUrl} alt={`${attendant.fullName}'s avatar`} className="comment__avatar" />
      ) : (
        <div className="comment__avatar comment__avatar--placeholder">
          {getInitials(attendant.fullName)}
        </div>
      )}
      <div className="comment__content">
        <div className="comment__header">
          <span className="comment__user">{attendant.fullName}</span>
          <span className="comment__time">{formattedDate}</span>
        </div>
        <div className="comment__message">{comment.message}</div>
        {isCurrentUser && (
           <ButtonComponent
            cssClass="e-flat comment__delete"
            iconCss="e-icons e-delete-1"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};

const CommentBox: React.FC<CommentBoxProps> = ({ onAddComment }) => {
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if (message.trim()) {
      onAddComment(message);
      setMessage('');
    }
  };

  return (
    <div className="comment-box">
      <textarea
        className="comment-box__textarea"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Escreva um comentário..."
      />
      <button className="comment-box__button" onClick={handleSubmit}>Comentar</button>
    </div>
  );
};

export { Comment, CommentBox };

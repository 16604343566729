import React, { HtmlHTMLAttributes } from "react";
import classnames from "classnames";
import { UncontrolledTooltip } from "reactstrap";

interface AvatarAttendantProps extends HtmlHTMLAttributes<HTMLDivElement> {
  attendant: { id: string; avatarUrl: string; fullName: string } | null;
  showArrow?: boolean;
  isGroup?: boolean;
  tooltip?: boolean;
}
const AvatarAttendant = ({
  attendant,
  showArrow = false,
  isGroup = false,
  tooltip = false,
  ...rest
}: AvatarAttendantProps) => {
  return (
    <>
      <div {...rest} id={attendant.id}>
        <span
          className={classnames(
            "avatar-title rounded-circle border border-light"
          )}
          style={{
            background: attendant.avatarUrl
              ? `url(${attendant.avatarUrl}) center/cover no-repeat`
              : "#DBDBDC",
          }}
        >
          {!attendant.avatarUrl && !isGroup ? (
            <i className="bx bxs-user font-size-10 text-white" />
          ) : null}

          {!attendant.avatarUrl && isGroup ? (
            <i className="bx bxs-group font-size-10 text-white" />
          ) : null}
        </span>
      </div>

      {showArrow ? (
        <i
          className={classnames("bx bx-chevron-right", {})}
          style={{ color: "#DBDBDC" }}
        />
      ) : null}

      {tooltip ? (
        <UncontrolledTooltip placement="top" target={attendant.id}>
          {attendant.fullName}
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

export default AvatarAttendant;

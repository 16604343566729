import classNames from "classnames";
import { HtmlHTMLAttributes } from "react";

interface TemplateMessageContentProps
  extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}
export function TemplateMessageContent({
  children,
  className,
  ...rest
}: TemplateMessageContentProps) {
  return (
    <div
      className={classNames(
        "modal-templates bg-light rounded overflow-hidden mt-2 mb-4 font-size-11",
        className
      )}
      style={{ border: "1px solid #EAEAF0" }}
      {...rest}
    >
      {children}
    </div>
  );
}

import { useState, useRef } from 'react';
import { Button} from "reactstrap";
interface MediaItemPreviewAudioProps {
  url: string;
  mimeType: string;
}

export const MediaItemPreviewAudio = ({
  url,
  mimeType,
}: MediaItemPreviewAudioProps) => {
  
  const audioRef = useRef(null);
  const inputRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);


  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
      inputRef.current.max = audioRef.current.duration
      inputRef.current.step = 0.01
    }
    setIsPlaying(!isPlaying);
  };
   if (isPlaying) {
        setInterval(()=>{
          if (audioRef.current) {
             inputRef.current.value = audioRef.current.currentTime
          }
        },100)
   }
   
  return ( 
    <div className="inputRangeAudio links-list-item  d-sm-block">
     { !isPlaying ? 
     <Button 
      color="none"
      type="button"
      className="btn btn-link text-decoration-none btn-lg"
      id="audio-btn" 
      onClick={handlePlayPause}>

            <i className="bx bx-play align-middle "></i>
      </Button> 
      :
      <Button 
      color="none"
      type="button"
      className="btn btn-link text-decoration-none btn-lg waves-effect"
      id="audio-btn" 
      onClick={handlePlayPause}>

            <i className="bx bx-pause align-middle "></i>
      </Button> 
          }
      <input  className="  align-middle " ref={inputRef} type="range" value={0} />
      <audio ref={audioRef} preload="metadata">
      <source src={url} type={mimeType} />
    </audio>
    </div>
   
  );
};

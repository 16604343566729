import axios from "axios";

const isValidImageURL = async (url: string) => {
  if (!url) return false;

  try {
    const response = await axios.get(url);
    return response.status !== 404;
  } catch (error) {
    return false;
  }
};

export { isValidImageURL };

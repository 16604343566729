import { ReactNode } from "react";
import { Modal, ModalBody } from "reactstrap";

interface MediaItemPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export function MediaItemPreviewModal({
  onClose,
  isOpen,
  children,
}: MediaItemPreviewModalProps) {
  const externalCloseBtn = (
    <div className="ril__toolbar justify-content-end">
      <ul className="ril-toolbar-right ril__toolbarSide ril__toolbarRightSide">
        <li className="ril-toolbar__item ril__toolbarItem">
          <button
            type="button"
            aria-label="Close lightbox"
            title="Close lightbox"
            className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
            onClick={onClose}
          />
        </li>
      </ul>
    </div>
  );

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          tabIndex={-1}
          centered
          external={externalCloseBtn}
          backdropClassName="modal-item-preview-backdrop"
          className="modal-item-preview"
        >
          <ModalBody>
            <div className="message-img-list d-flex justify-content-center">
              {children}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

import React from "react";

//translations
import { useTranslation } from "react-i18next";

// component
import Campaign from "./Campaign";
import { CampaignProps } from "../../../data/analytics";
interface CampaignsProps {
  campaigns: CampaignProps[];
  selectedCampaignId: string | number;
  onSelectedCampaign: (campaign: CampaignProps) => void;
}
const Campaigns = ({
  campaigns,
  selectedCampaignId,
  onSelectedCampaign,
}: CampaignsProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <h5 className="mb-3 px-4 mt-4 font-size-11 text-muted text-uppercase">
        {t(["common:options.active"])}
      </h5>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {campaigns.map(campaign => (
            <Campaign
              campaign={campaign}
              key={campaign.id}
              selectedCampaignId={selectedCampaignId}
              onSelectedCampaign={onSelectedCampaign}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Campaigns;

import React from "react";
//translations
import { useTranslation } from "react-i18next";

interface StatusSectionProps {
  rank: number;
  title: string;
  value: string | number;
  isLast?: boolean;
}

export const TopCampaignSectionItem = ({
  rank,
  title,
  value,
  isLast,
}: StatusSectionProps) => {
  const { t } = useTranslation(["analytics"]);

  return (
    <li
      className={`list-group-item col-auto p-0 d-flex justify-content-between py-1 px-1 border-primary border-0  rounded-0 ${
        !isLast && "border-bottom"
      }`}
    >
      <div>
        <span>{String(rank + 1)}.</span>
        <span className="px-2 text-capitalize">{title}</span>
      </div>
      <div>
        {value} {t(["analytics:overview.table.leads"])}
      </div>
    </li>
  );
};

import { toast } from "react-toastify";
import i18next from "../i18n/config";

function getValidTranslation(message:string) {
  return i18next.exists(message) ? i18next.t(message as any) : i18next.t("notifications:error.toast.invalid-translation")
}

const showSuccessNotification = (message: string) => {
  toast.success(getValidTranslation(message));
};

const showErrorNotification = (error: string) => {
  toast.error(getValidTranslation(error));
};

export { showSuccessNotification, showErrorNotification };

import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Badge,
} from "reactstrap";

import { MessageProps } from "../data/templates";
import { TemplateMessageSection } from "./TemplateMessageSection";
import { useRedux } from "../hooks";
import { sendTemplate } from "../redux/actions";
import { TypeOfMessage } from "../data";

interface TemplatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: MessageProps;
}

const TemplatesModal = ({
  isOpen,
  onClose,
  message = null,
}: TemplatesModalProps) => {
  const { t } = useTranslation(["common", "templates", "form"]);

  const { dispatch, useAppSelector } = useRedux();

  const { isTemplatesSent, chatUserDetails, profileDetails, chatStatus } =
    useAppSelector(state => ({
      isTemplatesSent: state.Templates.isTemplatesSent,
      chatUserDetails: state.Chats.chatUserDetails,
      profileDetails: state.Profile.profileDetails,
      chatStatus: state.Chats.chatStatus,
    }));

  const [submitDisabled, setSubmitDisabled] = useState(true);

  function handleConfirmationInput(e: ChangeEvent<HTMLInputElement>) {
    if (e.target) setSubmitDisabled(!e.target.checked);
  }

  function onSubmit() {
    if (submitDisabled) return;
    if (!message || !profileDetails?.basicDetails || !chatUserDetails.id) {
      return;
    }

    dispatch(
      sendTemplate({
        recipientPhoneNumber: chatUserDetails.id,
        templateName: message.name,
        language: message.language,
        attendantName: profileDetails?.basicDetails?.fullName,
        type: TypeOfMessage.template,
      })
    );

    setSubmitDisabled(true);
  }

  useEffect(() => {
    if (chatStatus.status === "disabled") onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatStatus.status]);

  useEffect(() => {
    if (isTemplatesSent) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplatesSent]);

  return (
    message && (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        tabIndex={-1}
        centered
        className="contactModal"
      >
        <ModalHeader className="modal-title-custom" toggle={onClose}>
          {t(["templates:menu.templates"])}
        </ModalHeader>
        <ModalBody className="p-4">
          <div>
            <small>{message.category}</small>
            <div className="d-flex">
              <h6 className="text-uppercase">{message.name}</h6>
              <Badge
                className="text-secondary text-uppercase"
                style={{ fontSize: 8 }}
                color="light"
              >
                ° {message.language}
              </Badge>
            </div>
          </div>

          <TemplateMessageSection message={message} />

          <div className="d-flex align-items-center font-size-11 gap-1 m-2">
            <i className="bx bxs-error text-warning font-size-14" />
            <span>{t(["templates:modal.price-alert"])}</span>
          </div>

          <div className="mx-2 my-3 font-size-13">
            <Input
              id="confirm-input"
              type="checkbox"
              aria-label="Checkbox for confirmation and enable submit"
              aria-describedby="templates-checkbox-confirmation"
              onChange={handleConfirmationInput}
              checked={!submitDisabled}
            />
            <label htmlFor="confirm-input" className="col-10 col-sm-11 ms-1">
              {t(["form:input.confirm-send-templates"])}
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="link" onClick={onClose}>
            {t(["common:button.cancel"])}
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitDisabled}
            onClick={onSubmit}
          >
            <i className="bx bxs-send align-middle"></i>
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
};

export default TemplatesModal;

import { PopoverBoxContent } from "./PopoverBoxContent";
import { PopoverBoxFooter } from "./PopoverBoxFooter";
import { PopoverBoxFooterAction } from "./PopoverBoxFooterAction";
import { PopoverBoxHeader } from "./PopoverBoxHeader";
import { PopoverBoxRoot } from "./PopoverBoxRoot";

export const PopoverBox = {
  Root: PopoverBoxRoot,
  Header: PopoverBoxHeader,
  Content: PopoverBoxContent,
  Footer: PopoverBoxFooter,
  FooterAction: PopoverBoxFooterAction,
};

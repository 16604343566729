import { ReactNode } from "react";
import classnames from "classnames";

import { Button, ButtonProps, UncontrolledTooltip } from "reactstrap";

interface ChatInputMenuItemTemplatesProps extends ButtonProps {
  color?: string;
  className?: string;
  show?: boolean;
  children: ReactNode;
}

export const ChatInputMenuItemTemplates = ({
  color = "none",
  className = null,
  show = true,
  children,
  ...rest
}: ChatInputMenuItemTemplatesProps) => {
  return (
    show && (
      <>
        <div className="links-list-item">
          <Button
            id="item-templates-btn"
            type="button"
            className={classnames(
              "btn btn-link text-decoration-none btn-lg waves-effect d-none d-lg-block",
              className
            )}
            color={color}
            {...rest}
          >
            <i className="bx bx-mail-send align-middle"></i>
          </Button>
        </div>
        <UncontrolledTooltip target="item-templates-btn" placement="top">
          {children}
        </UncontrolledTooltip>
      </>
    )
  );
};

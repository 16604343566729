import classNames from "classnames";
import { HTMLAttributes } from "react";

interface TemplateMessageFooterProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
}

export function TemplateMessageFooter({
  children,
  className,
  ...rest
}: TemplateMessageFooterProps) {
  return (
    <footer className={classNames("px-4", className)} {...rest}>
      {children}
    </footer>
  );
}

import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { KanbanDataModel } from "../../data/kanban";
import { useRedux } from '../../hooks';
import { CommentBox, Comment } from './Comment';
import { KanbanComponent } from '@syncfusion/ej2-react-kanban';
import { CommentProps } from '../../redux/kanban/types';
import { Timestamp } from 'firebase/firestore';


interface CommentSectionProps {
    props: KanbanDataModel;
}

const CommentSession: React.FC<CommentSectionProps> = ({ props }) => {
    const { useAppSelector } = useRedux();

    const { settings, attendants } = useAppSelector(state => ({
        settings: state.Settings.settings,
        attendants: state.Attendants.attendants
    }));

    const [comments, setComments] = useState<CommentProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const urlPattern = settings.urlApi + '/api/Kanban/Comments';

    useEffect(() => {
        async function getCommentsFromAPI() {
            try {
                const response = await fetch(`${urlPattern}?boardId=${props.boardId}&contactId=${props.contactId}`);
                const data = await response.json();
                setComments(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching comments:', error);
                setIsLoading(false);
            }
        }

        getCommentsFromAPI();

    }, [props.boardId, props.contactId, urlPattern]);

    const addComment = async (message: string) => {
        const newComment: CommentProps = {
            id: "",
            contactId: props.contactId,
            createdAt: formatDate(),
            attendantId: settings.basicDetails.id,
            message: message
        };
         setComments([newComment, ...comments]);
         await postComment(newComment);
    };

    const postComment = async (comment: CommentProps) => {
        try {
            const response = await fetch(`${urlPattern}/${props.boardId}/${props.contactId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(comment),
            });
            if (response.ok) {
                console.log('Comment posted successfully');
            } else {
                console.error('Failed to post comment');
            }
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    function formatDate() {
        const currentTimestamp = Timestamp.now();
        const formattedTimestamp = {
            seconds: currentTimestamp.seconds,
            nanos: currentTimestamp.nanoseconds
        } as unknown as Timestamp;
        return formattedTimestamp;
    }

    const deleteComment = async (index: number) => {

            const commentToDelete = comments[index];
            try {
                const response = await fetch(`${urlPattern}/${props.boardId}/${props.contactId}/${commentToDelete.id}`, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    console.log('Comment deleted successfully');
                    setComments(comments.filter((_, i) => i !== index));
                } else {
                    console.error('Failed to delete comment');
                }
            } catch (error) {
                console.error('Error deleting comment:', error);
            }
    };

    const currentUser = settings.basicDetails.id;

    return (
        <div className="comment-section">
            <h2 className="comment-section__title">Comentários</h2>
            <div className="comment-section__splitter"></div>
            <CommentBox onAddComment={addComment} />

            {!isLoading ? (
                comments.map((comment, index) => (
                    <Comment
                        key={index}
                        comment={comment}
                        currentUser={currentUser}
                        attendants={attendants}
                        onDelete={() => deleteComment(index)}
                    />
                ))
            ) : (
                <Loader className="position-relative" />
            )}

        </div>
    );
};


export default CommentSession;
import { ReactNode } from "react";
import classnames from "classnames";
import { Badge, BadgeProps } from "reactstrap";

interface TagsItemProps extends BadgeProps {
  className?: string;
  color?: string;
  active?: boolean;
  children: ReactNode;
}

export const TagsItem = ({
  className,
  color,
  active = false,
  children,
  ...rest
}: TagsItemProps) => {
  const bgColor = active ? `${color}bf` : `${color}40`;
  const txtColor = active ? "#f6f6f9bf" : color;
  return (
    <Badge
      color={bgColor}
      className={classnames("text-truncate col-auto", className)}
      style={color && { backgroundColor: bgColor, color: txtColor }}
      {...rest}
    >
      {children}
    </Badge>
  );
};

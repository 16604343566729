import { ReactNode } from "react";

import { Collapse, CollapseProps, Card, CardBody } from "reactstrap";

// swiper
import { Swiper } from "swiper/react";
import "swiper/swiper.min.css";

interface CardMenuContentProps extends CollapseProps {
  children: ReactNode;
}

export const CardMenuContent = ({
  children,
  ...rest
}: CardMenuContentProps) => {
  return (
    <Collapse className="chat-input-collapse" {...rest}>
      <Card className="mb-0">
        <CardBody className="py-3">
          <Swiper
            observer
            observeParents
            freeMode
            watchSlidesProgress
            watchSlidesVisibility
            slidesPerView={2}
            spaceBetween={30}
            breakpoints={{
              720: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1600: {
                slidesPerView: 6,
              },
            }}
            className="chatinput-links"
          >
            <div className="swiper-wrapper">{children}</div>
          </Swiper>
        </CardBody>
      </Card>
    </Collapse>
  );
};

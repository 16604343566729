import {
  takeEvery,
  fork,
  put,
  all,
  call,
  take,
  select,
} from "redux-saga/effects";

// Login Redux States
import { ChatsActionTypes } from "./types";
import { chatsApiResponseSuccess, chatsApiResponseError } from "./actions";

import {
  ContactTypes,
  ContactTypesBackend,
  ConversationTypes,
  PlatformTypes,
} from "../../data";

import {
  // getFavourites as getFavouritesApi,
  // getDirectMessages as getDirectMessagesApi,
  getChannels as getChannelsApi,
  addContacts as addContactsApi,
  createChannel as createChannelApi,
  getChatUserDetails as getChatUserDetailsApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  receiveMessage as receiveMessageApi,
  readMessage as readMessageApi,
  receiveMessageFromUser as receiveMessageFromUserApi,
  deleteMessage as deleteMessageApi,
  forwardMessage as forwardMessageApi,
  deleteUserMessages as deleteUserMessagesApi,
  getChannelDetails as getChannelDetailsApi,
  toggleFavouriteContact as toggleFavouriteContactApi,
  // getArchiveContact as getArchiveContactApi,
  toggleArchiveContact as toggleArchiveContactApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
  getRobotStatus as getRobotStatusApi,
  toggleRobotStatus as toggleRobotStatusApi,
  getMediaHistory as getMediaHistoryApi,
  assignAttendant as assignAttendantApi,
  unassignAttendant as unassignAttendantApi,
  getTags as getTagsApi,
  addContactTag as addContactTagApi,
  removeContactTag as removeContactTagApi,
  createTag as createTagApi,
  deleteTag as deleteTagApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import {
  // getDirectMessages as getDirectMessagesAction,
  // getFavourites as getFavouritesAction,
  // getChannels as getChannelsAction,
  getRobotStatus as getRobotStatusAction,
} from "./actions";

import {
  GetFirestoreDocProps,
  getAuthUserStorageData,
  getFirestoreDocsRT,
} from "../../api/apiFirestore";
import * as url from "../../api/urls";

import { getFirstLastNameOrPhone } from "../../utils";
import { isValidImageURL } from "../../utils/isValidImageURL";
import { STATUS_TYPES } from "../../constants";
import { EventChannel } from "redux-saga";

function snapshotContacts(doc: ContactTypesBackend) {
  const { firstName, lastName } = getFirstLastNameOrPhone(
    doc.Name,
    doc.PhoneNumber
  );
  let isValidImage = false;
  isValidImageURL(doc.AvatarUrl).then(res => {
    isValidImage = res;
  });
  const contact = {
    id: doc.id,
    firstName,
    lastName,
    profileImage: isValidImage ? doc.AvatarUrl : undefined,
    about: undefined,
    email: undefined,
    location: undefined,
    channels: undefined,
    media: undefined,
    attachedFiles: undefined,
    status: STATUS_TYPES.ACTIVE,
    isFavourite: doc.IsFavorite,
    isArchived: doc.IsArchived,
    meta: {
      unRead: doc.UnreadCount,
      status: STATUS_TYPES.ACTIVE,
    },
    platform: doc.Platform ? doc.Platform : PlatformTypes.whatsApp,
    phoneNumber:
      doc.Platform !== PlatformTypes.instagram &&
      doc.Platform !== PlatformTypes.messenger
        ? doc.PhoneNumber
        : undefined,
    assignedAttendant: doc.AssignedAttendant
      ? doc.AssignedAttendant
      : undefined,
    tags: doc.Tags ? doc.Tags : undefined,
    boards: doc.boards
  } as ContactTypes;

  return contact;
}

function handleSnapshotContacts(snapshot: any) {
  const data = snapshot.docs.map((doc: any) => {
    const contact = snapshotContacts({
      id: doc.id,
      ...(doc.data() as ContactTypesBackend),
    });
    return contact;
  });
  return data;
}

let getFavouritesChannel: EventChannel<unknown>;
function* getFavourites() {
  const user = yield call(getAuthUserStorageData);
  if (!user.tenantId) {
    throw new Error("Invalid tenant! Tenant not found!");
  }

  const fixedFilters = [
    {
      fieldPath: "IsFavorite",
      opStr: "==",
      value: true,
    },
    {
      fieldPath: "IsArchived",
      opStr: "==",
      value: false,
    },
  ];

  const filters = yield select(state => state.Chats.chatListFilters);
  const filtersParams =
    filters && filters.length ? [...filters, ...fixedFilters] : fixedFilters;

  const params: GetFirestoreDocProps = {
    paths: [user.tenantId, url.FIRESTORE_CONTACTS_COLLECTION],
    filter: filtersParams,
    sortBy: [{ fieldPath: "LastMessageAt", directionStr: "desc" }],
    handleSnapshot: handleSnapshotContacts,
  };

  getFavouritesChannel = yield call(getFirestoreDocsRT, params);

  try {
    while (true) {
      const data = yield take(getFavouritesChannel);
      yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_FAVOURITES, data));
    }
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_FAVOURITES, error));
  } finally {
    getFavouritesChannel.close();
  }
}

let getDirectMessagesChannel: EventChannel<unknown>;
function* getDirectMessages() {
  const user = yield call(getAuthUserStorageData);
  if (!user.tenantId) {
    throw new Error("Invalid tenant! Tenant not found!");
  }

  const fixedFilters = [
    {
      fieldPath: "IsFavorite",
      opStr: "==",
      value: false,
    },
    {
      fieldPath: "IsArchived",
      opStr: "==",
      value: false,
    },
  ];

  const filters = yield select(state => state.Chats.chatListFilters);
  const filtersParams =
    filters && filters.length ? [...filters, ...fixedFilters] : fixedFilters;

  const params: GetFirestoreDocProps = {
    paths: [user.tenantId, url.FIRESTORE_CONTACTS_COLLECTION],
    filter: filtersParams,
    sortBy: [{ fieldPath: "LastMessageAt", directionStr: "desc" }],
    handleSnapshot: handleSnapshotContacts,
  };

  getDirectMessagesChannel = yield call(getFirestoreDocsRT, params);

  try {
    while (true) {
      const data = yield take(getDirectMessagesChannel);
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, data)
      );
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_DIRECT_MESSAGES, error)
    );
  } finally {
    getDirectMessagesChannel.close();
  }
}

function* getChannels() {
  try {
    const response: Promise<any> = yield call(getChannelsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNELS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_CHANNELS, error));
  }
}

function* addContacts({ payload: contacts }: any) {
  try {
    const response: Promise<any> = yield call(addContactsApi, contacts);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.ADD_CONTACTS, response));
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ADD_CONTACTS, error));
  }
}

function* createChannel({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(createChannelApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_CHANNEL, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error));
  }
}

function* getChatUserDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_DETAILS, error)
    );
  }
}

function* getChatUserConversations({
  payload: id,
}: any): Generator<any, void, any> {
  try {
    const channel = yield call(getChatUserConversationsApi, id);

    while (true) {
      const data: ConversationTypes = yield take(channel);
      const selectedChat = yield select(state => state.Chats.selectedChat);

      if (selectedChat === data.conversationId) {
        yield put(
          chatsApiResponseSuccess(
            ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
            data
          )
        );
      }
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* onSendMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendMessage, data);

    if (data.media) {
      yield call(showSuccessNotification, String(response));
    }
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSAGE, response)
    );
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_MESSAGE, error));
  }
}

function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}

function* receiveMessageFromUser({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER, error)
    );
  }
}

function* deleteMessage({ payload: { userId, messageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteMessageApi,
      userId,
      messageId
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_MESSAGE, error));
  }
}

function* forwardMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(forwardMessageApi, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.FORWARD_MESSAGE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(chatsApiResponseError(ChatsActionTypes.FORWARD_MESSAGE, error));
  }
}

function* deleteUserMessages({ payload: userId }: any) {
  try {
    const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_USER_MESSAGES, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_USER_MESSAGES, error)
    );
  }
}

function* getChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChannelDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_DETAILS, error)
    );
  }
}

function* toggleFavouriteContact({
  payload: { id, isFavorite },
}: any): Generator<any, void, any> {
  try {
    const response: Promise<any> = yield call(toggleFavouriteContactApi, {
      id,
      isFavorite,
    });
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
    );
  }
}

let getArchiveContactChannel: EventChannel<unknown>;
function* getArchiveContact() {
  const user = yield call(getAuthUserStorageData);
  if (!user.tenantId) {
    throw new Error("Invalid tenant! Tenant not found!");
  }

  const fixedFilters = [
    {
      fieldPath: "IsFavorite",
      opStr: "==",
      value: false,
    },
    {
      fieldPath: "IsArchived",
      opStr: "==",
      value: true,
    },
  ];

  const filters = yield select(state => state.Chats.chatListFilters);
  const filtersParams =
    filters && filters.length ? [...filters, ...fixedFilters] : fixedFilters;

  const params: GetFirestoreDocProps = {
    paths: [user.tenantId, url.FIRESTORE_CONTACTS_COLLECTION],
    filter: filtersParams,
    sortBy: [{ fieldPath: "LastMessageAt", directionStr: "desc" }],
    handleSnapshot: handleSnapshotContacts,
  };

  getArchiveContactChannel = yield call(getFirestoreDocsRT, params);
  try {
    while (true) {
      const data = yield take(getArchiveContactChannel);
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVE_CONTACT, data)
      );
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ARCHIVE_CONTACT, error)
    );
  } finally {
    getArchiveContactChannel.close();
  }
}

function* toggleArchiveContact({ payload: { id, isArchived } }: any) {
  try {
    const response: Promise<any> = yield call(toggleArchiveContactApi, {
      id,
      isArchived,
    });
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, error)
    );
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

function* getRobotStatus({ payload: contactId }: any) {
  try {
    const response: Promise<any> = yield call(getRobotStatusApi, contactId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ROBOT_STATUS, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_ROBOT_STATUS, error));
  }
}

function* toggleRobotStatus({ payload: { contactId, isStopped } }: any) {
  try {
    const response: Promise<any> = yield call(toggleRobotStatusApi, {
      contactId,
      isStopped,
    });
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.TOGGLE_ROBOT_STATUS, response)
    );
    yield put(getRobotStatusAction(contactId));
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_ROBOT_STATUS, error)
    );
  }
}

function* getMediaHistory({ payload: contactId }: any) {
  try {
    const response: Promise<any> = yield call(getMediaHistoryApi, contactId);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_MEDIA_HISTORY,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_MEDIA_HISTORY, error)
    );
  }
}

function* setChatStatus({ payload: chatStatus }: any) {
  try {
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.SET_CHAT_STATUS, chatStatus)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.SET_CHAT_STATUS, error));
  }
}

function* assignAttendant({ payload: { id, attendantId } }: any) {
  try {
    const response: Promise<any> = yield call(assignAttendantApi, {
      id,
      attendantId,
    });
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ASSIGN_ATTENDANT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ASSIGN_ATTENDANT, error));
  }
}

function* unassignAttendant({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(unassignAttendantApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.UNASSIGN_ATTENDANT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      chatsApiResponseError(ChatsActionTypes.UNASSIGN_ATTENDANT, error)
    );
  }
}

function* setChatListFilters({ payload: filters }: any) {
  try {
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.SET_CHAT_LIST_FILTERS, filters)
    );
    const channels = [call(getFavourites), call(getDirectMessages)];
    getFavouritesChannel.close();
    getDirectMessagesChannel.close();
    if (getArchiveContactChannel) {
      getArchiveContactChannel.close();
      channels.push(call(getArchiveContact));
    }
    yield all(channels);
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      chatsApiResponseError(ChatsActionTypes.SET_CHAT_LIST_FILTERS, error)
    );
  }
}

function* getTags() {
  try {
    const response: Promise<any> = yield call(getTagsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_TAGS, response));
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.GET_TAGS, error));
  }
}

function* addContactTag({ payload: { id, tagId, usedCount } }: any) {
  try {
    const response: Promise<any> = yield call(addContactTagApi, {
      id,
      tagId,
      usedCount,
    });
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ADD_CONTACT_TAG, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ADD_CONTACT_TAG, error));
  }
}

function* removeContactTag({ payload: { id, tagId, usedCount } }: any) {
  try {
    const response: Promise<any> = yield call(removeContactTagApi, {
      id,
      tagId,
      usedCount,
    });
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.REMOVE_CONTACT_TAG, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      chatsApiResponseError(ChatsActionTypes.REMOVE_CONTACT_TAG, error)
    );
  }
}

function* createTag({ payload: { name, color } }: any) {
  try {
    const response: Promise<any> = yield call(createTagApi, {
      name,
      color,
    });
    yield put(chatsApiResponseSuccess(ChatsActionTypes.CREATE_TAG, response));
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CREATE_TAG, error));
  }
}

function* deleteTag({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(deleteTagApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_TAG, response));
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_TAG, error));
  }
}

export function* watchGetFavourites() {
  yield takeEvery(ChatsActionTypes.GET_FAVOURITES, getFavourites);
}

export function* watchGetDirectMessages() {
  yield takeEvery(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages);
}
export function* watchGetChannels() {
  yield takeEvery(ChatsActionTypes.GET_CHANNELS, getChannels);
}
export function* watchAddContacts() {
  yield takeEvery(ChatsActionTypes.ADD_CONTACTS, addContacts);
}
export function* watchCreateChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_CHANNEL, createChannel);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchOnSendMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage);
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}
export function* watchReceiveMessageFromUser() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
    receiveMessageFromUser
  );
}
export function* watchDeleteMessage() {
  yield takeEvery(ChatsActionTypes.DELETE_MESSAGE, deleteMessage);
}
export function* watchForwardMessage() {
  yield takeEvery(ChatsActionTypes.FORWARD_MESSAGE, forwardMessage);
}
export function* watchDeleteUserMessages() {
  yield takeEvery(ChatsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
}
export function* watchGetChannelDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_DETAILS, getChannelDetails);
}
export function* watchToggleFavouriteContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
    toggleFavouriteContact
  );
}
export function* watchGetArchiveContact() {
  yield takeEvery(ChatsActionTypes.GET_ARCHIVE_CONTACT, getArchiveContact);
}
export function* watchToggleArchiveContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
    toggleArchiveContact
  );
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}
export function* watchGetRobotStatus() {
  yield takeEvery(ChatsActionTypes.GET_ROBOT_STATUS, getRobotStatus);
}
export function* watchToggleRobotStatus() {
  yield takeEvery(ChatsActionTypes.TOGGLE_ROBOT_STATUS, toggleRobotStatus);
}
export function* watchGetMediaHistory() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_MEDIA_HISTORY,
    getMediaHistory
  );
}

export function* watchSetChatStatus() {
  yield takeEvery(ChatsActionTypes.SET_CHAT_STATUS, setChatStatus);
}

export function* watchAssignAttendant() {
  yield takeEvery(ChatsActionTypes.ASSIGN_ATTENDANT, assignAttendant);
}

export function* watchUnassignAttendant() {
  yield takeEvery(ChatsActionTypes.UNASSIGN_ATTENDANT, unassignAttendant);
}

export function* watchSetChatListFilters() {
  yield takeEvery(ChatsActionTypes.SET_CHAT_LIST_FILTERS, setChatListFilters);
}

export function* watchGetTags() {
  yield takeEvery(ChatsActionTypes.GET_TAGS, getTags);
}

export function* watchAddContactTag() {
  yield takeEvery(ChatsActionTypes.ADD_CONTACT_TAG, addContactTag);
}

export function* watchRemoveContactTag() {
  yield takeEvery(ChatsActionTypes.REMOVE_CONTACT_TAG, removeContactTag);
}

export function* watchCreateTag() {
  yield takeEvery(ChatsActionTypes.CREATE_TAG, createTag);
}

export function* watchDeleteTag() {
  yield takeEvery(ChatsActionTypes.DELETE_TAG, deleteTag);
}

function* chatsSaga() {
  yield all([
    fork(watchGetFavourites),
    fork(watchGetDirectMessages),
    fork(watchGetChannels),
    fork(watchAddContacts),
    fork(watchCreateChannel),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchOnSendMessage),
    fork(watchReceiveMessage),
    fork(watchReadMessage),
    fork(watchReceiveMessageFromUser),
    fork(watchDeleteMessage),
    fork(watchForwardMessage),
    fork(watchDeleteUserMessages),
    fork(watchGetChannelDetails),
    fork(watchToggleFavouriteContact),
    fork(watchGetArchiveContact),
    fork(watchToggleArchiveContact),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    fork(watchGetRobotStatus),
    fork(watchToggleRobotStatus),
    fork(watchGetMediaHistory),
    fork(watchSetChatStatus),
    fork(watchAssignAttendant),
    fork(watchUnassignAttendant),
    fork(watchSetChatListFilters),
    fork(watchGetTags),
    fork(watchAddContactTag),
    fork(watchRemoveContactTag),
    fork(watchCreateTag),
    fork(watchDeleteTag),
  ]);
}

export default chatsSaga;

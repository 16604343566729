// types
import { SettingsActionTypes, SettingsState } from "./types";

export const INIT_STATE: SettingsState = {
  settings: {},
  isStatusUpdated: null,
  connectedPagesData: null,
  getConnectedPagesLoading: null,
  isConnectedPageFetched: null,
};

const Settings = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SettingsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SettingsActionTypes.GET_USER_SETTINGS:
          return {
            ...state,
            settings: action.payload.data,
            isSettingsFetched: true,
            getSettingsLoading: false,
            isSettingsUpdated: false,
          };
        case SettingsActionTypes.UPDATE_USER_SETTINGS:
          return {
            ...state,
            isSettingsUpdated: true,
          };
        case SettingsActionTypes.UPDATE_USER_STATUS:
          return {
            ...state,
            isStatusUpdated: true,
          };
        case SettingsActionTypes.GET_CONNECTED_PAGES:
          return {
            ...state,
            connectedPagesData: action.payload.data,
            getConnectedPagesLoading: false,
            isConnectedPageFetched: true,
          };

        default:
          return { ...state };
      }

    case SettingsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SettingsActionTypes.GET_USER_SETTINGS:
          return {
            ...state,
            isSettingsFetched: false,
            getSettingsLoading: false,
          };
        case SettingsActionTypes.UPDATE_USER_SETTINGS:
          return {
            ...state,
            isSettingsUpdated: false,
          };
        case SettingsActionTypes.UPDATE_USER_STATUS:
          return {
            ...state,
            isStatusUpdated: false,
          };
        case SettingsActionTypes.GET_CONNECTED_PAGES:
          return {
            ...state,
            getConnectedPagesLoading: false,
            isConnectedPageFetched: false,
          };
        default:
          return { ...state };
      }

    case SettingsActionTypes.GET_USER_SETTINGS: {
      return {
        ...state,
        getSettingsLoading: true,
        isSettingsFetched: false,
      };
    }
    case SettingsActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        isSettingsUpdated: false,
      };
    case SettingsActionTypes.UPDATE_USER_STATUS:
      return {
        ...state,
        isStatusUpdated: false,
      };
    case SettingsActionTypes.GET_CONNECTED_PAGES:
      return {
        ...state,
        getConnectedPagesLoading: false,
        isConnectedPageFetched: false,
      };

    default:
      return { ...state };
  }
};

export default Settings;

import React, { HtmlHTMLAttributes } from "react";
import classnames from "classnames";
import { randomColorClass } from "../utils/randomColorClass";
import { PlatformTypes } from "../data";

interface AvatarNameProps extends HtmlHTMLAttributes<HTMLDivElement> {
  user: {
    id: string | number;
    profileImage?: string | null;
    firstName: string;
    lastName: string;
    platform: PlatformTypes | null;
  };
  isChannel?: boolean;
  showPlatform?: boolean;
}
const AvatarName = ({
  user,
  isChannel = false,
  showPlatform = false,
  ...rest
}: AvatarNameProps) => {
  const shortName =
    !user.profileImage && !isChannel
      ? user.firstName
        ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
        : "-"
      : "#";

  return (
    <div {...rest}>
      <span
        className={classnames(
          "avatar-title",
          "rounded-circle",
          "text-uppercase",
          "text-white"
        )}
        style={{
          background: !user.profileImage
            ? randomColorClass(String(user.id))
            : ` url(${user.profileImage}) center/cover no-repeat`,
        }}
      >
        {!user.profileImage ? (
          <span className="username">{shortName}</span>
        ) : null}
        {showPlatform ? (
          <span
            className={classnames("user-platform", {
              "platform-whatsapp": user.platform === PlatformTypes.whatsApp,
              "platform-instagram": user.platform === PlatformTypes.instagram,
              "platform-messenger": user.platform === PlatformTypes.messenger,
            })}
          />
        ) : null}
      </span>
    </div>
  );
};

export default AvatarName;

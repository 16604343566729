import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import { StatusProps } from "../../../../../data/analytics";
import { BoxChart } from "../../../../../components/charts/Box";
import {
  PieChart,
  PieChartDataProps,
  PieChartOptionsProps,
} from "../../../../../components/charts/Pie";

interface StatusSectionProps {
  data: StatusProps;
}

export const StatusSection = ({ data }: StatusSectionProps) => {
  const { t } = useTranslation(["analytics"]);

  const [dataChart, setDataChart] = useState<PieChartDataProps>();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
      },
    },
  } as PieChartOptionsProps;

  useEffect(() => {
    if (!data) return;
    setDataChart({
      labels: [
        t(["analytics:campaign.status.qualified"]),
        t(["analytics:campaign.status.not-qualified"]),
        t(["analytics:campaign.status.not-generated"]),
      ],
      datasets: [
        {
          label: "Status (leads)",
          data: [
            data.qualifiedPercentage,
            data.notQualifiedPercentage,
            data.notGeneratedPercentage,
          ],
          backgroundColor: [
            "rgba(133, 202, 231, 1)",
            "rgba(241, 163, 205, 1)",
            "rgba(227, 218, 235, 1)",
          ],
          borderColor: ["rgba(255, 255, 255, 1)"],
          borderWidth: 1.5,
        },
      ],
    });
  }, [data, t]);

  if (!data || !dataChart) return <></>;
  return (
    <div className="container px-4 my-5 d-flex flex-column flex-xl-row gap-3">
      <div className="border border-primary bg-light rounded p-4 col-sm">
        <div className="header">
          <h4>{t(["analytics:campaign.status.title"])}</h4>
          <span>({t(["analytics:campaign.status.subtitle"])})</span>
        </div>
        <div className="col-sm d-flex justify-content-center">
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ maxWidth: 450, maxHeight: 300 }}
          >
            <PieChart data={dataChart} options={options} />
          </div>
        </div>
      </div>
      <ul className="col-sm col-xl-3 d-flex flex-column flex-sm-row flex-xl-column justify-content-center xl-justify-content-between gap-2 list-group">
        <li className="list-group-item col-auto col-sm-4 col-xl-auto p-0">
          <BoxChart
            title={t(["analytics:campaign.general.total"])}
            value={data.total}
            iconName="bx-group"
          />
        </li>
        <li className="list-group-item col-auto col-sm-4 col-xl-auto p-0">
          <BoxChart
            title={t(["analytics:campaign.general.generated"])}
            value={data.genereatedLeads}
            iconName="bx-user-plus"
          />
        </li>
        <li className="list-group-item col-auto col-sm-4 col-xl-auto p-0">
          <BoxChart
            title={t(["analytics:campaign.general.contacted"])}
            value={data.customerContacted}
            iconName="bx-phone-outgoing"
          />
        </li>
      </ul>
    </div>
  );
};

import classNames from "classnames";
import { HTMLAttributes } from "react";

enum ButtonTypes {
  "QUICK_REPLY" = "bxs-share",
}

interface TemplateMessageIconProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  type: keyof typeof ButtonTypes | string;
}

export function TemplateMessageIcon({
  className,
  type = null,
  ...rest
}: TemplateMessageIconProps) {
  return (
    type && (
      <i
        className={classNames(
          "bx text-warning font-size-14 me-1",
          ButtonTypes[type],
          className
        )}
        {...rest}
      />
    )
  );
}

// types
import { AttendantsActionTypes, AttendantsState } from "./types";

export const INIT_STATE: AttendantsState = {
  attendants: null,
  getAttendantsLoading: null,
  isAttendantsFetched: null,
} as AttendantsState;

const Chats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AttendantsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AttendantsActionTypes.GET_ATTENDANTS:
          return {
            ...state,
            attendants: action.payload.data,
            isAttendantsFetched: true,
            getAttendantsLoading: false,
          };
        default:
          return { ...state };
      }

    case AttendantsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AttendantsActionTypes.GET_ATTENDANTS:
          return {
            ...state,
            isAttendantsFetched: false,
            getAttendantsLoading: false,
          };

        default:
          return { ...state };
      }

    case AttendantsActionTypes.GET_ATTENDANTS:
      return {
        ...state,
        isAttendantsFetched: false,
        getAttendantsLoading: true,
      };

    default:
      return { ...state };
  }
};

export default Chats;

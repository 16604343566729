export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}
export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum ANALYTICS_TABS {
  DEFAULT = "overview",
}

export enum TABS {
  USERS = "pills-user",
  CHAT = "pills-chat",
  CONTACTS = "pills-contacts",
  CALLS = "pills-calls",
  BOOKMARK = "pills-bookmark",
  ANALYTICS = "pills-analytics",
  SETTINGS = "pills-setting",
  KANBAN = "pills-kanban",
}

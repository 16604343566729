import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { CampaignProps } from "../../../data/analytics";

interface ChatUserProps {
  campaign: CampaignProps;
  selectedCampaignId: string | number;
  onSelectedCampaign: (campaign: CampaignProps) => void;
}
const ChatUser = ({
  campaign,
  selectedCampaignId,
  onSelectedCampaign,
}: ChatUserProps) => {
  const isSelectedCampaign: boolean =
    selectedCampaignId && selectedCampaignId === campaign.id ? true : false;
  const onClick = () => {
    onSelectedCampaign(campaign);
  };
  return (
    <li
      className={classnames({ active: isSelectedCampaign })}
      onClick={onClick}
    >
      <Link to="#">
        <div className="d-flex align-items-center gap-1">
          <i className="bx bxs-bar-chart-alt-2"></i>
          <div className="overflow-hidden">
            <p className="text-truncate text-capitalize mb-0">
              {campaign.name}
            </p>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ChatUser;

import React, { useState, useMemo } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

// components
import LightBox from "../../../components/LightBox";

//images
import imagePlaceholder from "../../../assets/images/users/profile-placeholder.png";

// interface
import {
  MessagesTypes,
  ImageTypes,
  AttachmentTypes,
  AudioTypes,
  VideoTypes,
} from "../../../data/messages";

// hooks
import { useProfile, useRedux } from "../../../hooks";

// utils
import { formateDate } from "../../../utils";
import RepliedMessage from "./RepliedMessage";
import AvatarName from "../../../components/AvatarName";
import { TemplateMessageSection } from "../../../components/TemplateMessageSection";

interface MenuProps {
  onDelete: () => any;
  onReply: () => any;
  onForward: () => void;
}

const Menu = ({ onDelete, onReply, onForward }: MenuProps) => {
  const { t } = useTranslation(["common", "conversation", "bookmarks"]);

  return (
    <UncontrolledDropdown className="align-self-start message-box-drop">
      <DropdownToggle className="btn btn-toggle" role="button" tag={"a"}>
        <i className="ri-more-2-fill"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
          onClick={onReply}
        >
          {t(["common:button.reply"])}{" "}
          <i className="bx bx-share ms-2 text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
          onClick={onForward}
        >
          {t(["common:button.forward"])}{" "}
          <i className="bx bx-share-alt ms-2 text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          {t(["common:button.copy"])}{" "}
          <i className="bx bx-copy text-muted ms-2"></i>
        </DropdownItem>
        {/* <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          {t(["bookmarks:menu.bookmark_one"])}{" "}
          <i className="bx bx-bookmarks text-muted ms-2"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          {t(["common:button.mark-unread"])}{" "}
          <i className="bx bx-message-error text-muted ms-2"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between delete-item"
          onClick={onDelete}
        >
          {t(["common:button.delete"])}{" "}
          <i className="bx bx-trash text-muted ms-2"></i>
        </DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
interface ImageMoreMenuProps {
  onDelete: () => void;
}
const ImageMoreMenu = ({ onDelete }: ImageMoreMenuProps) => {
  const { t } = useTranslation(["common", "conversation", "bookmarks"]);
  return (
    <div className="message-img-link">
      <ul className="list-inline mb-0">
        <UncontrolledDropdown
          tag="li"
          color="none"
          className="list-inline-item dropdown"
        >
          <DropdownToggle tag="a" role="button" className="btn btn-toggle">
            <i className="bx bx-dots-horizontal-rounded"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className=" d-flex align-items-center justify-content-between"
              to="#"
            >
              {t(["common:button.download"])}{" "}
              <i className="bx bx-download ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              className=" d-flex align-items-center justify-content-between"
              to="#"
              data-bs-toggle="collapse"
              data-bs-target=".replyCollapse"
            >
              {t(["common:button.reply"])}{" "}
              <i className="bx bx-share ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              className=" d-flex align-items-center justify-content-between"
              to="#"
              data-bs-toggle="modal"
              data-bs-target=".forwardModal"
            >
              {t(["common:button.forward"])}{" "}
              <i className="bx bx-share-alt ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              className=" d-flex align-items-center justify-content-between"
              to="#"
            >
              {t(["bookmarks:menu.bookmark_one"])}{" "}
              <i className="bx bx-bookmarks text-muted ms-2"></i>
            </DropdownItem>
            <DropdownItem
              className=" d-flex align-items-center justify-content-between delete-item"
              to="#"
              onClick={onDelete}
            >
              {t(["common:button.delete"])}{" "}
              <i className="bx bx-trash ms-2 text-muted"></i>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ul>
    </div>
  );
};

interface VideoProps {
  video: VideoTypes;
  onDeleteVideo: (videoId: string | number) => void;
}

const Video = ({ video, onDeleteVideo }: VideoProps) => {
  // const onDelete = () => {
  //   onDeleteVideo(video.id);
  // };
  return (
    <div className="message-img-list">
      <video
        height="200"
        width="200"
        controls
        className="rounded border"
        preload="metadata"
      >
        <source src={video.downloadLink + "#t=0.1"} />
      </video>
    </div>
  );
};

interface VideosProps {
  videos: VideoTypes[];
  onDeleteVideo: (videoId: string | number) => void;
}

const Videos = ({ videos, onDeleteVideo }: VideosProps) => {
  return (
    <>
      <div className="message-img mb-0">
        {(videos || []).map((video: VideoTypes, key: number) => (
          <Video video={video} key={key} onDeleteVideo={onDeleteVideo} />
        ))}
      </div>
    </>
  );
};

interface AudioProps {
  audio: AudioTypes;
  onDeleteAudio: (audioId: string | number) => void;
}

const Audio = ({ audio, onDeleteAudio }: AudioProps) => {
  // const onDelete = () => {
  //   onDeleteAudio(audio.id);
  // };

  return (
    <div className="message-img-list">
      <audio
        controls
        preload="metadata"
        //style={{ maxWidth: "300px", width: "50vw" }}
      >
        <source src={audio.downloadLink} type={audio.mimeType} />
      </audio>
    </div>
  );
};

interface AudiosProps {
  audios: AudioTypes[];
  onDeleteAudio: (audiosId: string | number) => void;
}

const Audios = ({ audios, onDeleteAudio }: AudiosProps) => {
  return (
    <div className="message-img mb-0">
      {(audios || []).map((audio: AudioTypes, key: number) => (
        <Audio audio={audio} key={key} onDeleteAudio={onDeleteAudio} />
      ))}
    </div>
  );
};

interface ImageProps {
  image: ImageTypes;
  onImageClick: (id: number) => void;
  index: number;
  onDeleteImg: (imageId: string | number) => void;
}
const Image = ({ image, onImageClick, index, onDeleteImg }: ImageProps) => {
  const onDelete = () => {
    onDeleteImg(image.id);
  };
  return (
    <div className="message-img-list">
      <div>
        <Link
          className="popup-img d-inline-block"
          to={"#"}
          onClick={() => onImageClick(index)}
        >
          <img src={image.downloadLink} alt="" className="rounded border" />
        </Link>
      </div>
      <ImageMoreMenu onDelete={onDelete} />
    </div>
  );
};
interface ImagesProps {
  images: ImageTypes[];
  onDeleteImg: (imageId: string | number) => void;
}
const Images = ({ images, onDeleteImg }: ImagesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const onImageClick = (id: number) => {
    setSelected(id);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="message-img mb-0">
        {(images || []).map((image: ImageTypes, key: number) => (
          <Image
            image={image}
            key={key}
            index={key}
            onImageClick={onImageClick}
            onDeleteImg={onDeleteImg}
          />
        ))}
      </div>
      {isOpen && (
        <LightBox
          isOpen={isOpen}
          images={images}
          onClose={onClose}
          defaultIdx={selected}
        />
      )}
    </>
  );
};

interface AttachmentsProps {
  attachments?: AttachmentTypes[] | undefined;
  currentTrack?: {
    src: string;
  };
}

const Attachments = ({ attachments }: AttachmentsProps) => {
  return (
    <>
      {(attachments || []).map((attachment: AttachmentTypes, key: number) => (
        <div
          key={key}
          className={classnames("p-3", "border-primary", "border rounded-3", {
            "mt-2": key !== 0,
          })}
        >
          <div className="d-flex align-items-center attached-file">
            <div className="flex-shrink-0 avatar-sm me-3 ms-0 attached-file-avatar">
              <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-20">
                <i className="ri-attachment-2"></i>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <div title={attachment.name}className="text-start">
                <h5 className="font-size-14 mb-1">{attachment.name.length < 21 ? attachment.name : `${attachment.name.slice(0, 21)}...`  }</h5>
                <p className="text-muted text-truncate font-size-13 mb-0">
                  {attachment.desc}
                </p>
              </div>
            </div>
            <div className="flex-shrink-0 ms-4">
              <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                <div>
                  <Link
                    id={attachment.name}
                    to={{ pathname: attachment.downloadLink }}
                    download={attachment.name}
                    target="_blank"
                    rel="noreferrer"
                    className="text-muted"
                  >
                    <i className="bx bxs-download"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const Typing = () => {
  const { t } = useTranslation(["conversation"]);
  return (
    <p className="mb-0">
      {t(["conversation:chat.typing"])}
      <span className="animate-typing">
        <span className="dot mx-1"></span>
        <span className="dot me-1"></span>
        <span className="dot"></span>
      </span>
    </p>
  );
};
interface MessageProps {
  message: MessagesTypes;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isFromMe: boolean;
  isSameDay: boolean;
  showAvatar: boolean;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
}
const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  isSameDay,
  showAvatar,
  onOpenForward,
  isChannel,
  onDeleteImage,
}: MessageProps) => {
  const { t } = useTranslation(["conversation"]);

  const { userProfile } = useProfile();

  const { useAppSelector } = useRedux();

  const { templates } = useAppSelector(
    useMemo(() => (state) => ({
      templates: state.Templates.templates,
    }), [])
  );

  if (message.attachments) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [type, _] = message.attachments[0].desc.split("/");
    switch (type) {
      case "video":
        message.video = message.attachments.map(video => ({
          id: video.id,
          downloadLink: video.downloadLink,
        }));
        message.attachments = undefined;
        break;
      case "audio":
        message.audio = message.attachments.map(audio => ({
          id: audio.id,
          downloadLink: audio.downloadLink,
          mimeType: audio.desc,
        }));
        message.attachments = undefined;
        break;
      case "image":
        message.image = message.attachments.map(image => ({
          id: image.id,
          downloadLink: image.downloadLink,
        }));
        message.attachments = undefined;
        break;
    }
  }

  const hasAttachments = message.attachments && message.attachments.length;
  const hasVideos = message.video && message.video.length;
  const hasAudios = message.audio && message.audio.length;
  const hasImages = message.image && message.image.length;
  const hasTemplates = message.template && message.template.length;
  const hasText = message.text;
  const isTyping = false;

  //Contact
  const chatUserFullName = chatUserDetails.firstName
    ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
    : "-";

  const date = formateDate(message.time, "dd-MM-yyyy");
  const time = formateDate(message.time, "HH:mm");

  const {
    sent: isSent,
    received: isReceived,
    read: isRead,
    isForwarded,
  } = message.meta;

  const channdelSenderFullname = message.meta.userData
    ? `${message.meta.userData.firstName} ${message.meta.userData.lastName}`
    : "-";
  const fullName = isChannel ? channdelSenderFullname : chatUserFullName;

  //Attendance

  const myProfileImage = userProfile.profileImage
    ? userProfile.profileImage
    : imagePlaceholder;

  const isFromMeName = `${t(["conversation:chat.you"])} (${
    message.meta.sender
  })`;

  const onDeleteMessage = () => {
    onDelete(message.mId);
  };

  const onClickReply = () => {
    onSetReplyData(message);
  };
  const isRepliedMessage = message.replyOf;

  const onForwardMessage = () => {
    onOpenForward(message);
  };

  const onDeleteImg = (imageId: number | string) => {
    onDeleteImage(message.mId, imageId);
  };

  return (
    <>
      {!isSameDay && (
        <li className="next-day">
          <small>{date}</small>
        </li>
      )}
      <li
        className={classnames(
          "chat-list",
          { right: isFromMe },
          { reply: isRepliedMessage }
        )}
      >
        <div
          className={classnames("conversation-list", {
            "group-end": showAvatar,
          })}
        >
          <div className="user-chat-content">
            <div
              className={classnames("ctext-wrap", { "ctext-right": isFromMe })}
            >
              {/* other message types start */}
              {hasImages && message.image && !hasText ? (
                <div
                  className="ctext-wrap-content text-right"
                  style={{ backgroundColor: "transparent", padding: 0 }}
                >
                  <Images images={message.image} onDeleteImg={onDeleteImg} />

                  <div className="d-flex right gap-1 m-2">
                    {isFromMe && (
                      <span
                        className={classnames(
                          "me-1",
                          { "text-success": isRead },
                          { "text-muted": (isSent || isReceived) && !isRead }
                        )}
                      >
                        <i
                          className={classnames(
                            "bx",
                            { "bx-check-double": isRead || isReceived },
                            { "bx-check": isSent }
                          )}
                        ></i>
                      </span>
                    )}
                    <small className="text-muted mb-0">{time}</small>
                  </div>
                </div>
              ) : (
                <>
                  <div className="ctext-wrap-content">
                    {isRepliedMessage && (
                      <RepliedMessage
                        fullName={fullName}
                        message={message}
                        isFromMe={isFromMe}
                      />
                    )}

                    {/* typing start */}
                    {isTyping && <Typing />}
                    {/* typing end */}

                    {/* image message start */}
                    {hasImages && message.image && (
                      <Images
                        images={message.image}
                        onDeleteImg={onDeleteImg}
                      />
                    )}
                    {/* image message end */}

                    {/* audio message start */}
                    {hasAudios && message.audio && (
                      <Audios
                        audios={message.audio}
                        onDeleteAudio={onDeleteImg}
                      />
                    )}
                    {/* audio message end */}

                    {/* files message start */}
                    {hasAttachments && message.attachments && (
                      <Attachments attachments={message.attachments} />
                    )}
                    {/* files message end */}

                    {/* video message start */}
                    {hasVideos && message.video && (
                      <Videos
                        videos={message.video}
                        onDeleteVideo={onDeleteImg}
                      />
                    )}
                    {/* video message end */}

                    {/* video message start */}
                    {hasTemplates &&
                      message.template &&
                      templates &&
                      templates.data.length &&
                      message.template.map(template => {
                        const templateMessage = templates.data.filter(
                          (item: {
                            id: string;
                            name: string;
                            language: string;
                          }) =>
                            item.name === template.name &&
                            item.language === template.language
                        );
                        return templateMessage.length ? (
                          <TemplateMessageSection
                            key={templateMessage[0].id}
                            message={templateMessage[0]}
                          />
                        ) : null;
                      })}
                    {/* video message end */}

                    {/* text message start */}
                    {hasText && (
                      <p className="mb-0 ctext-content">{message.text}</p>
                    )}
                    {/* text message end */}

                    <div className="d-flex right gap-1 mt-2">
                      {isFromMe && (
                        <span
                          className={classnames(
                            "me-1",
                            { "text-success": isRead },
                            { "text-muted": (isSent || isReceived) && !isRead }
                          )}
                        >
                          <i
                            className={classnames(
                              "bx",
                              { "bx-check-double": isRead || isReceived },
                              { "bx-check": isSent }
                            )}
                          ></i>
                        </span>
                      )}
                      <small className="text-muted mb-0">{time}</small>
                    </div>
                  </div>
                </>
              )}
              <Menu
                onForward={onForwardMessage}
                onDelete={onDeleteMessage}
                onReply={onClickReply}
              />
              {/* other message types end */}
            </div>

            {isForwarded && (
              <span
                className={classnames(
                  "me-1",
                  "text-muted",
                  "font-size-13",
                  "mb-1",
                  "d-block"
                )}
              >
                <i
                  className={classnames(
                    "ri",
                    "ri-share-forward-line",
                    "align-middle",
                    "me-1"
                  )}
                ></i>
                Forwarded
              </span>
            )}

            <div
              className={classnames(
                "d-flex align-items-center",
                { "justify-content-end": isFromMe },
                { "justify-content-start": !isFromMe }
              )}
            >
              {showAvatar && (
                <>
                  <div className="chat-avatar">
                    <AvatarName
                      className="avatar-xs font-size-10"
                      user={
                        isFromMe
                          ? { profileImage: myProfileImage }
                          : chatUserDetails
                      }
                    />
                  </div>
                  <div className="conversation-name">
                    {isFromMe ? (
                      <span>{isFromMeName}</span>
                    ) : (
                      <span>{fullName}</span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default Message;

import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getAttendants = async () => {
  return api.get(url.GET_ATTENDANTS);
};

export { getAttendants };

import { takeEvery, fork, all, call, put } from "redux-saga/effects";

// Login Redux States
import { KanbanActionTypes } from "./types";
import { kanbanApiResponseSuccess, kanbanApiResponseError } from "./actions";

import { getBoards as getBoardsApi, createBoard as createBoardApi, createComment as createCommentApi, getComments as getCommentsApi} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getBoards() {
  try {
    const response: Promise<any> = yield call(getBoardsApi, {});
    yield put(kanbanApiResponseSuccess(KanbanActionTypes.GET_BOARDS, response));
  } catch (error: any) {
    yield put(kanbanApiResponseError(KanbanActionTypes.GET_BOARDS, error));
  }
}

function* createBoard() {
  try {
    const response: Promise<any> = yield call(createBoardApi);
    yield put(kanbanApiResponseSuccess(KanbanActionTypes.CREATE_BOARD, response));
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(kanbanApiResponseError(KanbanActionTypes.CREATE_BOARD, error));
  }
}

function* createComment({ payload: { comment, boardId, contactId }}: any) {
  try {
    const response: Promise<any> = yield call(createCommentApi, comment, boardId, contactId);
    yield put(kanbanApiResponseSuccess(KanbanActionTypes.CREATE_COMMENT, response));
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(kanbanApiResponseError(KanbanActionTypes.CREATE_COMMENT, error));
  }
}

function* getComments({ payload: {boardId, contactId }}: any) {
  try {
    const response: Promise<any> = yield call(getCommentsApi, boardId, contactId);
    yield put(kanbanApiResponseSuccess(KanbanActionTypes.GET_COMMENTS, response));
  } catch (error: any) {
    yield put(kanbanApiResponseError(KanbanActionTypes.GET_COMMENTS, error));
  }
}

export function* watchGetBoards() {
  yield takeEvery(KanbanActionTypes.GET_BOARDS, getBoards);
}

export function* watchCreateBoard() {
  yield takeEvery(KanbanActionTypes.CREATE_BOARD, createBoard);
}

export function* watchCreateComment() {
  yield takeEvery(KanbanActionTypes.CREATE_COMMENT, createComment);
}

export function* watchGetComments() {
  yield takeEvery(KanbanActionTypes.GET_COMMENTS, getComments);
}

function* kanbanSaga() {
  yield all([fork(watchGetBoards)]);
  yield all([fork(watchCreateBoard)]);
  yield all([fork(watchCreateComment)]);
  yield all([fork(watchGetComments)]);
}

export default kanbanSaga;

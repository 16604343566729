import { APIClient } from "./apiCore";
import * as url from "./urls";
import { getFirstLastNameOrPhone } from "../utils/getFirstLastName";
import {
  AttachmentTypes,
  ContactTypes,
  ContactTypesBackend,
  ConversationTypes,
  ImageTypes,
  MessagesResponseTypesBackend,
  TypeOfMessage,
  AudioTypes,
  VideoTypes,
  MessageSendTypes,
  TemplateTypes,
  PlatformTypes,
  TagProps,
} from "../data";
import { STATUS_TYPES } from "../constants/settings";
import {
  GetFirestoreDocProps,
  UpdateFirestoreFieldProps,
  getFirestoreDoc,
  getFirestoreDocs,
  updateFirestoreDocField,
  getAuthUserStorageData,
  addFirestoreDoc,
  deleteFirestoreDoc,
  DeleteFirestoreDoc,
  fireStoreTimestamp,
  getFirestoreDocsRTLegacy,
} from "./apiFirestore";
import { eventChannel } from "redux-saga";
import { add } from "date-fns";
import { isValidImageURL } from "../utils/isValidImageURL";
import { FieldPath, WhereFilterOp } from "firebase/firestore/lite";
import { arrayUnion, arrayRemove } from "firebase/firestore";

const api = new APIClient();

const getFavourites = async (
  filters?: {
    fieldPath: string | FieldPath;
    opStr: WhereFilterOp | any;
    value: unknown;
  }[]
) => {
  var user = await getAuthUserStorageData();
  return eventChannel<ContactTypes[]>(emit => {
    if (!user.tenantId) return () => {};

    const fixedFilters = [
      {
        fieldPath: "IsFavorite",
        opStr: "==",
        value: true,
      },
      {
        fieldPath: "IsArchived",
        opStr: "==",
        value: false,
      },
    ];

    const filtersParams =
      filters && filters.length ? [...filters, ...fixedFilters] : fixedFilters;

    const params: GetFirestoreDocProps = {
      paths: [user.tenantId, url.FIRESTORE_CONTACTS_COLLECTION],
      filter: filtersParams,
      sortBy: [{ fieldPath: "LastMessageAt", directionStr: "desc" }],
    };

    const handleSnapshot = async (snapshot: any) => {
      if (!snapshot || snapshot.length <= 0) {
        emit([]);
      }

      const contacts: ContactTypes[] = await Promise.all(
        snapshot.map(async (doc: ContactTypesBackend) => {
          const { firstName, lastName } = getFirstLastNameOrPhone(
            doc.Name,
            doc.PhoneNumber
          );

          const isValidImage = await isValidImageURL(doc.AvatarUrl);

          const contact = {
            id: doc.id,
            firstName,
            lastName,
            profileImage: isValidImage ? doc.AvatarUrl : undefined,
            about: undefined,
            email: undefined,
            location: undefined,
            channels: undefined,
            media: undefined,
            attachedFiles: undefined,
            status: STATUS_TYPES.ACTIVE,
            isFavourite: doc.IsFavorite,
            isArchived: doc.IsArchived,
            meta: {
              unRead: doc.UnreadCount,
              status: STATUS_TYPES.ACTIVE,
            },
            platform: doc.Platform ? doc.Platform : PlatformTypes.whatsApp,
            phoneNumber:
              doc.Platform !== PlatformTypes.instagram &&
              doc.Platform !== PlatformTypes.messenger
                ? doc.PhoneNumber
                : undefined,
            assignedAttendant: doc.AssignedAttendant
              ? doc.AssignedAttendant
              : undefined,
            tags: doc.Tags ? doc.Tags : undefined,
          };
          return contact;
        })
      );

      emit(contacts);
    };

    const unsubscribe = getFirestoreDocsRTLegacy(params, handleSnapshot);

    return unsubscribe;
  });
};

const getDirectMessages = async (
  filters?: {
    fieldPath: string | FieldPath;
    opStr: WhereFilterOp | any;
    value: unknown;
  }[]
) => {
  var user = await getAuthUserStorageData();

  return eventChannel<ContactTypes[]>(emit => {
    if (!user.tenantId) return () => {};

    const fixedFilters = [
      {
        fieldPath: "IsFavorite",
        opStr: "==",
        value: false,
      },
      {
        fieldPath: "IsArchived",
        opStr: "==",
        value: false,
      },
    ];

    const filtersParams =
      filters && filters.length ? [...filters, ...fixedFilters] : fixedFilters;

    const params: GetFirestoreDocProps = {
      paths: [user.tenantId, url.FIRESTORE_CONTACTS_COLLECTION],
      filter: filtersParams,
      sortBy: [{ fieldPath: "LastMessageAt", directionStr: "desc" }],
    };

    const handleSnapshot = async (snapshot: any) => {
      if (!snapshot || snapshot.length <= 0) {
        emit([]);
      }

      const contacts: ContactTypes[] = await Promise.all(
        snapshot.map(async (doc: ContactTypesBackend) => {
          const { firstName, lastName } = getFirstLastNameOrPhone(
            doc.Name,
            doc.PhoneNumber
          );

          const isValidImage = await isValidImageURL(doc.AvatarUrl);

          const contact = {
            id: doc.id,
            firstName,
            lastName,
            profileImage: isValidImage ? doc.AvatarUrl : undefined,
            about: undefined,
            email: undefined,
            location: undefined,
            channels: undefined,
            media: undefined,
            attachedFiles: undefined,
            status: STATUS_TYPES.ACTIVE,
            isFavourite: doc.IsFavorite,
            isArchived: doc.IsArchived,
            meta: {
              unRead: doc.UnreadCount,
              status: STATUS_TYPES.ACTIVE,
            },
            platform: doc.Platform ? doc.Platform : PlatformTypes.whatsApp,
            phoneNumber:
              doc.Platform !== PlatformTypes.instagram &&
              doc.Platform !== PlatformTypes.messenger
                ? doc.PhoneNumber
                : undefined,
            assignedAttendant: doc.AssignedAttendant
              ? doc.AssignedAttendant
              : undefined,
            tags: doc.Tags ? doc.Tags : undefined,
          };
          return contact;
        })
      );

      emit(contacts);
    };

    const unsubscribe = getFirestoreDocsRTLegacy(params, handleSnapshot);
    return unsubscribe;
  });
};

const getChannels = () => {
  return api.get(url.GET_CHANNELS);
};

const addContacts = (contacts: Array<string | number>) => {
  return api.create(url.ADD_CONTACTS, contacts);
};

const createChannel = (data: object) => {
  return api.create(url.CREATE_CHANNEL, data);
};

const getChatUserDetails = async (id: string | number) => {
  const params: GetFirestoreDocProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(id)],
  };

  const doc: ContactTypesBackend = await getFirestoreDoc(params);
  const { firstName, lastName } = getFirstLastNameOrPhone(
    doc.Name,
    doc.PhoneNumber
  );

  const isValidImage = await isValidImageURL(doc.AvatarUrl);

  return {
    id: doc.id,
    firstName,
    lastName,
    profileImage: isValidImage ? doc.AvatarUrl : undefined,
    about: undefined,
    email: undefined,
    location: undefined,
    channels: undefined,
    media: undefined,
    attachedFiles: undefined,
    status: STATUS_TYPES.ACTIVE,
    isFavourite: doc.IsFavorite,
    isArchived: doc.IsArchived,
    meta: {
      unRead: doc.UnreadCount,
      status: STATUS_TYPES.ACTIVE,
    },
    platform: doc.Platform ? doc.Platform : PlatformTypes.whatsApp,
    phoneNumber:
      doc.Platform !== PlatformTypes.instagram &&
      doc.Platform !== PlatformTypes.messenger
        ? doc.PhoneNumber
        : undefined,
    assignedAttendant: doc.AssignedAttendant
      ? doc.AssignedAttendant
      : undefined,
    tags: doc.Tags ? doc.Tags : undefined,
    boards: doc.boards
  } as ContactTypes;
};

async function getChatUserConversations(id: string) {
  var user = await getAuthUserStorageData();
  return eventChannel<ConversationTypes>(emit => {
    if (!user.tenantId) return () => {};

    const params: GetFirestoreDocProps = {
      paths: [
        user.tenantId,
        url.FIRESTORE_CONTACTS_COLLECTION,
        String(id),
        "messages",
      ],
      sortBy: [{ fieldPath: "Timestamp", directionStr: "asc" }],
      //limitSizeToLast: 100,
    };

    const handleSnapshot = (snapshot: any) => {
      const messages: ConversationTypes["messages"] = [];

      snapshot.forEach((doc: MessagesResponseTypesBackend) => {
        const attachments: AttachmentTypes[] = [];
        const image: ImageTypes[] = [];
        const audio: AudioTypes[] = [];
        const video: VideoTypes[] = [];
        const template: TemplateTypes[] = [];
        let message: any = {};

        switch (doc.Type) {
          case TypeOfMessage.audio:
            audio.push({
              id: Number(doc.Id),
              downloadLink: user.tenantData?.UrlApi + doc.Url,
              mimeType: doc.MimeType,
            });
            break;
          case TypeOfMessage.document:
            attachments.push({
              id: Number(doc.Id),
              name: doc.FileName,
              downloadLink: user.tenantData?.UrlApi + doc.Url,
              desc: doc.MimeType,
            });
            break;
          case TypeOfMessage.text:
            break;
          case TypeOfMessage.image:
            image.push({
              id: Number(doc.Id),
              downloadLink: user.tenantData?.UrlApi + doc.Url,
            });
            break;
          case TypeOfMessage.contacts:
            break;

          case TypeOfMessage.location:
            break;

          case TypeOfMessage.video:
            video.push({
              id: Number(doc.Id),
              downloadLink: user.tenantData?.UrlApi + doc.Url,
            });
            break;

          case TypeOfMessage.template:
            template.push({
              name: doc.Name,
              language: doc.Language,
              type: doc.Type,
            });
            break;
        }

        message = {
          mId: doc.id,
          text: doc.Caption ? doc.Caption : doc.Body,
          time: doc.Timestamp.toDate().toISOString(),
          meta: {
            receiver: doc.To,
            sender: doc.From,
            userData: undefined,
            sent: true,
            received: true,
            read: doc.IsRead,
            isForwarded: undefined,
          },
          attachments: attachments.length > 0 ? attachments : undefined,
          image: image.length > 0 ? image : undefined,
          audio: audio.length > 0 ? audio : undefined,
          video: video.length > 0 ? video : undefined,
          template: template.length > 0 ? template : undefined,
          replyOf: undefined,
        };

        messages.push(message);
      });

      const finalData: ConversationTypes = {
        conversationId: id,
        userId: String(id),
        typingUser: id,
        isGroupConversation: undefined,
        messages: messages,
      };

      emit(finalData);
    };

    const unsubscribe = getFirestoreDocsRTLegacy(params, handleSnapshot);

    return unsubscribe;
  });
}

const sendMessage = (data: MessageSendTypes) => {
  return new Promise(async (resolve, reject) => {
    if (!data.media) {
      await api
        .create(url.SEND_MESSAGE, {
          recipientPhoneNumber: data.meta.receiver,
          contactId: data.meta.receiver,

          message: data.text,

          attendantId: data.meta.sender,
          attendantName: data.meta.senderFullName,

          platform: data.meta.platform,
        })
        .then((response: any) => {
          return resolve(response.message);
        })
        .catch(error => {
          return reject("notifications:error.chats.chats/something-wrong");
        });
    } else {
      if (!data.media)
        return reject("notifications:error.chats.chats/something-wrong");

      await api
        .createWithFile(url.SEND_MESSAGE_MEDIA_UPLOAD, {
          ...data.media,
          platform: data.meta.platform,
        })
        .then(async (mediaResponse: any) => {
          await api
            .create(url.SEND_MESSAGE_MEDIA, {
              recipientPhoneNumber:
                data.meta.platform === PlatformTypes.whatsApp
                  ? data.meta.receiver
                  : null,
              contactId: data.meta.receiver,

              message: data.text,

              attendantId: data.meta.sender,
              attendantName: data.meta.senderFullName,

              platform: data.meta.platform,

              mediaType: data.mediaType,
              mediaId: mediaResponse.id,
              mediaTitle: mediaResponse.filename,
            })
            .then((response: any) => {
              resolve("notifications:success.chats.chat/upload-files");
            })
            .catch(error => {
              reject("notifications:error.chats.chats/something-wrong");
            });
        })
        .catch(error => {
          reject("notifications:error.chats.chat/upload-files");
        });
    }
  });
};

const receiveMessage = (id: string | number) => {
  return api.update(url.RECEIVE_MESSAGE + "/" + id, { params: { id } });
};

const readMessage = (id: string | number) => {
  return api.update(url.READ_MESSAGE + "/" + id, { params: { id } });
};

const receiveMessageFromUser = (id: string | number) => {
  return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
    params: { id },
  });
};

const deleteMessage = (userId: number | string, messageId: number | string) => {
  return api.delete(url.DELETE_MESSAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId },
  });
};

const forwardMessage = (data: object) => {
  return api.create(url.FORWARD_MESSAGE, data);
};

const deleteUserMessages = (userId: number | string) => {
  return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
    params: { userId },
  });
};

const getChannelDetails = (id: string | number) => {
  console.log("error");
  return api.get(url.GET_CHANNEL_DETAILS + "/" + id, { params: { id } });
};

const toggleFavouriteContact = async (user: {
  id: string | number;
  isFavorite: boolean;
}) => {
  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(user.id)],
    data: {
      IsFavorite: user.isFavorite,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.chat/user"]);
    }

    if (!user.isFavorite) {
      resolve(["notifications:success.chats.chat/remove-fav"]);
    } else {
      resolve(["notifications:success.chats.chat/add-fav"]);
    }
  });
};

/*
archive
*/
const getArchiveContact = async (
  filters?: {
    fieldPath: string | FieldPath;
    opStr: WhereFilterOp | any;
    value: unknown;
  }[]
) => {
  var user = await getAuthUserStorageData();
  return eventChannel<ContactTypes[]>(emit => {
    if (!user.tenantId) return () => {};

    const fixedFilters = [
      {
        fieldPath: "IsArchived",
        opStr: "==",
        value: true,
      },
    ];

    const filtersParams =
      filters && filters.length ? [...filters, ...fixedFilters] : fixedFilters;

    const params: GetFirestoreDocProps = {
      paths: [user.tenantId, url.FIRESTORE_CONTACTS_COLLECTION],
      filter: filtersParams,
      sortBy: [{ fieldPath: "LastMessageAt", directionStr: "desc" }],
    };

    const handleSnapshot = (snapshot: any) => {
      const contacts: ContactTypes[] = [];

      snapshot.forEach((doc: ContactTypesBackend) => {
        const { firstName, lastName } = getFirstLastNameOrPhone(
          doc.Name,
          doc.PhoneNumber
        );

        var contact = {
          id: doc.id,
          firstName,
          lastName,
          profileImage: doc.AvatarUrl ? doc.AvatarUrl : undefined,
          about: undefined,
          email: undefined,
          location: undefined,
          channels: undefined,
          media: undefined,
          attachedFiles: undefined,
          status: STATUS_TYPES.ACTIVE,
          isFavourite: doc.IsFavorite,
          isArchived: doc.IsArchived,
          meta: {
            unRead: doc.UnreadCount,
            status: STATUS_TYPES.ACTIVE,
          },
          platform: doc.Platform ? doc.Platform : PlatformTypes.whatsApp,
          phoneNumber:
            doc.Platform !== PlatformTypes.instagram &&
            doc.Platform !== PlatformTypes.messenger
              ? doc.PhoneNumber
              : undefined,
          assignedAttendant: doc.AssignedAttendant
            ? doc.AssignedAttendant
            : undefined,
          tags: doc.Tags ? doc.Tags : undefined,
          boards: doc.boards
        };

        contacts.push(contact);
      });

      emit(contacts);
    };

    const unsubscribe = getFirestoreDocsRTLegacy(params, handleSnapshot);

    return unsubscribe;
  });
};

const toggleArchiveContact = async (user: {
  id: string | number;
  isArchived: boolean;
}) => {
  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(user.id)],
    data: {
      IsArchived: user.isArchived,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.chat/user"]);
    }

    if (!user.isArchived) {
      resolve(["notifications:success.chats.chat/remove-archive"]);
    } else {
      resolve(["notifications:success.chats.chat/add-archive"]);
    }
  });
};

const readConversation = async (id: string | number) => {
  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(id)],
    data: {
      UnreadCount: 0,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.chat/id"]);
    }
    resolve(["true"]);
  });
};

const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

const getRobotStatus = async (contactId: string | number) => {
  const response: any = await api.get(url.GET_ROBOT_STATUS + "/" + contactId);

  if (response.isStopped) {
    const [hours, minutes, seconds] = response.expirationTime
      .split(":")
      .map(Number);
    const todayDate = new Date();
    const expirationTime = add(todayDate, { hours, minutes, seconds });

    return { ...response, expirationTime };
  }

  return response;
};

const toggleRobotStatus = async ({
  contactId,
  isStopped,
}: {
  contactId: string | number;
  isStopped: boolean;
}) => {
  const response: any = await api
    .create(url.TOGGLE_ROBOT_STATUS, {
      contactId,
      isStopped,
    })
    .then(() => {
      return ["notifications:success.chats.robot/status"];
    })
    .catch(() => {
      return ["notifications:error.chats.robot/status"];
    });

  return response;
};

const getMediaHistoryList = async (id: string | number) => {
  if (!id) return;
  const params: GetFirestoreDocProps = {
    paths: [
      url.FIRESTORE_CONTACTS_COLLECTION,
      String(id),
      url.FIRESTORE_MEDIA_HISTORY_COLLECTION,
    ],
  };

  const data: { id: string; MessageId: string }[] = await getFirestoreDocs(
    params
  );
  const total = data.length - 1;
  const messages = data.map(message => message.MessageId);

  return { total, messages } as {
    total: number;
    messages: [];
  };
};

const getMediaHistory = async (id: string | number) => {
  if (!id) return;
  var user = await getAuthUserStorageData();
  const messagesWithMedia = await getMediaHistoryList(id);

  const params: GetFirestoreDocProps = {
    paths: [
      url.FIRESTORE_CONTACTS_COLLECTION,
      String(id),
      url.FIRESTORE_MESSAGES_COLLECTION,
    ],
    filter: [
      { fieldPath: "__name__", opStr: "in", value: messagesWithMedia.messages },
    ],
  };

  const data: {
    id: string;
    Url: string;
    FileName: string;
    Type: number;
    MimeType: string;
  }[] = await getFirestoreDocs(params);

  const mediaList = [];
  const attachedFilesList = [];
  const mediaTypes = {
    audio: TypeOfMessage.audio,
    image: TypeOfMessage.image,
    video: TypeOfMessage.video,
  };
  const validMessagesTypes = [
    TypeOfMessage.document,
    TypeOfMessage.audio,
    TypeOfMessage.video,
    TypeOfMessage.image,
  ];

  data.forEach(media => {
    /** Continue only with valid message type */
    if (!validMessagesTypes.includes(media.Type)) return;

    /** Checking upload media as attachments */
    const attachmentType = media.MimeType.split("/")[0];

    if (!mediaTypes[attachmentType] && media.Type === TypeOfMessage.document) {
      attachedFilesList.push({
        id: media.id,
        downloadUrl: user.tenantData?.UrlApi + media.Url,
        fileName: media.FileName,
        size: media.MimeType, //File size not available from backend
        icon: "bx bx-file",
        mimeType: media.MimeType,
      });
      return;
    }
    mediaList.push({
      id: media.id,
      url: user.tenantData?.UrlApi + media.Url,
      fileName: media.FileName,
      type: mediaTypes[attachmentType]
        ? mediaTypes[attachmentType]
        : media.Type,
      mimeType: media.MimeType,
    });
  });

  return {
    media: {
      total: messagesWithMedia.total,
      list: mediaList,
    },
    attachedFiles: { total: messagesWithMedia.total, list: attachedFilesList },
  };
};

const assignAttendant = async ({
  id,
  attendantId,
}: {
  id: string | number;
  attendantId: string | number;
}) => {
  var user = await getAuthUserStorageData();
  if (!user.tenantId)
    return Promise.reject(["notifications:error.chats/something-wrong"]);

  if (!id || !attendantId)
    return Promise.reject(["notifications:error.chats/something-wrong"]);

  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(id)],
    data: {
      AssignedAttendant: attendantId,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.attendant/assign"]);
    }
    resolve(["notifications:success.chats.attendant/assign"]);
  });
};

const unassignAttendant = async (id: string | number) => {
  if (!id) return Promise.reject(["notifications:error.chats/something-wrong"]);
  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(id)],
    data: {
      AssignedAttendant: null,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.attendant/unassign"]);
    }
    resolve(["notifications:success.chats.attendant/unassign"]);
  });
};

const getTags = async () => {
  const params: GetFirestoreDocProps = {
    paths: [url.FIRESTORE_TAGS_COLLECTION],
    sortBy: [{ fieldPath: "UsedCount", directionStr: "desc" }],
  };

  const data: any = await getFirestoreDocs(params);

  const parsedData: TagProps[] = data.map((doc: any) => {
    return {
      ...doc,
      UsedAt: doc.UsedAt.toDate().toString(),
      CreatedAt: doc.CreatedAt.toDate().toString(),
      EditedAt: doc.EditedAt.toDate().toString(),
    };
  });

  return parsedData;
};

const addContactTag = async ({
  id,
  tagId,
  usedCount,
}: {
  id: string | number;
  tagId: string | number;
  usedCount: number;
}) => {
  if (!id || !tagId || usedCount < 0 || usedCount === undefined) {
    return Promise.reject(["notifications:error.chats/something-wrong"]);
  }

  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(id)],
    data: {
      Tags: arrayUnion(tagId),
    },
  };
  const usedCountAdd = usedCount + 1;
  const params2: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_TAGS_COLLECTION, String(tagId)],
    data: {
      UsedCount: usedCountAdd,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise(async (resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.tag/add"]);
    }
    await updateFirestoreDocField(params2);
    resolve(["notifications:success.chats.tag/add"]);
  });
};

const removeContactTag = async ({
  id,
  tagId,
  usedCount,
}: {
  id: string | number;
  tagId: string | number;
  usedCount: number;
}) => {
  if (!id || !tagId || usedCount < 0 || usedCount === undefined) {
    return Promise.reject(["notifications:error.chats/something-wrong"]);
  }

  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_CONTACTS_COLLECTION, String(id)],
    data: {
      Tags: arrayRemove(tagId),
    },
  };
  const usedCountSub = usedCount - 1;
  const params2: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_TAGS_COLLECTION, String(tagId)],
    data: {
      UsedCount: usedCountSub,
    },
  };

  const response = await updateFirestoreDocField(params);

  return new Promise(async (resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.tag/remove"]);
    }
    await updateFirestoreDocField(params2);
    resolve(["notifications:success.chats.tag/remove"]);
  });
};

const createTag = async ({ name, color }: { name: string; color: string }) => {
  if (!name && !color) {
    return Promise.reject(["notifications:error.chats/something-wrong"]);
  }

  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_TAGS_COLLECTION],
    data: {
      Name: name,
      Color: color,
      CreatedAt: fireStoreTimestamp(),
      EditedAt: fireStoreTimestamp(),
      UsedAt: fireStoreTimestamp(),
      UsedCount: 0,
    },
  };

  const response = await addFirestoreDoc(params);
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.tag/create"]);
    }
    resolve(["notifications:success.chats.tag/create"]);
  });
};

const deleteTag = async (id: string | number) => {
  const params: DeleteFirestoreDoc = {
    paths: [url.FIRESTORE_TAGS_COLLECTION, String(id)],
  };

  const response = await deleteFirestoreDoc(params);
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.chats.tag/delete"]);
    }
    resolve(["notifications:success.chats.tag/delete"]);
  });
};

export {
  getFavourites,
  getDirectMessages,
  getChannels,
  addContacts,
  createChannel,
  getChatUserDetails,
  getChatUserConversations,
  sendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  getChannelDetails,
  toggleFavouriteContact,
  getArchiveContact,
  toggleArchiveContact,
  readConversation,
  deleteImage,
  getRobotStatus,
  toggleRobotStatus,
  getMediaHistory,
  assignAttendant,
  unassignAttendant,
  getTags,
  addContactTag,
  removeContactTag,
  createTag,
  deleteTag,
};

import { HtmlHTMLAttributes, ReactNode } from "react";

interface TagsButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  color: string;
  active?: boolean;
  children: ReactNode;
}

export const TagsButton = ({
  color = "#797c8c",
  active = false,
  children,
  ...rest
}: TagsButtonProps) => {
  const bgColor = active ? `${color}bf` : `${color}40`;
  return (
    <button type="button" style={{ backgroundColor: bgColor }} {...rest}>
      <i className="bx bx-x" />
    </button>
  );
};

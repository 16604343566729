interface MediaHeaderTitleProps {
  title: string;
}

export function MediaHeaderTitle({ title }: MediaHeaderTitleProps) {
  return (
    <div className="flex-grow-1">
      <h5 className="font-size-11 text-muted text-uppercase">{title}</h5>
    </div>
  );
}

import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  query,
  where,
  orderBy,
  FieldPath,
  OrderByDirection,
  WhereFilterOp,
  onSnapshot,
  Primitive,
  FirestoreError,
  limit,
  limitToLast,
  addDoc,
  setDoc,
  FieldValue,
  serverTimestamp,
  deleteDoc,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";

import { FIRESTORE_MAIN_COLLECTION, FIRESTORE_USERS_COLLECTION } from "./urls";
import {
  FirestoreErrorHandler,
  FirestoreErrorHandlerProps,
} from "./apiFirestoreResponseHandler";

import {
  AuthenticatedUserProps,
  UpdateResponseAuthUserProps,
  getFirebaseBackend,
} from "../helpers/firebase_helper";
import { EventChannel, eventChannel } from "redux-saga";

const firebase = getFirebaseBackend();

const getAuthUserStorageData = async (): Promise<AuthenticatedUserProps> => {
  return new Promise((resolve, reject) => {
    if (!firebase)
      return reject(["notifications:error.apifirestore.initialize"]);

    const user = firebase.getAuthenticatedUser();

    if (!user) return reject(["notifications:error.apifirestore.credential"]);

    resolve(user);
  });
};

const reauthenticateUser = async (
  currentPassword: string
): Promise<UpdateResponseAuthUserProps> => {
  return new Promise(async (resolve, reject) => {
    if (!firebase)
      return reject(["notifications:error.apifirestore.initialize"]);

    const response = await firebase.reauthenticate(currentPassword);
    if (response.success) {
      return resolve(response);
    }
    if (!response.success) {
      return reject(response.code);
    }
    return reject("auth/something-wrong");
  });
};

const changeAuthUserPassword = async (
  newPassword: string
): Promise<UpdateResponseAuthUserProps> => {
  return new Promise(async (resolve, reject) => {
    if (!firebase)
      return reject(["notifications:error.apifirestore.initialize"]);

    const response = await firebase.changePassword(newPassword);
    if (response.success) {
      return resolve(response);
    }
    if (!response.success) {
      return reject(response.code);
    }
    return reject({ success: false, code: "auth/something-wrong" });
  });
};

export interface GetFirestoreDocProps {
  paths: Array<string>;
  filter?: {
    fieldPath: string | FieldPath;
    opStr: WhereFilterOp;
    value: unknown;
  }[];
  sortBy?: {
    fieldPath: string | FieldPath;
    directionStr?: OrderByDirection | undefined;
  }[];
  limitSize?: number;
  limitSizeToLast?: number;
  handleSnapshot?: (snapshot: QuerySnapshot<DocumentData>) => Array<unknown>;
}
export interface UpdateFirestoreFieldProps {
  paths: Array<string>;
  data: {
    [name: string]: Primitive | FieldValue | string[];
  };
}

export interface UpdateFirestoreFieldResponseProps {
  error?: FirestoreErrorHandlerProps;
  ok?: boolean;
}

export interface CheckFirestoreDocExistsResponseProps {
  error?: FirestoreErrorHandlerProps;
  exist?: boolean;
}
export interface SetFirestoreDocResponseProps
  extends UpdateFirestoreFieldResponseProps {}

export interface AddFirestoreDocResponseProps {
  error?: FirestoreErrorHandlerProps;
  id?: string | number;
}

export interface DeleteFirestoreDoc {
  paths: Array<string>;
}

const getFirestoreDocsRTLegacy = (
  { paths, filter, sortBy, limitSize, limitSizeToLast }: GetFirestoreDocProps,
  handleSnapshot: (snapshot: any) => void
): (() => void) => {
  if (!firebase) return () => {};
  const firestoreRef = firebase.getFirestore();

  const queryConstraints = [];

  if (filter && filter.length) {
    filter.forEach(({ fieldPath, opStr, value }) => {
      queryConstraints.push(where(fieldPath, opStr, value));
    });
  }

  if (sortBy && sortBy.length) {
    sortBy.forEach(({ fieldPath, directionStr }) => {
      queryConstraints.push(orderBy(fieldPath, directionStr));
    });
  }

  if (limitSize) {
    queryConstraints.push(limit(limitSize));
  }

  if (limitSizeToLast) {
    queryConstraints.push(limitToLast(limitSizeToLast));
  }

  const collectionRef = collection(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    ...paths
  );

  const unsubscribe = onSnapshot(
    query(collectionRef, ...queryConstraints),
    snapshot => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      handleSnapshot(data);
    },
    error => {
      console.log(error);
    }
  );

  // Return the unsubscribe function or a fallback function
  return unsubscribe ?? (() => {});
};

const getFirestoreDocsRT = ({
  paths,
  filter,
  sortBy,
  limitSize,
  limitSizeToLast,
  handleSnapshot,
}: GetFirestoreDocProps): (() => void) | EventChannel<unknown> => {
  return eventChannel(emit => {
    if (!firebase)
      return () => {
        console.log("closing firebase");
      };
    const firestoreRef = firebase.getFirestore();

    const queryConstraints = [];

    if (filter && filter.length) {
      filter.forEach(({ fieldPath, opStr, value }) => {
        queryConstraints.push(where(fieldPath, opStr, value));
      });
    }

    if (sortBy && sortBy.length) {
      sortBy.forEach(({ fieldPath, directionStr }) => {
        queryConstraints.push(orderBy(fieldPath, directionStr));
      });
    }

    if (limitSize) {
      queryConstraints.push(limit(limitSize));
    }

    if (limitSizeToLast) {
      queryConstraints.push(limitToLast(limitSizeToLast));
    }

    const collectionRef = collection(
      firestoreRef,
      FIRESTORE_MAIN_COLLECTION,
      ...paths
    );

    const unsubscribe = onSnapshot(
      query(collectionRef, ...queryConstraints),
      snapshot => {
        const data = handleSnapshot
          ? handleSnapshot(snapshot)
          : snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
        emit(data);
      }
    );

    const cancelSubscription = () => {
      unsubscribe();
    };

    return cancelSubscription;
  });
};

const getFirestoreDocs = async ({
  paths,
  filter,
  sortBy,
  limitSize,
  limitSizeToLast,
}: GetFirestoreDocProps) => {
  if (!firebase) return;

  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) return;

  const queryConstraints = [];

  if (filter && filter.length) {
    filter.forEach(({ fieldPath, opStr, value }) => {
      queryConstraints.push(where(fieldPath, opStr, value));
    });
  }

  if (sortBy && sortBy.length) {
    sortBy.forEach(({ fieldPath, directionStr }) => {
      queryConstraints.push(orderBy(fieldPath, directionStr));
    });
  }

  if (limitSize) {
    queryConstraints.push(limit(limitSize));
  }

  if (limitSizeToLast) {
    queryConstraints.push(limitToLast(limitSizeToLast));
  }

  const collectionRef = query(
    collection(
      firestoreRef,
      FIRESTORE_MAIN_COLLECTION,
      user.tenantId,
      ...paths
    ),
    ...queryConstraints
  );

  const data = await getDocs(collectionRef)
    .then(result => {
      return result.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
    })
    .catch(error => {
      console.log(error);
      return error;
    });

  return data;
};

const getFirestoreDoc = async ({ paths }: GetFirestoreDocProps) => {
  if (!firebase) return;

  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) return;

  const collectionRef = doc(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    user.tenantId,
    ...paths
  );

  const data = await getDoc(collectionRef)
    .then(doc => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
    .catch(error => {
      console.log(error);
      return error;
    });

  return data;
};

const getFirestoreUserDoc = async ({ paths }: GetFirestoreDocProps) => {
  if (!firebase) return;
  const firestoreRef = firebase.getFirestore();

  const collectionRef = doc(firestoreRef, FIRESTORE_USERS_COLLECTION, ...paths);

  const data = await getDoc(collectionRef)
    .then(doc => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
    .catch(error => {
      console.log(error);
      return error;
    });

  return data;
};

const updateFirestoreDocField = async ({
  paths,
  data,
}: UpdateFirestoreFieldProps): Promise<UpdateFirestoreFieldResponseProps> => {
  if (!firebase) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }
  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }

  const docRef = doc(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    user.tenantId,
    ...paths
  );

  return await updateDoc(docRef, data)
    .then(() => ({ ok: true }))
    .catch((error: FirestoreError) => ({
      error: FirestoreErrorHandler(error),
    }));
};

export type UpdateTenantDataProps = Pick<UpdateFirestoreFieldProps, "data">;

const updateTenantData = async ({
  data,
}: UpdateTenantDataProps): Promise<UpdateFirestoreFieldResponseProps> => {
  if (!firebase) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }
  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.uid) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }

  const docRef = doc(firestoreRef, FIRESTORE_USERS_COLLECTION, user.uid);

  return await updateDoc(docRef, data)
    .then(() => {
      const newData = { ...user, userData: { ...user.userData, ...data } };
      localStorage.setItem("authUser", JSON.stringify(newData));
      return { ok: true };
    })
    .catch((error: FirestoreError) => ({
      error: FirestoreErrorHandler(error),
    }));
};

/**
 *  Check -> any document with id exists
 */
const checkFirestoreDocExists = async ({
  paths,
}: GetFirestoreDocProps): Promise<CheckFirestoreDocExistsResponseProps> => {
  if (!firebase) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }
  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }

  const docRef = doc(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    user.tenantId,
    ...paths
  );

  const docSnap = await getDoc(docRef);

  return { exist: docSnap.exists() };
};

/**
 *  Set -> add new document with custom id
 */
const setFirestoreDoc = async ({
  paths,
  data,
}: UpdateFirestoreFieldProps): Promise<SetFirestoreDocResponseProps> => {
  if (!firebase) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }
  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }

  const docRef = doc(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    user.tenantId,
    ...paths
  );

  return await setDoc(docRef, data)
    .then(() => ({ ok: true }))
    .catch((error: FirestoreError) => ({
      error: FirestoreErrorHandler(error),
    }));
};

/**
 *  Add -> add new document with auto firestore id
 */
const addFirestoreDoc = async ({
  paths,
  data,
}: UpdateFirestoreFieldProps): Promise<AddFirestoreDocResponseProps> => {
  if (!firebase) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }
  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }

  const colRef = collection(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    user.tenantId,
    ...paths
  );

  return await addDoc(colRef, data)
    .then(docRef => ({ id: docRef.id }))
    .catch((error: FirestoreError) => ({
      error: FirestoreErrorHandler(error),
    }));
};

/**
 *  Delete -> delete a document with firestore id
 */
const deleteFirestoreDoc = async ({
  paths,
}: DeleteFirestoreDoc): Promise<UpdateFirestoreFieldResponseProps> => {
  if (!firebase) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }
  const firestoreRef = firebase.getFirestore();
  const user = firebase.getAuthenticatedUser();

  if (!user?.tenantId) {
    return {
      error: FirestoreErrorHandler({ code: "internal" } as FirestoreError),
    };
  }

  const colRef = doc(
    firestoreRef,
    FIRESTORE_MAIN_COLLECTION,
    user.tenantId,
    ...paths
  );

  return await deleteDoc(colRef)
    .then(() => ({ ok: true }))
    .catch((error: FirestoreError) => ({
      error: FirestoreErrorHandler(error),
    }));
};

/**
 *  DateTime -> server firestore timestamp
 */
const fireStoreTimestamp = (): FieldValue | null => {
  if (!firebase) {
    return null;
  }
  return serverTimestamp();
};

export {
  getAuthUserStorageData,
  getFirestoreDocs,
  getFirestoreDoc,
  getFirestoreDocsRTLegacy,
  getFirestoreDocsRT,
  getFirestoreUserDoc,
  updateFirestoreDocField,
  checkFirestoreDocExists,
  setFirestoreDoc,
  addFirestoreDoc,
  fireStoreTimestamp,
  reauthenticateUser,
  changeAuthUserPassword,
  updateTenantData,
  deleteFirestoreDoc,
};

import { ReactNode } from "react";
import classnames from "classnames";

import { Button, ButtonProps, UncontrolledTooltip } from "reactstrap";

interface ChatInputMenuItemMoreProps extends ButtonProps {
  color?: string;
  className?: string;
  show?: boolean;
  children: ReactNode;
}

export const ChatInputMenuItemMore = ({
  color = "none",
  className = null,
  show = true,
  children,
  ...rest
}: ChatInputMenuItemMoreProps) => {
  return (
    show && (
      <>
        <div className="links-list-item">
          <Button
            id="item-more-btn"
            type="button"
            className={classnames(
              "btn btn-link text-decoration-none btn-lg waves-effect",
              className
            )}
            color={color}
            {...rest}
          >
            <i className="bx bx-dots-horizontal-rounded align-middle"></i>
          </Button>
        </div>
        <UncontrolledTooltip target="item-more-btn" placement="top">
          {children}
        </UncontrolledTooltip>
      </>
    )
  );
};

import {
  ref,
  getDownloadURL,
  UploadMetadata,
  uploadBytesResumable,
} from "firebase/storage";

import { STORAGE_IMAGES } from "./urls";

// import {
//   FirestoreErrorHandler,
//   FirestoreErrorHandlerProps,
// } from "./apiFirestoreResponseHandler";

import { getFirebaseBackend } from "../helpers/firebase_helper";

const firebase = getFirebaseBackend();
const imgMaxSize = 5000000;
type PathsProps = typeof STORAGE_IMAGES;

export interface CreateStorageFileProps {
  paths: PathsProps;
  file: File;
  metadata?: UploadMetadata;
  customFileName?: string;
}

const createStorageFile = async ({
  paths,
  file,
  metadata = undefined,
  customFileName = undefined,
}: CreateStorageFileProps): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!firebase) return reject(["notifications:error.apistorage.initialize"]);
    const user = firebase.getAuthenticatedUser();
    if (!user) return reject(["notifications:error.apistorage.credential"]);
    if(file.size > imgMaxSize) { 
      if(customFileName.includes("profile")){
       return reject(["notifications:error.profile.profile/update-profile-picture-size"]);
      }else{
        return reject(["notifications:error.profile.profile/update-profile-cover-size"]);
      }
  }
    const storage = firebase.getStorage();

    // Upload file and metadata to the object 'images/mountains.jpg'
  
    const storageRef = ref(
      storage,
      `/tenants/${user.tenantId}/users/${user.uid}/${paths}/${
        customFileName ? customFileName : file.name
      }`
    );
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      snapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        // switch (snapshot.state) {
        //   case "paused":
        //     console.log("Upload is paused");
        //     break;
        //   case "running":
        //     console.log("Upload is running");
        //     break;
        // }
      },
      error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        reject(error.code);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          // console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export { createStorageFile };

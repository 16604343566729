// types
import { AnalyticsActionTypes, AnalyticsState } from "./types";

export const INIT_STATE: AnalyticsState = {
  campaignsList: undefined,
  isCampaignsListFetched: false,
  getCampaignsListLoading: false,

  selectedCampaign: null,

  overviewData: undefined,
  isOverviewDataFetched: false,
  getOverviewDataLoading: false,

  selectedCampaignData: undefined,
  isSelectedCampaignDataFetched: false,
  getSelectedCampaignDataLoading: false,

  selectedCampaignLeads: undefined,
  isSelectedCampaignLeadsFetched: false,
  getSelectedCampaignLeadsLoading: false,

  selectedContactCampaigns: undefined,
  isSelectedContactCampaignsFetched: false,
  getSelectedContactCampaignsLoading: false,
};

const Chats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AnalyticsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AnalyticsActionTypes.GET_CAMPAIGNS_LIST:
          return {
            ...state,
            campaignsList: action.payload.data,
            isCampaignsListFetched: true,
            getCampaignsListLoading: false,
          };
        case AnalyticsActionTypes.GET_OVERVIEW_DATA:
          return {
            ...state,
            overviewData: action.payload.data,
            isOverviewDataFetched: true,
            getOverviewDataLoading: false,
          };
        case AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA:
          return {
            ...state,
            selectedCampaignData: action.payload.data,
            isSelectedCampaignDataFetched: true,
            getSelectedCampaignDataLoading: false,
          };
        case AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS:
          return {
            ...state,
            selectedCampaignLeads: action.payload.data,
            isSelectedCampaignLeadsFetched: true,
            getSelectedCampaignLeadsLoading: false,
          };
        case AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED:
          if (state && !state.selectedCampaignLeads) {
            return {
              ...state,
              isLeadWasContactedUpdated: false,
            };
          }
          return {
            ...state,
            isLeadWasContactedUpdated: true,
          };
        case AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD:
          if (state && !state.selectedCampaignLeads) {
            return {
              ...state,
              isLeadWasLeadUpdated: false,
            };
          }
          const { index, lead } = action.payload.data;
          const selectedCampaignLeads = [...state?.selectedCampaignLeads];
          selectedCampaignLeads[index] = lead;
          return {
            ...state,
            selectedCampaignLeads,
            isLeadWasContactedUpdated: true,
          };
        case AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS:
          return {
            ...state,
            selectedContactCampaigns: action.payload.data,
            isSelectedContactCampaignsFetched: true,
            getSelectedContactCampaignsLoading: false,
          };
        default:
          return { ...state };
      }

    case AnalyticsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AnalyticsActionTypes.GET_CAMPAIGNS_LIST:
          return {
            ...state,
            isCampaignsListFetched: false,
            getCampaignsListLoading: false,
          };
        case AnalyticsActionTypes.GET_OVERVIEW_DATA:
          return {
            ...state,
            isOverviewDataFetched: false,
            getOverviewDataLoading: false,
          };
        case AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA:
          return {
            ...state,
            isSelectedCampaignDataFetched: false,
            getSelectedCampaignDataLoading: false,
          };
        case AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS:
          return {
            ...state,
            isSelectedCampaignLeadsFetched: false,
            getSelectedCampaignLeadsLoading: false,
          };
        case AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED:
          return {
            ...state,
            isLeadWasContactedUpdated: false,
            error: action.payload.error,
          };
        case AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD:
          return {
            ...state,
            isLeadWasLeadUpdated: false,
            error: action.payload.error,
          };
        case AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS:
          return {
            ...state,
            isSelectedContactCampaignsFetched: false,
            getSelectedContactCampaignsLoading: false,
          };
        default:
          return { ...state };
      }

    case AnalyticsActionTypes.GET_CAMPAIGNS_LIST:
      return {
        ...state,
        isCampaignsListFetched: false,
        getCampaignsListLoading: true,
      };
    case AnalyticsActionTypes.CHANGE_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    case AnalyticsActionTypes.GET_OVERVIEW_DATA:
      return {
        ...state,
        isOverviewDataFetched: false,
        getOverviewDataLoading: true,
      };
    case AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA:
      return {
        ...state,
        isSelectedCampaignDataFetched: false,
        getSelectedCampaignDataLoading: true,
      };
    case AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS:
      return {
        ...state,
        isSelectedCampaignLeadsFetched: false,
        getSelectedCampaignLeadsLoading: true,
      };
    case AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED:
      return {
        ...state,
        isLeadWasContactedUpdated: false,
      };
    case AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD:
      return {
        ...state,
        isLeadWasLeadUpdated: false,
      };
    case AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS:
      return {
        ...state,
        isSelectedContactCampaignsFetched: false,
        getSelectedContactCampaignsLoading: true,
      };
    default:
      return { ...state };
  }
};

export default Chats;

import { HTMLAttributes, ReactNode } from "react";

interface PopoverBoxFooterProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export function PopoverBoxFooter({ children, ...rest }: PopoverBoxFooterProps) {
  return (
    <div {...rest} className="popover-footer border-top">
      {children}
    </div>
  );
}

import { initializeApp, FirebaseApp, FirebaseOptions } from "firebase/app";

// Add the Firebase products that you want to use
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithCredential,
  signOut,
  FacebookAuthProvider,
  GoogleAuthProvider,
  Auth,
  UserCredential,
  OAuthCredentialOptions,
  OAuthCredential,
  User,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  serverTimestamp,
  doc,
  setDoc,
  getDoc,
  Firestore,
} from "firebase/firestore";

import { FirebaseStorage, getStorage } from "firebase/storage";

import { ProfileDetailsBackend } from "../data";
import { TenantTypes } from "../data/settings";
import { setAxiosBaseUrl } from "../api/apiCore";

export interface AuthenticatedUserProps extends User {
  userData: ProfileDetailsBackend | null;
  tenantData: TenantTypes | null;
}

export interface UpdateResponseAuthUserProps {
  code: string;
  success: boolean;
}

class FirebaseAuthBackend {
  private app!: FirebaseApp;
  private auth!: Auth;
  private firestore!: Firestore;
  private storage!: FirebaseStorage;

  constructor(firebaseConfig: FirebaseOptions) {
    if (firebaseConfig) {
      this.app = initializeApp(firebaseConfig);
      this.auth = getAuth(this.app);
      this.firestore = getFirestore(this.app);
      this.storage = getStorage(this.app);

      onAuthStateChanged(this.auth, async user => {
        if (user) {
          const authUser = await this.getAuthUserData(user);
          if (authUser && authUser.tenantData) {
            setLoggeedInUser(authUser);
            setAxiosBaseUrl(authUser.tenantData.UrlApi + "/api");
          }
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser(): AuthenticatedUserProps | null {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const finalAuthUser = JSON.parse(authUser);
      return finalAuthUser;
    }
    return null;
  }

  /**
   * Returns the firestore reference
   */
  getFirestore(): Firestore {
    return this.firestore;
  }

  /**
   * Returns the storage reference
   */
  getStorage(): FirebaseStorage {
    return this.storage;
  }

  /**
   * Get Authenticated user.
   * Get User data from Authenticated user.
   * Get UrlApi from Tenants of Authenticated user.
   * Set new Authenticated user data.
   */
  private async getAuthUserData(
    user: User
  ): Promise<AuthenticatedUserProps | null> {
    const userData = await getDoc(doc(this.firestore, "users", user.uid))
      .then(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        } as ProfileDetailsBackend;
      })
      .catch(error => {
        return Promise.reject(null);
      });

    const tenantData = await getDoc(
      doc(this.firestore, "tenants", String(userData.TenantId))
    )
      .then(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        } as TenantTypes;
      })
      .catch(error => {
        return Promise.reject(null);
      });
    this.auth.tenantId = userData.TenantId;
    return {
      ...user,
      tenantId: userData.TenantId,
      userData,
      tenantData,
    };
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(
        userCredential => {
          resolve(this.auth.currentUser);
        },
        error => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(
        userCredential => {
          resolve(this.auth.currentUser);
        },
        error => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password).then(
        async userCredential => {
          const authUser = await this.getAuthUserData(userCredential.user);
          resolve(JSON.stringify(authUser));
        },
        error => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(this.auth, email, {
        url: window.location.protocol + "//" + window.location.host + "/login",
      })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise(async (resolve, reject) => {
      await signOut(this.auth)
        .then(() => {
          deleteLoggeedInUser();
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Reauthenticate credentials
   * @Return { success | error }
   */
  reauthenticate = async (
    currentPassword: string
  ): Promise<UpdateResponseAuthUserProps> => {
    const user = this.auth.currentUser;

    if (!user || !user.email)
      return { code: "auth/unauthenticated", success: false };

    // Reauthenticate the user with their current password
    const credentials = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    const userCredential = await reauthenticateWithCredential(user, credentials)
      .then(() => {
        return { code: "auth/user-authenticated", success: true };
      })
      .catch(error => {
        return { code: error.code, success: false };
      });

    return userCredential;
  };

  /**
   * Change authenticated user password
   * @Return { success | error }
   */
  changePassword = async (
    newPassword: string
  ): Promise<UpdateResponseAuthUserProps> => {
    const user = this.auth.currentUser;
    if (!user)
      return {
        code: "auth/unauthenticated",
        success: false,
      };

    const userCredential = await updatePassword(user, newPassword)
      .then(() => {
        return { code: "password-changed", success: true };
      })
      .catch(error => {
        return { code: error.code, success: false };
      });

    return userCredential;
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (
    { idToken, accessToken }: OAuthCredentialOptions,
    type: string
  ) => {
    let credential: OAuthCredential;

    if (!accessToken) return;

    if (type === "google") {
      credential = GoogleAuthProvider.credential(idToken, accessToken);
    } else if (type === "facebook") {
      credential = FacebookAuthProvider.credential(accessToken);
    }

    return new Promise((resolve, reject) => {
      if (!!credential) {
        signInWithCredential(this.auth, credential)
          .then(userCredential => {
            const userAdded = this.addNewUserToFirestore(userCredential);
            const userAddToJson = JSON.stringify(userAdded);
            resolve(userAddToJson);
          })
          .catch(error => {
            reject(this._handleError(error));
          });
      } else {
        // reject(this._handleError(error));
      }
    });
  };

  addNewUserToFirestore = async (userCredential: UserCredential) => {
    const { user } = userCredential;

    const details = {
      uid: user.uid,
      fullName: user.displayName,
      email: user.email,
      phone: user.phoneNumber,
      picture: user.photoURL,
      refreshToken: user.refreshToken,
      createdDtm: serverTimestamp(),
      lastLoginTime: serverTimestamp(),
    };

    const collectionRef = collection(this.firestore, "users");

    await setDoc(doc(collectionRef, this.auth.currentUser?.uid), details);

    return { user, details };
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend: FirebaseAuthBackend | null = null;

const hasLoggeedInUser = () => {
  if (localStorage.getItem("authUser")) {
    return true;
  }
  return false;
};

const setLoggeedInUser = (user: AuthenticatedUserProps | null) => {
  if (!user) return false;
  localStorage.setItem("authUser", JSON.stringify(user));
  return hasLoggeedInUser();
};

const deleteLoggeedInUser = () => {
  localStorage.removeItem("authUser");
  return !hasLoggeedInUser();
};

/**
 * Initilize the backend
 * @param {*} config FirebaseOptions
 */
const initFirebaseBackend = (config: FirebaseOptions) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export {
  initFirebaseBackend,
  getFirebaseBackend,
  setLoggeedInUser,
  deleteLoggeedInUser,
  hasLoggeedInUser,
};

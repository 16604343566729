import React, { useEffect, useState } from "react";
// hooks
import { useRedux } from "../../../../../hooks/index";
// actions
import {
  changeSelectedCampaign,
  changeSelectedChat,
  // changeTab,
  getChatUserConversations,
  getChatUserDetails,
  getSelectedCampaignLeads,
  readConversation,
  updateLeadWasContacted,
  updateLeadIsLead,
} from "../../../../../redux/actions";

import { Table } from "./LeadsComponents/Table";
import Loader from "../../../../../components/Loader";

// import { TABS } from "../../../../../constants";

interface LeadsSectionProps {
  id: string | number;
  period?: { startDate: string; endDate: string };
}

export const LeadsSection = ({ id, period }: LeadsSectionProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    selectedCampaignLeads,
    getSelectedCampaignLeadsLoading,
    chatUserDetails,
    getUserDetailsLoading,
  } = useAppSelector((state: any) => ({
    selectedCampaignLeads: state.Analytics.selectedCampaignLeads,
    getSelectedCampaignLeadsLoading:
      state.Analytics.getSelectedCampaignLeadsLoading,
    chatUserDetails: state.Chats.chatUserDetails,
    getUserDetailsLoading: state.Chats.getUserDetailsLoading,
  }));

  const [isContactClicked, setIsContactClicked] = useState(false);

  function handleToggleWasContacted(index: number) {
    dispatch(
      updateLeadWasContacted(id, {
        index,
        lead: selectedCampaignLeads[index],
      })
    );
  }
  function handleToggleIslead(index: number) {
    dispatch(
      updateLeadIsLead(id, {
        index,
        lead: selectedCampaignLeads[index],
      })
    );
  }

  function handleClickContact(userId: string) {
    dispatch(getChatUserDetails(userId));
    setIsContactClicked(true);
  }

  useEffect(() => {
    if (!id) return;
    dispatch(getSelectedCampaignLeads(id));
    dispatch(changeSelectedChat(null));
  }, [dispatch, id]);

  useEffect(() => {
    if (isContactClicked && !getUserDetailsLoading) {
      dispatch(changeSelectedCampaign(null));
      dispatch(changeSelectedChat(chatUserDetails?.id));
      dispatch(getChatUserConversations(chatUserDetails?.id));
      dispatch(readConversation(chatUserDetails?.id));
      //dispatch(changeTab(TABS.CHAT));
    }
  }, [dispatch, chatUserDetails, isContactClicked, getUserDetailsLoading]);

  if (!selectedCampaignLeads) return <></>;
  return (
    <div className="container px-4 my-5 leads-section">
      {getSelectedCampaignLeadsLoading ? (
        <Loader />
      ) : (
        <Table
          data={selectedCampaignLeads}
          handleClickContact={handleClickContact}
          handleToggleWasContacted={handleToggleWasContacted}
          handleToggleIslead={handleToggleIslead}
        />
      )}
    </div>
  );
};

import { ReactNode } from "react";
import { PopoverBody, PopoverBodyProps } from "reactstrap";

interface PopoverBoxContentProps extends PopoverBodyProps {
  children: ReactNode;
}

export function PopoverBoxContent({
  children,
  ...rest
}: PopoverBoxContentProps) {
  return <PopoverBody {...rest}>{children}</PopoverBody>;
}

import { ReactNode } from "react";

interface FileItemActionsProps {
  children: ReactNode;
}

export function FileItemActions({ children }: FileItemActionsProps) {
  return (
    <div className="flex-shrink-0 ms-3">
      <div className="d-flex gap-2">{children}</div>
    </div>
  );
}

// interfaces
import { ChannelTypes } from "./chat";
import { MediaTypes, AttachedfileTypes, ProfileBoards, ActivitiesBoard } from "./myProfile";
import { STATUS_TYPES } from "../constants";

/**
 * 
  public enum Platform
  {
      WhatsApp,
      Instagram,
      Messenger        
  }
 * 
 */
export enum PlatformTypes {
  "whatsApp" = 0,
  "instagram" = 1,
  "messenger" = 2,
}

export interface TagProps {
  id: string;
  Color: string;
  Name: string;
  UsedCount: number;
  UsedAt: string;
  CreatedAt: string;
  EditedAt: string;
}

export interface ContactTypesBackend {
  id: string | number;
  AvatarUrl: string;
  IsFavorite: false;
  IsArchived: false;
  LastMessageAt: string;
  LastMessagePreview: string | null;
  Name: string;
  PhoneNumber: string;
  UnreadCount: number;
  Platform: PlatformTypes;
  AssignedAttendant: string | number;
  Tags: string[] | null;
  boards: ProfileBoards
}

export interface ProfileBoardsBackend {
  id: string;
  activities: Array<ActivitiesBoard>;
  Column: number;
  ColumnPosition: number;
  Description: string
}

export interface ContactTypes {
  id: string | number;
  firstName: string;
  lastName: string;
  profileImage?: string;
  about?: string;
  email?: string;
  location?: string;
  channels?: Array<ChannelTypes>;
  media?: MediaTypes;
  attachedFiles?: AttachedfileTypes;
  status?: STATUS_TYPES;
  isFavourite?: boolean;
  isArchived?: boolean;
  platform: PlatformTypes;
  phoneNumber: string;
  assignedAttendant?: string | number;
  tags: string[];
  boards: ProfileBoards
}

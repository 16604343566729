import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { TemplatesActionTypes } from "./types";
import {
  TemplatesApiResponseSuccess,
  TemplatesApiResponseError,
} from "./actions";

import {
  getTemplates as getTemplatesApi,
  sendTemplate as sendTemplateApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getTemplates() {
  try {
    const response: Promise<any> = yield call(getTemplatesApi);
    yield put(
      TemplatesApiResponseSuccess(TemplatesActionTypes.GET_TEMPLATES, response)
    );
  } catch (error: any) {
    yield put(
      TemplatesApiResponseError(TemplatesActionTypes.GET_TEMPLATES, error)
    );
  }
}

function* sendTemplate({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendTemplateApi, data);
    yield put(
      TemplatesApiResponseSuccess(TemplatesActionTypes.SEND_TEMPLATE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      TemplatesApiResponseError(TemplatesActionTypes.SEND_TEMPLATE, error)
    );
  }
}

export function* watchGetTemplates() {
  yield takeEvery(TemplatesActionTypes.GET_TEMPLATES, getTemplates);
}

export function* watchSendTemplate() {
  yield takeEvery(TemplatesActionTypes.SEND_TEMPLATE, sendTemplate);
}

function* templatesSaga() {
  yield all([fork(watchGetTemplates), fork(watchSendTemplate)]);
}

export default templatesSaga;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export type AreaChartOptionsProps = ChartOptions<"line">;
export type AreaChartDataProps = ChartData<"line">;

export interface AreaChartProps {
  options?: AreaChartOptionsProps;
  data: AreaChartDataProps;
}

export const AreaChart = ({ data, options }: AreaChartProps) => {
  return <Line data={data} options={options} />;
};

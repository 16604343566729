import { HTMLAttributes, ReactNode } from "react";
import classnames from "classnames";
interface CardMenuItemTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
}

export const CardMenuItemTitle = ({
  className,
  children,
  ...rest
}: CardMenuItemTitleProps) => {
  return (
    <h5
      className={classnames(
        "font-size-11 text-uppercase mb-0 text-body text-truncate",
        className
      )}
      {...rest}
    >
      {children}
    </h5>
  );
};

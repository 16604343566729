// types
import { NotificationsActionTypes, NotificationsState } from "./types";

export const INIT_STATE: NotificationsState = {
  notifications: undefined,
  isNotificationsFetched: false,
  getNotificationsLoading: false,

  contactNotifications: undefined,
  isContactNotificationsFetched: false,
  getContactNotificationsLoading: false,

  isNotificationStatusUpdated: false,
};

const Chats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case NotificationsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case NotificationsActionTypes.GET_NOTIFICATIONS:
          return {
            ...state,
            notifications: action.payload.data,
            isNotificationsFetched: true,
            getNotificationsLoading: false,
          };
        case NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS:
          return {
            ...state,
            contactNotifications: action.payload.data,
            isContactNotificationsFetched: true,
            getContactNotificationsLoading: false,
          };
        case NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS:
          return {
            ...state,
            isNotificationStatusUpdated: true,
          };
        default:
          return { ...state };
      }

    case NotificationsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case NotificationsActionTypes.GET_NOTIFICATIONS:
          return {
            ...state,
            isNotificationsFetched: false,
            getNotificationsLoading: false,
          };
        case NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS:
          return {
            ...state,
            isContactNotificationsFetched: false,
            getContactNotificationsLoading: false,
          };
        case NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS:
          return {
            ...state,
            isNotificationStatusUpdated: false,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case NotificationsActionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        isNotificationsFetched: false,
        getNotificationsLoading: true,
      };
    case NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS:
      return {
        ...state,
        isContactNotificationsFetched: false,
        getContactNotificationsLoading: true,
      };
    case NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS:
      return {
        ...state,
        isNotificationStatusUpdated: false,
      };
    default:
      return { ...state };
  }
};

export default Chats;

import { CardMenuContent } from "./CardMenuContent";
import { CardMenuItem } from "./CardMenuItem";
import { CardMenuItemBody } from "./CardMenuItemBody";
import { CardMenuItemHeader } from "./CardMenuItemHeader";
import { CardMenuItemIcon } from "./CardMenuItemIcon";
import { CardMenuItemSubTitle } from "./CardMenuItemSubTitle";
import { CardMenuItemTitle } from "./CardMenuItemTitle";

export const CardMenu = {
  Root: CardMenuContent,
  Item: CardMenuItem,
  ItemHeader: CardMenuItemHeader,
  ItemBody: CardMenuItemBody,
  ItemTitle: CardMenuItemTitle,
  ItemSubTitle: CardMenuItemSubTitle,
  ItemIcon: CardMenuItemIcon,
};

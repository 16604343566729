import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// hooks
import { useRedux } from "../../../hooks/useRedux";

// interface
import { UserTypes } from "../../../data/chat";
import { NotificationsProps } from "../../../data/notifications";

import AvatarName from "../../../components/AvatarName";
import Notifications from "./Notifications";
import { Badge } from "reactstrap";
import { STATUS_TYPES } from "../../../constants";
import AvatarAttendant from "../../../components/AvatarAttendant";
import { AttendantProps } from "../../../data/attendants";
import { TagProps } from "../../../data";
import { TagsList } from "../../../components/Tags";

interface ChatUserProps {
  user: UserTypes;
  selectedChat: string | number;
  onSelectChat: (
    id: string | number,
    isChannel?: boolean,
    notifications?: NotificationsProps[]
  ) => void;
}
const ChatUser = ({ user, selectedChat, onSelectChat }: ChatUserProps) => {
  // global store
  const { useAppSelector } = useRedux();

  const { notifications, attendants, tags } = useAppSelector(
    useMemo(() => (state) => ({
      notifications: state.Notifications.notifications,
      attendants: state.Attendants.attendants,
      tags: state.Chats.tags,
    }), [])
  );

  /*
  get data
  */
  const [contactNotifications, setContactNotification] =
    useState<NotificationsProps[]>();

  useEffect(() => {
    if (!notifications || notifications.length <= 0) {
      setContactNotification([]);
      return;
    }
    const filteredContactNotification = notifications.filter(
      (notification: NotificationsProps) => notification.ClientId === user.id
    );
    setContactNotification(filteredContactNotification);
  }, [notifications, user.id]);

  const fullName = `${user.firstName} ${user.lastName}`;
  const isOnline = user.status && user.status === STATUS_TYPES.ACTIVE;
  const unRead = user.meta && user.meta.unRead;
  const isSelectedChat = selectedChat && selectedChat === user.id;
  const assignedAttendant: AttendantProps | null =
    user.assignedAttendant && attendants && attendants.length
      ? attendants.filter(
          (attendant: AttendantProps) => attendant.id === user.assignedAttendant
        )[0]
      : null;
  const contactAssignedTags =
    user && user.tags
      ? user.tags.map(tagId =>
          tags ? tags.filter((tag: TagProps) => tag.id === tagId)[0] : null
        )
      : null;

  const onClick = () => {
    onSelectChat(user.id, false, contactNotifications);
  };

  return (
    <li className={classnames({ active: isSelectedChat })}>
      <Link
        to="#"
        className={classnames("row m-0 col-10", {
          "unread-msg-user": unRead,
        })}
        onClick={onClick}
      >
        <div className="d-flex gap-2 p-0 m-0">
          <Notifications id={user.id} notifications={contactNotifications} />

          <div className={classnames("chat-user-img", { online: isOnline })}>
            <AvatarName
              className="avatar-sm font-size-12"
              user={user}
              showPlatform
            />
          </div>

          <div className="overflow-hidden col d-flex justify-content-center flex-column">
            <div className="d-flex align-items-center ">
              {assignedAttendant ? (
                <AvatarAttendant
                  className="avatar-xxs"
                  attendant={assignedAttendant}
                  showArrow
                  tooltip
                />
              ) : null}
              <span className="text-truncate mb-0">{fullName}</span>
            </div>

            {contactAssignedTags && contactAssignedTags.length ? (
              <TagsList.Root className="chat-user-tags gap-1 col-10 flex-nowrap">
                {contactAssignedTags.map((tag: TagProps) => {
                  return tag ? (
                    <TagsList.Container key={tag.id}>
                      <TagsList.Item
                        color={tag.Color}
                        active={isSelectedChat}
                        className={classnames({
                          active: isSelectedChat,
                        })}
                      >
                        {tag.Name}
                      </TagsList.Item>
                    </TagsList.Container>
                  ) : null;
                })}
              </TagsList.Root>
            ) : null}
          </div>

          {unRead && unRead > 0 ? (
            <div className="more-info">
              <Badge color="rgba(52, 58, 64, 0.18)" className="badge-soft-dark rounded px-1">{unRead}</Badge>

              {/* <AvatarName
                className="avatar-xs assigned-attendant"
                user={user}
                showPlatform
              /> */}
            </div>
          ) : null}
        </div>
      </Link>

      {/* <div className="chat-user-actions col-2">
        <div>
          <button
            id={`add-concluded-${user.id}`}
            className="btn btn-soft-primary btn-sm p-0 pt-1 rounded-0"
            onClick={() => {}}
          >
            <i className="bx bxs-check-circle" />
          </button>
          <UncontrolledTooltip
            target={`add-concluded-${user.id}`}
            placement="left"
          >
            Concluir
          </UncontrolledTooltip>
        </div>
        <div>
          <button
            id={`add-bookmark-${user.id}`}
            className="btn btn-soft-primary btn-sm p-0 pt-1 rounded-0"
            onClick={() => {}}
          >
            <i className="bx bxs-bookmark-star" />
          </button>
          <UncontrolledTooltip
            target={`add-bookmark-${user.id}`}
            placement="left"
          >
            Acompanhar
          </UncontrolledTooltip>
        </div>
      </div> */}
    </li>
  );
};

export default ChatUser;

import { Button, PopoverBody } from "reactstrap";
import MicRecorder from 'mic-recorder-to-mp3';
import  { useState,useEffect, Dispatch, SetStateAction } from 'react';
import { TypeOfAttachment, TypeOfMedia } from "../../../../data";
import { MediaItemPreviewAudio } from "../../../../components/Media/MediaItemPreviewAudio";

interface RecordProps {
    disabled: boolean;
    setFiles:Dispatch<SetStateAction<{ mediaType: TypeOfMedia; media: { files: any[]; attachmentType: TypeOfAttachment; }; }>>
}

const Recorder = ({ setFiles, disabled }: RecordProps) => {
  const [blobUrl,setBlobUrl] = useState("");
  const [isRecording,setIsRecording] = useState(false);
  const [Mp3Recorder, setMp3Recorder] = useState(
new MicRecorder({ bitRate: 128,prefix: "data:audio/wav;base64," })
);
useEffect(()=>{
  if (disabled) {
    setBlobUrl(null)
  }
},[disabled])

const deleteAudio = () =>{
  setFiles(null)
  setBlobUrl(null) 
}
const start = () => {
      Mp3Recorder
        .start()
        .then(() => {
          setIsRecording(true);
        }).catch((e) => console.error(e));
  };
const stop = () => {
    Mp3Recorder
      .stop()
      .getMp3() 
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        const audioFile = new File([blob],"recordedAudio",{type:"audio/mpeg"})
        setBlobUrl(blobURL)
        setIsRecording(false)
        setFiles({mediaType:0,media:{files:[audioFile],attachmentType:2}})
      }).catch((e) => console.log(e));
  };
 
  return (
    <div className="chat-input-links ms-2 gap-md-1">
      
      <div className="links-list-item  d-sm-block">
         {
         blobUrl ? 
         <Button
          onClick={() => deleteAudio()}
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
        > 
          <i className="bx bx-trash-alt align-middle red"></i>
        </Button>
        : 
          <Button
          onClick={()=>{
            !isRecording ? start() : stop()
          }}
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
        >{ !isRecording ?  <i className="bx bx-microphone align-middle"></i> 
        :<i className="bx bx-stop-circle align-middle red"></i>

        }
        </Button>
        }
            
      </div>
       {  isRecording && !blobUrl ?  
       <PopoverBody >
          <div className="loader-line">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </PopoverBody> 
        : blobUrl ? <MediaItemPreviewAudio mimeType="audio/mpeg" url={blobUrl} ></MediaItemPreviewAudio> : null
       } 
    </div>
  );
};

export default Recorder;

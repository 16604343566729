import classNames from "classnames";
import { HTMLAttributes } from "react";

interface TemplateMessageBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

export function TemplateMessageBody({
  children,
  className,
  ...rest
}: TemplateMessageBodyProps) {
  return (
    <div className={classNames("px-4 py-3", className)} {...rest}>
      {children}
    </div>
  );
}

import { Timestamp } from "firebase/firestore";
import { KanbanComment } from "../../data/kanban";

export enum KanbanActionTypes {
  API_RESPONSE_SUCCESS = "@@kanban/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@kanban/API_RESPONSE_ERROR",

  GET_BOARDS = "@@kanban/GET_BOARDS",
  CREATE_BOARD = "@@kanban/CREATE_BOARD",
  GET_COMMENTS = "@@kanban/GET_COMMENTS",
  CREATE_COMMENT = "@@kanban/CREATE_COMMENT",
}

export type BoardBackendProps = {
  id: string | number;
  Columns: string[];
  Active: boolean;
  CreatedAt: Timestamp;
};

export type BoardProps = {
  id: string | number;
  columns: string[];
  active: boolean;
  createdAt: string | Date;
};

export type CommentBackendProps = {
  id: string | number;
  AttendantId: string;
  Message: string;
  CreatedAt: Timestamp;
};

export type CommentProps = {
  id: string | number;
  attendantId: string;
  contactId: string;
  message: string;
  createdAt: Timestamp;
};

export interface KanbanState {
  boards: BoardProps[];
  isBoardsFetched: boolean | null;
  isKanbanBoardAdded: boolean | null;
  comments: KanbanComment[];
  isCommentsFetched: boolean | null;
}

import AvatarName from "../AvatarName";
import classnames from "classnames";

import { getFirstLastName } from "../../utils";
import { STATUS_TYPES } from "../../constants";
import { AttendantProps } from "../../data/attendants";
import { TagProps, UncontrolledTooltip } from "reactstrap";
import { KanbanDataModel } from "../../data/kanban";
import { TagsList } from "../Tags";

interface KanbanCardTemplateProps {
  props: KanbanDataModel;
  attendants: Array<AttendantProps>;
  tags: Array<TagProps>;
}

const KanbanCardTemplate = ({ props, attendants, tags }: KanbanCardTemplateProps) => {

  const { firstName, lastName } = getFirstLastName(props.title as string);
  const isOnline = STATUS_TYPES.ACTIVE;

  const user = {
    id: props.contactId as string,
    platform: props.platform as number,
    firstName: firstName,
    lastName: lastName,
    tags: props.tags as string[],
    assignedAttendant: props.assignee as string
  }

  const contactAssignedTags =
    user && user.tags
      ? user.tags.map(tagId =>
        tags ? tags.filter((tag: TagProps) => tag.id === tagId)[0] : null
      )
      : null;

  function selectPriority(priority: string): string {
    const priorityMap: { [key: string]: string } = {
      "Baixa": "https://syncfusion.atlassian.net/images/icons/priorities/minor.svg",
      "Normal": "https://syncfusion.atlassian.net/images/icons/priorities/trivial.svg",
      "Alta": "https://syncfusion.atlassian.net/images/icons/priorities/major.svg",
      "Crítica": "https://syncfusion.atlassian.net/images/icons/priorities/critical.svg"
    };

    return priorityMap[priority] || "https://syncfusion.atlassian.net/images/icons/priorities/trivial.svg";
  }


  return (
    <div className="card-template">
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-header-title">
            <div>
              <UncontrolledTooltip placement="top" target={"priority-" + props.contactId}>
                {"Prioridade: "+ props.priority}
              </UncontrolledTooltip>
              <img id={"priority-" + props.contactId} className="priority-tag" alt="" src={selectPriority(props.priority)}></img>
            </div>
            <div className={classnames("chat-user-img", { online: isOnline }, "chat-user-img2")}>
              <AvatarName
                className="avatar-sm font-size-12"
                user={user}
                showPlatform
              />
            </div>
            <div className="bold-dark-gray-text e-tooltip-text">
              <span className="span-title-kanban">{props.title}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="e-card-content e-tooltip-text">
        <div className="e-text">{props.summary}</div>
      </div>
      <div className="e-card-custom-footer">
        {contactAssignedTags && contactAssignedTags.length ? (
          <TagsList.Root className="chat-user-tags gap-1 col-10">
            {contactAssignedTags.map((tag: TagProps) => {
              return tag ? (
                <TagsList.Container key={tag.id}>
                  <TagsList.Item
                    color={tag.Color}
                    active={false}
                    className={classnames({
                      active: false,
                    })}
                  >
                    {tag.Name}
                  </TagsList.Item>
                </TagsList.Container>
              ) : null;
            })}
          </TagsList.Root>
        ) : null}

        {/* {props.tags.split(",").map((tag: string) => (
                  <div className="e-card-tag-field e-tooltip-text" key={tag}>{tag}</div>
              ))} */}

        {/* <div className="e-card-tag-field e-tooltip-text" key={props.priority as string}>{props.priority}</div> */}
        {/* <img className="e-card-avatar" src="https://firebasestorage.googleapis.com/v0/b/surfphotospt.appspot.com/o/tenants%2FHBhigPUXa2YNz2sEwyOJ%2Fusers%2FMkDg2z2s1DeIizgttJtNEQnBXY22%2Fimages%2Fprofile.jfif?alt=media&token=a2ddec89-203a-422c-b31f-3610cd600d20" /> */}
      </div>
    </div>
  );
}


export default KanbanCardTemplate;

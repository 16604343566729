import React, { useEffect, useState, useMemo } from "react";

//translations
import { useTranslation } from "react-i18next";

import { AttendantProps } from "../../../data/attendants";
import { PopoverBox } from "../../../components/PopoverBox";
import { useRedux } from "../../../hooks/index";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AvatarAttendant from "../../../components/AvatarAttendant";
import {
  assignAttendant,
  getChatUserDetails,
  unassignAttendant,
} from "../../../redux/actions";

interface AssignAttendantProps {
  attendant: AttendantProps;
}

const AssignAttendant = ({ attendant }: AssignAttendantProps) => {
  const { t } = useTranslation(["common", "form"]);

  // global store
  const { useAppSelector, dispatch } = useRedux();

  const { selectedChat, attendants, isAttendantAssign, isAttendantUnassign } =
    useAppSelector(
      useMemo(() => (state) => ({
        selectedChat: state.Chats.selectedChat,
        attendants: state.Attendants.attendants,
        isAttendantAssign: state.Chats.isAttendantAssign,
        isAttendantUnassign: state.Chats.isAttendantUnassign,
      }), [])
    );


  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  function handleToggleIsOpen() {
    setPopoverIsOpen(prev => !prev);
  }

  function handleAssignAttendant(attendant: AttendantProps) {
    if (!selectedChat && !attendant.id) return;
    dispatch(assignAttendant({ id: selectedChat, attendantId: attendant.id }));
  }

  function handleUnassignAttendant() {
    if (!selectedChat) return;
    dispatch(unassignAttendant(selectedChat));
  }

  useEffect(() => {
    if (selectedChat && (isAttendantAssign || isAttendantUnassign)) {
      dispatch(getChatUserDetails(selectedChat));
    }
  }, [dispatch, selectedChat, isAttendantAssign, isAttendantUnassign]);

  return (
    <div>
      <small
        id="tooltipBoxBtn"
        className="cursor-pointer btn-text-light"
        onClick={handleToggleIsOpen}
      >
        {attendant
          ? `${t(["common:options.assigned-to"])} ${attendant.fullName}`
          : t(["common:options.assign-conversation"])}
        <i className="bx bxs-down-arrow font-size-10 p-1" />
      </small>
      <PopoverBox.Root
        isOpen={popoverIsOpen}
        target="tooltipBoxBtn"
        placement="bottom-start"
        toggle={handleToggleIsOpen}
        trigger="legacy"
        className="popover-box"
      >
        <PopoverBox.Header>{t(["common:options.assign-to"])}</PopoverBox.Header>
        <PopoverBox.Content>
          <UncontrolledDropdown>
            <DropdownToggle color="soft-secondary">
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-truncate">
                  {attendant
                    ? attendant.fullName
                    : t(["form:placeholder.select-attendant"])}
                </span>
                <i className="bx bxs-down-arrow font-size-10" />
              </div>
            </DropdownToggle>
            <DropdownMenu>
              {attendant ? (
                <>
                  <DropdownItem
                    key={attendant?.id}
                    onClick={handleUnassignAttendant}
                    className="selected"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <i className="bx bx-x font-size-18" />
                      <AvatarAttendant
                        className="avatar-xs"
                        attendant={attendant}
                      />
                      <span className="text-truncate">
                        {attendant?.fullName}
                      </span>
                    </div>
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              ) : null}
              {attendants && attendants.length
                ? attendants.map((attendantItem: AttendantProps) =>
                    attendantItem.id !== attendant?.id ? (
                      <DropdownItem
                        key={attendantItem.id}
                        onClick={() => handleAssignAttendant(attendantItem)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <AvatarAttendant
                            className="avatar-xs"
                            attendant={attendantItem}
                          />
                          <span className="text-truncate">
                            {attendantItem.fullName}
                          </span>
                        </div>
                      </DropdownItem>
                    ) : null
                  )
                : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </PopoverBox.Content>
      </PopoverBox.Root>
    </div>
  );
};

export default AssignAttendant;

import { WhereFilterOp } from "firebase/firestore/lite";
import { ContactTypesBackend, TagProps } from "../../data";

export enum ChatsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_FAVOURITES = "@@chats/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@chats/GET_DIRECT_MESSAGES",
  GET_CHANNELS = "@@chats/GET_CHANNELS",

  ADD_CONTACTS = "@@chats/ADD_CONTACTS",
  CREATE_CHANNEL = "@@chats/CREATE_CHANNEL",
  CHANGE_SELECTED_CHAT = "@@chats/CHANGE_SELECTED_CHAT",
  GET_CHAT_USER_DETAILS = "@@chats/GET_CHAT_USER_DETAILS",
  GET_CHAT_USER_CONVERSATIONS = "@@chats/GET_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@chats/TOGGLE_USER_DETAILS_TAB",
  GET_CHAT_USER_MEDIA_HISTORY = "@@chats/GET_CHAT_USER_MEDIA_HISTORY",

  // MESSAGE
  ON_SEND_MESSAGE = "@@chats/ON_SEND_MESSAGE",
  RECEIVE_MESSAGE = "@@chats/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@chats/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@chats/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@chats/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@chats/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@chats/DELETE_USER_MESSAGES",
  GET_CHANNEL_DETAILS = "@@chats/GET_CHANNEL_DETAILS",
  TOGGLE_FAVOURITE_CONTACT = "@@chats/TOGGLE_FAVOURITE_CONTACT",
  GET_ARCHIVE_CONTACT = "@@chats/GET_ARCHIVE_CONTACT",
  TOGGLE_ARCHIVE_CONTACT = "@@chats/TOGGLE_ARCHIVE_CONTACT",
  READ_CONVERSATION = "@@chats/READ_CONVERSATION",
  DELETE_IMAGE = "@@chats/DELETE_IMAGE",

  //ROBOT
  GET_ROBOT_STATUS = "@@chats/GET_ROBOT_STATUS",
  TOGGLE_ROBOT_STATUS = "@@chats/TOGGLE_ROBOT_STATUS",

  //CHAT STATUS
  SET_CHAT_STATUS = "@@chats/SET_CHAT_STATUS",

  //ATTENDANTS
  ASSIGN_ATTENDANT = "@@chats/ASSIGN_ATTENDANT",
  UNASSIGN_ATTENDANT = "@@chats/UNASSIGN_ATTENDANT",

  //FILTERS
  SET_CHAT_LIST_FILTERS = "@@chats/SET_CHAT_LIST_FILTERS",

  //TAGS
  GET_TAGS = "@@chats/GET_TAGS",
  ADD_CONTACT_TAG = "@@chats/ADD_CONTACT_TAG",
  REMOVE_CONTACT_TAG = "@@chats/REMOVE_CONTACT_TAG",
  CREATE_TAG = "@@chats/CREATE_TAG",
  DELETE_TAG = "@@chats/DELETE_TAG",
}

export interface RobotStatusProps {
  contactId: string | number;
  isStopped: boolean;
  expirationTime: Date;
}

export interface ChatStatusProps {
  isActive: boolean;
  expirationTime: Date;
}

type FilterParams = {
  fieldPath: keyof ContactTypesBackend;
  opStr: WhereFilterOp;
  value: unknown;
};

export interface ChatsState {
  favourites: Array<any>;
  getFavouritesLoading: boolean;
  directMessages: Array<any>;
  getDirectMessagesLoading: boolean;
  channels: Array<any>;
  selectedChat: string | number | null;
  chatUserDetails: object;
  isUserDetailsFetched: boolean | null;
  chatUserConversations: {};
  isUserConversationsFetched: boolean | null;
  isOpenUserDetails: boolean;
  channelDetails: object;
  archiveContacts: Array<any>;
  isContactsAdded: boolean | null;
  isChannelCreated: boolean | null;
  isFavouriteContactToggled: boolean | null;
  isContactArchiveToggled: boolean | null;
  isUserMessageSent: boolean | null;
  isMessageDeleted: boolean | null;
  isMessageForwarded: boolean | null;
  isUserMessagesDeleted: boolean | null;
  isImageDeleted: boolean | null;
  getUserDetailsLoading: boolean | null;
  robotStatus: RobotStatusProps | null;
  isRobotStatusFetched: boolean | null;
  getRobotStatusLoading: boolean | null;
  isRobotStatusToggled: boolean | null;
  getMediaHistoryLoading: boolean;
  isMediaHistoryFetched: boolean;
  chatStatus: ChatStatusProps;
  isChatStatusSet: boolean | null;
  isAttendantAssign: boolean;
  isAttendantUnassign: boolean;
  chatListFilters: FilterParams[] | null;
  tags: TagProps[] | null;
  getTagsFetched: boolean | null;
  getTagsLoading: boolean | null;
  isContactTagAdded: boolean | null;
  isContactTagRemoved: boolean | null;
  isTagCreated: boolean | null;
  isTagDeleted: boolean | null;
}

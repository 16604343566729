import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";

import { PlatformTypes, TypeOfAttachment, TypeOfMedia } from "../../../../data";
import { CardMenu } from "../../../../components/CardMenu";
import { ChatsState } from "../../../../redux/chats/types";
import ChatBotSection from "./ChatbotSection";
import { RobotStatus } from "../../../../components/RobotStatusButton";
interface MoreMenuProps extends Pick<ChatsState, "chatStatus" | "robotStatus"> {
  isOpen: boolean;
  onSelectFiles: (
    mediaType: TypeOfMedia,
    media: {
      files: Array<any>;
      attachmentType: TypeOfAttachment;
    }
  ) => void;
  
  onToggleTemplates: () => void;
  platform: PlatformTypes;
}

const MoreMenu = ({
  isOpen = false,
  onSelectFiles,
  chatStatus,
  robotStatus,
  onToggleTemplates,
  platform,
  
}: MoreMenuProps) => {
  const { t } = useTranslation(["conversation"]);

  function onSelect(
    e: any,
    attachmentType: TypeOfAttachment,
    mediaType: TypeOfMedia
  ) {
    const files = [...e.target.files];

    if (files) {
      onSelectFiles(mediaType, {
        files,
        attachmentType,
      });
    }
  }

  return (
    <CardMenu.Root
      className="chat-input-collapse"
      id="chat-input-more-collapse"
      isOpen={isOpen}
    >
      <CardMenu.Item className="btn-robot-more-section">
        <CardMenu.ItemBody>
          <ChatBotSection className="border-0" />
        </CardMenu.ItemBody>
      </CardMenu.Item>
      
      {chatStatus.isActive &&
        robotStatus.isStopped &&
        platform === PlatformTypes.whatsApp && (
          <CardMenu.Item className="btn-robot-more-section">
            <CardMenu.ItemBody>
              <RobotStatus.Root className="border-0">
                <RobotStatus.Button color="info" onClick={onToggleTemplates}>
                  <i className="bx bx-mail-send no-text" />
                </RobotStatus.Button>
              </RobotStatus.Root>
            </CardMenu.ItemBody>
          </CardMenu.Item>
        )}
      
        
      {/* Items */}
      <CardMenu.Item
        className={classnames({
          "d-none":
            !chatStatus.isActive ||
            (chatStatus.isActive && !robotStatus.isStopped),
          "d-block": chatStatus.isActive && robotStatus.isStopped,
          "opacity-50": platform !== PlatformTypes.whatsApp,
        })}
        hoverHue={platform === PlatformTypes.whatsApp}
      >
        <CardMenu.ItemBody>
          <Input
            id="attached-file-input"
            type="file"
            className="d-none"
            accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf"
            onChange={(e: any) =>
              onSelect(e, TypeOfAttachment.document, TypeOfMedia.document)
            }
            // multiple
            disabled={platform !== PlatformTypes.whatsApp}
          />
          
           <CardMenu.ItemIcon
            for="attached-file-input"
            disabled={platform !== PlatformTypes.whatsApp}
          >
            <i className="bx bx-paperclip" />
          </CardMenu.ItemIcon> 
        </CardMenu.ItemBody>

        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            {t(["conversation:upload.files.title"])}
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item>

      {/* Items */}
      <CardMenu.Item
        className={classnames({
          "d-none":
            !chatStatus.isActive ||
            (chatStatus.isActive && !robotStatus.isStopped),
          "d-block": chatStatus.isActive && robotStatus.isStopped,
        })}
        hoverHue
      >
        <CardMenu.ItemBody>
          <Input
            id="attached-image-input"
            type="file"
            className="d-none"
            accept="image/png, image/jpeg"
            onChange={(e: any) =>
              onSelect(e, TypeOfAttachment.image, TypeOfMedia.image)
            }
            // multiple
          />
          <CardMenu.ItemIcon for="attached-image-input">
            <i className="bx bx-images" />
          </CardMenu.ItemIcon>
        </CardMenu.ItemBody>
        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            {t(["conversation:upload.images.title"])}
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item>

      {/* Items */}
      <CardMenu.Item
        className={classnames({
          "d-none":
            !chatStatus.isActive ||
            (chatStatus.isActive && !robotStatus.isStopped),
          "d-block": chatStatus.isActive && robotStatus.isStopped,
        })}
        hoverHue
      >
        <CardMenu.ItemBody>
          <Input
            id="attached-audio-input"
            type="file"
            className="d-none"
            accept="audio/mp3, audio/wav, audio/mpeg, audio/ogg"
            onChange={(e: any) =>
              onSelect(e, TypeOfAttachment.audio, TypeOfMedia.audio)
            }
            // multiple
          />
          <CardMenu.ItemIcon for="attached-audio-input">
            <i className="bx bx-headphone" />
          </CardMenu.ItemIcon>
        </CardMenu.ItemBody>
        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            {t(["conversation:upload.audios.title"])}
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item>

      {/* Items */}
      <CardMenu.Item
        className={classnames({
          "d-none":
            !chatStatus.isActive ||
            (chatStatus.isActive && !robotStatus.isStopped),
          "d-block": chatStatus.isActive && robotStatus.isStopped,
        })}
        hoverHue
      >
        <CardMenu.ItemBody>
          <Input
            id="attached-video-input"
            type="file"
            className="d-none"
            accept="video/mp4, video/webm, video/ogg"
            onChange={(e: any) =>
              onSelect(e, TypeOfAttachment.video, TypeOfMedia.video)
            }
            // multiple
          />
          <CardMenu.ItemIcon for="attached-video-input">
            <i className="bx bx-video" />
          </CardMenu.ItemIcon>
        </CardMenu.ItemBody>
        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            {t(["conversation:upload.videos.title"])}
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item>

      {/* Items */}
      {/* <CardMenu.Item
        className={classnames({
          "d-none": chatStatus.status !== "enabled",
          "d-block": chatStatus.status === "enabled",
        })}
        hoverHue
      >
        <CardMenu.ItemBody>
          <CardMenu.ItemIcon for="attached-camera-input">
            <i className="bx bx-camera" />
          </CardMenu.ItemIcon>
        </CardMenu.ItemBody>
        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            <Link to="#" className="text-body stretched-link">
              Camera
            </Link>
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item> */}

      {/* Items */}
      {/* <CardMenu.Item
        className={classnames({
          "d-none": chatStatus.status !== "enabled",
          "d-block": chatStatus.status === "enabled",
        })}
        hoverHue
      >
        <CardMenu.ItemBody>
          <CardMenu.ItemIcon for="attached-current-location-input">
            <i className="bx bx-current-location" />
          </CardMenu.ItemIcon>
        </CardMenu.ItemBody>
        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            <Link to="#" className="text-body stretched-link">
              Location
            </Link>
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item> */}

      {/* Items */}
      {/* <CardMenu.Item
        className={classnames({
          "d-none": chatStatus.status !== "enabled",
          "d-block": chatStatus.status === "enabled",
        })}
        hoverHue
      >
        <CardMenu.ItemBody>
          <CardMenu.ItemIcon for="attached-contacts-input">
            <i className="bx bxs-user-circle" />
          </CardMenu.ItemIcon>
        </CardMenu.ItemBody>
        <CardMenu.ItemHeader>
          <CardMenu.ItemTitle>
            <Link
              to="#"
              className="text-body stretched-link"
              data-bs-toggle="modal"
              data-bs-target=".contactModal"
            >
              Contacts
            </Link>
          </CardMenu.ItemTitle>
        </CardMenu.ItemHeader>
      </CardMenu.Item> */}
    </CardMenu.Root>
  );
};

export default MoreMenu;

// types
import { ChatsActionTypes, ChatsState } from "./types";

export const INIT_STATE: ChatsState = {
  favourites: [],
  directMessages: [],
  getFavouritesLoading: false,
  getDirectMessagesLoading: false,
  channels: [],
  selectedChat: null,
  chatUserDetails: {},
  isUserDetailsFetched: null,
  chatUserConversations: {},
  isUserConversationsFetched: null,
  isOpenUserDetails: false,
  channelDetails: {},
  archiveContacts: [],
  isContactsAdded: null,
  isChannelCreated: null,
  isFavouriteContactToggled: null,
  isContactArchiveToggled: null,
  isUserMessageSent: null,
  isMessageDeleted: null,
  isMessageForwarded: null,
  isUserMessagesDeleted: null,
  isImageDeleted: null,
  getUserDetailsLoading: null,
  robotStatus: { contactId: null, isStopped: false, expirationTime: null },
  isRobotStatusFetched: null,
  getRobotStatusLoading: null,
  isRobotStatusToggled: null,
  getMediaHistoryLoading: null,
  isMediaHistoryFetched: null,
  chatStatus: { isActive: false, expirationTime: null },
  isChatStatusSet: null,
  isAttendantAssign: null,
  isAttendantUnassign: null,
  chatListFilters: null,
  tags: null,
  getTagsFetched: null,
  getTagsLoading: null,
  isContactTagAdded: null,
  isContactTagRemoved: null,
  isTagCreated: null,
  isTagDeleted: null,
};

const Chats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ChatsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            favourites: action.payload.data,
            isFavouritesFetched: true,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            directMessages: action.payload.data,
            isDirectMessagesFetched: true,
            getDirectMessagesLoading: false,
            isContactsAdded: false,
          };
        case ChatsActionTypes.GET_CHANNELS:
          return {
            ...state,
            channels: action.payload.data,
            isChannelsFetched: true,
            getChannelsLoading: false,
            isChannelCreated: false,
          };
        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: true,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: true,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            chatUserDetails: action.payload.data,
            isUserDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_MEDIA_HISTORY:
          const mediaHistory = {
            ...state.chatUserDetails,
            media: action.payload.data.media,
            attachedFiles: action.payload.data.attachedFiles,
          };
          return {
            ...state,
            chatUserDetails: mediaHistory,
            isLatestMediaHistoryFetched: true,
            getLatestMediaHistoryLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: action.payload.data,
            isUserConversationsFetched: true,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
            isMessageDeleted: false,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: true,
          };
        case ChatsActionTypes.RECEIVE_MESSAGE:
        case ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            chatUserConversations: action.payload.data,
          };
        case ChatsActionTypes.READ_MESSAGE:
          return {
            ...state,
            isMessageRead: true,
            chatUserConversations: action.payload.data,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageDeleted: true,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: true,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: true,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            chatUserDetails: { ...action.payload.data, isChannel: true },
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: true,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            archiveContacts: action.payload.data,
            isArchiveContactFetched: true,
            isContactArchiveToggled: false,
          };
        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: true,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.TOGGLE_ROBOT_STATUS:
          return {
            ...state,
            isRobotStatusToggled: true,
          };
        case ChatsActionTypes.GET_ROBOT_STATUS:
          return {
            ...state,
            robotStatus: action.payload.data,
            isRobotStatusFetched: true,
            getRobotStatusLoading: false,
          };
        case ChatsActionTypes.SET_CHAT_STATUS:
          return {
            ...state,
            isChatStatusSet: true,
            chatStatus: action.payload.data,
          };
        case ChatsActionTypes.ASSIGN_ATTENDANT:
          return {
            ...state,
            isAttendantAssign: true,
          };
        case ChatsActionTypes.UNASSIGN_ATTENDANT:
          return {
            ...state,
            isAttendantUnassign: true,
          };
        case ChatsActionTypes.SET_CHAT_LIST_FILTERS:
          return {
            ...state,
            chatListFilters: action.payload.data,
          };
        case ChatsActionTypes.GET_TAGS:
          return {
            ...state,
            tags: action.payload.data,
            getTagsFetched: true,
            getTagsLoading: false,
          };
        case ChatsActionTypes.ADD_CONTACT_TAG:
          return {
            ...state,
            isContactTagAdded: true,
          };
        case ChatsActionTypes.REMOVE_CONTACT_TAG:
          return {
            ...state,
            isContactTagRemoved: true,
          };
        case ChatsActionTypes.CREATE_TAG:
          return {
            ...state,
            isTagCreated: true,
          };
        case ChatsActionTypes.DELETE_TAG:
          return {
            ...state,
            isTagDeleted: true,
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            isFavouritesFetched: false,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            isDirectMessagesFetched: false,
            getDirectMessagesLoading: false,
          };
        case ChatsActionTypes.GET_CHANNELS:
          return {
            ...state,
            isChannelsFetched: false,
            getChannelsLoading: false,
          };
        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: false,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_MEDIA_HISTORY:
          return {
            ...state,
            isMediaHistoryFetched: false,
            getMediaHistoryLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: {},
            isUserConversationsFetched: false,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageDeleted: false,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: false,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            isChannelDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: false,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            isArchiveContactFetched: false,
          };
        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: false,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            isRead: false,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.TOGGLE_ROBOT_STATUS:
          return {
            ...state,
            isRobotStatusToggled: false,
          };
        case ChatsActionTypes.GET_ROBOT_STATUS:
          return {
            ...state,
            isRobotStatusFetched: false,
            getRobotStatusLoading: false,
          };
        case ChatsActionTypes.SET_CHAT_STATUS:
          return {
            ...state,
            isChatStatusSet: false,
          };
        case ChatsActionTypes.ASSIGN_ATTENDANT:
          return {
            ...state,
            isAttendantAssign: false,
          };
        case ChatsActionTypes.UNASSIGN_ATTENDANT:
          return {
            ...state,
            isAttendantUnassign: false,
          };
        case ChatsActionTypes.SET_CHAT_LIST_FILTERS:
          return {
            ...state,
            chatListFilters: null,
          };
        case ChatsActionTypes.GET_TAGS:
          return {
            ...state,
            getTagsFetched: false,
            getTagsLoading: false,
          };
        case ChatsActionTypes.ADD_CONTACT_TAG:
          return {
            ...state,
            isContactTagAdded: false,
          };
        case ChatsActionTypes.REMOVE_CONTACT_TAG:
          return {
            ...state,
            isContactTagRemoved: false,
          };
        case ChatsActionTypes.CREATE_TAG:
          return {
            ...state,
            isTagCreated: false,
          };
        case ChatsActionTypes.DELETE_TAG:
          return {
            ...state,
            isTagDeleted: false,
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.GET_FAVOURITES:
      return {
        ...state,
        getFavouritesLoading: true,
        isFavouritesFetched: false,
      };
    case ChatsActionTypes.GET_DIRECT_MESSAGES:
      return {
        ...state,
        isDirectMessagesFetched: false,
        getDirectMessagesLoading: true,
      };
    case ChatsActionTypes.GET_CHANNELS:
      return {
        ...state,
        isChannelsFetched: false,
        getChannelsLoading: true,
      };
    case ChatsActionTypes.ADD_CONTACTS:
      return {
        ...state,
        isContactsAdded: false,
        addContactsLoading: true,
      };
    case ChatsActionTypes.CREATE_CHANNEL:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.CHANGE_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case ChatsActionTypes.GET_CHAT_USER_DETAILS:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.GET_CHAT_USER_MEDIA_HISTORY:
      return {
        ...state,
        isMediaHistoryFetched: false,
        getMediaHistoryLoading: true,
      };
    case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
      return {
        ...state,
        isUserConversationsFetched: false,
        getUserConversationsLoading: true,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.TOGGLE_USER_DETAILS_TAB:
      return {
        ...state,
        isOpenUserDetails: action.payload,
      };
    case ChatsActionTypes.ON_SEND_MESSAGE:
      return {
        ...state,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        isMessageDeleted: false,
      };
    case ChatsActionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        isMessageForwarded: false,
      };
    case ChatsActionTypes.DELETE_USER_MESSAGES:
      return {
        ...state,
        isUserMessagesDeleted: false,
      };
    case ChatsActionTypes.GET_CHANNEL_DETAILS:
      return {
        ...state,
        isChannelDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
      return {
        ...state,
        isFavouriteContactToggled: false,
      };
    case ChatsActionTypes.GET_ARCHIVE_CONTACT:
      return {
        ...state,
        isArchiveContactFetched: false,
      };
    case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
      return {
        ...state,
        isContactArchiveToggled: false,
      };
    case ChatsActionTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageDeleted: false,
      };
    case ChatsActionTypes.TOGGLE_ROBOT_STATUS:
      return {
        ...state,
        isRobotStatusToggled: false,
      };
    case ChatsActionTypes.GET_ROBOT_STATUS:
      return {
        ...state,
        isRobotStatusFetched: false,
        getRobotStatusLoading: true,
      };
    case ChatsActionTypes.SET_CHAT_STATUS:
      return {
        ...state,
        isChatStatusSet: false,
      };
    case ChatsActionTypes.ASSIGN_ATTENDANT:
      return {
        ...state,
        isAttendantAssign: false,
      };
    case ChatsActionTypes.UNASSIGN_ATTENDANT:
      return {
        ...state,
        isAttendantUnassign: false,
      };
    case ChatsActionTypes.SET_CHAT_LIST_FILTERS:
      return {
        ...state,
        chatListFilters: null,
      };
    case ChatsActionTypes.GET_TAGS:
      return {
        ...state,
        getTagsFetched: false,
        getTagsLoading: true,
      };
    case ChatsActionTypes.ADD_CONTACT_TAG:
      return {
        ...state,
        isContactTagAdded: false,
      };
    case ChatsActionTypes.REMOVE_CONTACT_TAG:
      return {
        ...state,
        isContactTagRemoved: false,
      };
    case ChatsActionTypes.CREATE_TAG:
      return {
        ...state,
        isTagCreated: false,
      };
    case ChatsActionTypes.DELETE_TAG:
      return {
        ...state,
        isTagDeleted: false,
      };
    default:
      return { ...state };
  }
};

export default Chats;

import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface MediaItemProps {
  children: ReactNode;
  onClick?: () => void;
}

export function MediaItem({ children, onClick }: MediaItemProps) {
  return (
    <div className="media-img-list border" style={{ width: 76 }}>
      <Link to="#" onClick={onClick}>
        <div style={{ height: 76 }}>
          <div className="avatar-title bg-soft-primary text-primary font-size-20">
            {children}
          </div>
        </div>
      </Link>
    </div>
  );
}

import React from "react";

//translations
import { useTranslation } from "react-i18next";

import { Button, Col, Row } from "reactstrap";

const Welcome = () => {
  const { t } = useTranslation(["conversation"]);
  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                  <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                </div>
              </div>
              <h4>{t(["conversation:welcome.title"])}</h4>
              <p className="text-muted mb-4">
                {t(["conversation:welcome.subtitle"])}
              </p>
              <Button type="button" className="btn btn-primary w-lg">
                {t(["conversation:welcome.get-started"])}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;

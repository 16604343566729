import classNames from "classnames";
import { HTMLAttributes } from "react";

interface TemplateMessageHeaderProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
}

export function TemplateMessageHeader({
  children,
  className,
  ...rest
}: TemplateMessageHeaderProps) {
  return (
    <header
      className={classNames("pt-4 px-4 font-size-13", className)}
      {...rest}
    >
      <b>{children}</b>
    </header>
  );
}

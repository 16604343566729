import React, { useEffect, useState } from "react";
// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { getConnectedPages } from "../../../redux/actions";

//translations
import { useTranslation } from "react-i18next";
import {
  ConnectedPageFacebookProps,
  ConnectedPageInstagramProps,
  ConnectedPageProps,
} from "../../../data/settings";
import Loader from "../../../components/Loader";

interface ConnectedPageItemFacebookProps {
  page: ConnectedPageFacebookProps;
}
const ConnectedPageItemFacebook = ({
  page,
}: ConnectedPageItemFacebookProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div>
      <h5 className="mb-3 font-size-11 text-muted text-uppercase">Facebook</h5>
      {!page ? (
        <div className="d-flex align-items-center flex-wrap gap-2 theme-btn-list theme-color-list">
          <i className="bx bx-unlink text-danger" />
          <small>Not connected.</small>
        </div>
      ) : (
        <div className="d-flex flex-wrap flex-column gap-2 theme-btn-list theme-color-list">
          {/* <div
            className="rounded-circle border border-primary overflow-hidden d-flex items-align-center justify-content-center"
            style={{ width: 40, height: 40 }}
          >
            <img src={page.picture.data.url} alt={`${page.name} - Profile`} />
          </div> */}

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0 text-truncate">Id:</h5>
            </div>
            <small>{page.id}</small>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0 text-truncate">
                {t(["common:profile.name"])}:
              </h5>
            </div>
            <small>{page.name}</small>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 text-truncate overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0">Bio:</h5>
            </div>
            <small>{page.about}</small>
          </div>

          <div className="d-flex align-items-center gap-4">
            <small className="text-truncate overflow-hidden">
              <i className="bx bxs-user-pin" /> {page.followers_count}{" "}
              {t(["common:profile.followers"])}
            </small>
            <small className="text-truncate overflow-hidden">
              <i className="bx bxs-like" /> {page.fan_count}{" "}
              {t(["common:profile.likes"])}
            </small>
          </div>

          <div className="d-flex align-items-center gap-4">
            <small className="text-truncate overflow-hidden">
              <a href={page.website} target="_blank" rel="noreferrer">
                <i className="bx bx-link-external" /> Website
              </a>
            </small>
            <small className="text-truncate overflow-hidden">
              <a href={page.link} target="_blank" rel="noreferrer">
                <i className="bx bx-link-external" /> Facebook
              </a>
            </small>
          </div>
        </div>
      )}
    </div>
  );
};

interface ConnectedPageItemInstagramProps {
  page: ConnectedPageInstagramProps;
}
const ConnectedPageItemInstagram = ({
  page,
}: ConnectedPageItemInstagramProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div>
      <h5 className="mb-3 font-size-11 text-muted text-uppercase">Instagram</h5>
      {!page ? (
        <div className="d-flex align-items-center flex-wrap gap-2 theme-btn-list theme-color-list">
          <i className="bx bx-unlink text-danger" />
          <small>Not connected.</small>
        </div>
      ) : (
        <div className="d-flex flex-wrap flex-column gap-2 theme-btn-list theme-color-list">
          {/* <div
            className="rounded-circle border border-primary overflow-hidden d-flex items-align-center justify-content-center"
            style={{ width: 40, height: 40 }}
          >
            <img
              src={page.profile_picture_url}
              alt={`${page.name} - Profile`}
            />
          </div> */}

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0 text-truncate">Id:</h5>
            </div>
            <small>{page.id}</small>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0 text-truncate">
                {t(["common:profile.name"])}:
              </h5>
            </div>
            <small>{page.name}</small>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 text-truncate overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0">Bio:</h5>
            </div>
            <small>{page.biography}</small>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div
              className="flex-shrink-0 text-truncate overflow-hidden"
              style={{ width: 52 }}
            >
              <h5 className="font-size-13 mb-0">
                {t(["common:profile.user"])}:
              </h5>
            </div>
            <small>{page.username}</small>
          </div>

          <div className="d-flex align-items-center gap-4">
            <small className="text-truncate overflow-hidden">
              <i className="bx bxs-file-image" /> {page.media_count}{" "}
              {t(["common:medias.media"])}
            </small>
          </div>

          <div className="d-flex align-items-center gap-4">
            <small className="text-truncate overflow-hidden">
              <i className="bx bxs-user-pin" /> {page.followers_count}{" "}
              {t(["common:profile.followers"])}
            </small>
            <small className="text-truncate overflow-hidden">
              <i className="bx bxs-user-pin" /> {page.follows_count}{" "}
              {t(["common:profile.follows"])}
            </small>
          </div>

          <div className="d-flex align-items-center gap-4">
            <small className="text-truncate overflow-hidden">
              <a href={page.website} target="_blank" rel="noreferrer">
                <i className="bx bx-link-external" /> Website
              </a>
            </small>
            <small className="text-truncate overflow-hidden">
              <a
                href="https://www.instagram.com/codebe.dev/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bx bx-link-external" /> Instagram
              </a>
            </small>
          </div>
        </div>
      )}
    </div>
  );
};

const ConnectedPages = () => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { connectedPagesData, getConnectedPagesLoading } = useAppSelector(
    state => ({
      connectedPagesData: state.Settings.connectedPagesData,
      getConnectedPagesLoading: state.Settings.getConnectedPagesLoading,
    })
  );

  // get user settings
  useEffect(() => {
    dispatch(getConnectedPages());
  }, [dispatch]);

  const [connectedPages, setConnectedPages] =
    useState<ConnectedPageProps[]>(connectedPagesData);

  useEffect(() => {
    setConnectedPages(connectedPagesData);
  }, [connectedPagesData]);

  return getConnectedPagesLoading || !connectedPages ? (
    <div className="accordion-body">
      <Loader className="position-relative" />
    </div>
  ) : (
    <div className="accordion-body">
      <div className="d-flex flex-column gap-5">
        {connectedPages &&
          connectedPages.length &&
          connectedPages.map(page => {
            return page.pageType === "Facebook" ? (
              <ConnectedPageItemFacebook
                key={`${page.pageId}-${page.pageType}`}
                page={page.data as ConnectedPageFacebookProps}
              />
            ) : (
              <ConnectedPageItemInstagram
                key={`${page.pageId}-${page.pageType}`}
                page={page.data as ConnectedPageInstagramProps}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ConnectedPages;

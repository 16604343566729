import React from "react";

//translations
import { useTranslation } from "react-i18next";

import DisplaySelectLanguage from "./DisplaySelectLanguage";
import { i18nLanguages } from "../../../i18n/config";

const Languages = () => {
  const { t, i18n } = useTranslation(["settings"]);

  const onChangeData = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <div className="accordion-body">
      <ul className="list-group list-group-flush">
        <li className="list-group-item py-3 px-0 pb-0">
          <DisplaySelectLanguage
            value={i18n.resolvedLanguage}
            options={i18nLanguages}
            onChange={(value: string) => {
              onChangeData(value);
            }}
            label={t(["settings:language.text"])}
          />
        </li>
      </ul>
    </div>
  );
};

export default Languages;

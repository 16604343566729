import React, { useMemo } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table as BTable, UncontrolledTooltip } from "reactstrap";
import { LeadProps } from "../../../../../../data/analytics";
import { Filter } from "./Filter";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";
import { format, isBefore, isSameDay, parse } from "date-fns";

declare module "@tanstack/table-core" {
  interface FilterFns {
    isNotEmpty: FilterFn<unknown>;
    isBeforeDate: FilterFn<unknown>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends unknown, TValue> {
    filterComponent: (props: any) => any;
  }
}

interface TableProps {
  data: any[];
  handleToggleWasContacted: (index: number) => void;
  handleToggleIslead: (index: number) => void;
  handleClickContact: (userId: string) => void;
}

export enum TypeOfFilters {
  select,
  date,
  text,
  number,
}

export const Table = ({
  data,
  handleClickContact,
  handleToggleWasContacted,
  handleToggleIslead,
}: TableProps) => {
  const { t } = useTranslation(["common", "analytics"]);

  const formattedData = useMemo<unknown[]>(() => {
    if (!data) return [];

    const tempData = data.map((lead: LeadProps) => {
      const row = Object.entries(lead).reduce((formattedRow, [key, value]) => {
        if (typeof value === "boolean") {
          return {
            ...formattedRow,
            [key]: value ? t(["common:options.yes"]) : t(["common:options.no"]),
          };
        }

        if (value && typeof value === "object" && value.isEqual(value)) {
          return {
            ...formattedRow,
            [key]: format(value.toDate(), "dd/MM/yyyy"),
          };
        }

        return { ...formattedRow, [key]: String(value) };
      }, {});

      return row;
    });

    return tempData ?? [];
  }, [data, t]);

  const isBeforeDateFn: FilterFn<any> = (row, columnId, value) => {
    const tableValue = parse(row.getValue(columnId), "dd/MM/yyyy", new Date());
    return isSameDay(value, tableValue) || isBefore(value, tableValue);
  };

  const isNotEmptyFilterFn: FilterFn<any> = (row, columnId, value) => {
    const tableValue = row.getValue(columnId);
    if (value === t(["common:options.yes"]) && tableValue !== "") return true;
    if (value === t(["common:options.no"]) && tableValue === "") return true;
    return false;
  };

  const columns = useMemo<ColumnDef<unknown>[]>(() => {
    const setColumns = (key: string) => {
      // Get translations as objects, because key don´t have a type and cause error looking for type.
      const columnNames = t(`analytics:campaign.table`, {
        returnObjects: true,
      });

      return {
        accessorKey: key,
        header: () => (columnNames[key] ? columnNames[key] : "undefined"),
        footer: props => props.column.id,
        enableColumnFilter: false,
        cell: (info: any): React.ReactNode =>
          info.getValue() ? String(info.getValue()) : "",
        filterFn: "isNotEmpty",
        meta: undefined,
      } as ColumnDef<unknown>;
    };

    const genericColumns = [
      "id",
      "Name",
      "IsLead",
      "PercentageLead",
      "CreationDate",
      "WasContacted",
    ];

    const unknownColumns = Object.keys(data[0])
      .filter(column => !genericColumns.includes(column))
      .map(setColumns);

    return [
      {
        accessorKey: "id",
        header: () => t(["analytics:campaign.table.id"]),
        footer: props => props.column.id,
        cell: info => {
          const phone = String(info.getValue());
          return (
            <Link
              to="#"
              onClick={() => {
                handleClickContact(phone);
              }}
            >{`+${phone}`}</Link>
          );
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: "Name",
        header: () => t(["analytics:campaign.table.Name"]),
        footer: props => props.column.id,
        enableColumnFilter: false,
      },
      /** */

      ...unknownColumns,

      /** */
      {
        accessorKey: "IsLead",
        header: () => t(["analytics:campaign.table.IsLead"]),
        footer: props => props.column.id,
        cell: info => {
          return (
            <select
              className="form-control-sm form-control py-0 cursor-pointer text-center"
              value={String(info.getValue())}
              onChange={() => {
                return handleToggleIslead(info.row.index);
              }}
            >
              <option value={t(["common:options.yes"])}>
                {t(["common:options.yes"])}
              </option>
              <option value={t(["common:options.no"])}>
                {t(["common:options.no"])}
              </option>
            </select>
          );
        },
        enableColumnFilter: true,
        meta: {
          filterComponent: setFilterValue => (
            <Filter
              type="select"
              value={[t(["common:options.yes"]), t(["common:options.no"])]}
              handleOnChange={setFilterValue}
            />
          ),
        },
      },
      {
        accessorKey: "PercentageLead",
        header: () => t(["analytics:campaign.table.PercentageLead"]),
        footer: props => props.column.id,
        cell: info => info.getValue(),
        enableColumnFilter: true,
        meta: {
          filterComponent: setFilterValue => (
            <Filter
              type="select"
              value={[25, 50, 75, 100]}
              handleOnChange={setFilterValue}
            />
          ),
        },
      },
      {
        accessorKey: "CreationDate",
        header: () => t(["analytics:campaign.table.CreationDate"]),
        footer: props => props.column.id,
        enableColumnFilter: true,
        filterFn: "isBeforeDate",
        meta: {
          filterComponent: setFilterValue => (
            <Filter type="date" value={[""]} handleOnChange={setFilterValue} />
          ),
        },
      },
      {
        accessorKey: "WasContacted",
        header: () => t(["analytics:campaign.table.WasContacted"]),
        footer: props => props.column.id,
        cell: info => {
          return (
            <select
              className="form-control-sm form-control py-0 cursor-pointer text-center"
              value={String(info.getValue())}
              onChange={() => {
                return handleToggleWasContacted(info.row.index);
              }}
            >
              <option value={t(["common:options.yes"])}>
                {t(["common:options.yes"])}
              </option>
              <option value={t(["common:options.no"])}>
                {t(["common:options.no"])}
              </option>
            </select>
          );
        },
        enableColumnFilter: true,
        meta: {
          filterComponent: setFilterValue => (
            <Filter
              type="select"
              value={[t(["common:options.yes"]), t(["common:options.no"])]}
              handleOnChange={setFilterValue}
            />
          ),
        },
      },
    ];
  }, [
    handleClickContact,
    handleToggleWasContacted,
    handleToggleIslead,
    t,
    data,
  ]);

  const table = useReactTable({
    data: formattedData,
    columns,
    filterFns: {
      isNotEmpty: isNotEmptyFilterFn,
      isBeforeDate: isBeforeDateFn,
    },
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: true,
  });

  const hiddenTooltip = ["id", "IsLead", "WasContacted"];

  function checkTooltip(id: string) {
    const tempId = id.split("_");

    if (hiddenTooltip.includes(tempId[1])) {
      return false;
    }

    return true;
  }

  return (
    <>
      <ul className="list-group filters flex-sm-row">
        {table.getHeaderGroups().map(headerGroup => {
          return headerGroup.headers.map(header => {
            return header.isPlaceholder ? null : header.column.getCanFilter() ? (
              <li className="list-group-item" key={`${header.id}-filter`}>
                <div className="d-flex gap-1">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {header.column.columnDef?.meta?.filterComponent(
                    header.column.setFilterValue
                  )}
                </div>
              </li>
            ) : null;
          });
        })}
      </ul>
      <div className="leads">
        <BTable responsive borderless striped hover>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} scope="col">
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        <div className="text-truncate" id={`col-${cell.id}`}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                        {
                          /** Add tooltip */
                          checkTooltip(cell.id) ? (
                            <UncontrolledTooltip
                              placement="top-start"
                              target={`col-${cell.id}`}
                              innerClassName="p-3"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </UncontrolledTooltip>
                          ) : null
                        }
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}

      <div className="d-flex align-items-center justify-content-between mt-4 flex-column flex-sm-row gap-3 pagination">
        <div className="d-flex gap-1">
          <Pagination aria-label="Leads table navigation">
            <PaginationItem disabled={!table.getCanPreviousPage()}>
              <PaginationLink
                tag="button"
                onClick={() => table.setPageIndex(0)}
              >
                <span aria-hidden="true">« </span>
                {t(["common:button.first"])}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={!table.getCanNextPage()}>
              <PaginationLink
                tag="button"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              >
                {t(["common:button.last"])}
                <span aria-hidden="true"> »</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
          <Pagination aria-label="Leads table navigation">
            <PaginationItem>
              <PaginationLink tag="div">
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={e => {
                    table.setPageSize(Number(e.target.value));
                  }}
                  className="form-control-sm form-control py-0"
                  style={{ minHeight: "auto" }}
                >
                  {[5, 10, 25, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {t(["common:button.show"])} {pageSize}
                    </option>
                  ))}
                </select>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
        <Pagination aria-label="Leads table navigation">
          <PaginationItem disabled={!table.getCanPreviousPage()}>
            <PaginationLink
              tag="button"
              title="dawwa"
              onClick={() => table.previousPage()}
            >
              <span aria-hidden="true">« </span>
              {t(["common:button.prev"])}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem active>
            <PaginationLink tag="strong">
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!table.getCanNextPage()}>
            <PaginationLink tag="button" onClick={() => table.nextPage()}>
              {t(["common:button.next"])}
              <span aria-hidden="true"> »</span>
            </PaginationLink>
          </PaginationItem>

          {/* <PaginationItem>
            <PaginationLink
              last
              tag="div"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <label htmlFor="pagination-input">Go to page:</label>
              <input
                name="pagination-input"
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
              />
            </PaginationLink>
          </PaginationItem> */}
        </Pagination>
      </div>
      {/* <div>{table.getRowModel().rows.length} Rows</div>
      <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
    </>
  );
};

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

//Media Composition
import { Media as MediaC } from "./Media/index";

// interface
import { MediaTypes, MediaListItemTypes } from "../data/myProfile";
import { TypeOfMessage } from "../data/messages";

interface MediaProps {
  media: MediaTypes;
  limit: number;
}

interface MediaItemProps {
  mediaItem: MediaListItemTypes;
  hasMore: boolean;
  remainingItem: number;
  handleOnClick?: () => void;
}

const MediaItem = ({
  mediaItem,
  hasMore,
  remainingItem,
  handleOnClick,
}: MediaItemProps) => {
  const mediaItemTypes = {
    [TypeOfMessage.document]: <i className="ri-attachment-2"></i>,
    [TypeOfMessage.image]: (
      <img src={mediaItem.url} alt={mediaItem.fileName} className="img-fluid" />
    ),
    [TypeOfMessage.audio]: <i className="bx bxs-music"></i>,
    [TypeOfMessage.video]: <i className="bx bxs-videos"></i>,
  };

  return (
    <MediaC.Item onClick={handleOnClick} key={mediaItem.id}>
      {mediaItemTypes[mediaItem.type]}
      {hasMore && <MediaC.HasMoreOverlay remainingItem={remainingItem} />}
    </MediaC.Item>
  );
};

const Media = ({ media, limit = 4 }: MediaProps) => {
  const { t } = useTranslation(["common", "profileDetails", "notifications"]);

  const [mediaList, setMediaList] = useState<MediaListItemTypes[]>([]);
  const [mediaLimit, setMediaLimit] = useState(limit);
  const total = mediaList.length;
  const remainingItem = total - mediaLimit;

  useEffect(() => {
    if (media && media.list) {
      setMediaList(media.list);
    } else {
      setMediaList([]);
    }
  }, [media]);

  function toggleLimit() {
    if (total > limit) {
      setMediaLimit(prev => (prev === limit ? total : limit));
    }
  }

  const [itemPreviewIsOpen, setItemPreviewIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MediaListItemTypes>(null);

  function handleSelectedItem(mediaItem: MediaListItemTypes) {
    setSelectedItem(mediaItem);
    setItemPreviewIsOpen(true);
  }

  function handleItemPreviewOnClose() {
    setItemPreviewIsOpen(false);
    setSelectedItem(null);
  }

  return (
    <MediaC.Root>
      <MediaC.ItemPreview
        selectedItem={selectedItem}
        isOpen={itemPreviewIsOpen}
        onClose={handleItemPreviewOnClose}
      />
      <MediaC.Header>
        <MediaC.HeaderTitle title={t(["profileDetails:sidebar.media"])} />
        {total > limit && (
          <MediaC.HeaderAction>
            <Link
              to="#"
              className="font-size-12 d-block mb-2"
              onClick={toggleLimit}
            >
              {remainingItem
                ? t(["common:button.show-all"])
                : t(["common:button.hide"])}
            </Link>
          </MediaC.HeaderAction>
        )}
      </MediaC.Header>

      <MediaC.Content>
        {media ? (
          (mediaList || []).map(
            (mediaItem: MediaListItemTypes, key: number) => {
              const hasMore: boolean = key === mediaLimit;

              if (key <= mediaLimit) {
                return (
                  <MediaItem
                    mediaItem={mediaItem}
                    key={key}
                    hasMore={hasMore}
                    remainingItem={remainingItem}
                    handleOnClick={() => handleSelectedItem(mediaItem)}
                  />
                );
              } else return null;
            }
          )
        ) : (
          <p>{t(["notifications:error.media.not-exists"])}</p>
        )}
      </MediaC.Content>
    </MediaC.Root>
  );
};
export default Media;

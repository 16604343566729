import React from "react";
import classnames from "classnames";
import { PlatformTypes } from "../../../data";
import WhatsApp from "../../../assets/images/platform/whatsapp.svg";
import Instagram from "../../../assets/images/platform/instagram.svg";
import Messenger from "../../../assets/images/platform/messenger.svg";

interface PlatformProps {
  platform: PlatformTypes;
}
const Status = ({ platform }: PlatformProps) => {
  return (
    <div
      className={classnames("platform", {
        "platform-whatsapp": platform === PlatformTypes.whatsApp,
        "platform-instagram": platform === PlatformTypes.instagram,
        "platform-messenger": platform === PlatformTypes.messenger,
      })}
    >
      <div className="d-flex align-items-center gap-1">
        {platform === PlatformTypes.whatsApp ? (
          <>
            <img src={WhatsApp} alt="WhatsApp" />
            <span>whatsApp</span>
          </>
        ) : null}
        {platform === PlatformTypes.instagram ? (
          <>
            <img src={Instagram} alt="Instagram" />
            <span>Instagram</span>
          </>
        ) : null}
        {platform === PlatformTypes.messenger ? (
          <>
            <img src={Messenger} alt="Messenger" />
            <span>Messenger</span>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Status;

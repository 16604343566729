import React, { useEffect } from "react";

//translations
import { useTranslation } from "react-i18next";

import { NotAvailable } from "./NotAvailable";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
// hooks
import { useRedux } from "../../../../hooks/index";
// actions
import {
  changeSelectedCampaign,
  getOverviewData,
} from "../../../../redux/actions";
import AppSimpleBar from "../../../../components/AppSimpleBar";
import { StatusSection } from "./OverviewSection/Status";
import { MonthlySection } from "./OverviewSection/Monthly";
import { TopCampaignSection } from "./OverviewSection/TopCampaigns";
import Loader from "../../../../components/Loader";

export const OverviewChart = () => {
  const { t } = useTranslation(["analytics"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { overviewData, getOverviewDataLoading } = useAppSelector(state => ({
    overviewData: state.Analytics.overviewData,
    getOverviewDataLoading: state.Analytics.getOverviewDataLoading,
  }));

  useEffect(() => {
    dispatch(getOverviewData("undefined"));
  }, [dispatch]);

  /* mobile close */
  const onClosePage = () => {
    dispatch(changeSelectedCampaign(null));
  };

  return (
    <>
      <div className="p-3 p-lg-4 user-chat-topbar">
        <Row className="align-items-center">
          {/* <NotAvailable /> */}
          <Col sm={12} className="col-12">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 d-block d-lg-none me-2">
                <Link
                  to="#"
                  onClick={onClosePage}
                  className="user-chat-remove text-muted font-size-24 p-2"
                >
                  <i className="bx bx-chevron-left align-middle"></i>
                </Link>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="bx bxs-pie-chart-alt-2"
                    style={{ fontSize: "38.8px" }}
                  ></i>
                  <div className="flex-grow-1 overflow-hidden">
                    <h6 className="text-truncate text-capitalize mb-0 font-size-18">
                      {t(["analytics:menu.overview"])}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <AppSimpleBar className="campaign">
        {getOverviewDataLoading && <Loader />}
        {!overviewData ? (
          <NotAvailable />
        ) : (
          <>
            <div className="container px-4 my-5 d-flex flex-column flex-xl-row gap-3">
              <h4 className="text-secondary">{t(["analytics:overview.title"])}</h4>
            </div>
            <StatusSection data={overviewData.general} />
            <MonthlySection data={overviewData.monthly} />
            <TopCampaignSection data={overviewData.top} />
          </>
        )}
      </AppSimpleBar>
    </>
  );
};

import React from "react";

export interface BoxChartProps {
  iconName?: string;
  title: string;
  value: string | number;
  headColor?: string;
  textColor?: string;
  bodyColor?: string;
}
export const BoxChart = ({
  iconName,
  title,
  value,
  headColor = "#CEEAF6",
  textColor = "#495057",
  bodyColor = "#F3F3F3",
}: BoxChartProps) => {
  return (
    <div
      className="chart-box p-0 m-0"
      style={{ borderColor: headColor, color: textColor }}
    >
      <div
        className="header p-0 m-0"
        style={{ borderColor: headColor, backgroundColor: headColor }}
      >
        {iconName && <i className={`bx ${iconName}`}></i>}
        <span>{title}</span>
      </div>
      <div className="container p-0 m-0" style={{ backgroundColor: bodyColor }}>
        {value}
      </div>
    </div>
  );
};

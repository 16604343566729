import { HtmlHTMLAttributes, ReactNode } from "react";
import classnames from "classnames";

interface PopoverBoxFooterActionProps
  extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

export function PopoverBoxFooterAction({
  className,
  children,
  ...rest
}: PopoverBoxFooterActionProps) {
  return (
    <div className={classnames("flex-shrink-0", className)} {...rest}>
      {children}
    </div>
  );
}

import React from "react";

//translations
import { useTranslation } from "react-i18next";

import classnames from "classnames";

// interface
import { BasicDetailsTypes } from "../../../data/myProfile";
import { STATUS_TYPES } from "../../../constants";
import { i18nLocalesCountries } from "../../../i18n/i18next";

interface UserDescriptionProps {
  basicDetails: BasicDetailsTypes;
}
const UserDescription = ({ basicDetails }: UserDescriptionProps) => {
  const { t } = useTranslation(["common", "settings", "countries"]);

  const location =
    basicDetails && basicDetails.location && basicDetails.location.length === 2
      ? (basicDetails.location as i18nLocalesCountries)
      : null;

  const userStatus =
    basicDetails && basicDetails.status
      ? (basicDetails.status.toLowerCase() as STATUS_TYPES)
      : STATUS_TYPES.ACTIVE;

  return (
    <>
      <div className="text-muted">
        <p className="mb-4">
          {basicDetails && basicDetails.description
            ? basicDetails.description
            : "-"}
        </p>
      </div>

      <div>
        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-user align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              {basicDetails && basicDetails.fullName
                ? basicDetails.fullName
                : "-"}
            </p>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-message-rounded-dots align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              {basicDetails && basicDetails.email ? basicDetails.email : "-"}
            </p>
          </div>
        </div>

        <div className="d-flex py-2 align-items-center">
          <div className="flex-shrink-0 me-3">
            <i className="bx bx-location-plus align-middle text-muted"></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">
              {location ? t(`countries:countries.${location}` as const) : "-"}
            </p>
          </div>
        </div>

        <div className="d-flex py-2">
          <div className="flex-shrink-0 me-3 text-center" style={{ width: 15 }}>
            <i
              className={classnames(
                "bx",
                "bxs-circle",
                "font-size-12",
                "align-middle",
                {
                  "text-success": userStatus === STATUS_TYPES.ACTIVE,
                },
                {
                  "text-warning": userStatus === STATUS_TYPES.AWAY,
                },
                {
                  "text-danger": userStatus === STATUS_TYPES.DO_NOT_DISTURB,
                },
                {
                  "text-secondary": userStatus === STATUS_TYPES.INACTIVE,
                }
              )}
            ></i>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0">{t(`common:options.${userStatus}` as const)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDescription;

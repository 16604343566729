// types
import { KanbanActionTypes, KanbanState } from "./types";

export const INIT_STATE: KanbanState = {
  boards: [],
  isBoardsFetched: null,
  isKanbanBoardAdded: null,
  comments: [],
  isCommentsFetched: null,
};

const Kanban = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case KanbanActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case KanbanActionTypes.GET_BOARDS:
          return {
            ...state,
            boards: action.payload.data,
            isBoardsFetched: true,
          };
        case KanbanActionTypes.GET_COMMENTS:
          return {
            ...state,
            comments: action.payload.data,
            isCommentsFetched: true,
          };

        case KanbanActionTypes.CREATE_BOARD:
          return {
            ...state,
            isKanbanBoardAdded: true
          };

        case KanbanActionTypes.CREATE_COMMENT:
          return {
            ...state,
            isKanbanCommentAdded: true
          };


        default:
          return { ...state };
      }

    case KanbanActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case KanbanActionTypes.GET_BOARDS:
          return {
            ...state,
            isBoardsFetched: false,
          };
        case KanbanActionTypes.GET_COMMENTS:
          return {
            ...state,
            isCommentsFetched: false,
          };

        case KanbanActionTypes.CREATE_BOARD:
          return {
            ...state,
            isKanbanBoardAdded: false,
          };

        case KanbanActionTypes.CREATE_COMMENT:
          return {
            ...state,
            isKanbanCommentAdded: false
          };

        default:
          return { ...state };
      }

    case KanbanActionTypes.GET_BOARDS:
      return {
        ...state,
        isBoardsFetched: true,
      };
    case KanbanActionTypes.GET_COMMENTS:
      return {
        ...state,
        isCommentsFetched: true,
      };

    case KanbanActionTypes.CREATE_BOARD:
      return {
        ...state,
        isKanbanBoardAdded: false,
      }
    case KanbanActionTypes.CREATE_COMMENT:
      return {
        ...state,
        isKanbanCommentAdded: false
      };

    default:
      return { ...state };
  }
};

export default Kanban;

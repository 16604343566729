import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { SettingsActionTypes } from "./types";
import {
  settingsApiResponseSuccess,
  settingsApiResponseError,
} from "./actions";

import {
  getSettings as getSettingsApi,
  updateSettings as updateSettingsApi,
  updateStatus as updateStatusApi,
  getConnectedPages as getConnectedPagesApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getSettings() {
  try {
    const response: Promise<any> = yield call(getSettingsApi);
    yield put(
      settingsApiResponseSuccess(
        SettingsActionTypes.GET_USER_SETTINGS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      settingsApiResponseError(SettingsActionTypes.GET_USER_SETTINGS, error)
    );
  }
}

function* updateSettings({ payload: { field, value } }: any) {
  try {
    const response: Promise<any> = yield call(updateSettingsApi, field, value);
    yield put(
      settingsApiResponseSuccess(
        SettingsActionTypes.UPDATE_USER_SETTINGS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      settingsApiResponseError(SettingsActionTypes.UPDATE_USER_SETTINGS, error)
    );
  }
}

function* updateStatus({ payload: { status } }: any) {
  try {
    const response: Promise<any> = yield call(updateStatusApi, status);
    yield put(
      settingsApiResponseSuccess(
        SettingsActionTypes.UPDATE_USER_STATUS,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      settingsApiResponseError(SettingsActionTypes.UPDATE_USER_STATUS, error)
    );
  }
}

function* getConnectedPages() {
  try {
    const response: Promise<any> = yield call(getConnectedPagesApi);
    yield put(
      settingsApiResponseSuccess(
        SettingsActionTypes.GET_CONNECTED_PAGES,
        response
      )
    );
  } catch (error: any) {
    yield put(
      settingsApiResponseError(SettingsActionTypes.GET_CONNECTED_PAGES, error)
    );
  }
}

export function* watchGetSettings() {
  yield takeEvery(SettingsActionTypes.GET_USER_SETTINGS, getSettings);
}
export function* watchUpdateSettings() {
  yield takeEvery(SettingsActionTypes.UPDATE_USER_SETTINGS, updateSettings);
}
export function* watchUpdateStatus() {
  yield takeEvery(SettingsActionTypes.UPDATE_USER_STATUS, updateStatus);
}
export function* watchGetConnectedPages() {
  yield takeEvery(SettingsActionTypes.GET_CONNECTED_PAGES, getConnectedPages);
}

function* settingsSaga() {
  yield all([
    fork(watchGetSettings),
    fork(watchUpdateSettings),
    fork(watchUpdateStatus),
    fork(watchGetConnectedPages),
  ]);
}

export default settingsSaga;

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

// hooks
import { useRedux } from "../hooks/index";

// actions
import {
  changeSelectedChat,
  deleteContact,
  getContacts,
  toggleUserDetailsTab,
} from "../redux/actions";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  Label,
} from "reactstrap";

// interfaces
import { ContactTypes } from "../data/contacts";
import AvatarName from "./AvatarName";
import Loader from "./Loader";

interface DeleteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  contact: ContactTypes;
}
const DeleteContactModal = ({
  isOpen,
  onClose,
  contact,
}: DeleteContactModalProps) => {
  const { t } = useTranslation(["common", "form"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { selectChat, isContactDeleted, isContactDeletedLoading } =
    useAppSelector(state => ({
      selectChat: state.Chats.selectedChat,
      isContactDeleted: state.Contacts.isContactDeleted,
      isContactDeletedLoading: state.Contacts.isContactDeletedLoading,
    }));

  const [disabled, setDisabled] = useState(true);

  /*
  submit data
  */
  const onSubmit = () => {
    if (contact.id) {
      if (selectChat === contact.id) {
        dispatch(toggleUserDetailsTab(false));
        dispatch(changeSelectedChat(null));
      }
      dispatch(deleteContact(contact.id));
      handleToggleDisabled();
    }
  };

  function handleToggleDisabled() {
    setDisabled(prev => !prev);
  }

  useEffect(() => {
    if (isContactDeleted) {
      dispatch(getContacts());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactDeleted]);

  if (!contact) return <></>;
  const fullName = `${contact.firstName} ${contact.lastName}`;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      tabIndex={-1}
      centered
      className="contactModal"
    >
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        {t(["common:button.delete-contact"])}
      </ModalHeader>
      <ModalBody className="p-4">
        {isContactDeletedLoading ? <Loader /> : null}
        <div className="border p-4 d-flex flex-column align-items-center">
          <div className="flex-shrink-0">
            {contact.profileImage ? (
              <div className="avatar-sm">
                <img
                  src={contact.profileImage}
                  alt=""
                  className="img-fluid rounded-circle"
                />
              </div>
            ) : (
              <AvatarName
                className="avatar-sm align-self-center font-size-10"
                user={contact}
              />
            )}
          </div>
          <div className="d-flex gap-2 mt-3">
            <strong>{t(["common:profile.name"])}:</strong>
            <span>{fullName}</span>
          </div>
          <div className="d-flex gap-2">
            <strong>{t(["common:profile.phone"])}:</strong>
            <span>+{contact.id}</span>
          </div>
        </div>
        <InputGroup className="flex-nowrap gap-2 mt-4">
          <div>
            <Input
              id="deleteContactModal-confirm"
              name="deleteContactModal-confirm"
              type="checkbox"
              className="p-2 col-sm pointer"
              onChange={() => {
                handleToggleDisabled();
              }}
            />
          </div>

          <Label htmlFor="deleteContactModal-confirm">
            {t(["form:input.confirm-delete-contact"])}
          </Label>
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          color="danger"
          disabled={disabled}
          onClick={onSubmit}
        >
          Excluir
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteContactModal;

import React from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export type PieChartOptionsProps = ChartOptions<"pie">;
export type PieChartDataProps = ChartData<"pie">;

interface PieChartProps {
  options?: PieChartOptionsProps;
  data: PieChartDataProps;
}

export const PieChart = ({ data, options }: PieChartProps) => {
  return <Pie data={data} options={options} />;
};

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { AnalyticsActionTypes } from "./types";
import {
  analyticsApiResponseSuccess,
  analyticsApiResponseError,
} from "./actions";

import {
  getCampaignsList as getCampaignsListApi,
  getOverviewData as getOverviewDataApi,
  getSelectedCampaignData as getSelectedCampaignDataApi,
  getSelectedCampaignLeads as getSelectedCampaignLeadsApi,
  updateLeadWasContacted as updateLeadWasContactedApi,
  updateLeadIsLead as updateLeadIsLeadApi,
  getSelectedContactCampaigns as getSelectedContactCampaignsApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import {} from "./actions";

function* getListCampaigns() {
  try {
    const response: Promise<any> = yield call(getCampaignsListApi);
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.GET_CAMPAIGNS_LIST,
        response
      )
    );
  } catch (error: any) {
    yield put(
      analyticsApiResponseError(AnalyticsActionTypes.GET_CAMPAIGNS_LIST, error)
    );
  }
}

function* getOverviewData({ payload: { id } }: any) {
  try {
    const response: Promise<any> = yield call(getOverviewDataApi, id);
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.GET_OVERVIEW_DATA,
        response
      )
    );
  } catch (error: any) {
    yield put(
      analyticsApiResponseError(AnalyticsActionTypes.GET_OVERVIEW_DATA, error)
    );
  }
}

function* getSelectedCampaignData({ payload: { id, name, period } }: any) {
  try {
    const response: Promise<any> = yield call(
      getSelectedCampaignDataApi,
      id,
      name,
      period
    );
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA,
        response
      )
    );
  } catch (error: any) {
    yield put(
      analyticsApiResponseError(
        AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA,
        error
      )
    );
  }
}

function* getSelectedCampaignLeads({ payload: { id } }: any) {
  try {
    const response: Promise<any> = yield call(getSelectedCampaignLeadsApi, id);
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      analyticsApiResponseError(
        AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS,
        error
      )
    );
  }
}

function* updateLeadWasContacted({ payload: { id, data } }: any) {
  try {
    const response: { data: any; message: string } = yield call(
      updateLeadWasContactedApi,
      id,
      data
    );
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED,
        response.data
      )
    );
    yield call(showSuccessNotification, response.message + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      analyticsApiResponseError(
        AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED,
        error
      )
    );
  }
}
function* updateLeadIsLead({ payload: { id, data } }: any) {
  try {
    const response: { data: any; message: string } = yield call(
      updateLeadIsLeadApi,
      id,
      data
    );
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD,
        response.data
      )
    );
    yield call(showSuccessNotification, response.message + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      analyticsApiResponseError(AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD, error)
    );
  }
}

function* getSelectedContactCampaigns({ payload: { id, campaigns } }: any) {
  try {
    const response: Promise<any> = yield call(
      getSelectedContactCampaignsApi,
      id,
      campaigns
    );
    yield put(
      analyticsApiResponseSuccess(
        AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      analyticsApiResponseError(
        AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS,
        error
      )
    );
  }
}

export function* watchGetListCampaigns() {
  yield takeEvery(AnalyticsActionTypes.GET_CAMPAIGNS_LIST, getListCampaigns);
}

export function* watchGetOverviewData() {
  yield takeEvery(AnalyticsActionTypes.GET_OVERVIEW_DATA, getOverviewData);
}

export function* watchGetSelectedCampaignData() {
  yield takeEvery(
    AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA,
    getSelectedCampaignData
  );
}

export function* watchGetSelectedCampaignLeads() {
  yield takeEvery(
    AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS,
    getSelectedCampaignLeads
  );
}

export function* watchUpdateLeadWasContacted() {
  yield takeEvery(
    AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED,
    updateLeadWasContacted
  );
}
export function* watchUpdateLeadIsLead() {
  yield takeEvery(AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD, updateLeadIsLead);
}

export function* watchGetSelectedContactCampaigns() {
  yield takeEvery(
    AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS,
    getSelectedContactCampaigns
  );
}

function* analyticsSaga() {
  yield all([
    fork(watchGetListCampaigns),
    fork(watchGetOverviewData),
    fork(watchGetSelectedCampaignData),
    fork(watchGetSelectedCampaignLeads),
    fork(watchUpdateLeadWasContacted),
    fork(watchUpdateLeadIsLead),
    fork(watchGetSelectedContactCampaigns),
  ]);
}

export default analyticsSaga;

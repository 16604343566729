import { useEffect, useState, useMemo } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useTranslation } from "react-i18next";

import { CardMenu } from "../../../../components/CardMenu";
import { MessageProps } from "../../../../data/templates";
import { ChatsState } from "../../../../redux/chats/types";
import TemplatesModal from "../../../../components/TemplatesModal";

import { useRedux } from "../../../../hooks";
import { getTemplates } from "../../../../redux/actions";
import Loader from "../../../../components/Loader";

interface TemplatesMenuProps
  extends Pick<ChatsState, "chatStatus" | "robotStatus"> {
  isOpen: boolean;
}

const TemplatesMenu = ({
  isOpen = false,
  chatStatus,
  robotStatus,
}: TemplatesMenuProps) => {
  const { t } = useTranslation(["common"]);

  const { dispatch, useAppSelector } = useRedux();

  const { templates, isTemplatesLoading } = useAppSelector(
    useMemo(() => (state) => ({
      templates: state.Templates.templates,
      isTemplatesLoading: state.Templates.getTemplatesLoading,
    }), [])
  );
  

  const [selectedMessage, setSelectedMessage] = useState<MessageProps | null>(
    null
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal(message: MessageProps) {
    setSelectedMessage(message);
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
    setSelectedMessage(null);
  }

  useEffect(() => {
    setModalIsOpen(false);
    dispatch(getTemplates());
  }, [dispatch]);

  return (
    <>
      <CardMenu.Root
        className="chat-input-collapse"
        id="chat-input-templates-collapse"
        isOpen={isOpen}
      >
        {isTemplatesLoading && (
          <CardMenu.Item>
            <Loader />
          </CardMenu.Item>
        )}

        {!isTemplatesLoading && !templates && (
          <CardMenu.Item>
            <CardMenu.ItemHeader className="cursor-pointer">
              <CardMenu.ItemTitle>
                {t(["common:medias.no-results"])}
              </CardMenu.ItemTitle>
            </CardMenu.ItemHeader>
          </CardMenu.Item>
        )}

        {!isTemplatesLoading &&
          templates &&
          templates.data.length &&
          templates.data.map((item: MessageProps) => (
            <CardMenu.Item
              className={classnames({
                "d-none": chatStatus.isActive && !robotStatus.isStopped,
                "d-block":
                  !chatStatus.isActive ||
                  (chatStatus.isActive && robotStatus.isStopped),
              })}
              hoverHue
              key={item.id}
              onClick={() => {
                openModal(item);
              }}
            >
              <CardMenu.ItemBody className="position-relative">
                <Link to="#" className="border-0 bg-transparent">
                  <Badge
                    color="secondary"
                    className="position-absolute top-1 start-50 ms-2 margin-auto bg-primary text-white border border-primary text-uppercase"
                    style={{ fontSize: ".5rem" }}
                  >
                    {item.language}
                  </Badge>

                  <CardMenu.ItemIcon>
                    <i className="bx bx-file" />
                  </CardMenu.ItemIcon>
                </Link>
              </CardMenu.ItemBody>

              <CardMenu.ItemHeader className="cursor-pointer">
                <CardMenu.ItemSubTitle>{item.category}</CardMenu.ItemSubTitle>
                <CardMenu.ItemTitle>{item.name}</CardMenu.ItemTitle>
              </CardMenu.ItemHeader>
            </CardMenu.Item>
          ))}
      </CardMenu.Root>
      <TemplatesModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        message={selectedMessage}
      />
    </>
  );
};

export default TemplatesMenu;

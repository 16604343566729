import { TemplateMessage } from "./TemplateMessage";
import { MessageProps } from "../data/templates";

enum MessageComponentsTypes {
  "header" = "HEADER",
  "body" = "BODY",
  "footer" = "FOOTER",
  "buttons" = "BUTTONS",
}

interface TemplateMessageSectionProps {
  message: MessageProps;
}

export const TemplateMessageSection = ({
  message = null,
}: TemplateMessageSectionProps) => {
  return message ? (
    <TemplateMessage.Root>
      {message.components.map((item, index) => {
        switch (item.type) {
          case MessageComponentsTypes.header:
            return (
              <TemplateMessage.Header key={`${index}-${item.type}`}>
                {item.text}
              </TemplateMessage.Header>
            );

          case MessageComponentsTypes.body:
            return (
              <TemplateMessage.Body key={`${index}-${item.type}`}>
                {item.text}
              </TemplateMessage.Body>
            );
          case MessageComponentsTypes.footer:
            return (
              <TemplateMessage.Footer key={`${index}-${item.type}`}>
                {item.text}
              </TemplateMessage.Footer>
            );
          case MessageComponentsTypes.buttons:
            return (
              <TemplateMessage.ButtonGroup key={`${index}-${item.type}`}>
                {item.buttons.map((button, bIndex) => (
                  <TemplateMessage.Button
                    key={`${index}-${bIndex}-${button.type}`}
                  >
                    <TemplateMessage.Icon type={button.type} />
                    {button.text}
                  </TemplateMessage.Button>
                ))}
              </TemplateMessage.ButtonGroup>
            );
          default:
            return null;
        }
      })}
    </TemplateMessage.Root>
  ) : null;
};

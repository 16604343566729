import classNames from "classnames";
import { HTMLAttributes } from "react";

interface TemplateMessageButtonGroupProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
}

export function TemplateMessageButtonGroup({
  children,
  className,
  ...rest
}: TemplateMessageButtonGroupProps) {
  return (
    <section className={classNames("pt-4", className)} {...rest}>
      {children}
    </section>
  );
}

import { AttendantProps } from "../../data/attendants";

export enum AttendantsActionTypes {
  API_RESPONSE_SUCCESS = "@@attendants/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@attendants/API_RESPONSE_ERROR",

  GET_ATTENDANTS = "@@attendants/GET_ATTENDANTS",
  CREATE_ATTENDANT = "@@attendants/CREATE_ATTENDANT",
  DELETE_ATTENDANT = "@@attendants/DELETE_ATTENDANT",
}

export interface AttendantsState {
  attendants: AttendantProps[];
  isAttendantsFetched: boolean;
  getAttendantsLoading: boolean;
}

import React from "react";

//translations
import { useTranslation } from "react-i18next";

import { BoxChart } from "../../../../../components/charts/Box";
import { GeneralProps } from "../../../../../data/analytics";

interface StatusSectionProps {
  data: GeneralProps;
}

export const StatusSection = ({ data }: StatusSectionProps) => {
  const { t } = useTranslation(["analytics"]);

  return (
    <div className="container px-4 my-5 d-flex flex-column flex-xl-row gap-3">
      <ul className="col-sm d-flex flex-column flex-sm-row justify-content-center xl-justify-content-between gap-2 list-group px-1">
        <li className="list-group-item col-auto col-sm-4 col-xl-4 p-0">
          <BoxChart
            title={t(["analytics:overview.general.total-contact"])}
            value={data.totalContacts}
            iconName="bxs-user-check"
          />
        </li>
        <li className="list-group-item col-auto col-sm-4 col-xl-4 p-0">
          <BoxChart
            title={t(["analytics:overview.general.total-campaigns"])}
            value={data.totalCampaigns}
            iconName="bxs-bar-chart-alt-2"
          />
        </li>
        <li className="list-group-item col-auto col-sm-4 col-xl-4 p-0">
          <BoxChart
            title={t(["analytics:overview.general.total-attendants"])}
            value={data.totalAttendants}
            iconName="bxs-user-voice"
          />
        </li>
      </ul>
    </div>
  );
};

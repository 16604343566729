import classNames from "classnames";
import { HtmlHTMLAttributes } from "react";

interface RobotStatusContentProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  mobile?: boolean;
  children: React.ReactNode;
}
export function RobotStatusContent({
  children,
  className,
  mobile = false,
  ...rest
}: RobotStatusContentProps) {
  return (
    <div
      className={classNames(
        {
          "btn-robot-section": !mobile,
          "btn-robot-mobile-section": mobile,
        },
        className
      )}
      {...rest}
    >
      <>{children}</>
    </div>
  );
}

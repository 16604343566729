import React from "react";

//translations
import { useTranslation } from "react-i18next";

import { TopCampaignsProps } from "../../../../../data/analytics";
import { TopCampaignSectionItem } from "./TopCampaignsItem";

interface TopCampaignSectionProps {
  data: TopCampaignsProps[];
}

export const TopCampaignSection = ({ data }: TopCampaignSectionProps) => {
  const { t } = useTranslation(["analytics"]);

  return (
    <div className="container px-4 my-5">
      <div className="border border-primary bg-light rounded p-4 col-sm">
        <div className="header">
          <h4>{t(["analytics:overview.table.title"])}</h4>
        </div>
        <ul className="col-sm d-flex flex-column justify-content-center gap-2 list-group">
          {(data || []).map((campaign, index, array) => {
            const isLast = index === array.length - 1;
            return (
              <TopCampaignSectionItem
                key={`${index}-${campaign.title}`}
                rank={index}
                title={campaign.title}
                value={campaign.value}
                isLast={isLast}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

import React from "react";

//translations
import { useTranslation } from "react-i18next";

import { NavItem, UncontrolledTooltip, NavLink } from "reactstrap";

//constants
import { LAYOUT_MODES } from "../constants/index";

interface LightDarkProps {
  layoutMode: any;
  onChangeLayoutMode: any;
}

const LightDarkMode = ({ layoutMode, onChangeLayoutMode }: LightDarkProps) => {
  const { t } = useTranslation(["common"]);

  const mode =
    layoutMode === LAYOUT_MODES["DARK"]
      ? LAYOUT_MODES["LIGHT"]
      : LAYOUT_MODES["DARK"];

  return (
    <NavItem className="mt-auto" id="color-mode">
      <NavLink
        className="nav-link light-dark"
        onClick={() => onChangeLayoutMode(mode)}
      >
        <i className="bx bx-moon" id="moon"></i>{" "}
      </NavLink>{" "}
      <UncontrolledTooltip placement="right" target="color-mode">
        <span className="light-mode">{t(["common:theme.light"])}</span>
        <span className="dark-mode">{t(["common:theme.dark"])}</span>
      </UncontrolledTooltip>{" "}
    </NavItem>
  );
};

export default LightDarkMode;

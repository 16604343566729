import React, { useEffect, useRef, useState, useMemo } from "react";
import classnames from "classnames";

//translations
import { useTranslation } from "react-i18next";

import { Alert, Form } from "reactstrap";

import { useRedux } from "../../../../hooks";

// components
import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";

// interface
import {
  MessageSendInputTypes,
  MessagesTypes,
  TypeOfAttachment,
  TypeOfMedia,
} from "../../../../data/messages";

import TemplatesMenu from "./TemplatesMenu";
import ChatBotSection from "./ChatbotSection";

interface IndexProps {
  onSend: (data: any) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
}

const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
}: IndexProps) => {
  const { t } = useTranslation(["conversation"]);

  const { useAppSelector } = useRedux();

  const { chatStatus, robotStatus } = useAppSelector(
    useMemo(() => (state) => ({
      chatStatus: state.Chats.chatStatus,
      robotStatus: state.Chats.robotStatus,
    }), [])
  );
  

  /**
   * Slide Menus
   */
  const mediaFormRef = useRef<HTMLFormElement | null>(null);

  const [moreIsOpen, setMoreIsOpen] = useState(false);
  const [templatesIsOpen, setTemplatesIsOpen] = useState(false);

  function handleOnToggleMore() {
    if (templatesIsOpen) handleOnToggleTemplates();
    setMoreIsOpen(prev => !prev);

    if (mediaFormRef.current) {
      mediaFormRef.current.reset();
    }
  }

  function handleOnToggleTemplates() {
     if (moreIsOpen) handleOnToggleMore();
     setTemplatesIsOpen(prev => !prev);
  }

  const [disabled, setDisabled] = useState<boolean>(true);

  const [text, setText] = useState<null | string>(null);
  const onChangeText = (value: string) => {
    setText(value);
  };

  const [files, setFiles] = useState<{
    mediaType: TypeOfMedia;
    media: {
      files: Array<any>;
      attachmentType: TypeOfAttachment;
    };
  } | null>(null);
  const onSelectFiles = (
    mediaType: TypeOfMedia,
    media: {
      files: Array<any>;
      attachmentType: TypeOfAttachment;
    }
  ) => {
    
    setFiles({ mediaType, media });
    setMoreIsOpen(false);
  };

  const onSubmit = () => {
    const data: MessageSendInputTypes = {
      text: text ? text : undefined,
      mediaType: files ? files.mediaType : undefined,
      media: files && files.media ? files.media : undefined,
    };
    setText("");
    setFiles(null);
    onSend(data);
    
  };

  const onClearMedia = () => {
    setFiles(null);
    if (mediaFormRef.current) {
      mediaFormRef.current.reset();
    }
  };

  useEffect(() => {
    if (text || files?.media?.files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, files]);

  useEffect(() => {
    setMoreIsOpen(false);
    setTemplatesIsOpen(false);
  }, [chatUserDetails.id]);

  return (
    <div className="chat-input-section">
      
      <Form
        id="chatinput-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          if (!chatStatus.isActive || !robotStatus.isStopped) {
            return;
          }
          onSubmit();
        }}
      >
        <div className="row g-0 align-items-center">
          <ChatBotSection
            className="col-auto d-none d-lg-flex"
            onClick={() => {
              if (!chatStatus.isActive) handleOnToggleTemplates();
            }}
          />

          <div
            className={classnames("col p-3 p-lg-4 d-flex align-items-center")}
          >
            <StartButtons
              inputText={text}
              setInputText={onChangeText}
              chatStatus={chatStatus}
              robotStatus={robotStatus}
              onToggleMore={handleOnToggleMore}
              onToggleTemplates={handleOnToggleTemplates}
              platform={chatUserDetails.platform}
              
            />

            {!chatStatus.isActive ? (
              <div className="col text-center">
                {t(["conversation:bot.is-blocked"])}
              </div>
            ) : null}

            {chatStatus.isActive && !robotStatus.isStopped ? (
              <div className="col text-center">
                {t(["conversation:bot.is-active"])}
              </div>
            ) : null}

            {chatStatus.isActive && robotStatus.isStopped ? (
              <>
                <div className="col">
                  <InputSection value={text} onChange={onChangeText} />
                </div>
                <div className="col-auto">
                  <EndButtons  setFiles={setFiles} onSubmit={onSubmit} disabled={disabled} />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Form>

      {chatStatus.isActive && robotStatus.isStopped && files && files.media && files.media.files[0].name != "recordedAudio" ? (
        <Alert
          isOpen={true}
          toggle={onClearMedia}
          color="secondary"
          className="alert-dismiss-custom 
        rounded-pill font-size-12 mb-1 selected-media"
          closeClassName="selected-media-close"
        >
          <p className="me-2 mb-0">
            {files.media.attachmentType === TypeOfAttachment.document &&
              t(["conversation:upload.files.text"], {
                count: files.media.files.length,
              })}

            {files.media.attachmentType === TypeOfAttachment.image &&
              t(["conversation:upload.images.text"], {
                count: files.media.files.length,
              })}

            {files.media.attachmentType === TypeOfAttachment.audio &&
              t(["conversation:upload.audios.text"], {
                count: files.media.files.length,
              })}

            {files.media.attachmentType === TypeOfAttachment.video &&
              t(["conversation:upload.videos.text"], {
                count: files.media.files.length,
              })}
          </p>
        </Alert>
      ) : null}

      <TemplatesMenu
        isOpen={templatesIsOpen}
        chatStatus={chatStatus}
        robotStatus={robotStatus}
      />

      <form
        ref={mediaFormRef}
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <MoreMenu
          isOpen={moreIsOpen}
          chatStatus={chatStatus}
          robotStatus={robotStatus}
          onSelectFiles={onSelectFiles}
          onToggleTemplates={handleOnToggleTemplates}
          platform={chatUserDetails.platform}
          
        />
      </form>

      <Reply
        reply={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </div>
  );
};

export default Index;

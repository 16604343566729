import { ReactNode } from "react";
import { PopoverHeader, PopoverHeaderProps } from "reactstrap";

interface PopoverBoxHeaderProps extends PopoverHeaderProps {
  children: ReactNode;
}

export function PopoverBoxHeader({ children, ...rest }: PopoverBoxHeaderProps) {
  return <PopoverHeader {...rest}>{children}</PopoverHeader>;
}

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import { MonthlyLeadsProps } from "../../../../../data/analytics";
import {
  BarChart,
  BarChartDataProps,
  BarChartOptionsProps,
} from "../../../../../components/charts/Bar";

interface MonthlySectionSectionProps {
  data: MonthlyLeadsProps;
}

export const MonthlySection = ({ data }: MonthlySectionSectionProps) => {
  const { t } = useTranslation(["analytics"]);

  const [dataChart, setDataChart] = useState<BarChartDataProps>();

  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  } as BarChartOptionsProps;

  useEffect(() => {
    if (!data) return;

    setDataChart({
      labels: Object.keys(data),
      datasets: [
        {
          label: t(["analytics:campaign.monthly.subtitle"]),
          data: Object.values(data),
          backgroundColor: ["rgba(51, 184, 165, 1)"],
          borderColor: ["rgba(255, 255, 255, 1)"],
          borderWidth: 1.5,
        },
      ],
    });
  }, [data, t]);

  if (!data || !dataChart) return <></>;
  return (
    <div className="container px-4 my-5">
      <div className="border border-primary bg-light rounded p-4 col-sm">
        <div className="header">
          <h4>{t(["analytics:campaign.monthly.title"])}</h4>
        </div>
        <div className="col-sm d-flex justify-content-center">
          <div className="col-10" style={{ maxWidth: 700 }}>
            <BarChart data={dataChart} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

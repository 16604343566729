interface MediaItemPreviewVideoProps {
  url: string;
}

export const MediaItemPreviewVideo = ({ url }: MediaItemPreviewVideoProps) => {
  return (
    <video
      height="auto"
      width="auto"
      style={{ maxWidth: "80dvw", maxHeight: "80dvh" }}
      controls
      preload="metadata"
    >
      <source src={url + "#t=0.1"} />
    </video>
  );
};

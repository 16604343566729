import React from "react";

import { ANALYTICS_TABS } from "../../../../constants";

import { OverviewChart } from "./Overview";
import { CampaignChart } from "./Campaign";
import { CampaignProps } from "../../../../data/analytics";
import { NotAvailable } from "./NotAvailable";

interface IndexProps {
  campaign: CampaignProps;
}
const Index = ({ campaign }: IndexProps) => {
  if (!campaign) return <NotAvailable />;

  return campaign.id === ANALYTICS_TABS.DEFAULT ? (
    <OverviewChart />
  ) : (
    <CampaignChart campaign={campaign} />
  );
};

export default Index;

import { ContactTypes, PlatformTypes } from "./contacts";
import { Timestamp } from "firebase/firestore";

/** Type of file to upload 
 * 
  public enum AttachmentType
  {
      [JsonProperty("image")]
      Image,
      [JsonProperty("video")]
      Video,
      [JsonProperty("audio")]
      Audio
  }
 *
*/
export enum TypeOfAttachment {
  image = 0,
  video = 1,
  audio = 2,
  document = 3,
}

/** Type of message with media to send 
 *
  public enum MediaType
  {
      Audio,
      Document,
      Image,
      Video
  }
 *
*/
export enum TypeOfMedia {
  audio = 0,
  document = 1,
  image = 2,
  video = 3,
}

export enum TypeOfMessage {
  audio = 0,
  document = 1,
  text = 2,
  image = 3,
  contacts = 4,
  location = 5,
  video = 6,
  template = 7,
}
export interface MessageTypesBackend {
  id: string;
  From: string;
  To: string;
  IsRead: boolean;
  Timestamp: Timestamp;
  Type: TypeOfMessage;
}

export interface AudioMessageTypesBackend extends MessageTypesBackend {
  Id: string;
  MimeType: string;
  SHA256: string;
  Url: string;
}
export interface DocumentMessageTypesBackend extends MessageTypesBackend {
  Id: string;
  FileName: string;
  Caption: string;
  MimeType: string;
  SHA256: string;
  Url: string;
}

export interface TextMessageTypesBackend extends MessageTypesBackend {
  Body: string;
}

export interface ImageMessageTypesBackend extends MessageTypesBackend {
  Id: string;
  Caption: string;
  MimeType: string;
  SHA256: string;
  Url: string;
}

export interface ContactMessageTypesBackend extends MessageTypesBackend {}

export interface LocationMessageTypesBackend extends MessageTypesBackend {}

export interface TemplatesMessageTypesBackend extends MessageTypesBackend {
  Language: string;
  Name: string;
  TemplateType: number;
}

export interface VideoMessageTypesBackend extends MessageTypesBackend {
  Id: string;
  Caption: string;
  MimeType: string;
  SHA256: string;
  Url: string;
}

export type MessagesResponseTypesBackend = AudioMessageTypesBackend &
  DocumentMessageTypesBackend &
  TextMessageTypesBackend &
  ImageMessageTypesBackend &
  ContactMessageTypesBackend &
  LocationMessageTypesBackend &
  VideoMessageTypesBackend &
  TemplatesMessageTypesBackend;

export interface AttachmentTypes {
  id: number;
  name: string;
  downloadLink: string;
  desc: string;
}
export interface VideoTypes {
  id: number;
  downloadLink: string;
}
export interface ImageTypes {
  id: number;
  downloadLink: string;
}
export interface AudioTypes {
  id: number;
  downloadLink: string;
  mimeType: string;
}

export interface TemplateTypes {
  name: string;
  language: string;
  type: number;
}
export interface MessagesTypes {
  mId: number;
  text?: string;
  time: string;
  meta: {
    receiver: string | number;
    sender: string | number;
    userData?: ContactTypes;
    sent: boolean;
    received: boolean;
    read: boolean;
    isForwarded?: boolean;
  };
  attachments?: AttachmentTypes[];
  image?: ImageTypes[];
  audio?: AudioTypes[];
  video?: VideoTypes[];
  template?: TemplateTypes[];
  replyOf?: MessagesTypes;
}
export interface ConversationTypes {
  conversationId: string | number;
  userId: string;
  isGroupConversation?: boolean;
  typingUser?: string | number;
  messages: MessagesTypes[];
}

export interface MessageSendInputTypes {
  text?: string;
  mediaType?: TypeOfMedia;
  media?: {
    attachmentType: TypeOfAttachment;
    files: Array<any>;
  };
}
export interface MessageSendTypes extends MessageSendInputTypes {
  meta: {
    receiver: string | number;
    sender: string | number;
    senderFullName: string;
    platform: PlatformTypes;
  };
  time?: string;
  replyOf?: MessagesTypes;
}

import { NotificationsProps } from "../../data/notifications";

export enum NotificationsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_NOTIFICATIONS = "@@chats/GET_NOTIFICATIONS",
  GET_CONTACT_NOTIFICATIONS = "@@chats/GET_CONTACT_NOTIFICATIONS",
  UPDATE_NOTIFICATION_STATUS = "@@chats/UPDATE_NOTIFICATION_STATUS",
}
export interface NotificationsState {
  notifications: NotificationsProps[] | undefined;
  isNotificationsFetched: boolean;
  getNotificationsLoading: boolean;

  contactNotifications: NotificationsProps[] | undefined;
  isContactNotificationsFetched: boolean;
  getContactNotificationsLoading: boolean;

  isNotificationStatusUpdated: boolean;
}

import { MediaContent } from "./MediaContent";
import { MediaHasMoreOverlay } from "./MediaHasMoreOverlay";
import { MediaHeader } from "./MediaHeader";
import { MediaHeaderAction } from "./MediaHeaderAction";
import { MediaHeaderTitle } from "./MediaHeaderTitle";
import { MediaItem } from "./MediaItem";
import { MediaItemPreview } from "./MediaItemPreview";
import { MediaRoot } from "./MediaRoot";

export const Media = {
  Root: MediaRoot,
  Header: MediaHeader,
  HeaderAction: MediaHeaderAction,
  HeaderTitle: MediaHeaderTitle,
  Content: MediaContent,
  Item: MediaItem,
  HasMoreOverlay: MediaHasMoreOverlay,
  ItemPreview: MediaItemPreview,
};

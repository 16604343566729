import { CampaignProps } from "../../data/analytics";
import { AnalyticsActionTypes } from "./types";

// common success
export const analyticsApiResponseSuccess = (actionType: string, data: any) => ({
  type: AnalyticsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const analyticsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AnalyticsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getListCampaigns = () => ({
  type: AnalyticsActionTypes.GET_CAMPAIGNS_LIST,
});

export const changeSelectedCampaign = (
  selectedCampaign: CampaignProps | null
) => ({
  type: AnalyticsActionTypes.CHANGE_SELECTED_CAMPAIGN,
  payload: selectedCampaign,
});

export const getOverviewData = (id: string | number) => ({
  type: AnalyticsActionTypes.GET_OVERVIEW_DATA,
  payload: { id },
});

export const getSelectedCampaignData = (
  id: string | number,
  name: string,
  period: { startDate: string; endDate: string }
) => ({
  type: AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_DATA,
  payload: { id, name, period },
});

export const getSelectedCampaignLeads = (id: string | number) => ({
  type: AnalyticsActionTypes.GET_SELECTED_CAMPAIGN_LEADS,
  payload: { id },
});

export const updateLeadWasContacted = (id: string | number, data: object) => ({
  type: AnalyticsActionTypes.UPDATE_LEAD_WAS_CONTACTED,
  payload: { id, data },
});
export const updateLeadIsLead = (id: string | number, data: object) => ({
  type: AnalyticsActionTypes.UPDATE_LEAD_IS_LEAD,
  payload: { id, data },
});

export const getSelectedContactCampaigns = (
  id: string | number,
  campaigns: CampaignProps[]
) => ({
  type: AnalyticsActionTypes.GET_SELECTED_CONTACT_CAMPAIGNS,
  payload: { id, campaigns },
});

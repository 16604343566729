import React, { ChangeEvent, useEffect, useRef } from "react";

//translations
import { useTranslation } from "react-i18next";

import { Label, UncontrolledTooltip } from "reactstrap";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  getProfileDetails,
  getSettings,
  uploadProfileCover,
} from "../../../redux/actions";

// interface
import { BasicDetailsTypes } from "../../../data/settings";
import Loader from "../../../components/Loader";

interface UserCoverImageProps {
  basicDetails: BasicDetailsTypes;
}
const UserCoverImage = ({ basicDetails }: UserCoverImageProps) => {
  const { t } = useTranslation(["settings", "common"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { isProfileCoverLoading, isProfileCoverUploaded } = useAppSelector(
    state => ({
      isProfileCoverLoading: state.Profile.isProfileCoverLoading,
      isProfileCoverUploaded: state.Profile.isProfileCoverUploaded,
    })
  );

  const fileInputRef = useRef<any>(null);
  const coverImage = basicDetails && basicDetails.coverImage;

  useEffect(() => {
    if (isProfileCoverUploaded) {
      dispatch(getSettings());
      dispatch(getProfileDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileCoverUploaded]);

  function handleChangeProfileCover(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files && files.length > 0) {
      dispatch(uploadProfileCover(files[0]));
      fileInputRef.current.value = null;
    }
  }

  return (
    <div className="user-profile-img">
      {isProfileCoverLoading && <Loader />}
      <img
        src={coverImage}
        className="profile-img profile-foreground-img"
        style={{ height: "160px" }}
        alt=""
      />
      <div className="overlay-content">
        <div>
          <div className="user-chat-nav p-3">
            <div className="d-flex w-100 align-items-center">
              <div className="flex-grow-1">
                <h5 className="text-white mb-0">
                  {t(["settings:menu.settings"])}
                </h5>
              </div>
              <div className="flex-shrink-0">
                <div
                  className="avatar-xs p-0 rounded-circle profile-photo-edit"
                  id="change-cover"
                >
                  <input
                    ref={fileInputRef}
                    id="profile-foreground-img-file-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    className="profile-foreground-img-file-input"
                    onChange={handleChangeProfileCover}
                  />
                  <Label
                    htmlFor="profile-foreground-img-file-input"
                    className="profile-photo-edit avatar-xs"
                  >
                    <span className="avatar-title rounded-circle bg-light text-body">
                      <i className="bx bxs-pencil"></i>
                    </span>
                  </Label>
                </div>
                <UncontrolledTooltip target="change-cover" placement="bottom">
                  {t(["common:button.change-background"])}
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCoverImage;

import * as url from "./urls";
import { GetFirestoreDocProps, UpdateFirestoreFieldProps, addFirestoreDoc, fireStoreTimestamp, getFirestoreDocs } from "./apiFirestore";
import { BoardBackendProps, BoardProps, CommentBackendProps, CommentProps } from "../redux/kanban/types";
import { KanbanComment } from "../data/kanban";

const getBoards = async (filters: Pick<GetFirestoreDocProps, "filter">) => {
  const params: GetFirestoreDocProps = {
    paths: [url.FIRESTORE_BOARDS_COLLECTION],
    filter: filters?.filter,
  };

  console.log("Carregando board")

  const response = await getFirestoreDocs(params);

  const data: BoardProps = response.map((doc: BoardBackendProps) => {
    return {
      id: doc.id,
      columns: doc.Columns,
      active: doc.Active,
      createdAt: doc.CreatedAt.toDate().toISOString(),
    } as BoardProps;
  });

  console.log(data)
  return data;
};


const createBoard = async () => {
  console.log("Criando Board...")
  const params: UpdateFirestoreFieldProps = {
    paths: [url.FIRESTORE_BOARDS_COLLECTION],
    data: {
      Active: true,
      Columns: ["Em Aberto", "Em Atendimento", "Aguardando Retorno", "Finalizado"],
      CreatedAt: fireStoreTimestamp(),
    },
  };
  
  const response = await addFirestoreDoc(params);
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.kanban.board/create"]);
    }
    resolve(["notifications:success.kanban.board/create"]);
  });
};

const createComment = async (comment: KanbanComment, boardId: string, contactId: string) => {
  console.log("Criando comment...")
  const params: UpdateFirestoreFieldProps = {
    paths: ["contacts/", contactId, "/boards/", boardId, "/activities"],
    data: {
      AttendantId: comment.attendantId,
      CreatedAt: fireStoreTimestamp(),
      Message: comment.message,
    },
  };
  
  const response = await addFirestoreDoc(params);
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(["notifications:error.kanban.board/create"]);
    }
  });
};

const getComments = async (boardId: string, contactId: string) => {
  const params: GetFirestoreDocProps = {
    paths: ["contacts/", contactId, "/boards/", boardId, "/activities"],
    sortBy: [{ fieldPath: "CreatedAt", directionStr: "desc" }],
  };

  console.log("Carregando Comments")

  const response = await getFirestoreDocs(params);

  const data: CommentProps = response.map((doc: CommentBackendProps) => {
    return {
      id: doc.id,
      attendantId: doc.AttendantId,
      message: doc.Message,
      contactId: contactId,
      createdAt: doc.CreatedAt,
    } as CommentProps;
  });

  console.log(data)
  return data;
};

export { getBoards, createBoard, createComment, getComments};

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type BarChartOptionsProps = ChartOptions<"bar">;
export type BarChartDataProps = ChartData<"bar">;

export interface BarChartProps {
  options?: BarChartOptionsProps;
  data: BarChartDataProps;
}

export const BarChart = ({ data, options }: BarChartProps) => {
  return <Bar data={data} options={options} />;
};

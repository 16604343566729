import React, { ChangeEvent, useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  AirlineProps,
  ConclusionPercentageProps,
} from "../../../../../data/analytics";
import {
  BarChart,
  BarChartDataProps,
  BarChartOptionsProps,
} from "../../../../../components/charts/Bar";
import { Input } from "reactstrap";

interface MultipleChartsSectionSectionProps {
  data: { conclusion: ConclusionPercentageProps; airline: AirlineProps };
}

export const MultipleChartsSection = ({
  data,
}: MultipleChartsSectionSectionProps) => {
  const { t } = useTranslation(["form", "analytics"]);

  const [dataChart1, setDataChart1] = useState<BarChartDataProps>();
  const [dataChart2, setDataChart2] = useState<BarChartDataProps>();

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  } as BarChartOptionsProps;

  useEffect(() => {
    if (!data.conclusion || !data.airline) return;

    setDataChart1({
      labels: data.conclusion ? Object.keys(data.conclusion) : undefined,
      datasets: [
        {
          label: t(["analytics:campaign.campaign-conclusion.subtitle"]),
          data: data.conclusion ? Object.values(data.conclusion) : [0],
          backgroundColor: ["rgba(185, 104, 173, 1)"],
          borderColor: ["rgba(255, 255, 255, 1)"],
          borderWidth: 1.5,
        },
      ],
    });

    setDataChart2({
      labels: data.airline ? Object.keys(data.airline) : undefined,
      datasets: [
        {
          label: t(["analytics:campaign.cia-aerea.subtitle"]),
          data: data.airline ? Object.values(data.airline) : [0],
          backgroundColor: ["rgba(50, 118, 181, 1)"],
          borderColor: ["rgba(255, 255, 255, 1)"],
          borderWidth: 1.5,
        },
      ],
    });
  }, [data.airline, data.conclusion, t]);

  const [selectValue, setSelectValue] = useState("0");

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSelectValue(e.target.value);
  };

  if (!data.airline || !data.conclusion || !dataChart1 || !dataChart2)
    return <></>;
  return (
    <div className="container px-4 my-5">
      <div className="col-sm col-lg-4 border border-secondary rounded">
        <Input value={selectValue} type="select" onChange={handleSelect}>
          <option value={-1}>{t(["form:input.select-option"])}</option>
          <option value={0}>
            {t(["analytics:campaign.campaign-conclusion.title"])}
            {/* {" & "}
            {t(["analytics:campaign.cia-aerea.title"])} */}
          </option>
        </Input>
      </div>

      {selectValue === "0" && (
        <div className="mt-3 px-2 row gap-3">
          <div className="border border-primary bg-light rounded p-4 col mw-50">
            <div className="header">
              <h4>{t(["analytics:campaign.campaign-conclusion.title"])}</h4>
              <span>
                ({t(["analytics:campaign.campaign-conclusion.subtitle"])})
              </span>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="col-12" style={{ maxWidth: 450 }}>
                <BarChart data={dataChart1} options={options} />
              </div>
            </div>
          </div>
          {/* <div className="border border-primary bg-light rounded p-4 col mw-50">
            <div className="header">
              <h4>{t(["analytics:campaign.cia-aerea.title"])}</h4>
              <span>
                ({t(["analytics:campaign.campaign-conclusion.subtitle"])})
              </span>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="col-12" style={{ maxWidth: 450 }}>
                <BarChart data={dataChart2} options={options} />
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

import { TagsItem } from "./TagsItem";
import { TagsContent } from "./TagsContent";
import { TagsButton } from "./TagsButton";
import { TagsContainer } from "./TagsContainer";

export const TagsList = {
  Root: TagsContent,
  Container: TagsContainer,
  Item: TagsItem,
  Button: TagsButton,
};

const getFirstLastName = (
  name: string,
  splitChar: string = " ",
  joinChar: string = " "
) => {
  const [firstName, ...lastName] = name.split(splitChar).filter(Boolean);
  return { firstName, lastName: lastName.join(joinChar) };
};

const getFirstLastNameOrPhone = (name: string | undefined, phone: string) => {
  if (name) {
    return getFirstLastName(name);
  }
  return { firstName: phone, lastName: "" };
};

export { getFirstLastName, getFirstLastNameOrPhone };

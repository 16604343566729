import React from "react";
import { useTranslation } from "react-i18next";
import { useRedux } from "../../../../hooks";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { TagProps } from "../../../../data";
import { FilterTagsProps, VisualFilterProps } from ".";

interface TagFiltersProps {
  filters: FilterTagsProps;
  visualFilters: VisualFilterProps[];
  handleAddFilter: (
    filter: FilterTagsProps,
    visualFilters: VisualFilterProps
  ) => void;
}

const TagFilters = ({
  handleAddFilter,
  filters,
  visualFilters,
}: TagFiltersProps) => {
  const { t } = useTranslation(["chats", "common"]);
  const { useAppSelector } = useRedux();

  const { tags } = useAppSelector(state => ({
    tags: state.Chats.tags,
  }));

  function handleSubmit(data: TagProps) {
    if (!data.id) return;

    const values = filters && filters.value.length ? filters.value : [];
    const filter = {
      fieldPath: "Tags",
      opStr: "array-contains-any",
      value: [...values, data.id],
    } as FilterTagsProps;
    const visualFilter = {
      id: data.id,
      Name: data.Name,
      Color: data.Color,
      fieldPath: "Tags",
    } as VisualFilterProps;
    handleAddFilter(filter, visualFilter);
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="soft-secondary">
        <div className="d-flex align-items-center gap-2">
          <i className="bx bxs-down-arrow font-size-10" />
          <span>{t(["form:placeholder.select-tag"])}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className="col-12">
        {tags
          ? tags.map((tag: TagProps) => {
              const isIncluded = visualFilters.filter(
                vFilter => vFilter.id === tag.id
              );
              return isIncluded.length <= 0 ? (
                <DropdownItem
                  key={tag.id}
                  onClick={() => handleSubmit(tag)}
                  className="d-flex align-items-center gap-2"
                >
                  <div
                    className="rounded border border-light"
                    style={{
                      backgroundColor: tag.Color,
                      width: 12,
                      height: 12,
                    }}
                  />
                  <div>{tag.Name}</div>
                </DropdownItem>
              ) : null;
            })
          : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default TagFilters;

import React, { useEffect, useState } from "react";

// hooks
import { useExpiredTime, useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
  setChatStatus,
  getRobotStatus,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import {
  MessageSendInputTypes,
  MessageSendTypes,
  MessagesTypes,
} from "../../../data/messages";

import { AuthenticatedUserProps } from "../../../helpers/firebase_helper";
import { PlatformTypes } from "../../../data";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    chatUserDetails,
    chatUserConversations,

    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    isContactArchiveToggled,
  } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
    chatUserConversations: state.Chats.chatUserConversations,
    isUserMessageSent: state.Chats.isUserMessageSent,
    isMessageDeleted: state.Chats.isMessageDeleted,
    isMessageForwarded: state.Chats.isMessageForwarded,
    isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
    isImageDeleted: state.Chats.isImageDeleted,
    isContactArchiveToggled: state.Chats.isContactArchiveToggled,
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile }: { userProfile: AuthenticatedUserProps } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: MessageSendInputTypes) => {
    if (!userProfile.userData || !chatUserDetails) return;

    const params: MessageSendTypes = {
      ...data,
      meta: {
        receiver: chatUserDetails.id,
        sender: userProfile.userData.id,
        senderFullName: userProfile.userData.FullName,
        platform: chatUserDetails.platform,
      },
      replyOf: replyData ? replyData : undefined,
    };
    dispatch(onSendMessage(params));
    setReplyData(null);
  };

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted ||
      isContactArchiveToggled
    ) {
      dispatch(getChatUserConversations(chatUserDetails.id));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    isContactArchiveToggled,
  ]);

  useEffect(() => {
    if (
      (chatUserConversations && !chatUserConversations.messages) ||
      !chatUserDetails.id
    ) {
      return;
    }

    if (chatUserDetails.platform !== PlatformTypes.whatsApp) {
      dispatch(
        setChatStatus({
          isActive: true,
          expirationTime: null,
        })
      );
      dispatch(getRobotStatus(chatUserDetails.id));
      return;
    }

    let lastContactMessageDate: Date | null = null;
    for (let i = chatUserConversations.messages.length - 1; i >= 0; i--) {
      if (
        chatUserConversations.messages[i].meta.sender === chatUserDetails.id
      ) {
        lastContactMessageDate = new Date(
          chatUserConversations.messages[i].time
        );
        break;
      }
    }

    if (!lastContactMessageDate) {
      dispatch(
        setChatStatus({
          isActive: false,
          expirationTime: null,
        })
      );
      return;
    }

    const isExpireDate = useExpiredTime;
    const additionalTime = { hours: 24, minutes: 0, seconds: 0 };
    const { isExpired, expirationTime } = isExpireDate(
      lastContactMessageDate,
      additionalTime
    );

    if (isExpired) {
      dispatch(
        setChatStatus({
          isActive: false,
          expirationTime,
        })
      );
      return;
    }

    dispatch(
      setChatStatus({
        isActive: true,
        expirationTime,
      })
    );
    dispatch(getRobotStatus(chatUserDetails.id));
  }, [
    dispatch,
    chatUserConversations,
    chatUserDetails.id,
    chatUserDetails.platform,
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(
      toggleArchiveContact(chatUserDetails.id, !chatUserDetails.isArchived)
    );
  };

  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        pinnedTabs={null}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;

import { ReactNode, MouseEvent } from "react";
import classnames from "classnames";

import {
  Button,
  ButtonProps,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
// emoji picker
import Picker, { IEmojiData } from "emoji-picker-react";

interface ChatInputMenuItemEmojiProps extends ButtonProps {
  id: string;
  color?: string;
  className?: string;
  show?: boolean;
  children: ReactNode;
  inputText: string;
  onEmojiClick: (text: string) => void;
}

export const ChatInputMenuItemEmoji = ({
  id = null,
  color = "none",
  className = null,
  show = true,
  children,
  inputText,
  onEmojiClick,
  ...rest
}: ChatInputMenuItemEmojiProps) => {
  const handleEmojiClick = (event: MouseEvent, emojiObject: IEmojiData) => {
    event.preventDefault();
    onEmojiClick(
      inputText ? `${inputText} ${emojiObject.emoji}` : emojiObject.emoji
    );
  };

  return (
    show && (
      <>
        <div className="links-list-item" id="emoji">
          <Button
            role="a"
            id={id}
            type="button"
            className={classnames(
              "btn btn-link text-decoration-none btn-lg waves-effect emoji-btn",
              className
            )}
            color={color}
            {...rest}
          >
            <i className="bx bx-smile align-middle"></i>
          </Button>
        </div>
        <UncontrolledTooltip target={id} placement="top">
          {children}
        </UncontrolledTooltip>

        <UncontrolledPopover placement="bottom" target={id}>
          <PopoverBody className="p-0">
            <Picker onEmojiClick={handleEmojiClick} />
          </PopoverBody>
        </UncontrolledPopover>
      </>
    )
  );
};

import { useState, useEffect, useMemo } from "react";
// hooks
import { useRedux } from "../hooks/index";
// api
import { getLoggedinUser } from "../api/apiCore";
//utils
import { divideByKey } from "../utils";
import { addMilliseconds } from "date-fns";

const useProfile = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { settings } = useAppSelector(
    useMemo(() => (state) => ({
        settings: state.Settings.settings,
    }), [])
  );

  const image = settings.basicDetails && settings.basicDetails.profile;
  const userProfileSession = getLoggedinUser();
  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? { ...userProfileSession, profileImage: image } : null
  );
  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    setUserProfile(
      userProfileSession ? { ...userProfileSession, profileImage: image } : null
    );
  }, [image]);

  return { userProfile, loading };
};

const useContacts = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { contactsList } = useAppSelector(state => ({
    contactsList: state.Contacts.contacts,
  }));

  const [contacts, setContacts] = useState<Array<any>>([]);
  const [categorizedContacts, setCategorizedContacts] = useState<Array<any>>(
    []
  );
  useEffect(() => {
    if (contactsList.length > 0) {
      setContacts(contactsList);
    }
  }, [contactsList]);

  useEffect(() => {
    if (contacts.length > 0) {
      const formattedContacts = divideByKey("firstName", contacts);
      setCategorizedContacts(formattedContacts);
    }
  }, [contacts]);

  const totalContacts = (categorizedContacts || []).length;
  return { categorizedContacts, totalContacts };
};

const useConversationUserType = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { chatUserDetails } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
  }));

  const [isChannel, setIsChannel] = useState<boolean>(false);
  useEffect(() => {
    setIsChannel(chatUserDetails.isChannel ? true : false);
  }, [chatUserDetails]);

  return { isChannel };
};

const useExpiredTime = (
  date: Date,
  additionalTime: {
    hours: number;
    minutes: number;
    seconds: number;
  }
) => {
  const additionalMilliseconds =
    (additionalTime ? additionalTime.hours * 60 * 60 * 1000 : 0) +
    (additionalTime ? additionalTime.minutes * 60 * 1000 : 0) +
    (additionalTime ? additionalTime.seconds * 1000 : 0);
  const targetDate = addMilliseconds(date, additionalMilliseconds);
  const nowDate = new Date();

  return { isExpired: nowDate >= targetDate, expirationTime: targetDate };
};

export { useProfile, useContacts, useConversationUserType, useExpiredTime };

import { TemplatesActionTypes } from "./types";

// common success
export const TemplatesApiResponseSuccess = (actionType: string, data: any) => ({
  type: TemplatesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const TemplatesApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: TemplatesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTemplates = () => ({
  type: TemplatesActionTypes.GET_TEMPLATES,
});

export const sendTemplate = (data: {
  recipientPhoneNumber: string;
  templateName: string;
  language: string;
  attendantName: string;
  type: number;
}) => ({
  type: TemplatesActionTypes.SEND_TEMPLATE,
  payload: data,
});

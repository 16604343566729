import React, { useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

// interface
import { BasicDetailsTypes } from "../../../data/settings";
import EditProfileModal from "../../../components/EditProfileModal";
import { i18nLocalesCountries } from "../../../i18n/i18next";

interface PersonalInfoProps {
  basicDetails: BasicDetailsTypes;
}

const PersonalInfo = ({ basicDetails }: PersonalInfoProps) => {
  const { t } = useTranslation(["common", "countries"]);

  const fullName = basicDetails
    ? `${basicDetails.firstName} ${basicDetails.lastName}`
    : "-";

  const location =
    basicDetails && basicDetails.location && basicDetails.location.length === 2
      ? (basicDetails.location as i18nLocalesCountries)
      : null;
  /*
  add edit modal handling
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="accordion-body">
      <div className="float-end">
        <Button
          color="none"
          type="button"
          className="btn btn-soft-primary btn-sm"
          onClick={openModal}
        >
          <i className="bx bxs-pencil align-middle"></i>
        </Button>
      </div>

      <div>
        <p className="text-muted mb-1">{t(["common:profile.name"])}</p>
        <h5 className="font-size-14">{fullName}</h5>
      </div>

      <div className="mt-4">
        <p className="text-muted mb-1">{t(["common:profile.email"])}</p>
        <h5 className="font-size-14">
          {basicDetails && basicDetails.email ? basicDetails.email : "-"}
        </h5>
      </div>

      <div className="mt-4">
        <p className="text-muted mb-1">{t(["common:profile.location"])}</p>
        <h5 className="font-size-14 mb-0">
          {location ? t(`countries:countries.${location}` as const) : "-"}
        </h5>
      </div>

      <EditProfileModal isOpen={isOpen} onClose={closeModal} />
    </div>
  );
};

export default PersonalInfo;

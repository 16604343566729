import { HtmlHTMLAttributes, ReactNode } from "react";
import classnames from "classnames";

interface TagsContentProps extends HtmlHTMLAttributes<HTMLUListElement> {
  className?: string;
  children: ReactNode;
}

export const TagsContent = ({
  className,
  children,
  ...rest
}: TagsContentProps) => {
  return (
    <ul className={classnames("tag-list list-unstyled", className)} {...rest}>
      {children}
    </ul>
  );
};

import React from "react";

//translations
import { useTranslation } from "react-i18next";

import { Input } from "reactstrap";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
}
const InputSection = ({ value, onChange }: InputSectionProps) => {
  const { t } = useTranslation(["form"]);
  return (
    <div className="position-relative">
      <Input
        type="text"
        className="form-control form-control-lg chat-input"
        id="chat-input"
        placeholder={t(["form:placeholder.type-message"])}
        value={value || ""}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        autoComplete="off"
      />
    </div>
  );
};
export default InputSection;

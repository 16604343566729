import React from "react";

//translations
import { useTranslation } from "react-i18next";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessagesTypes;
  fullName: string;
}
function RepliedMessage({ isFromMe, message, fullName }: RepliedMessageProps) {
  const { t } = useTranslation(["common", "conversation"]);
  const { userProfile } = useProfile();

  const isReplyFromMe = message.meta.sender + "" === userProfile.uid + "";
  return (
    <div className="">
      <div className="replymessage-block mb-2 d-flex align-items-start">
        <div className="flex-grow-1">
          <h5 className="conversation-name">
            {isReplyFromMe ? t(["conversation:chat.you"]) : fullName}
          </h5>
          {message.replyOf?.text && (
            <p className="mb-0">{message.replyOf?.text}</p>
          )}
          {(message.replyOf?.image || message.replyOf?.attachments) && (
            <p className="mb-0">
              {message.replyOf?.attachments &&
                !message.replyOf?.image &&
                `${message.replyOf?.attachments.length} ${t([
                  "common:medias.file_many",
                ])}`}
              {message.replyOf?.image &&
                !message.replyOf?.attachments &&
                `${message.replyOf?.image.length} ${t([
                  "common:medias.image_many",
                ])}`}
              {message.replyOf?.image &&
                message.replyOf?.attachments &&
                `${message.replyOf?.attachments.length} ${t([
                  "common:medias.file_many",
                ])} & ${message.replyOf?.image.length} ${t([
                  "common:medias.image_many",
                ])}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RepliedMessage;

import { Dispatch, SetStateAction } from "react";
import { Button } from "reactstrap";
import { TypeOfAttachment, TypeOfMedia } from "../../../../data";
import Recorder from "./Recorder";
interface EndButtonsProps {
  onSubmit: () => void;
  
  disabled: boolean; 
  setFiles:Dispatch<SetStateAction<{ mediaType: TypeOfMedia; media: { files: any[]; attachmentType: TypeOfAttachment; }; }>>

}
const EndButtons = ({ setFiles, onSubmit, disabled }: EndButtonsProps) => {
  return (
    <div   className="chat-input-links ms-2 gap-md-1">
        
       <Recorder setFiles={setFiles} disabled={disabled}  />

    

      <div className="links-list-item">
        <Button
          color="primary"
          type="submit"
          disabled={disabled}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
        >
          <i className="bx bxs-send align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;

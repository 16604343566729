import { ReactNode } from "react";
import { Label, LabelProps } from "reactstrap";
import classnames from "classnames";

interface CardMenuItemIconProps extends LabelProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const CardMenuItemIcon = ({
  children,
  className,
  disabled = false,
  ...rest
}: CardMenuItemIconProps) => {
  return (
    <Label
      className={classnames("avatar-sm mx-auto stretched-link", {
        "cursor-pointer": !disabled,
        "cursor-not-allowed": disabled,
        className,
      })}
      {...rest}
    >
      <span
        className={classnames(
          "avatar-title font-size-18 text-primary rounded-circle bg-light",
          { "bg-soft-primary": !disabled }
        )}
      >
        {children}
      </span>
    </Label>
  );
};

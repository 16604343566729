import React, { SelectHTMLAttributes, useMemo } from "react";

//translations
import { useTranslation } from "react-i18next";

interface InputCountrySelectProps
  extends SelectHTMLAttributes<HTMLSelectElement> {}

const InputCountrySelect = ({ ...rest }: InputCountrySelectProps) => {
  const { t } = useTranslation(["countries", "form"]);

  const countries: { code: string; name: string }[] = useMemo(
    () =>
      Object.entries(t(["countries:countries"], { returnObjects: true })).map(
        ([code, name]) => ({ code, name: name as string })
      ),
    [t]
  );
  
  return (
    <select {...rest}>
      <option value="">{t(["form:placeholder.location"])}</option>
      {countries.map(country => (
        <option key={country.code} value={country.code}>
          {country.name}
        </option>
      ))}
    </select>
  );
};

export default InputCountrySelect;

import React from "react";

//translations
import { useTranslation } from "react-i18next";

import classnames from "classnames";

// component
import { Link } from "react-router-dom";
import { ANALYTICS_TABS } from "../../../constants";
import { CampaignProps } from "../../../data/analytics";
interface OverviewProps {
  selectedCampaignId: string | number;
  onSelectedCampaign: (campaign: CampaignProps) => void;
}
const Overview = ({
  selectedCampaignId,
  onSelectedCampaign,
}: OverviewProps) => {
  const { t } = useTranslation(["analytics"]);

  const isSelectedCampaign: boolean =
    selectedCampaignId && selectedCampaignId === ANALYTICS_TABS.DEFAULT
      ? true
      : false;
  const onClick = () => {
    onSelectedCampaign({
      id: ANALYTICS_TABS.DEFAULT,
      name: ANALYTICS_TABS.DEFAULT,
    });
  };
  return (
    <div className="chat-message-list">
      <ul className="list-unstyled chat-list chat-user-list">
        <li
          className={classnames({ active: isSelectedCampaign })}
          onClick={onClick}
        >
          <Link to="#">
            <div className="d-flex align-items-center gap-1">
              <i className="bx bxs-pie-chart-alt-2"></i>
              <div className="overflow-hidden">
                <p className="text-truncate mb-0">
                  {t(["analytics:menu.overview"])}
                </p>
              </div>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Overview;

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// interface
import { AttachedfileTypes, AttachedfileItemTypes } from "../data/myProfile";

/** File Component Composition */
import { File } from "./AttachedFiles/index";
interface AttachedFileItemProps {
  attachedFile: AttachedfileItemTypes;
}
const AttachedFileItem = ({ attachedFile }: AttachedFileItemProps) => {
  const { t } = useTranslation(["common", "bookmarks"]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <File.Item>
      <File.ItemIcon>
        <i className={attachedFile.icon} />
      </File.ItemIcon>
      <File.ItemHeader>
        <File.ItemHeaderName name={attachedFile.fileName} />
        <File.ItemHeaderSize size={attachedFile.size} />
      </File.ItemHeader>

      <File.ItemActions>
        <File.ItemActionDownload url={attachedFile.downloadUrl}>
          <i className="bx bxs-download" />
        </File.ItemActionDownload>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            color="none"
            className="text-muted px-1"
            role="button"
          >
            <i className="bx bx-dots-horizontal-rounded"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              className="dropdown-item d-flex align-items-center justify-content-between"
              href="#"
            >
              {t(["common:button.share"])}{" "}
              <i className="bx bx-share-alt ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              className="dropdown-item d-flex align-items-center justify-content-between"
              href="#"
            >
              {t(["bookmarks:menu.bookmark_one"])}{" "}
              <i className="bx bx-bookmarks text-muted ms-2"></i>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="dropdown-item d-flex align-items-center justify-content-between"
              href="#"
            >
              {t(["common:button.delete"])}{" "}
              <i className="bx bx-trash ms-2 text-muted"></i>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </File.ItemActions>
    </File.Item>
  );
};
interface AttachedFilesProps {
  attachedFiles: AttachedfileTypes;
}

const AttachedFiles = ({ attachedFiles }: AttachedFilesProps) => {
  const { t } = useTranslation(["common"]);
  const [files, setFiles] = useState<AttachedfileItemTypes[]>([]);

  useEffect(() => {
    if (attachedFiles && attachedFiles.list) setFiles(attachedFiles.list);
  }, [attachedFiles]);

  return (
    <File.Root>
      <File.Header>
        <File.HeaderTitle title={t(["common:medias.attached-file_many"])} />
      </File.Header>
      <File.Content>
        {attachedFiles
          ? files.map((attachedFile: AttachedfileItemTypes, key: number) => (
              <AttachedFileItem attachedFile={attachedFile} key={key} />
            ))
          : t(["common:medias.no-file_many"])}
      </File.Content>
    </File.Root>
  );
};
export default AttachedFiles;

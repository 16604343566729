import React from "react";

import {
  Button,
  // Dropdown,
  // DropdownMenu,
  // DropdownToggle,
  // DropdownItem,
} from "reactstrap";

//images
import imagePlaceholder from "../../../assets/images/users/profile-placeholder.png";

interface ProfileUserProps {
  onCloseUserDetails: () => any;
  chatUserDetails: any;
  onOpenVideo: () => void;
  onOpenAudio: () => void;
  onToggleArchive: () => void;
}
const ProfileUser = ({
  onCloseUserDetails,
  chatUserDetails,
  // onOpenAudio,
  // onOpenVideo,
  onToggleArchive,
}: ProfileUserProps) => {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen(!dropdownOpen);

  const profile = chatUserDetails.profileImage
    ? chatUserDetails.profileImage
    : imagePlaceholder;
  const fullName =
    chatUserDetails.name === undefined
      ? chatUserDetails.firstName
        ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
        : "-"
      : chatUserDetails.name;

  return (
    <div className="p-3 border-bottom">
      <div className="user-profile-img">
        <img src={profile} className="profile-img rounded" alt="" />
        <div className="overlay-content rounded">
          <div className="user-chat-nav p-2">
            <div className="d-flex w-100">
              <div className="flex-grow-1">
                <Button
                  color="none"
                  type="button"
                  className="btn nav-btn text-white user-profile-show d-none d-lg-block close-user-details"
                  onClick={onCloseUserDetails}
                >
                  <i className="bx bx-x"></i>
                </Button>
                <Button
                  type="button"
                  color="none"
                  className="btn nav-btn text-white user-profile-show d-block d-lg-none close-user-details"
                  onClick={onCloseUserDetails}
                >
                  <i className="bx bx-left-arrow-alt"></i>
                </Button>
              </div>
              {/* <div className="flex-shrink-0">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    color="none"
                    className="btn nav-btn text-white"
                    type="button"
                  >
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {<DropdownItem
                      className="d-flex justify-content-between align-items-center d-lg-none user-profile-show"
                      to="#"
                    >
                      {t(["profile:view-profile"])}{" "}
                      <i className="bx bx-user text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center d-lg-none"
                      to="#"
                      onClick={onOpenAudio}
                    >
                      {t(["common:medias.audio_one"])}{" "}
                      <i className="bx bxs-phone-call text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center d-lg-none"
                      to="#"
                      onClick={onOpenVideo}
                    >
                      {t(["common:medias.video_one"])}{" "}
                      <i className="bx bx-video text-muted"></i>
                    </DropdownItem> }
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center"
                      to="#"
                      onClick={onToggleArchive}
                    >
                      {chatUserDetails.isArchived ? (
                        <>
                          {t(["common:button.un-archive"])}{" "}
                          <i className="bx bx-archive-out text-muted"></i>
                        </>
                      ) : (
                        <>
                          {t(["common:button.archive"])}{" "}
                          <i className="bx bx-archive text-muted"></i>
                        </>
                      )}
                    </DropdownItem>
                    {<DropdownItem
                      className="d-flex justify-content-between align-items-center"
                      to="#"
                    >
                      {t(["common:options.muted"])}{" "}
                      <i className="bx bx-microphone-off text-muted"></i>
                    </DropdownItem> }
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center"
                      to="#"
                    >
                      {t(["common:button.delete"])}{" "}
                      <i className="bx bx-trash text-muted"></i>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div> */}
            </div>
          </div>
          <div className="mt-auto p-3">
            <h5 className="user-name mb-1 text-truncate">{fullName}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUser;

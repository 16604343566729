import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { ChatInputMenu } from "../../../../components/ChatInputMenu";
import { ChatsState } from "../../../../redux/chats/types";
import { PlatformTypes } from "../../../../data";

interface StartButtonsProps
  extends Pick<ChatsState, "chatStatus" | "robotStatus"> {
  onToggleMore: () => void;
  onToggleTemplates: () => void;
  setInputText: (value: string) => void;
  inputText: null | string;
  platform: PlatformTypes;
}
const StartButtons = ({
  onToggleMore,
  onToggleTemplates,
  setInputText,
  inputText,
  chatStatus,
  robotStatus,
  platform,
}: StartButtonsProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="col-auto">
      <ChatInputMenu.Root>
        <ChatInputMenu.ItemMore
          className={classnames({
            "d-lg-none":
              !chatStatus.isActive ||
              (chatStatus.isActive && !robotStatus.isStopped),
          })}
          onClick={
            chatStatus.isActive
              ? onToggleMore
              : platform === PlatformTypes.whatsApp
              ? onToggleTemplates
              : onToggleMore
          }
        >
          {t(["common:button.more"])}
        </ChatInputMenu.ItemMore>

        {chatStatus.isActive &&
        robotStatus.isStopped &&
        platform === PlatformTypes.whatsApp ? (
          <ChatInputMenu.ItemTemplates onClick={onToggleTemplates}>
            {t(["common:button.templates"])}
          </ChatInputMenu.ItemTemplates>
        ) : null}

        {chatStatus.isActive && robotStatus.isStopped ? (
          <ChatInputMenu.ItemEmoji
            id="emoji-menu"
            inputText={inputText}
            onEmojiClick={setInputText}
          >
            {t(["common:medias.emoji"])}
          </ChatInputMenu.ItemEmoji>
        ) : null}
      </ChatInputMenu.Root>
    </div>
  );
};

export default StartButtons;

import React, { useEffect } from "react";

//translations
import { useTranslation } from "react-i18next";

import { Button, Col, Row } from "reactstrap";
import { useRedux } from "../../../hooks/useRedux";
import { createBoard, getBoards } from "../../../redux/actions";
import Loader from "../../../components/Loader";

const Welcome = () => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { boards, isLoading, isCreated } = useAppSelector(state => ({
    boards: state.Kanban.boards,
    isLoading: state.Kanban.isBoardsFetched,
    isCreated: state.Kanban.isKanbanBoardAdded
  }));

  /*
  get data
  */

  useEffect(() => {
    dispatch(getBoards());
  }, [dispatch]);

  useEffect(() => {
    if (isCreated) {
      dispatch(getBoards());
    }
  }, [isCreated, dispatch]);

  const { t } = useTranslation(["conversation"]);
  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                  <i className="bx bx-columns display-4 text-primary m-0"></i>
                </div>
              </div>

              {isLoading ? (
                <Button
                  type="button"
                  className="btn btn-primary w-lg"
                  onClick={() => {
                    dispatch(createBoard());
                  }}
                >
                  {/* {t(["conversation:welcome.get-started"])} */}
                  Criar Novo Board
                </Button>
              ) : (
                <Loader className="position-relative" />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;

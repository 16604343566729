import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import { Button, Form } from "reactstrap";
// hooks
import { useRedux } from "../../../hooks/index";
// actions
import {
  changeSelectedChat,
  changeSelectedCampaign,
  getListCampaigns,
} from "../../../redux/actions";
// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Overview from "./Overview";
import Campaigns from "./Campaigns";
import { CampaignProps } from "../../../data/analytics";

interface IndexProps {}
const Index = (props: IndexProps) => {
  const { t } = useTranslation(["analytics", "form", "common"]);

  const { dispatch, useAppSelector } = useRedux();

  const { selectedCampaign, campaigns } = useAppSelector(state => ({
    selectedCampaign: state.Analytics.selectedCampaign,
    campaigns: state.Analytics.campaignsList,
  }));

  const onSelectedCampaign = (campaign: CampaignProps) => {
    dispatch(changeSelectedCampaign(campaign));
    dispatch(changeSelectedChat(null));
  };

  useEffect(() => {
    dispatch(getListCampaigns());
  }, [dispatch]);

  const [campaignsList, setCampaignsList] = useState<CampaignProps[]>([]);
  useEffect(() => {
    if (campaigns) {
      setCampaignsList(campaigns);
    }
  }, [campaigns]);

  /*
   search
  */
  // const [filteredFavorites, setfFlteredFavorites] = useState<Array<any>>([]);
  // const [filteredDirectMessages, setFilteredDirectMessages] = useState<
  //   Array<any>
  // >([]);
  // const [filteredArchived, setFilteredArchived] = useState<Array<any>>([]);
  // const [search, setSearch] = useState("");

  // const onChangeSearch = (value: string) => {
  //   setSearch(value);
  //   const modifiedFavorites = [...favourites];
  //   const modifiedDirectMessages = [...directMessages];
  //   const modifiedArchived = [...archiveContacts];

  //   const filteredFavorites = (modifiedFavorites || []).filter((c: any) =>
  //     c["firstName"].toLowerCase().includes(value.toLowerCase())
  //   );
  //   setfFlteredFavorites(filteredFavorites);

  //   const filteredDirectMessages = (modifiedDirectMessages || []).filter(
  //     (c: any) => c["firstName"].toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredDirectMessages(filteredDirectMessages);

  //   const filteredArchived = (modifiedArchived || []).filter((c: any) =>
  //     c["firstName"].toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredArchived(filteredArchived);
  // };

  return (
    <>
      <div>
        <div className="px-4 pt-4">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h4 className="mb-4">{t(["analytics:menu.analytics"])}</h4>
            </div>
          </div>
          <Form>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control bg-light border-0 pe-0"
                placeholder={t(["form:placeholder.search-analytics"])}
                aria-label="Example text with button addon"
                aria-describedby="searchbtn-addon"
                // value={search || ""}
                // onChange={(e: any) => onChangeSearch(e.target.value)}
              />
              <Button
                className="btn btn-light"
                type="button"
                id="searchbtn-addon"
              >
                <i className="bx bx-search align-middle"></i>
              </Button>
            </div>
          </Form>
        </div>

        <AppSimpleBar className="chat-room-list">
          <Overview
            selectedCampaignId={selectedCampaign?.id}
            onSelectedCampaign={onSelectedCampaign}
          />
          <div className="px-4 pt-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-0">{t(["analytics:menu.campaigns"])}</h4>
              </div>
            </div>
          </div>
          {campaignsList && campaignsList.length > 0 && (
            <Campaigns
              campaigns={campaignsList}
              selectedCampaignId={selectedCampaign?.id}
              onSelectedCampaign={onSelectedCampaign}
            />
          )}
        </AppSimpleBar>
      </div>
    </>
  );
};

export default Index;

import React, { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { PopoverBox } from "../../../../components/PopoverBox";
import { useRedux } from "../../../../hooks/index";

import { TagProps } from "../../../../data";
import { addContactTag } from "../../../../redux/actions";

interface TagsAddProps {
  contactId: string | number;
  tags: TagProps[];
  isOpen: boolean;
  onToggle: () => void;
  elementRef: RefObject<HTMLElement>;
}

const TagsAdd = ({
  contactId,
  tags,
  isOpen,
  onToggle,
  elementRef,
}: TagsAddProps) => {
  const { t } = useTranslation(["common", "form", "profileDetails"]);

  // global store
  const { dispatch } = useRedux();

  const [selectedTag, setSelectedTag] = useState<TagProps>(null);

  const isValidData = selectedTag ? true : false;

  function handleSubmit() {
    if (!selectedTag) {
      return null;
    }
    dispatch(
      addContactTag({
        id: contactId,
        tagId: selectedTag.id,
        usedCount: selectedTag.UsedCount,
      })
    );
    setSelectedTag(null);
    onToggle();
  }

  useEffect(() => {
    setSelectedTag(null);
  }, [isOpen]);

  return (
    <PopoverBox.Root
      isOpen={isOpen}
      target={elementRef}
      placement="bottom-end"
      toggle={onToggle}
      trigger="legacy"
      className="popover-box"
    >
      <PopoverBox.Header>
        {t(["profileDetails:modals.add-tag"])}
      </PopoverBox.Header>

      <PopoverBox.Content>
        <UncontrolledDropdown>
          <DropdownToggle color="soft-secondary">
            <div className="d-flex align-items-center gap-2">
              {selectedTag ? (
                <>
                  <div
                    style={{
                      backgroundColor: selectedTag.Color,
                      width: 13,
                      height: 13,
                    }}
                  />
                  <div>{selectedTag.Name}</div>
                </>
              ) : (
                <>
                  <i className="bx bxs-down-arrow font-size-10" />
                  <span>{t(["form:placeholder.select-tag"])}</span>
                </>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu className="col-12">
            {tags
              ? tags.map((tag: TagProps) => (
                  <DropdownItem
                    key={tag.id}
                    onClick={() => setSelectedTag(tag)}
                    className="d-flex align-items-center gap-2"
                  >
                    <div
                      className="rounded border border-light"
                      style={{
                        backgroundColor: tag.Color,
                        width: 12,
                        height: 12,
                      }}
                    />
                    <div>{tag.Name}</div>
                  </DropdownItem>
                ))
              : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      </PopoverBox.Content>
      <PopoverBox.Footer>
        <PopoverBox.FooterAction className="d-flex gap-1 col-12">
          <button type="button" className="btn btn-link" onClick={onToggle}>
            {t(["common:button.cancel"])}
          </button>
          <button
            type="button"
            className="btn btn-soft-primary"
            onClick={handleSubmit}
            disabled={!isValidData}
          >
            {t(["common:button.add"])}
          </button>
        </PopoverBox.FooterAction>
      </PopoverBox.Footer>
    </PopoverBox.Root>
  );
};

export default TagsAdd;

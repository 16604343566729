import React from "react";
import { useTranslation } from "react-i18next";
import { useRedux } from "../../../../hooks";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AvatarAttendant from "../../../../components/AvatarAttendant";
import { AttendantProps } from "../../../../data/attendants";
import { FilterAttendantProps, VisualFilterProps } from ".";

interface AttendantFiltersProps {
  visualFilters: VisualFilterProps[];
  handleAddFilter: (
    filter: FilterAttendantProps,
    visualFilters: VisualFilterProps
  ) => void;
}

const AttendantFilters = ({
  handleAddFilter,
  visualFilters,
}: AttendantFiltersProps) => {
  const { t } = useTranslation(["chats", "common"]);
  const { useAppSelector } = useRedux();

  const { attendants } = useAppSelector(state => ({
    attendants: state.Attendants.attendants,
  }));

  function handleSubmit(data: AttendantProps) {
    if (!data.id) return;
    const filter = {
      fieldPath: "AssignedAttendant",
      opStr: "==",
      value: data.id,
    } as FilterAttendantProps;
    const visualFilter = {
      id: data.id,
      Name: data.fullName,
      Color: "#797c8c",
      fieldPath: "AssignedAttendant",
    } as VisualFilterProps;
    handleAddFilter(filter, visualFilter);
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="soft-secondary">
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-truncate">
            {t(["form:placeholder.select-attendant"])}
          </span>
          <i className="bx bxs-down-arrow font-size-10" />
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {attendants && attendants.length
          ? attendants.map((attendantItem: AttendantProps) => {
              const isIncluded = visualFilters.filter(
                vFilter => vFilter.id === attendantItem.id
              );
              return isIncluded.length <= 0 ? (
                <DropdownItem
                  key={attendantItem.id}
                  onClick={() => handleSubmit(attendantItem)}
                >
                  <div className="d-flex align-items-center gap-2">
                    <AvatarAttendant
                      className="avatar-xs"
                      attendant={attendantItem}
                    />
                    <span className="text-truncate">
                      {attendantItem.fullName}
                    </span>
                  </div>
                </DropdownItem>
              ) : null;
            })
          : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AttendantFilters;

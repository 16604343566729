import React, { HTMLAttributes } from "react";
import { Spinner } from "reactstrap";
import classnames from "classnames";

interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
  size?: "sm" | undefined;
}

const Loader = ({ className, size, ...rest }: LoaderProps) => {
  return (
    <div className={classnames("loader-container", className)} {...rest}>
      <div className="status">
        <Spinner color="primary" size={size} />
      </div>
    </div>
  );
};

export default Loader;

import { Button, ButtonProps } from "reactstrap";

interface RobotStatusButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void | undefined;
}

export function RobotStatusButton({
  children,
  onClick,
  ...rest
}: RobotStatusButtonProps) {
  return (
    <Button
      className="btn waves-effect waves-light"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

import { combineReducers } from "@reduxjs/toolkit";

import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import Layout from "./layout/reducer";
import Profile from "./profile/reducer";
import Contacts from "./contacts/reducer";
import Calls from "./calls/reducer";
import Bookmarks from "./bookmarks/reducer";
import Settings from "./settings/reducer";
import Chats from "./chats/reducer";
import Analytics from "./analytics/reducer";
import Notifications from "./notifications/reducer";
import Templates from "./templates/reducer";
import Attendants from "./attendants/reducer";
import Kanban from "./kanban/reducer";

export default combineReducers({
  ForgetPassword,
  Login,
  Register,
  Layout,
  Profile,
  Contacts,
  Calls,
  Bookmarks,
  Settings,
  Chats,
  Analytics,
  Notifications,
  Templates,
  Attendants,
  Kanban,
});

import { APIClient } from "./apiCore";
import { changeAuthUserPassword, reauthenticateUser } from "./apiFirestore";
import * as url from "./urls";

const api = new APIClient();

// postForgetPwd
const postFakeForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// postForgetPwd
const postJwtForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// Register Method
const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data);
};

// Register Method
const postJwtRegister = (data: any) => {
  return api.create(url.JWT_REGISTER, data);
};
const changePassword = async (data: object) => {
  const { oldPassword, password } = data as {
    oldPassword: string;
    password: string;
    confirmpassword: string;
  };
  if (!oldPassword && !password) {
    return Promise.reject("notifications:error.auth.auth/update-passoword");
  }
  await reauthenticateUser(oldPassword);

  const resPasswordChanged = await changeAuthUserPassword(password);

  if (resPasswordChanged && resPasswordChanged) {
    return Promise.resolve(resPasswordChanged.code);
  }

  return Promise.reject("notifications:error.auth.auth/something-wrong");
};

// postSocialLogin
const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

export {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postFakeLogin,
  postJwtLogin,
  postFakeRegister,
  postJwtRegister,
  changePassword,
  postSocialLogin,
};

import {
  CampaignProps,
  ContactCampaignProps,
  LeadProps,
  OverviewDataProps,
  selectedCampaignDataProps,
} from "../../data/analytics";

export enum AnalyticsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_CAMPAIGNS_LIST = "@@chats/GET_CAMPAIGNS_LIST",
  CHANGE_SELECTED_CAMPAIGN = "@@chats/CHANGE_SELECTED_CAMPAIGN",
  GET_OVERVIEW_DATA = "@@chats/GET_OVERVIEW_DATA",
  GET_SELECTED_CAMPAIGN_DATA = "@@chats/GET_SELECTED_CAMPAIGN_DATA",
  GET_SELECTED_CAMPAIGN_DATA_STATUS = "@@chats/GET_SELECTED_CAMPAIGN_DATA_STATUS",
  GET_SELECTED_CAMPAIGN_DATA_MONTHLY = "@@chats/GET_SELECTED_CAMPAIGN_DATA_MONTHLY",
  GET_SELECTED_CAMPAIGN_DATA_CONCLUSION = "@@chats/GET_SELECTED_CAMPAIGN_DATA_CONCLUSION",
  GET_SELECTED_CAMPAIGN_DATA_AIRLINE = "@@chats/GET_SELECTED_CAMPAIGN_DATA_AIRLINE",
  GET_SELECTED_CAMPAIGN_LEADS = "@@chats/GET_SELECTED_CAMPAIGN_LEADS",
  UPDATE_LEAD_WAS_CONTACTED = "@@chats/UPDATE_LEAD_WAS_CONTACTED",
  UPDATE_LEAD_IS_LEAD = "@@chats/UPDATE_LEAD_IS_LEAD",
  GET_SELECTED_CONTACT_CAMPAIGNS = "@@chats/GET_SELECTED_CONTACT_CAMPAIGNS",
}
export interface AnalyticsState {
  campaignsList: CampaignProps[] | undefined;
  isCampaignsListFetched: boolean;
  getCampaignsListLoading: boolean;

  selectedCampaign: CampaignProps | null;

  overviewData: OverviewDataProps | undefined;
  isOverviewDataFetched: boolean;
  getOverviewDataLoading: boolean;

  selectedCampaignData: selectedCampaignDataProps | undefined;
  isSelectedCampaignDataFetched: boolean;
  getSelectedCampaignDataLoading: boolean;

  selectedCampaignLeads: LeadProps[] | undefined;
  isSelectedCampaignLeadsFetched: boolean;
  getSelectedCampaignLeadsLoading: boolean;

  selectedContactCampaigns: ContactCampaignProps[] | undefined;
  isSelectedContactCampaignsFetched: boolean;
  getSelectedContactCampaignsLoading: boolean;
}

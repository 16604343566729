import React, { useEffect } from "react";

//translations
import { useTranslation } from "react-i18next";

import classnames from "classnames";
import { Alert, Row, Col, Form } from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";

// // hooks
import { useProfile } from "../../hooks";

//actions
import {
  getProfileDetails,
  logoutUser,
  userChangePassword,
} from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

import profilePlaceholder from "../../assets/images/users/profile-placeholder.png";

interface ChangePasswordProps {}
const ChangePassword = (props: ChangePasswordProps) => {
  const { t } = useTranslation(["common", "form", "auth", "notifications"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    changepasswordError,
    passwordChanged,
    changePassLoading,
    isUserLogout,
  } = useAppSelector(state => ({
    passwordChanged: state.ForgetPassword.passwordChanged,
    changepasswordError: state.ForgetPassword.changepasswordError,
    changePassLoading: state.ForgetPassword.loading,
    isUserLogout: state.Login.isUserLogout,
  }));

  // check password changed
  useEffect(() => {
    if (passwordChanged) {
      dispatch(logoutUser());
    }
  }, [dispatch, passwordChanged]);

  // get user profile details
  useEffect(() => {
    dispatch(getProfileDetails());
  }, [dispatch]);

  const resolver = yupResolver(
    yup.object().shape({
      oldPassword: yup.string().required(t(["form:required.old-password"])),
      password: yup.string().required(t(["form:required.new-password"])),
      confirmpassword: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          t(["form:invalid.confirm-new-password"])
        )
        .required(t(["form:required.confirm-new-password"])),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values: object) => {
    dispatch(userChangePassword(values));
  };

  const errorCode = changepasswordError as
    | "auth/wrong-password"
    | "auth/weak-password";

  const { userProfile, loading } = useProfile();
  const AvatarUrl =
    userProfile.userData.AvatarUrl && userProfile.userData.AvatarUrl.length
      ? userProfile.userData.AvatarUrl
      : profilePlaceholder;

  //After successfully changed password
  if (isUserLogout) {
    return <Redirect to="/auth-login" push />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader title={t(["auth:change.title"])} />
            {loading ? (
              <Loader />
            ) : (
              <div className="user-thumb text-center mb-4">
                <img
                  src={AvatarUrl}
                  className="rounded-circle img-thumbnail avatar-lg"
                  alt="thumbnail"
                />
                <h5 className="font-size-15 mt-3">
                  {userProfile.userData.FullName}
                </h5>
              </div>
            )}

            {changepasswordError && changepasswordError ? (
              <Alert color="danger">
                {t([
                  `notifications:error.auth.${errorCode}` as const,
                  "notifications:error.auth.auth/something-wrong",
                ])}
              </Alert>
            ) : null}
            {passwordChanged ? (
              <Alert color="success">
                {t(["notifications:success.auth.auth/password-changed"])}
              </Alert>
            ) : null}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {changePassLoading && <Loader />}
              <div className="mb-3">
                <FormInput
                  label={t(["form:input.old-password"])}
                  type="password"
                  name="oldPassword"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder={t(["form:placeholder.old-password"])}
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={true}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  label={t(["form:input.new-password"])}
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder={t(["form:placeholder.new-password"])}
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={false}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  label={t(["form:input.confirm-new-password"])}
                  type="password"
                  name="confirmpassword"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder={t(["form:placeholder.confirm-new-password"])}
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={true}
                />
              </div>

              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={passwordChanged ? true : false}
                    >
                      {t(["common:button.save"])}
                    </button>
                  </div>
                  <div className="col-6">
                    <Link
                      to="/dashboard"
                      className={classnames("btn btn-light w-100", {
                        disabled: passwordChanged ? true : false,
                      })}
                      type="button"
                      replace
                    >
                      {t(["common:button.cancel"])}
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default ChangePassword;

import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface FileItemActionDownloadProps {
  url: string;
  children: ReactNode;
}

export function FileItemActionDownload({
  url,
  children,
}: FileItemActionDownloadProps) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Link to={{ pathname: url }} target="_blank" className="text-muted px-1">
        {children}
      </Link>
    </div>
  );
}

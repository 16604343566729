import { ConnectedPageProps } from "../../data/settings";

export enum SettingsActionTypes {
  API_RESPONSE_SUCCESS = "@@settings/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@settings/API_RESPONSE_ERROR",

  GET_USER_SETTINGS = "@@settings/GET_USER_SETTINGS",
  UPDATE_USER_SETTINGS = "@@settings/UPDATE_USER_SETTINGS",
  UPDATE_USER_STATUS = "@@settings/UPDATE_USER_STATUS",

  GET_CONNECTED_PAGES = "@@settings/GET_CONNECTED_PAGES",
}

export interface SettingsState {
  settings: object;
  isStatusUpdated: boolean | null;
  connectedPagesData: ConnectedPageProps[];
  getConnectedPagesLoading: boolean | null;
  isConnectedPageFetched: boolean | null;
}

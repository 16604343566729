import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";

// Login Redux States
import { NotificationsActionTypes } from "./types";
import {
  notificationsApiResponseSuccess,
  notificationsApiResponseError,
} from "./actions";

import {
  getNotifications as getNotificationsApi,
  getContactNotifications as getContactNotificationsApi,
  updateNotificationStatus as updateNotificationStatusApi,
} from "../../api/index";

// helpers
// import {
//   showSuccessNotification,
//   showErrorNotification,
// } from "../../helpers/notifications";

//actions
import {} from "./actions";
import { NotificationsProps } from "../../data/notifications";

function* getNotifications(): Generator<any, void, any> {
  try {
    const response = yield call(getNotificationsApi);
    while (true) {
      const data: NotificationsProps[] = yield take(response);
      yield put(
        notificationsApiResponseSuccess(
          NotificationsActionTypes.GET_NOTIFICATIONS,
          data
        )
      );
    }
  } catch (error: any) {
    yield put(
      notificationsApiResponseError(
        NotificationsActionTypes.GET_NOTIFICATIONS,
        error
      )
    );
  }
}

function* getContactNotifications({ payload: { id } }: any) {
  try {
    const response: Promise<any> = yield call(getContactNotificationsApi, id);
    yield put(
      notificationsApiResponseSuccess(
        NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      notificationsApiResponseError(
        NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS,
        error
      )
    );
  }
}

function* updateNotificationStatus({ payload: { notifications } }: any) {
  try {
    const response: Promise<any> = yield call(
      updateNotificationStatusApi,
      notifications
    );
    yield put(
      notificationsApiResponseSuccess(
        NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS,
        response
      )
    );
    // yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    // yield call(showErrorNotification, error);
    yield put(
      notificationsApiResponseError(
        NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS,
        error
      )
    );
  }
}

export function* watchGetNotifications() {
  yield takeEvery(NotificationsActionTypes.GET_NOTIFICATIONS, getNotifications);
}

export function* watchGetContactNotifications() {
  yield takeEvery(
    NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS,
    getContactNotifications
  );
}

export function* watchUpdateNotificationStatus() {
  yield takeEvery(
    NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS,
    updateNotificationStatus
  );
}

function* notificationsSaga() {
  yield all([
    fork(watchGetNotifications),
    fork(watchGetContactNotifications),
    fork(watchUpdateNotificationStatus),
  ]);
}

export default notificationsSaga;

import { AttendantsActionTypes } from "./types";

// common success
export const attendantsApiResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: AttendantsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const attendantsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AttendantsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getAttendants = () => ({
  type: AttendantsActionTypes.GET_ATTENDANTS,
});

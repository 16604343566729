import { HTMLAttributes, ReactNode } from "react";
import classnames from "classnames";

interface CardMenuItemHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const CardMenuItemHeader = ({
  children,
  className,
  ...rest
}: CardMenuItemHeaderProps) => {
  return (
    <div className={classnames("mt-3", className)} {...rest}>
      {children}
    </div>
  );
};

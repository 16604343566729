import React from "react";

//translations
import { useTranslation } from "react-i18next";

export const NotAvailable = () => {
  const { t } = useTranslation(["notifications"]);

  return (
    <div
      className="w-100 justify-content-center align-items-center row"
      style={{ height: "100vh" }}
    >
      <div className="5 col-md-7">
        <div className="p-4 text-center">
          <div className="avatar-xl mx-auto mb-4">
            <div className="avatar-title bg-soft-warning rounded-circle">
              <i className="bx bxs-message-alt-error display-4 text-warning m-0"></i>
            </div>
          </div>
          <p className="text-muted mb-4">{t(["notifications:error.analytics.not-found"])}</p>
        </div>
      </div>
    </div>
  );
};

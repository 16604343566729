import { useRef, useState } from "react";
import { useRedux } from "../../../hooks/index";
import AppSimpleBar from "../../../components/AppSimpleBar";
import LeftbarTitle from "../../../components/LeftbarTitle";
import KanbanCardTemplate from "../../../components/Kanban/KanbanCardTemplate";
import KanbanDialogFormTemplate from "../../../components/Kanban/KanbanDialogFormTemplate";
import { KanbanComponent, ColumnsDirective, ColumnDirective, CardRenderedEventArgs } from "@syncfusion/ej2-react-kanban";
import { registerLicense, addClass } from '@syncfusion/ej2-base';
import { DataManager, WebApiAdaptor, Query } from '@syncfusion/ej2-data';
import { L10n } from '@syncfusion/ej2-base';
import { KanbanDataModel } from "../../../data/kanban";
import Loader from "../../../components/Loader";
import AvatarAttendant from "../../../components/AvatarAttendant";
import { AttendantProps } from "../../../data/attendants";
import KanbanFilter from "../../../components/Kanban/KanbanFilter";

L10n.load({
  'pt': {
    'kanban': {
      'items': 'card(s)',
      'min': 'Mínimo',
      'max': 'Máximo',
      'cardsSelected': 'Card Selecionado',
      'addTitle': 'Adicionar Novo Card',
      'editTitle': '',
      'deleteTitle': 'Deletar Card',
      'deleteContent': 'Você tem certeza que deseja deletar este card?',
      'save': 'Salvar',
      'delete': 'Deletar',
      'cancel': 'Cancelar',
      'yes': 'Sim',
      'no': 'Não',
      'close': 'Fechar',
      'noCard': 'Nenhum card disponível',
      'unassigned': 'Zero Atribuído / Sem Atendente',
    }
  }
});

registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0VjUH9ecXxXTmla');

interface IndexProps { }

const Index = (props: IndexProps) => {
  const { useAppSelector } = useRedux();
  const { board, tags, attendants, settings } = useAppSelector(state => ({
    board: state.Kanban.boards[0],
    tags: state.Chats.tags,
    attendants: state.Attendants.attendants,
    settings: state.Settings.settings
  }));

  const [isLoading, setIsLoading] = useState(true);

  let dataManager = new DataManager({
    url: `${settings.urlApi}/api/Kanban`,
    crossDomain: true,
    adaptor: new WebApiAdaptor()
  });


  const columnTemplate = (props: { [key: string]: string }) => {
    const fixColumns = ["Em Aberto", "Em Atendimento", "Aguardando Retorno", "Finalizado"];
    let keyField = props.keyField;

    if (!fixColumns.includes(keyField)) {
      keyField = "Generic";
    }

    return (
      <div className="header-template-wrap">
        <div className={"header-icon e-icons " + keyField.replace(" ", "")}></div>
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  };

  const cardRendered = (args: CardRenderedEventArgs): void => {
    let val: string = (args.data as { [key: string]: Object }).lastMessageAt as string;
    addClass([args.element], val);
  };

  const onKanbanDataBound = () => {
    setIsLoading(false);
  };


  function rowTemplate(props) {
    const assignedAttendant: AttendantProps | null =
      props.keyField && attendants && attendants.length
        ? attendants.filter(
          (attendant: AttendantProps) => attendant.fullName === props.keyField
        )[0]
        : null;

    return (<div className="swimlane-template e-swimlane-template-table">
      <div className="e-swimlane-row-text">
        {assignedAttendant ? (
          <AvatarAttendant
            className="avatar-swimlane"
            attendant={assignedAttendant}
            tooltip
          />
        ) : null}
        <span className="attendant-name">{props.textField}</span>
      </div>
    </div>);
  };

  function dialogTemplate(props: KanbanDataModel) {
    return (<KanbanDialogFormTemplate props={props} attendants={attendants} tags={tags} columns={board.columns} kanbanRef={kanbanObj} />);
  }

  function cardTemplate(props: KanbanDataModel) {
    return (<KanbanCardTemplate props={props} attendants={attendants} tags={tags} />);
  }

  let kanbanObj = useRef<KanbanComponent>(null);
  var query = new Query().addParams("attendant", settings.basicDetails.id);

  const handleFilterChange = (selectedUserId: string | null) => {
    console.log('Selected User ID:', selectedUserId);
    let filterQuery: Query = new Query();
    filterQuery.addParams("attendant", selectedUserId);
    filterQuery.sortByDesc("attendant");
    kanbanObj.current.query = filterQuery;
};

  return (
    <div className="position-relative">
      {isLoading && (
        <Loader className="position-fixed " />
      )}
      <LeftbarTitle title="Kanban" />
      <KanbanFilter users={attendants} preselectedUserId={settings.basicDetails.id} onFilterChange={handleFilterChange} />
      <AppSimpleBar className="chat-message-list chat-call-list">
        <div className="schedule-control-section">
          <div className="col-lg-12 control-section">
            <div className="control-wrapper">
              <KanbanComponent
                query={query}
                locale="pt"
                id="kanban"
                ref={kanbanObj}
                cssClass="kanban-overview"
                keyField="status"
                dataSource={dataManager}
                enableTooltip={true}
                swimlaneSettings={{ keyField: "assignee", template: rowTemplate.bind(this) }}
                cardSettings={{
                  headerField: "contactId",
                  template: cardTemplate.bind(this),
                  selectionType: "Multiple",
                  tagsField: "tags",
                }}
                dialogSettings={{ template: dialogTemplate.bind(this), enableEdit: true, enableDelete: false }}
                cardRendered={cardRendered.bind(this)}
                sortSettings={{ sortBy: "Custom", field: "title", direction: "Ascending" }}
                dataBound={onKanbanDataBound}
              >
                <ColumnsDirective>
                  {board.columns.map((column: string, index: number) => {
                    return column ? (
                      <ColumnDirective
                        key={index}
                        headerText={column}
                        keyField={column}
                        allowToggle={true}
                        template={columnTemplate.bind(this)}
                      />
                    ) : null;
                  })}
                </ColumnsDirective>
              </KanbanComponent>
            </div>
          </div>
        </div>
      </AppSimpleBar>
    </div>
  );
};

export default Index;

import { NotificationsProps } from "../../data/notifications";
import { NotificationsActionTypes } from "./types";

// common success
export const notificationsApiResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: NotificationsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const notificationsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: NotificationsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getNotifications = () => ({
  type: NotificationsActionTypes.GET_NOTIFICATIONS,
});

export const getContactNotifications = (id: string | number) => ({
  type: NotificationsActionTypes.GET_CONTACT_NOTIFICATIONS,
  payload: id,
});

export const updateNotificationStatus = (
  notifications: NotificationsProps[]
) => ({
  type: NotificationsActionTypes.UPDATE_NOTIFICATION_STATUS,
  payload: { notifications },
});

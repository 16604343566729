const colors = [
  "bg-primary",
  "bg-danger",
  "bg-info",
  "bg-warning",
  "bg-secondary",
  "bg-pink",
  "bg-purple",
] as const;

export type randomColorClassTypes = typeof colors[number];
const randomColor = new Map();

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (str: string) => {
  const hash = getHashOfString(str);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return `hsl(${String(h)}, ${String(s)}%, ${String(l)}%)`;
};

const randomColorClass = (id: string) => {
  if (!randomColor.has(id)) {
    randomColor.set(id, generateHSL(id));
  }
  return randomColor.get(id);
};

// const generateRandomColorFromList = () => {
//   return colors[Math.floor(Math.random() * colors.length)];
// };

export { randomColorClass };

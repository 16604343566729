import { HTMLAttributes, ReactNode } from "react";
import classnames from "classnames";
interface CardMenuItemSubTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
}

export const CardMenuItemSubTitle = ({
  className,
  children,
  ...rest
}: CardMenuItemSubTitleProps) => {
  return (
    <span
      className={classnames(
        "font-size-10 text-uppercase mb-0 text-truncate text-secondary",
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

import React, { ChangeEvent, RefObject, useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Input, InputGroup, UncontrolledTooltip } from "reactstrap";

import { PopoverBox } from "../../../../components/PopoverBox";
import { useRedux } from "../../../../hooks/index";
import { TagProps } from "../../../../data";
import AppSimpleBar from "../../../../components/AppSimpleBar";
import { createTag, deleteTag } from "../../../../redux/actions";

type FormTagProps = {
  color: string;
  name: string;
};

interface TagsManageProps {
  tags: TagProps[];
  isOpen: boolean;
  onToggle: () => void;
  elementRef: RefObject<HTMLElement>;
}

const TagsManage = ({
  tags,
  isOpen,
  onToggle,
  elementRef,
}: TagsManageProps) => {
  const { t } = useTranslation(["common", "form", "profileDetails"]);

  // global store
  const { dispatch } = useRedux();

  const [formTag, setFormTag] = useState<FormTagProps>(null);
  const isValidData = formTag && formTag.name && formTag.color ? true : false;

  function handleDeleteTag(tag: TagProps) {
    if (tag.UsedCount > 0) {
      return;
    }
    dispatch(deleteTag(tag.id));
  }

  function handleSubmit() {
    if (!formTag || !formTag.name || !formTag.color) {
      return;
    }
    dispatch(createTag({ name: formTag.name, color: formTag.color }));
    onToggle();
  }

  function handleFormTag(e: ChangeEvent<HTMLInputElement>) {
    const key = e.target.name;
    const val = e.target.value;
    setFormTag(prev => ({ ...prev, [key]: val }));
  }

  useEffect(() => {
    setFormTag(null);
  }, [isOpen]);

  return (
    <PopoverBox.Root
      isOpen={isOpen}
      target={elementRef}
      placement="bottom-end"
      toggle={onToggle}
      trigger="legacy"
      className="popover-box"
    >
      <PopoverBox.Header>
        {t(["profileDetails:modals.manage-tag"])}
      </PopoverBox.Header>

      <PopoverBox.Content>
        <InputGroup className="col-12">
          <Input
            name="name"
            placeholder="Nome do rótulo..."
            className="col-auto"
            onChange={handleFormTag}
          />
          <Input
            name="color"
            type="color"
            style={{ height: 39, maxWidth: 39, padding: ".75rem" }}
            onChange={handleFormTag}
            value={formTag && formTag.color ? formTag.color : "#4eac6d"}
          />
        </InputGroup>
      </PopoverBox.Content>
      <PopoverBox.Content>
        <AppSimpleBar style={{ height: 100 }}>
          <ul className="list-unstyled d-flex flex-column">
            {tags
              ? tags.map((tag: TagProps) => (
                  <li
                    key={tag.id}
                    className="btn-soft-light d-flex align-items-center gap-2 py-2 ps-2 pe-4"
                  >
                    <div
                      className="rounded"
                      style={{
                        backgroundColor: tag.Color,
                        width: 13,
                        height: 13,
                      }}
                    />
                    <span className="text-truncate text-capitalize col">
                      {tag.Name}
                    </span>
                    <div id={`delete-btn-${tag.id}`}>
                      <i
                        className={classnames(
                          "bx bx-trash btn btn-circular btn-soft-danger",
                          { disabled: tag.UsedCount > 0 }
                        )}
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </div>
                    {tag.UsedCount > 0 ? (
                      <UncontrolledTooltip
                        target={`delete-btn-${tag.id}`}
                        placement="bottom"
                      >
                        {t(["form:placeholder.cant-remove-tag"])}
                      </UncontrolledTooltip>
                    ) : null}
                  </li>
                ))
              : null}
          </ul>
        </AppSimpleBar>
      </PopoverBox.Content>
      <PopoverBox.Footer>
        <PopoverBox.FooterAction className="d-flex gap-1 col-12">
          <button type="button" className="btn btn-link" onClick={onToggle}>
            {t(["common:button.cancel"])}
          </button>
          <button
            type="button"
            className="btn btn-soft-primary"
            onClick={handleSubmit}
            disabled={!isValidData}
          >
            {t(["common:button.create"])}
          </button>
        </PopoverBox.FooterAction>
      </PopoverBox.Footer>
    </PopoverBox.Root>
  );
};

export default TagsManage;

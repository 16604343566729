import { FirestoreError } from "firebase/firestore";

export interface FirestoreErrorHandlerProps {
  code: string;
  message: string | undefined;
}

export function FirestoreErrorHandler(firebaseError: FirestoreError) {
  const error: FirestoreErrorHandlerProps = {
    code: firebaseError.code,
    message: undefined,
  };
  switch (firebaseError.code) {
    case "not-found":
      error.message = "notifications:error.apifirestoreresponse.document-not-found";
      break;

    default:
      error.message = firebaseError.name;
  }
  return { ...error };
}

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import { Row, Col } from "reactstrap";
// hooks
import { useRedux } from "../../../../hooks/index";
// actions
import {
  changeSelectedCampaign,
  getSelectedCampaignData,
} from "../../../../redux/actions";
import { AnalyticsMoreTypesProps, More } from "./HeaderSection/MoreOptions";
import { Link } from "react-router-dom";
import { CampaignProps } from "../../../../data/analytics";
import { subDays, subMonths, subWeeks } from "date-fns";
import { DateOptions } from "./HeaderSection/DateOptions";

export interface PeriodProps {
  startDate: Date;
  endDate: Date;
}
interface HeaderProps {
  campaign: CampaignProps;
}

const Header = ({ campaign }: HeaderProps) => {
  const { t } = useTranslation(["common"]);

  // global store
  const { dispatch } = useRedux();

  /* mobile close */
  const onClosePage = () => {
    dispatch(changeSelectedCampaign(null));
  };

  const numDays = 1;
  const numWeeks = 1;
  const numMonths = 1;
  const numMonthsDefault = 6;
  const endDateDefault = new Date();
  const startDateDefault = subMonths(endDateDefault, numMonthsDefault);

  const [period, setPeriod] = useState<PeriodProps>({} as PeriodProps);

  useEffect(() => {
    setPeriod({
      startDate: startDateDefault,
      endDate: endDateDefault,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    if (!campaign || !period.startDate || !period.endDate) return;
    dispatch(
      getSelectedCampaignData(campaign.id, campaign.name, {
        startDate: period.startDate.toISOString(),
        endDate: period.endDate.toISOString(),
      })
    );
  }, [dispatch, campaign, period]);

  const [selectedFilter, setSelectedFilter] =
    useState<AnalyticsMoreTypesProps>();

  function handleSelectedFilter(filter: AnalyticsMoreTypesProps) {
    if (filter === selectedFilter) {
      setSelectedFilter(undefined);
      setPeriod({
        startDate: startDateDefault,
        endDate: endDateDefault,
      });
      return;
    }
    if (filter === AnalyticsMoreTypesProps.day)
      setPeriod(({ endDate }: PeriodProps) => {
        return { startDate: subDays(endDateDefault, numDays), endDate };
      });
    if (filter === AnalyticsMoreTypesProps.week)
      setPeriod(({ endDate }: PeriodProps) => {
        return { startDate: subWeeks(endDateDefault, numWeeks), endDate };
      });
    if (filter === AnalyticsMoreTypesProps.month)
      setPeriod(({ endDate }: PeriodProps) => {
        return { startDate: subMonths(endDateDefault, numMonths), endDate };
      });
    setSelectedFilter(filter);
  }

  function handleOnValidDate({ startDate, endDate }: PeriodProps) {
    setPeriod({
      startDate,
      endDate,
    });
  }

  const status =
    campaign.status && campaign.status === "Active"
      ? t(["common:options.active"])
      : t(["common:options.inactive"]);

  return (
    <div className="p-3 p-lg-4 user-chat-topbar">
      <Row className="align-items-center">
        <Col sm={6} className="col-8">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 d-block d-lg-none me-2">
              <Link
                to="#"
                onClick={onClosePage}
                className="user-chat-remove text-muted font-size-24 p-2"
              >
                <i className="bx bx-chevron-left align-middle"></i>
              </Link>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <div className="d-flex align-items-center gap-3">
                <i
                  className="bx bxs-bar-chart-alt-2"
                  style={{ fontSize: "38.8px" }}
                ></i>
                <div className="flex-grow-1 overflow-hidden">
                  <h6 className="text-truncate text-capitalize mb-0 font-size-18">
                    {campaign.name}
                  </h6>
                  <p className="text-truncate text-muted mb-0">
                    <small>{status ? status : t(["common:options.no-status"])}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} className="col-4">
          <ul className="list-inline user-chat-nav mb-0 d-flex justify-content-end align-items-end">
            <li className="list-inline-item d-none d-sm-inline-block me-2 ms-0">
              {period.startDate && period.endDate && (
                <DateOptions
                  period={period}
                  onValidDate={handleOnValidDate}
                  isDisabled={selectedFilter !== undefined}
                />
              )}
            </li>
            <li className="list-inline-item">
              <More
                selectedFilter={selectedFilter}
                onToggleFilter={handleSelectedFilter}
              />
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Header;

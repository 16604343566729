import { Input } from "reactstrap";

interface FilterProps {
  type: "select" | "number" | "date" | "text";
  value: string[] | number[];
  handleOnChange: (value: string | number | Date) => void;
}

export const Filter = ({ type, value, handleOnChange }: FilterProps) => {
  if (type === "select") {
    return (
      <select
        className="form-control-sm form-control py-0 cursor-pointer text-center"
        onChange={e => handleOnChange(e.target.value)}
        defaultValue=""
      >
        <option value="">---</option>
        {value.map(val => (
          <option key={`${type}-${val}`} value={val}>
            {val}
          </option>
        ))}
      </select>
    );
  }

  if (type === "date") {
    return (
      <Input
        name="startDate"
        type="date"
        bsSize="sm"
        style={{ fontSize: ".7rem" }}
        onChange={e =>
          handleOnChange(e.target.valueAsDate ? e.target.valueAsDate : "")
        }
      />
    );
  }
  return <></>;
};

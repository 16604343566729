import React, { ChangeEvent, useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import classnames from "classnames";
import { Input, Tooltip } from "reactstrap";
import {
  endOfDay,
  format,
  isBefore,
  isWithinInterval,
  parse,
  startOfDay,
} from "date-fns";

interface PeriodProps {
  startDate?: string;
  endDate?: string;
}

interface DateOptionsProps {
  period: {
    startDate: Date;
    endDate: Date;
  };
  onValidDate: (period: { startDate: Date; endDate: Date }) => void;
  numDateInput?: number;
  isDisabled?: boolean;
}

export const DateOptions = ({
  period,
  onValidDate,
  numDateInput = 2,
  isDisabled = false,
}: DateOptionsProps) => {
  const { t } = useTranslation(["analytics"]);

  const [selectedPeriod, setSelectedPeriod] = useState<PeriodProps>({
    startDate: undefined,
    endDate: undefined,
  });
  const [isValidDates, setIsValidDates] = useState<boolean | null>(null);

  const periodStartDateFormatted = format(period.startDate, "yyyy-MM-dd");
  const periodEndDateFormatted = format(period.endDate, "yyyy-MM-dd");

  function handleSetDate(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setSelectedPeriod(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  function setDefaultDateValue() {
    setSelectedPeriod({
      startDate: undefined,
      endDate: undefined,
    });
  }

  function checkInterval(date: Date, start: Date, end: Date) {
    return isWithinInterval(date, {
      start: startOfDay(start),
      end: endOfDay(end),
    });
  }

  useEffect(() => {
    setDefaultDateValue();
  }, [period]);

  useEffect(() => {
    if (!selectedPeriod.startDate || !selectedPeriod.endDate) return;

    const startDate = parse(selectedPeriod.startDate, "yyyy-MM-dd", new Date());
    const endDate = parse(selectedPeriod.endDate, "yyyy-MM-dd", new Date());

    const isStartDateInRange = checkInterval(
      startDate,
      period.startDate,
      endDate
    );
    const isEndDateInRange = checkInterval(endDate, startDate, period.endDate);

    const isStartDateBefore = isBefore(startDate, endDate);

    if (!isStartDateInRange || !isEndDateInRange || !isStartDateBefore) {
      setDefaultDateValue();
      setIsValidDates(false);
      return;
    }

    setIsValidDates(true);
    onValidDate({ startDate, endDate });
  }, [onValidDate, period, selectedPeriod]);

  const [isTooltipDateOpen, setIsTooltipDateOpen] = useState(false);

  function handleTooltipDateToggle() {
    setIsTooltipDateOpen(prev => !prev);
  }

  return (
    <form>
      <div
        id="tooltipDate"
        className="d-flex align-items-center gap-1 position-relative"
        style={{ width: "240px", height: "40px" }}
      >
        {isDisabled && (
          <>
            <div
              className="position-absolute col-12 "
              style={{ height: "40px" }}
            />
            <Tooltip
              placement="bottom"
              isOpen={isTooltipDateOpen}
              target="tooltipDate"
              toggle={handleTooltipDateToggle}
            >
              {t(["analytics:date.disabled"])}
            </Tooltip>
          </>
        )}
        <Input
          name="startDate"
          type="date"
          bsSize="sm"
          style={{ fontSize: ".7rem" }}
          min={periodStartDateFormatted}
          max={periodEndDateFormatted}
          onChange={handleSetDate}
          value={
            selectedPeriod.startDate
              ? selectedPeriod.startDate
              : periodStartDateFormatted
          }
          className={classnames({
            "border-primary": isValidDates,
            "border-danger": isValidDates === false,
          })}
          disabled={isDisabled}
        />
        {numDateInput > 1 && (
          <>
            <small>{t(["analytics:date.to"])}</small>
            <Input
              name="endDate"
              type="date"
              bsSize="sm"
              style={{ fontSize: ".7rem" }}
              min={periodStartDateFormatted}
              max={periodEndDateFormatted}
              onChange={handleSetDate}
              value={
                selectedPeriod.endDate
                  ? selectedPeriod.endDate
                  : periodEndDateFormatted
              }
              className={classnames({
                "border-primary": isValidDates,
                "border-danger": isValidDates === false,
              })}
              disabled={isDisabled}
            />
          </>
        )}
      </div>
    </form>
  );
};

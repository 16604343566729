import { FileContent } from "./FileContent";
import { FileHeader } from "./FileHeader";
import { FileHeaderAction } from "./FileHeaderAction";
import { FileHeaderTitle } from "./FileHeaderTitle";
import { FileItem } from "./FileItem";
import { FileItemActionDownload } from "./FileItemActionDownload";
import { FileItemActions } from "./FileItemActions";
import { FileItemHeader } from "./FileItemHeader";
import { FileItemHeaderName } from "./FileItemHeaderName";
import { FileItemHeaderSize } from "./FileItemHeaderSize";
import { FileItemIcon } from "./FileItemIcon";
import { FileRoot } from "./FileRoot";

export const File = {
  Root: FileRoot,
  Header: FileHeader,
  HeaderAction: FileHeaderAction,
  HeaderTitle: FileHeaderTitle,
  Content: FileContent,
  Item: FileItem,
  ItemActions: FileItemActions,
  ItemActionDownload: FileItemActionDownload,
  ItemIcon: FileItemIcon,
  ItemHeader: FileItemHeader,
  ItemHeaderName: FileItemHeaderName,
  ItemHeaderSize: FileItemHeaderSize,
};

import React, { ChangeEvent, useEffect, useRef, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";

// interface
import { BasicDetailsTypes } from "../../../data/settings";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  getProfileDetails,
  getSettings,
  updateStatus,
  uploadProfilePhoto,
} from "../../../redux/actions";

// CONSTANTS
import { STATUS_TYPES } from "../../../constants";
import Loader from "../../../components/Loader";
interface UserProfileProps {
  basicDetails: BasicDetailsTypes;
  status: STATUS_TYPES;
}
const UserProfile = ({ basicDetails, status }: UserProfileProps) => {
  const { t } = useTranslation(["common", "settings"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { isStatusUpdated, isProfilePhotoLoading, isProfilePhotoUploaded } =
    useAppSelector(state => ({
      isStatusUpdated: state.Settings.isStatusUpdated,
      isProfilePhotoLoading: state.Profile.isProfilePhotoLoading,
      isProfilePhotoUploaded: state.Profile.isProfilePhotoUploaded,
    }));

  const fullName = basicDetails
    ? `${basicDetails.firstName} ${basicDetails.lastName}`
    : "-";

  const fileInputRef = useRef<any>(null);
  const profileImage = basicDetails && basicDetails.profile;

  useEffect(() => {
    if (isProfilePhotoUploaded) {
      dispatch(getSettings());
      dispatch(getProfileDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfilePhotoUploaded]);

  function handleChangeProfilePhoto(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files && files.length > 0) {
      dispatch(uploadProfilePhoto(files[0]));
    }
  }

  /*
    drop down state
    */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  /*
    status
    */
  const onChangeStatus = (status: STATUS_TYPES) => {
    dispatch(updateStatus(status));
  };

  useEffect(() => {
    if (isStatusUpdated) {
      dispatch(getSettings());
      dispatch(getProfileDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusUpdated]);

  return (
    <div className="text-center p-3 p-lg-4 border-bottom pt-2 pt-lg-2 mt-n5 position-relative">
      <div className="mb-3 profile-user">
        {isProfilePhotoLoading && <Loader />}
        <img
          src={profileImage}
          className="rounded-circle avatar-lg img-thumbnail user-profile-image"
          alt="user-profile"
        />
        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
          <input
            ref={fileInputRef}
            onChange={handleChangeProfilePhoto}
            id="profile-img-file-input"
            type="file"
            className="profile-img-file-input"
            accept="image/png, image/jpeg"
          />
          <Label
            htmlFor="profile-img-file-input"
            className="profile-photo-edit avatar-xs"
          >
            <span className="avatar-title rounded-circle bg-light text-body">
              <i className="bx bxs-camera"></i>
            </span>
          </Label>
        </div>
      </div>

      <h5 className="font-size-16 mb-1 text-truncate">{fullName}</h5>

      <Dropdown
        className="d-inline-block"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle tag="a" className="text-muted d-block" role="button">
          <i
            className={classnames(
              "bx",
              "bxs-circle",
              "font-size-10",
              "align-middle",
              { "text-success": status === STATUS_TYPES.ACTIVE },
              { "text-warning": status === STATUS_TYPES.AWAY },
              { "text-danger": status === STATUS_TYPES.DO_NOT_DISTURB },
              { "text-secondary": status === STATUS_TYPES.INACTIVE }
            )}
          ></i>{" "}
          {t(`common:options.${status}` as const)}
          <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={() => onChangeStatus(STATUS_TYPES.ACTIVE)}>
            <i className="bx bxs-circle text-success font-size-10 me-1 align-middle"></i>{" "}
            {t(["common:options.active"])}
          </DropdownItem>
          <DropdownItem onClick={() => onChangeStatus(STATUS_TYPES.AWAY)}>
            <i className="bx bxs-circle text-warning font-size-10 me-1 align-middle"></i>{" "}
            {t(["common:options.away"])}
          </DropdownItem>
          <DropdownItem
            onClick={() => onChangeStatus(STATUS_TYPES.DO_NOT_DISTURB)}
          >
            <i className="bx bxs-circle text-danger font-size-10 me-1 align-middle"></i>{" "}
            {t(["common:options.not-disturb"])}
          </DropdownItem>
          <DropdownItem onClick={() => onChangeStatus(STATUS_TYPES.INACTIVE)}>
            <i className="bx bxs-circle text-secondary font-size-10 me-1 align-middle"></i>{" "}
            {t(["common:options.inactive"])}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default UserProfile;

import axios, { AxiosRequestConfig } from "axios";
import { PlatformTypes, TypeOfAttachment } from "../data";

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

function setAxiosBaseUrl(url: string) {
  // default
  axios.defaults.baseURL = url;
}

async function getAxiosBaseUrl() {
  const user = await getLoggedinUser();
  if (user) {
    setAxiosBaseUrl(user.tenantData.UrlApi + "/api");
  }
}

if (!axios.defaults.baseURL) {
  getAxiosBaseUrl();
}

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "notifications:error.apicore.server";
        break;
      case 401:
        message = "notifications:error.apicore.credentials";
        break;
      case 404:
        message = "notifications:error.apicore.data";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}) => {
    return axios.post(url, data, {
      headers: {
        "Access-Control-Allow-Origin": "*", // Replace * with your desired domain
        "Access-Control-Allow-Methods": "GET, POST",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    return axios.delete(url, { ...config });
  };

  /*
   file upload update method
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, formData, config);
  };

  /*
   file upload post method
   */
  createWithFile = async (
    url: string,
    data: {
      attachmentType: TypeOfAttachment;
      files: Array<any>;
      platform: PlatformTypes;
    }
  ) => {
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
      params: { attachmentType: data.attachmentType, platform: data.platform },
    } as AxiosRequestConfig;

    const formData = new FormData();
    data.files.forEach(file => {
      formData.append("file", file);
    });

    return await axios.post(url, formData, config);
  };
}

export { APIClient, setAuthorization, getLoggedinUser, setAxiosBaseUrl };

import React from "react";
import classnames from "classnames";

// hooks
import { useRedux } from "../../hooks/index";

// hooks
import { useConversationUserType } from "../../hooks/index";

// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import AnalyticsCharts from "./Analytics/Campaign/index";
import Welcome from "./ConversationUser/Welcome";
import WelcomeKanban from "./Kanban/Welcome";
import Kanban from "./Kanban/index";
import { TABS } from "../../constants";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { useAppSelector } = useRedux();

  const { selectedChat, selectedCampaign, activeTab, hasBoards } =
    useAppSelector(state => ({
      selectedChat: state.Chats.selectedChat,
      selectedCampaign: state.Analytics.selectedCampaign,
      activeTab: state.Layout.activeTab,
      hasBoards: state.Kanban.boards.length,
    }));

  const { isChannel } = useConversationUserType();
  return (
    <>
      <Leftbar />

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat || selectedCampaign,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay" />
        {(selectedChat || selectedCampaign) && activeTab !== TABS.KANBAN ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              {selectedChat && <ConversationUser isChannel={isChannel} />}
              {activeTab === TABS.ANALYTICS && selectedCampaign && (
                <AnalyticsCharts campaign={selectedCampaign} />
              )}
            </div>
            {selectedChat && <UserProfileDetails isChannel={isChannel} />}
          </div>
        ) : (
          activeTab !== TABS.KANBAN && <Welcome />
        )}

        {activeTab === TABS.KANBAN && hasBoards ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <Kanban />
            </div>
          </div>
        ) : (
          activeTab === TABS.KANBAN && <WelcomeKanban />
        )}
      </div>
    </>
  );
};

export default Index;

import React, { useState } from "react";
import { Tooltip } from "reactstrap";
// actions
import { NotificationsProps } from "../../../data/notifications";

interface NotificationsComponentProps {
  id: string | number;
  notifications: NotificationsProps[] | undefined;
}

const Notifications = ({ id, notifications }: NotificationsComponentProps) => {
  /*
  get data
  */
  const [isTooltipNotificationOpen, setIsTooltipNotificationOpen] =
    useState(false);

  function handleTooltipNotificationToggle() {
    setIsTooltipNotificationOpen(prev => !prev);
  }

  if (!notifications || notifications.length <= 0) return <></>;

  return (
    <div className="notification" id={`tooltipNotification-${id}`}>
      <i className="bx bxs-error" />
      {notifications.length > 1 && (
        <span className="counter badge badge-soft-dark">
          {notifications.length}
        </span>
      )}
      <hr />
      <Tooltip
        placement="right-start"
        isOpen={isTooltipNotificationOpen}
        target={`tooltipNotification-${id}`}
        toggle={handleTooltipNotificationToggle}
      >
        <ul className="list-group ">
          {notifications.map((notification, index) => (
            <li className="px-4 py-2 m-0" key={notification.id}>
              {index !== 0 && <hr className="p-0 mt-0" />}
              <span>{notification.Message}</span>
              <br />
              <span style={{ fontSize: ".6rem" }}>
                {notification.Timestamp}
              </span>
            </li>
          ))}
        </ul>
      </Tooltip>
    </div>
  );
};

export default Notifications;

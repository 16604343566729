import React from "react";
// hooks
import { useRedux } from "../../../../hooks/index";

import { CampaignProps } from "../../../../data/analytics";
import Header from "./Header";
import Loader from "../../../../components/Loader";
import { StatusSection } from "./CampaignSection/Status";
import AppSimpleBar from "../../../../components/AppSimpleBar";
import { MultipleChartsSection } from "./CampaignSection/MultipleCharts";
import { MonthlySection } from "./CampaignSection/Monthly";
import { NotAvailable } from "./NotAvailable";
import { LeadsSection } from "./CampaignSection/Leads";

interface CampaignChartProps {
  campaign: CampaignProps;
}

export const CampaignChart = ({ campaign }: CampaignChartProps) => {
  // global store
  const { useAppSelector } = useRedux();

  const { selectedCampaignData, getSelectedCampaignDataLoading } =
    useAppSelector((state: any) => ({
      selectedCampaignData: state.Analytics.selectedCampaignData,
      getSelectedCampaignDataLoading:
        state.Analytics.getSelectedCampaignDataLoading,
    }));

  if (!campaign) return <NotAvailable />;

  return (
    <>
      <Header campaign={campaign} />
      {getSelectedCampaignDataLoading && <Loader />}
      {campaign.id !== selectedCampaignData?.id ? (
        <NotAvailable />
      ) : (
        <AppSimpleBar className="campaign">
          <StatusSection data={selectedCampaignData?.status} />
          <MultipleChartsSection
            data={{
              conclusion: selectedCampaignData?.conclusion,
              airline: selectedCampaignData?.airline,
            }}
          />
          <MonthlySection data={selectedCampaignData?.monthly} />
          <LeadsSection id={selectedCampaignData.id} />
        </AppSimpleBar>
      )}
    </>
  );
};

import React, { ReactNode } from "react";
import classnames from "classnames";
// swiper
import { SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

interface CardMenuItemProps extends SwiperSlide {
  children: ReactNode;
  hoverHue?: boolean;
}

export const CardMenuItem = ({
  children,
  hoverHue = false,
  ...rest
}: CardMenuItemProps) => {
  return (
    <SwiperSlide {...rest}>
      <div
        className={classnames(
          "text-center px-2 position-relative d-flex align-items-center justify-content-center",
          {
            "hover-hue": hoverHue,
          }
        )}
        style={{ minHeight: 90 }}
      >
        <div>{children}</div>
      </div>
    </SwiperSlide>
  );
};

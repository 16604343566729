import { HtmlHTMLAttributes, ReactNode, useEffect, useState } from "react";
import { formatDuration, intervalToDuration } from "date-fns";
import classNames from "classnames";
import Loader from "./Loader";

interface CountdownProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: ReactNode;
  childrenPosition?: "left" | "right";
  expirationDate: Date;
  onExpire?: () => void;
}

export function Countdown({
  className,
  expirationDate = null,
  children,
  childrenPosition = "left",
  onExpire,
}: CountdownProps) {
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  useEffect(() => {
    const countDownInterval = setInterval(function () {
      const nowDate = new Date();

      if (nowDate >= expirationDate) {
        clearInterval(countDownInterval);
        setTimeLeft(null);
        if (onExpire) onExpire();
      } else {
        const timeLeftFormatted = formatDuration(
          intervalToDuration({
            start: nowDate,
            end: expirationDate,
          }),
          {
            format: ["hours", "minutes", "seconds"],
            zero: true,
            delimiter: ":",
            locale: {
              formatDistance: (_token, count) => String(count).padStart(2, "0"),
            },
          }
        );
        setTimeLeft(timeLeftFormatted);
      }
    }, 1000);

    return () => {
      clearInterval(countDownInterval);
      setTimeLeft(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirationDate]);

  return timeLeft ? (
    <div
      className={classNames(
        className,
        "d-flex align-items-center justify-content-center gap-1"
      )}
    >
      {childrenPosition === "left" && children}
      <small className="m-0" style={{ bottom: "4px" }}>
        {timeLeft}
      </small>
      {childrenPosition === "right" && children}
    </div>
  ) : (
    <Loader className="position-relative h-auto" size="sm" />
  );
}

import React, { useState, useEffect } from 'react';
import UserAvatar from './UserAvatar';

interface User {
    id: string;
    avatarUrl: string;
    fullName: string;
}

interface KanbanFilterProps {
    users: User[];
    onFilterChange: (selectedUserId: string | null) => void;
    preselectedUserId?: string;
}

const KanbanFilter: React.FC<KanbanFilterProps> = ({ users, onFilterChange, preselectedUserId }) => {
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

    useEffect(() => {
        if (preselectedUserId && users.some(user => user.id === preselectedUserId)) {
            setSelectedUserId(preselectedUserId);
        }
    }, [preselectedUserId, users]);

    const handleAvatarClick = (userId: string) => {
        const newSelectedUserId = userId === selectedUserId ? null : userId;
        setSelectedUserId(newSelectedUserId);
        onFilterChange(newSelectedUserId);
    };

    return (
        <div className="kanban-filter-container">
            <label className="filter-label">Filtrar atendente:</label>
            <div className="kanban-filter">
                {users.map(user => (
                    <UserAvatar
                        key={user.id}
                        id={user.id}
                        avatarUrl={user.avatarUrl}
                        fullName={user.fullName}
                        isSelected={selectedUserId === user.id}
                        onClick={handleAvatarClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default KanbanFilter;

import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, UncontrolledTooltip } from "reactstrap";
import { FieldPath, WhereFilterOp } from "firebase/firestore/lite";

import { CHATS_TABS } from "../../../../constants";
import { useRedux } from "../../../../hooks";
import { setChatListFilters } from "../../../../redux/actions";
import { ContactTypes } from "../../../../data";
import { PopoverBox } from "../../../../components/PopoverBox";
import AttendantFilters from "./Attendant";
import TagFilters from "./Tags";
import { TagsList } from "../../../../components/Tags";

export type FilterProps = {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
};

export type FilterAttendantProps = FilterProps & {
  value: string;
};

export type FilterTagsProps = FilterProps & {
  value: string[];
};

type FiltersPathsProps = {
  AssignedAttendant: FilterAttendantProps[];
  Tags: FilterTagsProps;
};

export type VisualFilterProps = {
  id: string;
  Name: string;
  Color: string;
  fieldPath: "AssignedAttendant" | "Tags";
};

interface FiltersProps {
  activeTab: CHATS_TABS;
  favourites: ContactTypes[];
  directMessages: ContactTypes[];
  archiveContacts: ContactTypes[];
  setFilteredFavourites: React.Dispatch<React.SetStateAction<any>>;
  setFilteredDirectMessages: React.Dispatch<React.SetStateAction<any>>;
  setFilteredArchived: React.Dispatch<React.SetStateAction<any>>;
}

const Filters = ({
  activeTab,
  favourites,
  directMessages,
  archiveContacts,
  setFilteredFavourites,
  setFilteredDirectMessages,
  setFilteredArchived,
}: FiltersProps) => {
  const { t } = useTranslation(["chats", "common", "form"]);
  const { useAppSelector, dispatch } = useRedux();
  const { chatListFilters } = useAppSelector(state => ({
    chatListFilters: state.Chats.chatListFilters,
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [filters, setFilters] = useState<FiltersPathsProps>({
    AssignedAttendant: [],
    Tags: null,
  } as FiltersPathsProps);
  const [visualFilters, setVisualFilters] = useState<VisualFilterProps[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<VisualFilterProps[]>([]);

  function handleTogglePopover() {
    setIsOpen(prev => !prev);
  }

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.toLowerCase();
    setSearch(value);
  }

  function handleSubmit() {
    const AssignedAttendantFilters = filters.AssignedAttendant.length
      ? filters.AssignedAttendant
      : [];
    const TagsFilters = filters.Tags ? [filters.Tags] : [];
    const selectedFilters = [...AssignedAttendantFilters, ...TagsFilters];

    dispatch(
      setChatListFilters(selectedFilters.length ? selectedFilters : null)
    );
    setAppliedFilters(visualFilters);
  }

  function handleReset() {
    setFilters({ AssignedAttendant: [], Tags: null } as FiltersPathsProps);
    setVisualFilters([]);
  }

  function handleAppliedReset() {
    setFilters({ AssignedAttendant: [], Tags: null } as FiltersPathsProps);
    setVisualFilters([]);
    setAppliedFilters([]);
    dispatch(setChatListFilters(null));
  }

  function handleAddFilter(
    filter: FilterAttendantProps | FilterTagsProps,
    visualFilter: VisualFilterProps
  ) {
    if (filter.fieldPath === "AssignedAttendant") {
      setFilters(prev => ({
        ...prev,
        AssignedAttendant: [
          ...filters.AssignedAttendant,
          filter as FilterAttendantProps,
        ],
      }));
    }

    if (filter.fieldPath === "Tags") {
      setFilters(prev => ({ ...prev, Tags: filter as FilterTagsProps }));
    }

    setVisualFilters(prev => [...prev, visualFilter]);
  }

  function handleRemoveFilter(filterRemove: VisualFilterProps) {
    if (filterRemove.fieldPath === "AssignedAttendant") {
      const AssignedAttendant = filters[filterRemove.fieldPath].filter(
        filter => filter.value !== filterRemove.id
      );
      setFilters(prev => ({ ...prev, AssignedAttendant }));
    }

    if (filterRemove.fieldPath === "Tags") {
      const value = filters[filterRemove.fieldPath].value.filter(
        id => id !== filterRemove.id
      );
      const Tags = value.length
        ? { ...filters[filterRemove.fieldPath], value }
        : null;
      setFilters(prev => ({ ...prev, Tags }));
    }

    const updatedVisualFilters = visualFilters.filter(
      filter => filter.id !== filterRemove.id
    );

    setVisualFilters(updatedVisualFilters);
  }

  useEffect(() => {
    if (!search) {
      setFilteredFavourites(null);
      setFilteredDirectMessages(null);
      setFilteredArchived(null);
      return;
    }

    if (activeTab === CHATS_TABS.DEFAULT) {
      const filteredFavorites = favourites.filter((c: ContactTypes) => {
        const fullName = `${c["firstName"]} ${c["lastName"]}`;
        return fullName.toLowerCase().includes(search);
      });
      const filteredDirectMessages = directMessages.filter(
        (c: ContactTypes) => {
          const fullName = `${c["firstName"]} ${c["lastName"]}`;
          return fullName.toLowerCase().includes(search);
        }
      );
      setFilteredFavourites(filteredFavorites);
      setFilteredDirectMessages(filteredDirectMessages);
    } else if (activeTab === CHATS_TABS.ARCHIVE) {
      const filteredArchived = archiveContacts.filter((c: ContactTypes) => {
        const fullName = `${c["firstName"]} ${c["lastName"]}`;
        return fullName.toLowerCase().includes(search);
      });
      setFilteredArchived(filteredArchived);
    }
  }, [
    search,
    activeTab,
    archiveContacts,
    directMessages,
    favourites,
    setFilteredArchived,
    setFilteredDirectMessages,
    setFilteredFavourites,
  ]);

  return (
    <>
      <Form>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control bg-light border-0 pe-0"
            placeholder={t(["form:placeholder.search"])}
            aria-label={t(["form:placeholder.search"])}
            aria-describedby="searchbtn-addon"
            value={search}
            onChange={handleSearch}
          />
          <Button className="btn btn-light" type="button" id="searchbtn-addon">
            <i className="bx bx-search align-middle"></i>
          </Button>
        </div>
      </Form>

      {/* FILTERS TO IMPLEMENT */}
      <div className="d-flex align-items-start pb-2">
        <div className="flex-grow-1">
          <div className="input-group mb-3"></div>
        </div>
        <div className="flex-shrink-0">
          <div className="d-flex gap-1">
            <button
              id="reset-filter-btn"
              type="button"
              className="btn btn-soft-primary btn-sm"
              onClick={handleAppliedReset}
              disabled={!chatListFilters}
              hidden={!chatListFilters}
            >
              <i className="bx bx-reset" />
            </button>
            <button
              id="add-filter-btn"
              type="button"
              className="btn btn-soft-primary btn-sm"
              onClick={handleTogglePopover}
            >
              <i className="bx bx-slider-alt" />
            </button>
          </div>
          <UncontrolledTooltip target="reset-filter-btn" placement="bottom">
            {t(["common:button.reset-filters"])}
          </UncontrolledTooltip>
          <UncontrolledTooltip target="add-filter-btn" placement="bottom">
            {t(["common:button.add-filters"])}
          </UncontrolledTooltip>
        </div>
      </div>

      <TagsList.Root>
        {chatListFilters &&
          appliedFilters &&
          appliedFilters.map((tag: VisualFilterProps) => {
            return tag ? (
              <TagsList.Container key={tag.id}>
                <TagsList.Item color={tag.Color}>{tag.Name}</TagsList.Item>
                {/* <TagsList.Button
                  color={tag.Color}
                  onClick={() => handleRemoveFilter(tag)}
                >
                  <i className="bx bx-x" />
                </TagsList.Button> */}
              </TagsList.Container>
            ) : null;
          })}
      </TagsList.Root>

      {/* FILTERS TO IMPLEMENT */}
      <PopoverBox.Root
        isOpen={isOpen}
        toggle={handleTogglePopover}
        target="add-filter-btn"
        placement="bottom-start"
        trigger="legacy"
        className="popover-box"
      >
        <PopoverBox.Header>
          {t(["chats:modals.manage-filters"])}
        </PopoverBox.Header>
        <PopoverBox.Content>
          <h5 className="font-size-11 text-muted text-uppercase">
            {t(["form:placeholder.selected-filters"])}
          </h5>
          <div>
            <TagsList.Root>
              {visualFilters &&
                visualFilters.map((tag: VisualFilterProps) => {
                  return tag ? (
                    <TagsList.Container key={tag.id}>
                      <TagsList.Item color={tag.Color}>
                        {tag.Name}
                      </TagsList.Item>
                      <TagsList.Button
                        color={tag.Color}
                        onClick={() => handleRemoveFilter(tag)}
                      >
                        <i className="bx bx-x" />
                      </TagsList.Button>
                    </TagsList.Container>
                  ) : null;
                })}
            </TagsList.Root>
          </div>
        </PopoverBox.Content>
        <PopoverBox.Content>
          <h5 className="font-size-11 text-muted text-uppercase">
            {t(["form:placeholder.select-attendant"])}
          </h5>
          <AttendantFilters
            visualFilters={visualFilters}
            handleAddFilter={handleAddFilter}
          />
        </PopoverBox.Content>
        <PopoverBox.Content>
          <h5 className="font-size-11 text-muted text-uppercase">
            {t(["form:placeholder.select-tag"])}
          </h5>
          <TagFilters
            filters={filters.Tags}
            visualFilters={visualFilters}
            handleAddFilter={handleAddFilter}
          />
        </PopoverBox.Content>
        <PopoverBox.Footer>
          <PopoverBox.FooterAction className="d-flex gap-1 col-12">
            <button
              type="button"
              className="btn btn-link"
              onClick={handleReset}
              disabled={visualFilters.length <= 0}
            >
              {t(["common:button.reset"])}
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={handleSubmit}
              disabled={!chatListFilters && visualFilters.length <= 0}
            >
              {t(["common:button.apply"])}
            </button>
          </PopoverBox.FooterAction>
        </PopoverBox.Footer>
      </PopoverBox.Root>
    </>
  );
};

export default Filters;

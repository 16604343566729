import { TemplatesProps } from "../../data/templates";

export enum TemplatesActionTypes {
  API_RESPONSE_SUCCESS = "@@templates/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@templates/API_RESPONSE_ERROR",

  GET_TEMPLATES = "@@chats/GET_TEMPLATES",
  SEND_TEMPLATE = "@@chats/SEND_TEMPLATE",
}

export interface TemplatesState {
  templates: TemplatesProps;
  isTemplatesFetched: boolean | null;
  getTemplatesLoading: boolean | null;
  isTemplatesSent: boolean | null;
}

import React from "react";
import { i18nLanguagesProps } from "../../../i18n/i18next";

interface DisplaySelectLanguageProps {
  value: string | undefined;
  options: i18nLanguagesProps[];
  onChange: (value: string) => void;
  label: string;
}
const DisplaySelectLanguage = ({
  value,
  options,
  onChange,
  label,
}: DisplaySelectLanguageProps) => {
  const onChangeSelect = (value: string) => {
    onChange(value);
  };
  return (
    <div className="d-flex align-items-center">
      <div className="flex-grow-1 overflow-hidden">
        <h5 className="font-size-13 mb-0 text-truncate">{label}</h5>
      </div>
      <div className="flex-shrink-0 ms-2">
        <select
          value={value}
          className="form-select form-select-sm"
          onChange={(e: any) => {
            onChangeSelect(e.target.value);
          }}
        >
          {(Object(options) || []).map(
            (option: i18nLanguagesProps, key: number) => (
              <option
                key={key}
                value={option.key}
                disabled={option.key === value}
              >
                {option.nativeName}
              </option>
            )
          )}
        </select>
      </div>
    </div>
  );
};

export default DisplaySelectLanguage;

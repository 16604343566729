import { HTMLAttributes, ReactNode } from "react";

interface CardMenuItemBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const CardMenuItemBody = ({
  children,
  ...rest
}: CardMenuItemBodyProps) => {
  return <div {...rest}>{children}</div>;
};

// types
import { TemplatesActionTypes, TemplatesState } from "./types";

export const INIT_STATE: TemplatesState = {
  templates: null,
  getTemplatesLoading: null,
  isTemplatesFetched: null,
  isTemplatesSent: null,
};

const Templates = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case TemplatesActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TemplatesActionTypes.GET_TEMPLATES:
          return {
            ...state,
            templates: action.payload.data,
            getTemplatesLoading: false,
            isTemplatesFetched: true,
          };
        case TemplatesActionTypes.SEND_TEMPLATE:
          return {
            ...state,
            isTemplatesSent: true,
          };

        default:
          return { ...state };
      }

    case TemplatesActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TemplatesActionTypes.GET_TEMPLATES:
          return {
            ...state,
            getTemplatesLoading: false,
            isTemplatesFetched: false,
          };
        case TemplatesActionTypes.SEND_TEMPLATE:
          return {
            ...state,
            isTemplatesSent: false,
          };

        default:
          return { ...state };
      }

    case TemplatesActionTypes.GET_TEMPLATES: {
      return {
        ...state,
        getTemplatesLoading: true,
        isTemplatesFetched: false,
      };
    }
    case TemplatesActionTypes.SEND_TEMPLATE: {
      return {
        ...state,
        isTemplatesSent: false,
      };
    }

    default:
      return { ...state };
  }
};

export default Templates;

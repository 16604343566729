import { ReactNode } from "react";
import { Popover, PopoverProps } from "reactstrap";

interface PopoverBoxRootProps extends PopoverProps {
  children: ReactNode;
}

export function PopoverBoxRoot({ children, ...rest }: PopoverBoxRootProps) {
  return <Popover {...rest}>{children}</Popover>;
}

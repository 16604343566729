import classNames from "classnames";
import { Button, ButtonProps } from "reactstrap";

interface TemplateMessageButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void | undefined;
  className?: string;
}

export function TemplateMessageButton({
  children,
  onClick,
  className,
  ...rest
}: TemplateMessageButtonProps) {
  return (
    <Button
      className={classNames(
        "col-12 btn-outline-info border-start-0 border-end-0 border-bottom-0 rounded-0 font-size-12 py-3",
        className
      )}
      color="light"
      style={{ borderColor: "#EAEAF0" }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

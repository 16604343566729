import { APIClient } from "./apiCore";
import * as url from "./urls";
import {
  GetFirestoreDocProps,
  UpdateFirestoreFieldProps,
  getFirestoreDoc,
  getFirestoreDocs,
  updateFirestoreDocField,
} from "./apiFirestore";
import { compareAsc, format, parse } from "date-fns";
import {
  CampaignBackendProps,
  CampaignProps,
  ContactCampaignProps,
  LeadBackendProps,
  LeadProps,
} from "../data/analytics";

const api = new APIClient();

const getCampaignsList = async () => {
  const params: GetFirestoreDocProps = {
    paths: [url.FIRESTORE_CAMPAIGNS_COLLECTION],
    sortBy: [{ fieldPath: "Name", directionStr: "asc" }],
  };

  const response = await getFirestoreDocs(params);

  const data: LeadProps[] = response.map((doc: CampaignBackendProps) => {
    return {
      id: doc.id,
      name: doc.Name,
      status: "Active",
    } as CampaignProps;
  });

  return data;
};

const getOverviewData = async (id: string | number) => {
  const apiCalls = {
    generalApi: url.ANALYTICS_OVERVIEW_API + url.ANALYTICS_OVERVIEW_API_GENERAL,
    monthlyApi: url.ANALYTICS_OVERVIEW_API + url.ANALYTICS_OVERVIEW_API_MONTHLY,
    topApi: url.ANALYTICS_OVERVIEW_API + url.ANALYTICS_OVERVIEW_API_TOP,
  };

  const [general, monthly, top] = await Promise.all([
    api.get(apiCalls.generalApi),
    api.get(apiCalls.monthlyApi),
    api.get(apiCalls.topApi),
  ]);

  const topEntries = Object.entries(top);
  topEntries.sort((a, b) => b[1] - a[1]);
  const formattedTop = topEntries.map(([key, value]) => ({
    title: key,
    value: value,
  }));

  return { id, general, monthly, top: formattedTop };
};

const getSelectedCampaignData = async (
  id: string | number,
  name: string,
  period: { startDate: string; endDate: string }
) => {
  const apiCalls = {
    statusApi:
      url.ANALYTICS_CAMPAIGN_API +
      "/" +
      name +
      url.ANALYTICS_CAMPAIGN_API_STATUS,
    monthlyApi:
      url.ANALYTICS_CAMPAIGN_API +
      "/" +
      name +
      url.ANALYTICS_CAMPAIGN_API_MONTHLY_LEADS,
    conclusionApi:
      url.ANALYTICS_CAMPAIGN_API +
      "/" +
      name +
      url.ANALYTICS_CAMPAIGN_API_CONCLUSION_PERCENTAGE,
    airlineApi:
      url.ANALYTICS_CAMPAIGN_API +
      "/" +
      name +
      url.ANALYTICS_CAMPAIGN_API_AIRLINE,
  };

  const data = {
    startDate: period.startDate,
    endDate: period.endDate,
  };

  const [status, monthly, conclusion, airline] = await Promise.all([
    api.create(apiCalls.statusApi, data),
    api.get(apiCalls.monthlyApi),
    api.create(apiCalls.conclusionApi, data),
    api.create(apiCalls.airlineApi, data),
  ]);

  return { id, name, period, status, monthly, conclusion, airline };
};

const getSelectedCampaignLeads = async (id: string | number) => {
  const params: GetFirestoreDocProps = {
    paths: [
      url.FIRESTORE_CAMPAIGNS_COLLECTION,
      String(id),
      url.FIRESTORE_CAMPAIGNS_CONTACTS_COLLECTION,
    ],
    sortBy: [{ fieldPath: "CreationDate", directionStr: "desc" }],
  };

  const response = await getFirestoreDocs(params);

  const data: LeadProps[] = response.map((doc: LeadBackendProps) => {
    return {
      id: doc.id,
      ...doc,
    };
  });

  return data;
};

const updateLeadWasContacted = async (
  id: string | number,
  data: { index: number; lead: LeadProps }
) => {
 
  if (!data || data.index < 0) {
    
    return Promise.reject("notifications:error.analytics.lead-status");
  }
  

  const params: UpdateFirestoreFieldProps = {
    paths: [
      url.FIRESTORE_CAMPAIGNS_COLLECTION,
      String(id),
      url.FIRESTORE_CAMPAIGNS_CONTACTS_COLLECTION,
      String(data.lead.id),
    ],
    
    data: {
      WasContacted: !data.lead.WasContacted,
    },
    
  };
  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject("notifications:error.analytics.lead-status");
    }
    resolve({
      data: {
        index: data.index,
        lead: {
          ...data.lead,
          WasContacted: !data.lead.WasContacted,
        },
      },
      message: "notifications:success.analytics.lead-status",
    });
  });
};
const updateLeadIsLead = async (
  id: string | number,
  data: { index: number; lead: LeadProps }
) => {
 
  if (!data || data.index < 0) {
    
    return Promise.reject("notifications:error.analytics.lead-status");
  }
  

  const params: UpdateFirestoreFieldProps = {
    paths: [
      url.FIRESTORE_CAMPAIGNS_COLLECTION,
      String(id),
      url.FIRESTORE_CAMPAIGNS_CONTACTS_COLLECTION,
      String(data.lead.id),
    ],
    
    data: {
      IsLead: !data.lead.IsLead,
    },
    
  };
  const response = await updateFirestoreDocField(params);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject("notifications:error.analytics.lead-status");
    }
    resolve({
      data: {
        index: data.index,
        lead: {
          ...data.lead,
          IsLead: !data.lead.IsLead,
        },
      },
      message: "notifications:success.analytics.lead-status",
    });
  });
};

const getSelectedContactCampaigns = async (
  id: string | number,
  campaigns: CampaignProps[]
) => {
  const requestArray = campaigns.map(campaign => {
    const params: GetFirestoreDocProps = {
      paths: [
        url.FIRESTORE_CAMPAIGNS_COLLECTION,
        String(campaign.id),
        url.FIRESTORE_CAMPAIGNS_CONTACTS_COLLECTION,
        String(id),
      ],
    };
    return getFirestoreDoc(params);
  });

  const response = await Promise.all(requestArray);

  const dateFormat = "dd/MM/yyyy";
  const data: ContactCampaignProps[] = response.reduce(
    (prevDoc, doc: LeadBackendProps, index) => {
      if (!doc?.CreationDate) return prevDoc;

      const CreationDate = format(doc.CreationDate.toDate(), dateFormat);
      const DateOfOccurrence = doc.CreationDate
        ? format(doc.CreationDate.toDate(), dateFormat)
        : null;

      const newDoc = {
        ...campaigns[index],
        lead: { ...doc, CreationDate, DateOfOccurrence },
      } as ContactCampaignProps;

      return [...prevDoc, newDoc];
    },
    []
  );

  data.sort((a, b) => {
    const dateA = parse(String(a.lead.CreationDate), dateFormat, new Date());
    const dateB = parse(String(b.lead.CreationDate), dateFormat, new Date());
    return compareAsc(dateB, dateA);
  });

  return data;
};

export {
  getCampaignsList,
  getOverviewData,
  getSelectedCampaignData,
  getSelectedCampaignLeads,
  updateLeadWasContacted,
  updateLeadIsLead,
  getSelectedContactCampaigns,
};

import React, { useState } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";

export enum AnalyticsMoreTypesProps {
  day,
  week,
  month,
}
interface MoreProps {
  selectedFilter: AnalyticsMoreTypesProps | undefined;
  onToggleFilter: (filter: AnalyticsMoreTypesProps) => void;
}
export const More = ({ selectedFilter, onToggleFilter }: MoreProps) => {
  const { t } = useTranslation(["common"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          className={classnames(
            "d-flex justify-content-between align-items-center",
            { "text-primary": selectedFilter === AnalyticsMoreTypesProps.day }
          )}
          to="#"
          onClick={() => onToggleFilter(AnalyticsMoreTypesProps.day)}
        >
          {t(["common:date.last-day"])}{" "}
          <i
            className={classnames("bx bx-calendar", {
              "text-muted": selectedFilter !== AnalyticsMoreTypesProps.day,
            })}
          ></i>
        </DropdownItem>
        <DropdownItem
          className={classnames(
            "d-flex justify-content-between align-items-center",
            { "text-primary": selectedFilter === AnalyticsMoreTypesProps.week }
          )}
          to="#"
          onClick={() => onToggleFilter(AnalyticsMoreTypesProps.week)}
        >
          {t(["common:date.last-week"])}{" "}
          <i
            className={classnames("bx bx-calendar", {
              "text-muted": selectedFilter !== AnalyticsMoreTypesProps.week,
            })}
          ></i>
        </DropdownItem>
        <DropdownItem
          className={classnames(
            "d-flex justify-content-between align-items-center",
            { "text-primary": selectedFilter === AnalyticsMoreTypesProps.month }
          )}
          to="#"
          onClick={() => onToggleFilter(AnalyticsMoreTypesProps.month)}
        >
          {t(["common:date.last-month"])}{" "}
          <i
            className={classnames("bx bx-calendar", {
              "text-muted": selectedFilter !== AnalyticsMoreTypesProps.month,
            })}
          ></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

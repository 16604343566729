import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRedux } from "../../../../hooks/index";
import { TagProps } from "../../../../data";
import { TagsList } from "../../../../components/Tags";
import TagsAdd from "./add";
import TagsManage from "./manage";
import { removeContactTag } from "../../../../redux/actions";

interface TagsProps {
  chatUserDetails: { id: string | number; tags: string[] };
}

const Tags = ({ chatUserDetails }: TagsProps) => {
  const { t } = useTranslation(["common", "form", "profileDetails"]);

  // global store
  const { useAppSelector, dispatch } = useRedux();

  const { tags } = useAppSelector(state => ({
    tags: state.Chats.tags,
  }));

  const contactAssignedTags =
    chatUserDetails && chatUserDetails.tags
      ? chatUserDetails.tags.map(tagId =>
          tags ? tags.filter((tag: TagProps) => tag.id === tagId)[0] : null
        )
      : null;
  const tagLimit = 3;
  const isTagLimitReached =
    contactAssignedTags && contactAssignedTags.length >= tagLimit;

  const [popoverAddIsOpen, setPopoverAddIsOpen] = useState(false);
  const [popoverManageIsOpen, setPopoverManageIsOpen] = useState(false);
  const tagsAddBtnRef = useRef<HTMLButtonElement>();
  const tagsManageBtnRef = useRef<HTMLButtonElement>();

  function handlePopoverAddIsOpen() {
    setPopoverAddIsOpen(prev => !prev);
  }

  function handlePopoverManageIsOpen() {
    setPopoverManageIsOpen(prev => !prev);
  }

  function handleRemoveTag(tag: TagProps) {
    dispatch(
      removeContactTag({
        id: chatUserDetails.id,
        tagId: tag.id,
        usedCount: tag.UsedCount,
      })
    );
  }

  return (
    <div className="profile-groups">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h5 className="font-size-11 text-muted text-uppercase">
            {t(["profileDetails:sidebar.tags"])}
          </h5>
        </div>
        <div className="flex-shrink-0 d-flex gap-1">
          <button
            id="tagsAddBtnRef"
            type="button"
            ref={tagsAddBtnRef}
            className="btn btn-sm btn-soft-primary"
            onClick={handlePopoverAddIsOpen}
            disabled={isTagLimitReached}
            hidden={isTagLimitReached}
          >
            {t(["common:button.add"])}
          </button>

          <button
            id="tagsManageBtnRef"
            type="button"
            ref={tagsManageBtnRef}
            className="btn btn-sm btn-soft-secondary"
            onClick={handlePopoverManageIsOpen}
          >
            {t(["common:button.manage"])}
          </button>
        </div>
      </div>
      <TagsList.Root className="mt-4">
        {contactAssignedTags &&
          contactAssignedTags.map((tag: TagProps) => {
            return tag ? (
              <TagsList.Container key={tag.id}>
                <TagsList.Item color={tag.Color}>{tag.Name}</TagsList.Item>
                <TagsList.Button
                  color={tag.Color}
                  onClick={() => handleRemoveTag(tag)}
                >
                  <i className="bx bx-x" />
                </TagsList.Button>
              </TagsList.Container>
            ) : null;
          })}
      </TagsList.Root>

      <TagsAdd
        isOpen={popoverAddIsOpen}
        onToggle={() => {
          setPopoverAddIsOpen(prev => !prev);
        }}
        contactId={chatUserDetails.id}
        tags={tags}
        elementRef={tagsAddBtnRef}
      />
      <TagsManage
        isOpen={popoverManageIsOpen}
        onToggle={() => {
          setPopoverManageIsOpen(prev => !prev);
        }}
        tags={tags}
        elementRef={tagsManageBtnRef}
      />
    </div>
  );
};

export default Tags;

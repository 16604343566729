import type { Timestamp } from "firebase/firestore";

export enum NotificationsStatus {
  "Unread",
  "Read",
}
export enum NotificationsType {
  "Attendant",
  "NewContact",
}

export interface NotificationsProps {
  id: string | number;
  ClientId: string | number;
  Message: string;
  Status: NotificationsStatus;
  Timestamp: string;
  Type: NotificationsType;
}

export interface NotificationsBackendProps {
  id: string | number;
  ClientId: string | number;
  Message: string;
  Status: NotificationsStatus;
  Timestamp: Timestamp;
  Type: NotificationsType;
}

export enum ProfileActionTypes {
  API_RESPONSE_SUCCESS = "@@profile/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@profile/API_RESPONSE_ERROR",

  GET_PROFILE_DETAILS = "@@profile/GET_PROFILE_DETAILS",
  UPDATE_PROFILE_DETAILS = "@@profile/UPDATE_PROFILE_DETAILS",
  UPLOAD_PROFILE_PHOTO = "@@profile/UPLOAD_PROFILE_PHOTO",
  UPLOAD_PROFILE_COVER = "@@profile/UPLOAD_PROFILE_COVER",
}
export interface ProfileState {
  profileDetails: object;
  getProfileLoading: boolean | null;
  isProfileFetched: boolean | null;
  getSettingsLoading: boolean | null;
  isSettingsFetched: boolean | null;
  isProfileDetailsUpdated: boolean | null;
  isProfileDetailsLoading: boolean | null;
  isProfilePhotoUploaded: boolean | null;
  isProfilePhotoLoading: boolean | null;
  isProfileCoverUploaded: boolean | null;
  isProfileCoverLoading: boolean | null;
}

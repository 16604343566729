import { STATUS_TYPES } from "../../constants";
import { ProfileActionTypes } from "./types";

// common success
export const profileApiResponseSuccess = (actionType: string, data: any) => ({
  type: ProfileActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const profileApiResponseError = (actionType: string, error: string) => ({
  type: ProfileActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getProfileDetails = () => ({
  type: ProfileActionTypes.GET_PROFILE_DETAILS,
});

export const updateProfileDetails = (data: {
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  description: string | null;
  location: string | null;
  status: STATUS_TYPES;
}) => ({
  type: ProfileActionTypes.UPDATE_PROFILE_DETAILS,
  payload: data,
});

export const uploadProfilePhoto = (image: File) => ({
  type: ProfileActionTypes.UPLOAD_PROFILE_PHOTO,
  payload: { image },
});

export const uploadProfileCover = (image: File) => ({
  type: ProfileActionTypes.UPLOAD_PROFILE_COVER,
  payload: { image },
});

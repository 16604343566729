import ChatInputMenuContent from "./ChatInputMenuContent";
import { ChatInputMenuItem } from "./ChatInputMenuItem";
import { ChatInputMenuItemEmoji } from "./ChatInputMenuItemEmoji";
import { ChatInputMenuItemMore } from "./ChatInputMenuItemMore";
import { ChatInputMenuItemTemplates } from "./ChatInputMenuItemTemplates";

export const ChatInputMenu = {
  Root: ChatInputMenuContent,
  Item: ChatInputMenuItem,
  ItemMore: ChatInputMenuItemMore,
  ItemEmoji: ChatInputMenuItemEmoji,
  ItemTemplates: ChatInputMenuItemTemplates,
};

import Lightbox from "react-image-lightbox";

import { MediaListItemTypes } from "../../data/myProfile";
import { TypeOfMessage } from "../../data/messages";
import { MediaItemPreviewModal } from "./MediaItemPreviewModal";
import { MediaItemPreviewAudio } from "./MediaItemPreviewAudio";
import { MediaItemPreviewVideo } from "./MediaItemPreviewVideo";

interface MediaItemPreviewProps {
  selectedItem: MediaListItemTypes;
  isOpen: boolean;
  onClose: () => void;
}

export function MediaItemPreview({
  selectedItem,
  onClose,
  isOpen,
}: MediaItemPreviewProps) {
  if (!selectedItem) return <></>;

  const itemTypes = {
    [TypeOfMessage.image]: (
      <Lightbox mainSrc={selectedItem?.url} onCloseRequest={onClose} />
    ),
    [TypeOfMessage.audio]: (
      <MediaItemPreviewModal isOpen={isOpen} onClose={onClose}>
        <MediaItemPreviewAudio
          url={selectedItem?.url}
          mimeType={selectedItem?.mimeType}
        />
      </MediaItemPreviewModal>
    ),
    [TypeOfMessage.video]: (
      <MediaItemPreviewModal isOpen={isOpen} onClose={onClose}>
        <MediaItemPreviewVideo url={selectedItem?.url} />
      </MediaItemPreviewModal>
    ),
  };

  return <>{isOpen && selectedItem && itemTypes[selectedItem.type]}</>;
}

import { ReactNode } from "react";
import classnames from "classnames";

import { Button, ButtonProps, UncontrolledTooltip } from "reactstrap";

interface ChatInputMenuItemProps extends ButtonProps {
  id?: string;
  color?: string;
  className?: string;
  show?: boolean;
  icon: string;
  children: ReactNode;
}

export const ChatInputMenuItem = ({
  id,
  color = "none",
  className = null,
  show = true,
  icon,
  children,
  ...rest
}: ChatInputMenuItemProps) => {
  return (
    show && (
      <>
        <div className="links-list-item">
          <Button
            id={id}
            type="button"
            className={classnames(
              "btn btn-link text-decoration-none btn-lg waves-effect",
              className
            )}
            color={color}
            {...rest}
          >
            <i className={classnames("bx align-middle", icon)}></i>
          </Button>
        </div>
        {id && (
          <UncontrolledTooltip target={id} placement="top">
            {children}
          </UncontrolledTooltip>
        )}
      </>
    )
  );
};

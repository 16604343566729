// types
import { ProfileActionTypes, ProfileState } from "./types";

export const INIT_STATE: ProfileState = {
  profileDetails: {},
  getProfileLoading: null,
  isProfileFetched: null,
  getSettingsLoading: null,
  isSettingsFetched: null,
  isProfileDetailsUpdated: null,
  isProfileDetailsLoading: null,
  isProfilePhotoUploaded: null,
  isProfilePhotoLoading: null,
  isProfileCoverUploaded: null,
  isProfileCoverLoading: null,
};

const Profile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ProfileActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ProfileActionTypes.GET_PROFILE_DETAILS:
          return {
            ...state,
            profileDetails: action.payload.data,
            isProfileFetched: true,
            getProfileLoading: false,
          };
        case ProfileActionTypes.UPDATE_PROFILE_DETAILS:
          return {
            ...state,
            isProfileDetailsUpdated: true,
            isProfileDetailsLoading: false,
          };
        case ProfileActionTypes.UPLOAD_PROFILE_PHOTO:
          return {
            ...state,
            isProfilePhotoUploaded: true,
            isProfilePhotoLoading: false,
          };
        case ProfileActionTypes.UPLOAD_PROFILE_COVER:
          return {
            ...state,
            isProfileCoverUploaded: true,
            isProfileCoverLoading: false,
          };
        default:
          return { ...state };
      }

    case ProfileActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ProfileActionTypes.GET_PROFILE_DETAILS:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
          };
        case ProfileActionTypes.UPDATE_PROFILE_DETAILS:
          return {
            ...state,
            isProfileDetailsUpdated: false,
            isProfileDetailsLoading: false,
          };
        case ProfileActionTypes.UPLOAD_PROFILE_PHOTO:
          return {
            ...state,
            isProfilePhotoUploaded: false,
            isProfilePhotoLoading: false,
          };
        case ProfileActionTypes.UPLOAD_PROFILE_COVER:
          return {
            ...state,
            isProfileCoverUploaded: false,
            isProfileCoverLoading: false,
          };
        default:
          return { ...state };
      }

    case ProfileActionTypes.GET_PROFILE_DETAILS: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
      };
    }
    case ProfileActionTypes.UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        isProfileDetailsUpdated: false,
        isProfileDetailsLoading: true,
      };
    case ProfileActionTypes.UPLOAD_PROFILE_PHOTO:
      return {
        ...state,
        isProfilePhotoUploaded: false,
        isProfilePhotoLoading: true,
      };
    case ProfileActionTypes.UPLOAD_PROFILE_COVER:
      return {
        ...state,
        isProfileCoverUploaded: false,
        isProfileCoverLoading: true,
      };
    default:
      return { ...state };
  }
};

export default Profile;

import { KanbanComment } from "../../data/kanban";
import { KanbanActionTypes } from "./types";

// common success
export const kanbanApiResponseSuccess = (actionType: string, data: any) => ({
  type: KanbanActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const kanbanApiResponseError = (actionType: string, error: string) => ({
  type: KanbanActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getBoards = () => ({
  type: KanbanActionTypes.GET_BOARDS,
});

export const createBoard = () => ({
  type: KanbanActionTypes.CREATE_BOARD,
});

export const getComments = (boardId:string, contactId: string) => ({
  type: KanbanActionTypes.GET_COMMENTS,
  payload: {boardId, contactId},
});

export const createComment = (comment: KanbanComment, boardId:string, contactId: string) => ({
  type: KanbanActionTypes.CREATE_COMMENT,
  payload: {comment, boardId, contactId},
});
import * as url from "./urls";
import {
  GetFirestoreDocProps,
  UpdateFirestoreFieldProps,
  getAuthUserStorageData,
  getFirestoreDocs,
  getFirestoreDocsRTLegacy,
  updateFirestoreDocField,
} from "./apiFirestore";
import { format } from "date-fns";
import {
  NotificationsBackendProps,
  NotificationsProps,
  NotificationsStatus,
} from "../data/notifications";
import { eventChannel } from "redux-saga";

const getNotifications = async () => {
  var user = await getAuthUserStorageData();
  return eventChannel<NotificationsProps[]>(emit => {
    if (!user.tenantId) return () => {};
    const params: GetFirestoreDocProps = {
      paths: [user.tenantId, url.FIRESTORE_NOTIFICATIONS_COLLECTION],
      filter: [{ fieldPath: "Status", opStr: "==", value: 0 }],
    };

    const handleSnapshot = (snapshot: any) => {
      const data: NotificationsProps[] = snapshot.map(
        (doc: NotificationsBackendProps) => {
          const Timestamp = format(doc.Timestamp.toDate(), "dd/MM/yyyy hh:mm");

          return {
            ...doc,
            Timestamp,
          } as NotificationsProps;
        }
      );
      emit(data);
    };

    const unsubscribe = getFirestoreDocsRTLegacy(params, handleSnapshot);
    return unsubscribe;
  });
};

const getContactNotifications = async (id: string | number) => {
  const params: GetFirestoreDocProps = {
    paths: [url.FIRESTORE_NOTIFICATIONS_COLLECTION, String(id)],
  };

  const response = await getFirestoreDocs(params);

  const data: NotificationsProps[] = response.map(
    (doc: NotificationsBackendProps) => {
      const Timestamp = format(doc.Timestamp.toDate(), "dd/MM/yyyy hh:mm");

      return {
        ...doc,
        Timestamp,
      } as NotificationsProps;
    }
  );

  return data;
};

const updateNotificationStatus = async (
  notifications: NotificationsProps[]
) => {
  if (!notifications || notifications.length <= 0) {
    return Promise.reject("notifications:error.notifications.invalid");
  }

  const requests = notifications.map(notification => {
    const params: UpdateFirestoreFieldProps = {
      paths: [url.FIRESTORE_NOTIFICATIONS_COLLECTION, String(notification.id)],
      data: {
        Status: NotificationsStatus.Read,
      },
    };

    return updateFirestoreDocField(params);
  });

  await Promise.all(requests);
  // .then(res => {
  //   console.log(res);
  // })
  // .catch(err => {
  //   console.log(err);
  // });
};

export { getNotifications, getContactNotifications, updateNotificationStatus };

import React, { useEffect, useState } from "react";

//translations
import { useTranslation } from "react-i18next";

// import classnames from "classnames";

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

import { useRedux } from "../hooks/index";
import {
  getChatUserDetails,
  updateContact,
  getContacts,
} from "../redux/actions";

export interface DataTypes {
  fullName: string;
}
interface EditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  contactId: number | string;
}
const EditContactModal = ({
  isOpen,
  onClose,
  contactId,
}: EditProfileModalProps) => {
  const { t } = useTranslation(["common", "chats", "form"]);

  const { dispatch, useAppSelector } = useRedux();
  const { chatUserDetails } = useAppSelector(state => ({
    chatUserDetails: state.Chats.chatUserDetails,
  }));

  const [data, setData] = useState<DataTypes>({ fullName: "" } as DataTypes);
  useEffect(() => {
    dispatch(getChatUserDetails(contactId));
  }, [contactId, dispatch]);

  useEffect(() => {
    const fullName = `${chatUserDetails.firstName} ${chatUserDetails.lastName}`;
    setData({ fullName });
  }, [chatUserDetails]);

  const [valid, setValid] = useState<boolean>(false);
  const validateField = (value: string | boolean | null) => {
    if (data === null || data.fullName.length < 3) {
      return false;
    }
    return value !== chatUserDetails.firstName ? true : false;
  };

  const onChangeData = (value: string | null) => {
    setData({ fullName: value });
    setValid(validateField(value));
  };

  function handleSubmit() {
    if (!valid) return;
    dispatch(updateContact(contactId, data.fullName));
    dispatch(getChatUserDetails(contactId));
    dispatch(getContacts());
    onClose();
  }

  /*
    drop down state
    */
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen(!dropdownOpen);

  return chatUserDetails.id ? (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        {t(["common:button.edit-contact"])}
      </ModalHeader>
      <ModalBody className="p-4">
        {/* {(getProfileLoading && !isProfileFetched) ||
        (isProfileDetailsLoading && !isProfileDetailsUpdated) ? (
          <Loader />
        ) : null} */}
        <Form>
          <div className="mb-4">
            <Label htmlFor="EditProfileModalTitle-input" className="form-label">
              {t(["common:profile.name"])}
            </Label>
            <Input
              type="text"
              className="form-control"
              id="EditProfileModalTitle-input"
              placeholder={t(["form:placeholder.title"])}
              value={data.fullName ? data.fullName : ""}
              onChange={(e: any) => {
                onChangeData(e.target.value);
              }}
            />
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          {t(["common:button.close"])}
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={!valid ? true : false}
          onClick={() => handleSubmit()}
        >
          {t(["common:button.save"])}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;
};

export default EditContactModal;

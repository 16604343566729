import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// components
import AppSimpleBar, {
  SimpleBarReactProps,
} from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
// import Day from "./Day";

// interface
import { MessagesTypes } from "../../../data/messages";
import ForwardModal from "../../../components/ForwardModal";

// actions
import { forwardMessage, deleteImage } from "../../../redux/actions";

import { isSameDay } from "date-fns";
interface ConversationProps {
  chatUserConversations: any;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isChannel: boolean;
}
const Conversation = ({
  chatUserDetails,
  chatUserConversations,
  onDelete,
  onSetReplyData,
  isChannel,
}: ConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { getUserConversationsLoading, isMessageForwarded } = useAppSelector(
    useMemo(
      () => (state: any) => ({
        getUserConversationsLoading: state.Chats.getUserConversationsLoading,
        isMessageForwarded: state.Chats.isMessageForwarded,
      }),
      []
    )
  );

  const messagesContainerRef = useRef<any>(null);
  const messagesListRef = useRef<any>(null);

  const messages = useMemo<any>(() => {
    return chatUserConversations.messages &&
      chatUserConversations.messages.length
      ? chatUserConversations.messages
      : [];
  }, [chatUserConversations.messages]);

  const scrollElement = useCallback(() => {
    if (
      messagesContainerRef &&
      messagesContainerRef.current &&
      messagesListRef &&
      messagesListRef.current
    ) {
      const messagesContainer = messagesContainerRef.current.getScrollElement();
      const messagesList = messagesListRef.current;

      if (messagesContainer && messagesList) {
        if (messagesList.scrollHeight === 0) {
          setTimeout(() => {
            messagesContainer.scrollTo({
              top: messagesList.scrollHeight,
              behavior: "smooth",
            });
          }, 500);
        } else {
          messagesContainer.scrollTo({
            top: messagesList.scrollHeight,
            behavior: "instant",
          });
        }
      } else {
      }
    }
  }, [messagesContainerRef, messagesListRef]);

  useEffect(() => {
    if (messagesContainerRef && messagesContainerRef.current && messages.length)
      scrollElement();
  }, [messages, scrollElement]);

  useEffect(() => {
    if (
      messagesContainerRef &&
      messagesContainerRef.current &&
      messagesListRef &&
      messagesListRef.current
    ) {
      messagesContainerRef.current.recalculate();
    }
  }, []);

  /*
  forward message
  */
  const [forwardData, setForwardData] = useState<
    null | MessagesTypes | undefined
  >();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);
  const onOpenForward = (message: MessagesTypes) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };
  useEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  /*
  image delete
  */
  const onDeleteImage = (
    messageId: string | number,
    imageId: string | number
  ) => {
    dispatch(deleteImage(chatUserDetails.id, messageId, imageId));
  };
  return (
    <AppSimpleBar
      scrollRef={messagesContainerRef}
      className="chat-conversation p-3 p-lg-4 position-relative"
    >
      {getUserConversationsLoading ? (
        <Loader />
      ) : (
        <ul
          className="list-unstyled chat-conversation-list"
          id="chat-conversation-list"
          ref={messagesListRef}
        >
          {(messages || []).map((message: MessagesTypes, key: number) => {
            const isFromMe = message.meta.sender !== chatUserDetails.id;
            const previousDate = new Date(messages[key - 1]?.time);
            const date = new Date(message.time);
            return (
              <Message
                message={message}
                key={key}
                chatUserDetails={chatUserDetails}
                onDelete={onDelete}
                onSetReplyData={onSetReplyData}
                isFromMe={isFromMe}
                isSameDay={isSameDay(previousDate, date)}
                showAvatar={
                  message.meta.sender !== messages[key + 1]?.meta.sender
                }
                onOpenForward={onOpenForward}
                isChannel={isChannel}
                onDeleteImage={onDeleteImage}
              />
            );
          })}
          {/*  <Day /> */}
        </ul>
      )}

      {isOpenForward && (
        <ForwardModal
          isOpen={isOpenForward}
          onClose={onCloseForward}
          forwardData={forwardData}
          chatUserDetails={chatUserDetails}
          onForward={onForwardMessage}
        />
      )}
    </AppSimpleBar>
  );
};

export default Conversation;

import profilePlaceholder from "../assets/images/users/profile-placeholder.png";
import pattern1 from "../assets/images/pattern-1.jpg";
import { APIClient } from "./apiCore";
import * as url from "./urls";
import {
  UpdateTenantDataProps,
  getAuthUserStorageData,
  updateTenantData,
} from "./apiFirestore";

import {
  ProfileDetailsTypes,
  ProfileDetailsBackend,
  BasicDetailsTypes,
} from "../data";
import { ConnectedPageProps, SettingsTypes } from "../data/settings";
import { DISPLAY_TYPES } from "../constants";
import { getFirstLastName } from "../utils";
import { createStorageFile } from "./apiStorage";

const api = new APIClient();

const getProfileDetails = async () => {
  const user = await getAuthUserStorageData();

  if (!user || !user.userData || !user.tenantData)
    return Promise.reject([
      "notifications:error.profile.proflie/firebase-auth",
    ]);

  const data = user.userData as ProfileDetailsBackend;

  const { firstName, lastName } = getFirstLastName(data.FullName);

  var coverImage = data.CoverImageUrl;
  var profile = data.AvatarUrl;

  if (!data.CoverImageUrl) {
    coverImage = pattern1;
  }

  if (!data.AvatarUrl) {
    profile = profilePlaceholder;
  }

  return {
    basicDetails: {
      id: data.id,
      firstName,
      lastName,
      title: data.Title,
      description: data.Description,
      fullName: data.FullName,
      email: data.Email,
      location: data.Location ? data.Location : null,
      avatar: profile,
      coverImage: coverImage,
      status: data.Status,
      roles: data.Roles,
      tenantId: data.TenantId,
    },
    media: {
      total: 0,
      list: [],
    },
    attachedFiles: {
      total: 0,
      list: [],
    },
  } as ProfileDetailsTypes;
};

const getSettings = async () => {
  const user = await getAuthUserStorageData();
  if (!user || !user.tenantData)
    return Promise.reject([
      "notifications:error.profile.proflie/firebase-auth",
    ]);

  var { basicDetails } = await getProfileDetails();
  var tenantDetails = user.tenantData;

  return {
    basicDetails: {
      ...basicDetails,
      coverImage: basicDetails.coverImage,
      profile: basicDetails.avatar,
    },
    theme: {
      // color: "bgcolor-radio1",
      image: "bgimg-radio5",
    },
    privacy: {
      displayprofilePhoto: "selected",
      displayLastSeen: true,
      displayStatus: DISPLAY_TYPES.EVERYONE,
      readReceipts: true,
      displayGroups: DISPLAY_TYPES.EVERYONE,
    },
    security: {
      securityNotification: false,
    },
    status: basicDetails.status,
    urlApi: tenantDetails.UrlApi,
  } as SettingsTypes;
};

const updateSettings = (field: string, value: any) => {
  return api.update(url.UPDATE_ETTINGS, {
    field: field,
    value: value,
  });
};

const updateProfileDetails = async (data: BasicDetailsTypes) => {
  const params: UpdateTenantDataProps = {
    data: {
      FullName: data.firstName + " " + data.lastName,
      Title: data.title,
      Description: data.description,
      Location: data.location,
      Status: data.status,
    },
  };

  const response = await updateTenantData(params);
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response.error.message);
    }

    if (response.ok) {
      resolve(["notifications:success.profile.profile/update-data"]);
    } else {
      resolve(["notifications:error.profile.profile/update-data"]);
    }
  });
};

const updateStatus = async (status: string) => {
  const params: UpdateTenantDataProps = {
    data: {
      Status: status,
    },
  };

  const response = await updateTenantData(params);
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response.error.message);
    }

    if (response.ok) {
      resolve(["notifications:success.profile.profile/update-status"]);
    } else {
      resolve(["notifications:error.profile.profile/update-status"]);
    }
  });
};

const uploadProfilePhoto = async (image: File) => {
  const imageType = image.name.split(".");
  const customFileName = `profile.${imageType[imageType.length - 1]}`;

  const response = await createStorageFile({
    paths: "images",
    customFileName,
    file: image,
  });

  const params: UpdateTenantDataProps = {
    data: {
      AvatarUrl: response,
    },
  };

  const responseFirestore = await updateTenantData(params);
  return new Promise((resolve, reject) => {
    if (responseFirestore.error) {
      reject(response.error.message);
    }

    if (responseFirestore.ok) {
      resolve(["notifications:success.profile.profile/update-profile-picture"]);
    } else {
      resolve(["notifications:error.profile.profile/update-profile-picture"]);
    }
  });
};

const uploadProfileCover = async (image: File) => {
  const imageType = image.name.split(".");
  const customFileName = `cover.${imageType[imageType.length - 1]}`;

  const response = await createStorageFile({
    paths: "images",
    customFileName,
    file: image,
  });

  const params: UpdateTenantDataProps = {
    data: {
      CoverImageUrl: response,
    },
  };

  const responseFirestore = await updateTenantData(params);
  return new Promise((resolve, reject) => {
    if (responseFirestore.error) {
      reject(response.error.message);
    }

    if (responseFirestore.ok) {
      resolve(["notifications:success.profile.profile/update-profile-cover"]);
    } else {
      resolve(["notifications:error.profile.profile/update-profile-cover"]);
    }
  });
};

const getConnectedPages = async () => {
  /**
   * !!! WAITING - API ACCEPTANCE
   */
  return [
    {
      pageId: 0,
      pageType: "Facebook",
      data: {
        about: "Software Company",
        followers_count: 4,
        fan_count: 1,
        link: "https://www.facebook.com/106452002374963",
        website: "http://www.codebe.dev/",
        picture: {
          data: {
            url: "https://scontent.fccm3-1.fna.fbcdn.net/v/t39.30808-1/330285346_1214274569478257_5886642429924645922_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=4da83f&_nc_ohc=s0Uwclv-O9sAX-A97V8&_nc_ht=scontent.fccm3-1.fna&edm=AJdBtusEAAAA&oh=00_AfC00OM8BOfunb55KTLBfNohsFsZND6DJN_ut4a7JrKEqA&oe=6574A914",
          },
        },
        name: "CodeBe",
        id: "106452002374963",
      },
    },
    {
      pageId: 1,
      pageType: "Instagram",
      data: {
        followers_count: 19,
        follows_count: 5,
        biography: "💻 Software Company",
        profile_picture_url:
          "https://scontent.fccm3-1.fna.fbcdn.net/v/t51.2885-15/331000336_1870594453323245_2767092779013127232_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=7d201b&_nc_ohc=jhDTf4N1FVkAX_NrdNJ&_nc_oc=AQnFAJUGS38j3e3_RNL860p7zzuD_n76fvvQY1hyz6QlFSpmnXEDIoJFFhCDeIN6zAx_jf2_xWPA3zHoz8kyQx1m&_nc_ht=scontent.fccm3-1.fna&edm=AL-3X8kEAAAA&oh=00_AfAs0B8_ROhkJo7TqHBaYRIhHHUfzFFG6vr9-xDYMMufRA&oe=6574D009",
        media_count: 0,
        username: "codebe.dev",
        website: "http://www.codebe.dev",
        name: "CodeBe",
        id: "17841458493280798",
      },
    },
  ] as ConnectedPageProps[];
};

export {
  getProfileDetails,
  getSettings,
  updateSettings,
  updateProfileDetails,
  updateStatus,
  uploadProfilePhoto,
  uploadProfileCover,
  getConnectedPages,
};

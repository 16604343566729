import { APIClient } from "./apiCore";
import * as url from "./urls";
const api = new APIClient();

const getTemplates = async () => {
  return await api.get(url.GET_TEMPLATES).catch(error => {
    return Promise.reject("notifications:error.templates.load");
  });
};

const sendTemplate = async (data: {
  recipientPhoneNumber: string;
  templateName: string;
  language: string;
  attendantName: string;
  type: number;
}) => {
  return await api
    .create(url.SEND_TEMPLATE, data)
    .then((response: any) => {
      return Promise.resolve("notifications:success.templates.send");
    })
    .catch(error => {
      return Promise.reject("notifications:error.templates.send");
    });
};

export { getTemplates, sendTemplate };

import React, { useEffect, useState, useMemo } from "react";

//translations
import { useTranslation } from "react-i18next";

import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  // Alert,
  // UncontrolledTooltip,
  Badge,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

// hooks
import { useRedux } from "../../../hooks/index";

// components
import AudioCallModal from "../../../components/AudioCallModal";
import VideoCallModal from "../../../components/VideoCallModal";
import AddPinnedTabModal from "../../../components/AddPinnedTabModal";

// interface
import { PinTypes } from "../../../data/chat";

// actions
import {
  changeSelectedCampaign,
  changeSelectedChat,
  changeTab,
} from "../../../redux/actions";

// constants
import { STATUS_TYPES, TABS } from "../../../constants";

import AvatarName from "../../../components/AvatarName";
import { CampaignProps, ContactCampaignProps } from "../../../data/analytics";
import { AttendantProps } from "../../../data/attendants";
import AssignAttendant from "./AssignAttendant";
import { TagsList } from "../../../components/Tags";
import { TagProps } from "../../../data";

interface ProfileImageProps {
  chatUserDetails: any;
  onCloseConversation: () => any;
  onOpenUserDetails: () => any;
  isChannel: boolean;
}
const ProfileImage = ({
  chatUserDetails,
  onCloseConversation,
  onOpenUserDetails,
  isChannel,
}: ProfileImageProps) => {
  const { t } = useTranslation(["common"]);

  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    selectedContactCampaigns,
    getSelectedContactCampaignsLoading,
    attendants,
    tags,
  } = useAppSelector(
    useMemo(() => (state) => ({
      selectedContactCampaigns: state.Analytics.selectedContactCampaigns,
      getSelectedContactCampaignsLoading:
        state.Analytics.getSelectedContactCampaignsLoading,
      attendants: state.Attendants.attendants,
      tags: state.Chats.tags,
    }), [])
  );


  const fullName = !isChannel
    ? chatUserDetails.firstName
      ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
      : "-"
    : chatUserDetails.name;

  const isOnline =
    chatUserDetails.status && chatUserDetails.status === STATUS_TYPES.ACTIVE;

  // const members = (chatUserDetails.members || []).length;

  const assignedAttendant: AttendantProps | null =
    chatUserDetails.assignedAttendant &&
    chatUserDetails.assignedAttendant.length
      ? attendants.filter(
          (attendant: AttendantProps) =>
            attendant.id === chatUserDetails.assignedAttendant
        )[0]
      : null;

  const [lastContactCampaign, setLastContactCampaign] =
    useState<ContactCampaignProps>();

  const contactAssignedTags =
    chatUserDetails && chatUserDetails.tags
      ? chatUserDetails.tags.map(tagId =>
          tags ? tags.filter((tag: TagProps) => tag.id === tagId)[0] : null
        )
      : null;

  useEffect(() => {
    if (
      !getSelectedContactCampaignsLoading &&
      selectedContactCampaigns?.length
    ) {
      setLastContactCampaign(selectedContactCampaigns[0]);
      return;
    }
    setLastContactCampaign(undefined);
  }, [selectedContactCampaigns, getSelectedContactCampaignsLoading]);

  const onSelectChat = (campaign: CampaignProps) => {
    dispatch(changeSelectedChat(null));
    dispatch(changeSelectedCampaign(campaign));
    dispatch(changeTab(TABS.ANALYTICS));
  };

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 d-block d-lg-none me-2">
        <Link
          to="#"
          onClick={onCloseConversation}
          className="user-chat-remove text-muted font-size-24 p-2"
        >
          <i className="bx bx-chevron-left align-middle"></i>
        </Link>
      </div>
      <div className="flex-grow-1 overflow-hidden">
        <div className="d-flex flex-column flex-sm-row">
          <div
            className={classnames(
              "flex-shrink-0",
              "chat-user-img",
              "align-self-start",
              "me-3",
              "ms-0",
              { online: isOnline }
            )}
          >
            <AvatarName
              className="avatar-lg"
              user={chatUserDetails}
              showPlatform
            />
          </div>
          <div className="flex-grow-1 overflow-hidden mt-2">
            <h6 className="text-truncate mb-0 font-size-18">
              <Link
                to="#"
                onClick={onOpenUserDetails}
                className="user-profile-show text-reset"
              >
                {fullName}
              </Link>
            </h6>
            <AssignAttendant attendant={assignedAttendant} />
            <div className="text-truncate text-muted mb-1">
              <div
                className="d-flex flex-sm-row gap-1 gap-sm-1 flex-wrap"
                style={{ minHeight: 15 }}
              >
                {contactAssignedTags ? (
                  <TagsList.Root>
                    {contactAssignedTags.length
                      ? contactAssignedTags.map((tag: TagProps) => {
                          return tag ? (
                            <TagsList.Container>
                              <TagsList.Item color={tag.Color}>
                                {tag.Name}
                              </TagsList.Item>
                            </TagsList.Container>
                          ) : null;
                        })
                      : null}
                  </TagsList.Root>
                ) : null}
              </div>
            </div>
            <div className="text-truncate text-muted mb-1">
              <div
                className="d-flex flex-sm-row gap-1 gap-sm-1 flex-wrap"
                style={{ minHeight: 15 }}
              >
                {getSelectedContactCampaignsLoading ? (
                  <Spinner color="primary" children="" size="sm" />
                ) : (
                  <TagsList.Root>
                    {lastContactCampaign ? (
                      <>
                        <TagsList.Container>
                          <TagsList.Item
                            className="btn-soft-secondary text-capitalize cursor-pointer"
                            onClick={() =>
                              onSelectChat({
                                id: lastContactCampaign.id,
                                name: lastContactCampaign.name,
                                status: lastContactCampaign.status,
                              })
                            }
                          >
                            {lastContactCampaign.name}
                          </TagsList.Item>
                        </TagsList.Container>
                        <TagsList.Container>
                          <TagsList.Item
                            className={classnames({
                              "bg-soft-primary text-primary":
                                lastContactCampaign.lead.WasContacted,
                              "bg-soft-danger text-danger":
                                !lastContactCampaign.lead.WasContacted,
                            })}
                          >
                            {lastContactCampaign.lead.WasContacted
                              ? t(["common:options.contacted"])
                              : t(["common:options.not-contacted"])}
                          </TagsList.Item>
                          <Badge></Badge>
                        </TagsList.Container>
                        <TagsList.Container>
                          <TagsList.Item
                            className={classnames({
                              "bg-soft-primary text-primary":
                                lastContactCampaign.lead.IsLead,
                              "bg-soft-danger text-danger":
                                !lastContactCampaign.lead.IsLead,
                            })}
                          >
                            {lastContactCampaign.lead.IsLead
                              ? t(["common:options.lead_one"])
                              : t(["common:options.not-lead"])}
                          </TagsList.Item>
                        </TagsList.Container>
                      </>
                    ) : null}
                  </TagsList.Root>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = () => {
  const { t } = useTranslation(["form"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        color="none"
        className="btn nav-btn dropdown-toggle"
        type="button"
      >
        <i className="bx bx-search"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
        <div className="search-box p-2">
          <Input
            type="text"
            className="form-control"
            placeholder={t(["form:placeholder.search-conversation"])}
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
interface MoreProps {
  onOpenAudio: () => void;
  onOpenVideo: () => void;
  onDelete: () => void;
  isArchive: boolean;
  onToggleArchive: () => void;
  onOpenUserDetails: () => void;
}
const More = ({
  onOpenUserDetails,
  // onOpenAudio,
  // onOpenVideo,
  onDelete,
  isArchive,
  onToggleArchive,
}: MoreProps) => {
  const { t } = useTranslation(["common", "profile"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none user-profile-show"
          to="#"
          onClick={onOpenUserDetails}
        >
          {t(["profile:view-profile"])}{" "}
          <i className="bx bx-user text-muted"></i>
        </DropdownItem>
        {/* <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none"
          to="#"
          onClick={onOpenAudio}
        >
          {t(["common:medias.audio_one"])}{" "}
          <i className="bx bxs-phone-call text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none"
          to="#"
          onClick={onOpenVideo}
        >
          {t(["common:medias.video_one"])}{" "}
          <i className="bx bx-video text-muted"></i>
        </DropdownItem> */}
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
          onClick={onToggleArchive}
        >
          {isArchive ? (
            <>
              {t(["common:button.un-archive"])}{" "}
              <i className="bx bx-archive-out text-muted"></i>
            </>
          ) : (
            <>
              {t(["common:button.archive"])}{" "}
              <i className="bx bx-archive text-muted"></i>
            </>
          )}
        </DropdownItem>
        {/* <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
        >
          {t(["common:options.muted"])}{" "}
          <i className="bx bx-microphone-off text-muted"></i>
        </DropdownItem> */}

        {/* DELETE ACTION FOR CONVERSATION */}
        {/* <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
          onClick={onDelete}
        >
          {t(["common:button.delete"])}{" "}
          <i className="bx bx-trash text-muted"></i>
        </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};

interface PinnedAlertProps {
  onOpenPinnedTab: () => void;
}
const PinnedAlert = ({ onOpenPinnedTab }: PinnedAlertProps) => {
  // const [visible, setVisible] = useState(true);

  // const onDismiss = () => setVisible(false);

  return (
    <></>
    // <Alert
    //   color="warning"
    //   isOpen={visible}
    //   toggle={onDismiss}
    //   className="topbar-bookmark p-1 px-3 px-lg-4 pe-lg-5 pe-5 alert-dismiss-custom"
    //   role="alert"
    // >
    //   <div className="d-flex align-items-start bookmark-tabs">
    //     <div className="tab-list-link">
    //       <Link to="#" className="tab-links" onClick={onOpenPinnedTab}>
    //         <i className="ri-pushpin-fill align-middle me-1"></i> 10 Pinned
    //       </Link>
    //     </div>
    //     <div id="add-bookmark">
    //       <Link to="#" className="tab-links border-0 px-3">
    //         <i className="ri-add-fill align-middle"></i>
    //       </Link>
    //     </div>
    //     <UncontrolledTooltip target="add-bookmark" placement="bottom">
    //       Add Bookmark
    //     </UncontrolledTooltip>
    //   </div>
    // </Alert>
  );
};
interface UserHeadProps {
  chatUserDetails: any;
  pinnedTabs: Array<PinTypes>;
  onOpenUserDetails: () => void;
  onDelete: () => void;
  isChannel: boolean;
  onToggleArchive: () => void;
}
const UserHead = ({
  chatUserDetails,
  pinnedTabs,
  onOpenUserDetails,
  onDelete,
  isChannel,
  onToggleArchive,
}: UserHeadProps) => {
  // global store
  const { dispatch } = useRedux();
  /*
    video call modal
    */
  const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
  const onOpenVideo = () => {
    setIsOpenVideoModal(true);
  };
  const onCloseVideo = () => {
    setIsOpenVideoModal(false);
  };

  /*
        audio call modal
    */
  const [isOpenAudioModal, setIsOpenAudioModal] = useState<boolean>(false);
  const onOpenAudio = () => {
    setIsOpenAudioModal(true);
  };
  const onCloseAudio = () => {
    setIsOpenAudioModal(false);
  };

  /*
  pinned tab modal
  */
  const [isOpenPinnedTabModal, setIsOpenPinnedTabModal] =
    useState<boolean>(false);
  const onOpenPinnedTab = () => {
    setIsOpenPinnedTabModal(true);
  };
  const onClosePinnedTab = () => {
    setIsOpenPinnedTabModal(false);
  };

  /*
  mobile menu chat conversation close
  */
  const onCloseConversation = () => {
    dispatch(changeSelectedChat(null));
  };

  return (
    <div className="p-3 p-lg-4 user-chat-topbar">
      <Row className="align-items-center">
        <Col className="col-10 col-sm-8">
          <ProfileImage
            chatUserDetails={chatUserDetails}
            onCloseConversation={onCloseConversation}
            onOpenUserDetails={onOpenUserDetails}
            isChannel={isChannel}
          />
        </Col>
        <Col className="col-2 col-sm-4">
          <ul className="list-inline user-chat-nav text-end mb-0 d-flex flex-column d-sm-block">
            <li className="list-inline-item">
              <Search />
            </li>

            {
              /**
               * Setting all conversations to channel = true
               * When isChannel = true -> will disable make call and videos options
               */
              // !isChannel && (
              //   <>
              //     <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
              //       <Button
              //         type="button"
              //         color="none"
              //         className="btn nav-btn"
              //         onClick={onOpenAudio}
              //       >
              //         <i className="bx bxs-phone-call"></i>
              //       </Button>
              //     </li>
              //     <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
              //       <Button
              //         type="button"
              //         color="none"
              //         className="btn nav-btn"
              //         onClick={onOpenVideo}
              //       >
              //         <i className="bx bx-video"></i>
              //       </Button>
              //     </li>
              //   </>
              // )
            }

            <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
              <button
                onClick={onOpenUserDetails}
                type="button"
                className="btn nav-btn user-profile-show"
              >
                <i className="bx bxs-info-circle"></i>
              </button>
            </li>

            <li className="list-inline-item">
              <More
                onOpenAudio={onOpenAudio}
                onOpenVideo={onOpenVideo}
                onDelete={onDelete}
                isArchive={chatUserDetails.isArchived}
                onToggleArchive={onToggleArchive}
                onOpenUserDetails={onOpenUserDetails}
              />
            </li>
          </ul>
        </Col>
      </Row>
      <PinnedAlert onOpenPinnedTab={onOpenPinnedTab} />
      {isOpenAudioModal && (
        <AudioCallModal
          isOpen={isOpenAudioModal}
          onClose={onCloseAudio}
          user={chatUserDetails}
        />
      )}
      {isOpenVideoModal && (
        <VideoCallModal
          isOpen={isOpenVideoModal}
          onClose={onCloseVideo}
          user={chatUserDetails}
        />
      )}
      {isOpenPinnedTabModal && (
        <AddPinnedTabModal
          isOpen={isOpenPinnedTabModal}
          onClose={onClosePinnedTab}
          pinnedTabs={pinnedTabs}
        />
      )}
    </div>
  );
};

export default UserHead;
